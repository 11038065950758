import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetEmailTemplatesQuery } from '../../../store/api/email-template-api';
import { useGetCompanyQuery, useLazyGetSurveyPayloadQuery } from '../../../store';
import { useFailedActionSnackbar } from '../../CoreLib/library';

export const useStepRelatedDataLoader = (surveyAliasShort: string) => {
    const { id: companyId, surveyId: existingSurveyId } = useParams();
    const { data: emailTemplates, isError: isErrorLoadingEmailTemplates } = useGetEmailTemplatesQuery({});
    useFailedActionSnackbar('retrieve', 'email templates', isErrorLoadingEmailTemplates);
    const { data: surveyCompany } = useGetCompanyQuery(companyId || '');
    const [getSurveyPayload, { data: existingSurvey, isLoading: isExistingSurveyPayloadLoading, isError: isGetSurveyPayloadError }] = useLazyGetSurveyPayloadQuery();
    useFailedActionSnackbar('retrieve', `${surveyAliasShort.toLowerCase()} details`, isGetSurveyPayloadError);

    useEffect(() => {
        if (!existingSurveyId || isGetSurveyPayloadError || isExistingSurveyPayloadLoading) {
            return;
        }
        getSurveyPayload(existingSurveyId);
    }, [existingSurveyId, isGetSurveyPayloadError, isExistingSurveyPayloadLoading, getSurveyPayload]);

    return {
        emailTemplates: emailTemplates?.pageResults ?? [],
        surveyCompany,
        existingSurvey
    }
}