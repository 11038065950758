export const downloadFile = (downloadLink: string, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = downloadLink;
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};
