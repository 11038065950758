import { AddCircle, Delete, Email, FilterList, Send, ThumbUp } from '@mui/icons-material';
import { Alert, Divider, FormControlLabel, Grid, Paper, Switch, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, useCallback, useContext, useState } from 'react';
import { CompanyDto, QueryResultsDto, ScheduleDto } from '../../../dtos';
import { IconButtonWithTooltip } from '../../CommonComponents/IconButtonWithTooltip';
import { DataTableColumn, INavBreadcrumbLink, NavBreadcrumbs, PaginatedDataTable, StandardSearchInput } from '../../CoreLib/library';
import { DeleteScheduleModal } from '../CompanyForm/DeleteScheduleModal';
import { SelectCompanyModal } from '../CompanyForm/SelectCompanyModal';
import { FilterScheduleDialog } from '../../FilterDialogs/Schedules/FilterScheduleDialog';
import { ScheduleTableContext } from './ScheduleTableContext';
import { ScheduleTableFilterChips } from './ScheduleTableFilterChips';
import { ScheduleTableMenu } from './ScheduleTableMenu';
import { useScheduleTableActionConditions, useScheduleTableButtonHandlers } from './utils';
import { DataFilterContext } from '../../../Contexts';

export interface ISchedulesTableProps {
    company?: CompanyDto;
    schedules: QueryResultsDto<ScheduleDto>;
    isLoading: boolean;
    refetch: () => void;
    underTableContent?: JSX.Element;
    tableColumns: DataTableColumn<ScheduleDto>[];
    title: string;
    icon: JSX.Element;
    breadcrumbs?: INavBreadcrumbLink[];
    editRoute: string;
    detailsRoute: string;
    hideSearch?: boolean;
    hideBorders?: boolean;
    hidePauseResumeControls?: boolean;
    hideActiveExpiredTabs?: boolean;
}

export const SchedulesTable: FC<ISchedulesTableProps> = (props) => {
    const {
        title,
        icon,
        breadcrumbs,
        company,
        schedules,
        isLoading,
        refetch,
        underTableContent,
        tableColumns,
        editRoute,
        detailsRoute,
        hideSearch = false,
        hideBorders = false,
        hidePauseResumeControls = false,
        hideActiveExpiredTabs = false
    } = props;

    const { setPaginatedProps, searchText, setSearchText, paginatedProps, setShowOnlyExpired } = useContext(ScheduleTableContext);

    const { showInactive, setShowInactive, suppressTableFiltering } = useContext(DataFilterContext);

    const [selected, setSelected] = useState<readonly ScheduleDto[]>([]);
    const [currentTab, setCurrentTab] = useState(0);

    const { can, cant, isOnlyOneActiveRecurringSelected, areAnySelectedActiveRecurringSelected } = useScheduleTableActionConditions(selected);

    const {
        isSelectCompanyVisible,
        isSetInactiveModalVisible,
        filterOpen,
        handle,
        handleTableEdit,
        setIsSetInactiveModalVisible,
        setFilterOpen,
        setIsSelectCompanyVisible,
        handleDelayChange,
    } = useScheduleTableButtonHandlers(selected, can, isOnlyOneActiveRecurringSelected, editRoute, detailsRoute, company);

    const handleSearchChange = useCallback(
        (text: string) => {
            setSelected([]);
            setSearchText(text);
        },
        [setSearchText]
    );

    const handleActiveToggleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSelected([]);
            setShowInactive(event.target.checked);
        },
        [setShowInactive]
    );

    const handleSetInactivateRecurringScheduleModalIsVisible = useCallback(
        (val: boolean) => {
            if (val === false) {
                setSelected([]);
            }
            setIsSetInactiveModalVisible(val);
        },
        [setIsSetInactiveModalVisible]
    );

    // TODO: the existence of this methods indicates that we have not set up our provides/invalidates tags correctly. I have propagated this blight here to limit the scope of my changes but, this should be investigated in the future.
    const reloadAfter = useCallback(
        async (action: () => Promise<void>) => {
            action().then(() => {
                refetch();
                setSelected([]);
            });
        },
        [refetch]
    );

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='center' justifyContent={!title ? 'end' : 'space-between'}>
                {!!title && (
                    <Grid item xs={4}>
                        <Typography variant='h1'>
                            {icon} {title}
                        </Typography>
                        {breadcrumbs && <NavBreadcrumbs links={breadcrumbs} currentPageLabel={title} />}
                    </Grid>
                )}
                <Grid item container direction='row' alignItems='center' xs={'auto'} justifyContent='flex-end'>
                    {suppressTableFiltering || (
                        <>
                            <IconButtonWithTooltip text='Filter' color='primary' onClick={() => handle('Filter')}>
                                <FilterList fontSize='inherit' />
                            </IconButtonWithTooltip>
                            <Divider flexItem orientation='vertical' />
                        </>
                    )}
                    <Grid item xs={'auto'}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <IconButtonWithTooltip
                        text='Make Inactive'
                        onClick={() => (isOnlyOneActiveRecurringSelected ? handle('SetInactive') : reloadAfter(async () => await handle('SetInactive')))}
                        disabled={cant('SetInactive')}>
                        <Delete fontSize='inherit' />
                    </IconButtonWithTooltip>
                    <Divider flexItem orientation='vertical' />
                    <IconButtonWithTooltip text='Send Email Reminder' onClick={() => handle('SendEmailReminder')} disabled={cant('SendReminders')}>
                        <Email fontSize='inherit' />
                    </IconButtonWithTooltip>
                    <IconButtonWithTooltip text='Approve' onClick={() => handle('SetApproved')} disabled={cant('SetApproved')}>
                        <ThumbUp fontSize='inherit' />
                    </IconButtonWithTooltip>
                    <IconButtonWithTooltip text='Send Now' onClick={() => reloadAfter(async () => await handle('SendNow'))} disabled={cant('SendNow')}>
                        <Send fontSize='inherit' />
                    </IconButtonWithTooltip>
                    <Divider flexItem orientation='vertical' />
                    <IconButtonWithTooltip text='Add New' color='primary' onClick={() => handle('CreateNew')}>
                        <AddCircle fontSize='inherit' />
                    </IconButtonWithTooltip>
                    <ScheduleTableMenu
                        cant={cant}
                        handle={handle}
                        reloadAfter={reloadAfter}
                        handleDelayChange={handleDelayChange}
                        hidePauseResumeControls={hidePauseResumeControls}
                    />
                    {hideSearch || (
                        <Grid item xs={'auto'}>
                            <StandardSearchInput searchText={searchText} handleSearchChange={handleSearchChange} isLoading={isLoading} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <SelectCompanyModal isSelectCompanyVisible={isSelectCompanyVisible} setIsSelectCompanyVisible={setIsSelectCompanyVisible} editRoute={editRoute} />
            {isOnlyOneActiveRecurringSelected && (
                <DeleteScheduleModal
                    isSetInactiveModalVisible={isSetInactiveModalVisible}
                    setIsSetInactiveModalVisible={handleSetInactivateRecurringScheduleModalIsVisible}
                    scheduleData={selected[0]}
                />
            )}
            {areAnySelectedActiveRecurringSelected && (
                <Grid item>
                    <Alert variant='outlined' severity='warning'>
                        Recurring {title.toLowerCase()} must be inactivated one at a time
                    </Alert>
                </Grid>
            )}
            {suppressTableFiltering || <ScheduleTableFilterChips />}
            {suppressTableFiltering || <FilterScheduleDialog open={filterOpen} onClose={() => setFilterOpen(false)} title={title} />}
            <Grid item xs={12}>
                {hideActiveExpiredTabs || (
                    <Paper
                        elevation={0}
                        sx={{
                            bgcolor: 'background.paper',
                            border: hideBorders ? undefined : '1px solid #AAAAAA',
                            borderBottom: '0px',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                        }}>
                        <Tabs
                            value={currentTab}
                            onChange={(e, newValue) => {
                                setCurrentTab(newValue);
                                if (setShowOnlyExpired) {
                                    setShowOnlyExpired(newValue === 1);
                                    setPaginatedProps({ ...paginatedProps, page: 0 });
                                }
                                setSelected([]);
                            }}>
                            <Tab label='Active' key='active' />
                            <Tab label='Expired' key='expired' />
                        </Tabs>
                    </Paper>
                )}
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={schedules}
                    defaultSortKey={paginatedProps.sortKey}
                    setPagination={setPaginatedProps}
                    selectedRecords={selected}
                    // This is a quick fix for the typing here. I just not sure how to pass generics into the components
                    setSelectedRecords={setSelected as any}
                    isViewOnly={true}
                    onEdit={handleTableEdit}
                    defaultSortDesc={!paginatedProps.sortAsc}
                    editToolTip='View'
                    isBorderVisible={!hideBorders}
                />
            </Grid>
            {underTableContent}
        </Grid>
    );
};
