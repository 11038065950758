import { serverApi } from '..';
import { BulkUpdateResultDto, StudyDto, QueryResultsDto, WorkItemDto, BusinessComponentMatrixConfigDto } from '../../dtos';
import { GroupByDto } from '../../dtos/generated/GroupByDto';
import { buildSearchQueryRoute } from '../../util';

export interface GetStudyQueryParameters {
    searchText?: string;
    sortKey?: string;
    sortAsc: boolean;
    page: number;
    pageSize: number;
    includeInactive: boolean;
    companyId?: string;
    completedStatus?: string;
    activeStatus?: string;
    timePeriodStart?: Date;
    timePeriodEnd?: Date;
}

export interface GetWorkItemsQueryParameters {
    studyId: string;
    searchText?: string;
    sortKey?: string;
    sortAsc: boolean;
    page: number;
    pageSize: number;
    includeInactive: boolean;
    activeStatus?: 'ACTIVE' | 'INACTIVE';
    showCompleted?: boolean;
    periodStartDate?: Date;
    periodEndDate?: Date;
    itemType?: 'ACTIVITY' | 'BUSINESS COMPONENT';
}

export interface GetStudyByIdQueryParameters {
    id: string;
}

export interface GetStudySurveyStatisticsByIdQueryParameters {
    studyId: string;
    searchParams: {
        status?: string;
        formType?: string;
        reminder?: string;
        approvedBy?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
    }
}

export interface GetStudyBCMStatisticsByIdQueryParameters {
    studyId: string;
    searchParams: {
        status?: string;
        reminder?: string;
        approvedBy?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
    }
}

export interface DeleteStudyByIdQueryParameters {
    id: string;
}

export interface UpdateStudyByIdQueryParameters {
    id: string;
}

export interface GetRespondentInvolvementWorkItemsQueryParameters {
    studyId: string;
    respondentId: string;
}

export interface AddWorkItemsToRespondentQueryParameters {
    studyId: string;
    respondentId: string;
    WorkItemIds: string[];
}
export interface RemoveRespondentFromStudyQueryParameters {
    studyId: string;
    respondentIds: string[];
}

const studyApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getStudies: builder.query<QueryResultsDto<StudyDto>, GetStudyQueryParameters>({
            query: (args) => buildSearchQueryRoute('study', args),
            providesTags: ['Study'],
        }),
        createStudy: builder.mutation<StudyDto, StudyDto>({
            query: (args) => ({
                url: `study`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['Study'],
        }),
        updateStudy: builder.mutation<void, StudyDto>({
            query: (args) => ({
                url: `study`,
                method: 'PUT',
                body: args,
            }),
            invalidatesTags: ['Study'],
        }),
        getStudyById: builder.query<StudyDto, GetStudyByIdQueryParameters>({
            query: (args) => ({
                url: `study/${args.id}`,
                method: 'GET',
            }),
            providesTags: ['Study'],
        }),
        getStudyBCMConfigById: builder.query<BusinessComponentMatrixConfigDto, GetStudyByIdQueryParameters>({
            query: (args) => ({
                url: `study/${args.id}/BCMConfig`,
                method: 'GET',
            }),
            providesTags: ['Study'],
        }),
        archiveStudyById: builder.mutation<void, DeleteStudyByIdQueryParameters>({
            query: (args) => ({
                url: `study/${args.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Study'],
        }),
        unarchiveStudyById: builder.mutation<void, UpdateStudyByIdQueryParameters>({
            query: (args) => ({
                url: `study/${args.id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Study'],
        }),
        studyBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
            query: (args) => ({
                url: `study/bulkArchive`,
                method: 'PATCH',
                body: args,
            }),
            invalidatesTags: ['Study'],
        }),
        studyBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
            query: (args) => ({
                url: `study/bulkUnarchive`,
                method: 'PATCH',
                body: args,
            }),
            invalidatesTags: ['Study'],
        }),
        getStudyWorkItems: builder.query<QueryResultsDto<WorkItemDto>, GetWorkItemsQueryParameters>({
            query: (params) => buildSearchQueryRoute(`study/${params.studyId}/workItems`, params),
            providesTags: ['WorkItem'],
        }),
        getStudyWorkItemsByType: builder.query<GroupByDto[], GetStudyByIdQueryParameters>({
            query: (params) => buildSearchQueryRoute(`study/${params.id}/statistics/work-item-by-type`, params),
            providesTags: ['WorkItem'],
        }),
        getStudySurveysByStatus: builder.query<GroupByDto[], GetStudySurveyStatisticsByIdQueryParameters>({
            query: (params) => buildSearchQueryRoute(`study/${params.studyId}/statistics/survey-by-status`, params.searchParams),
            providesTags: ['Schedule'],
        }),
        getStudyBcmsByStatus: builder.query<GroupByDto[], GetStudyBCMStatisticsByIdQueryParameters>({
            query: (params) => buildSearchQueryRoute(`study/${params.studyId}/statistics/bcm-by-status`, params.searchParams),
            providesTags: ['Schedule'],
        }),
        getRespondentInvolvementWorkItems: builder.query<QueryResultsDto<WorkItemDto>, GetRespondentInvolvementWorkItemsQueryParameters>({
            query: (params) => ({ url: `study/${params.studyId}/respondent/${params.respondentId}/workItems` }),
            providesTags: ['Respondent', 'WorkItem'],
        }),
        setRespondentInvolvementWorkItems: builder.mutation<void, AddWorkItemsToRespondentQueryParameters>({
            query: (params) => ({
                url: `study/${params.studyId}/respondent/${params.respondentId}/workItems`,
                method: 'PUT',
                body: params.WorkItemIds,
            }),
            invalidatesTags: ['Respondent', 'WorkItem'],
        }),
        removeRespondentsFromStudy: builder.mutation<BulkUpdateResultDto, RemoveRespondentFromStudyQueryParameters>({
            query: (params) => ({
                url: `study/${params.studyId}/bulkRemoveRespondentFromStudy`,
                method: 'PATCH',
                body: params.respondentIds,
            }),
            invalidatesTags: ['Respondent', 'WorkItem'],
        }),
    }),
});

export const {
    useGetStudiesQuery,
    useLazyGetStudiesQuery,
    useCreateStudyMutation,
    useUpdateStudyMutation,
    useGetStudyByIdQuery,
    useLazyGetStudyByIdQuery,
    useArchiveStudyByIdMutation,
    useUnarchiveStudyByIdMutation,
    useStudyBulkArchiveMutation,
    useStudyBulkUnarchiveMutation,
    useSetRespondentInvolvementWorkItemsMutation,
    useGetRespondentInvolvementWorkItemsQuery,
    useLazyGetRespondentInvolvementWorkItemsQuery,
    useRemoveRespondentsFromStudyMutation,
    useGetStudyBCMConfigByIdQuery,
    useLazyGetStudyBCMConfigByIdQuery,
    useGetStudyWorkItemsQuery,
    useGetStudyWorkItemsByTypeQuery,
    useLazyGetStudyWorkItemsByTypeQuery,
    useGetStudySurveysByStatusQuery,
    useLazyGetStudySurveysByStatusQuery,
    useGetStudyBcmsByStatusQuery,
    useLazyGetStudyBcmsByStatusQuery
} = studyApi;
