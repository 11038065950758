import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Button, Chip, Grid, TextField, Typography } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RespondentDto } from '../../../../dtos';
import { useGetCompanyRespondentsQuery } from '../../../../store';
import { PaginatedProps } from '../../../CoreLib/library';
import { SurveyBuilderContext } from '../../utils/SurveyBuilderContext';

interface IAddRespondentsDropDownProps {
    paginatedProps: PaginatedProps | null;
    disabled?: boolean;
}
export const AddRespondentsDropDown: FC<IAddRespondentsDropDownProps> = (props) => {
    const { paginatedProps, disabled } = props;
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const { id } = useParams();
    const [value] = useState('');
    const [inputValue, setInputValue] = useState('');
    const { data: autoCompleteData, isLoading: autoCompleteLoading, refetch } = useGetCompanyRespondentsQuery({
        parentId: id!,
        searchText: inputValue,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize
    });

    const addToRespondentQueue = (respondent: RespondentDto) => {
        let newRespondentQueue = [...surveyPayload.respondentQueue];
        if (!newRespondentQueue.some((respondentQueue) => respondentQueue.id === respondent.id)) {
            newRespondentQueue = [...newRespondentQueue, respondent];
            setSurveyPayload({
                ...surveyPayload,
                respondentQueue: newRespondentQueue,
            });
        }
        setInputValue("");
    };

    const deleteRespondentQueue = (respondent: RespondentDto) => {
        let newRespondentQueue = surveyPayload.respondentQueue.filter((respondents) => respondents.id !== respondent.id);
        setSurveyPayload({
            ...surveyPayload,
            respondentQueue: newRespondentQueue,
        });
    };
    
    const clearRespondentQueue = () => {
        setSurveyPayload({
            ...surveyPayload,
            respondentQueue: [],
        });
        setInputValue("");
    };

    const stepErrorMessage = useMemo(() => {
        if (surveyPayload.respondentQueue.length === 0) {
            return "At least one respondent is required."
        }
    }, [surveyPayload.respondentQueue]);
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" alignItems="start">
                <Grid item container direction="column" justifyContent="start" xs={4}>
                    <Autocomplete
                        loading={autoCompleteLoading}
                        freeSolo
                        id="respondents-dropdown"
                        options={autoCompleteData ? autoCompleteData.pageResults.filter(x => !surveyPayload.respondentQueue.find(y => y.id === x.id)).map((row) => {
                            return { label: `${row.firstName} ${row.lastName}`, value: row }
                        }) : []}
                        value={value}
                        sx={{ pr: 6 }}
                        inputValue={inputValue}
                        onChange={(_: any, newValue: any | null) => {
                            if (newValue) {
                                addToRespondentQueue(newValue.value)
                            }
                        }}

                        onInputChange={(_, newInputValue) => {
                            setInputValue(newInputValue);
                            refetch()
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key === 'Enter') {
                                event.defaultMuiPrevented = true;
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select individual"
                            />
                        )}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item container rowSpacing={2} xs={6}>
                    {surveyPayload.respondentQueue.map((respondent) => {
                        return (
                            <Grid item xs="auto" sx={{ mr: 2 }} key={respondent.id}>
                                <Chip
                                    label={`${respondent.firstName} ${respondent.lastName}`}
                                    size="small"
                                    variant="outlined"
                                    style={{ backgroundColor: "#E9E9E9", borderColor: "#707070" }}
                                    onDelete={() => deleteRespondentQueue(respondent)}
                                    deleteIcon={<CancelIcon style={{ color: "#333333" }} />}
                                />
                            </Grid>

                        );
                    })}
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center">
                <Grid item xs={"auto"}>
                    <Button
                        variant='contained'
                        onClick={clearRespondentQueue}
                    >
                        Remove All
                    </Button>
                </Grid>
                <Grid item xs={"auto"}>
                    <Typography variant="body1" sx={{ px: 2, color: '#DF623B' }}>
                        {stepErrorMessage}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}