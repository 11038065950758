import { Download } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import { ImportRespondentsStepOne, ImportRespondentsStepThree, ImportRespondentsStepTwo } from ".";
import { emptyGuid } from "../../../models";
import { useCreateRespondentImportMutation } from "../../../store/api/import-api";
import { LoadingIndicator } from "../../CoreLib/library";
import { useNavigate } from 'react-router-dom';
import { ImportDto, ImportRespondentRowDto } from "../../../dtos";

export interface IImportRespondentsProps {
    companyId: string;
    companyName: string;
    setIsImportVisible: Function;
    isImportVisible: boolean;
    redirectOnSuccess?: boolean;
}

export const ImportRespondentsModal: FC<IImportRespondentsProps> = ({ setIsImportVisible, isImportVisible, companyId, companyName, redirectOnSuccess = false }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [selectedFile, setSelectedFile] = useState<File>();
    const [addNewDepartments, setAddNewDepartments] = useState<boolean>(true);
    const [isReadyForNext, setIsReadyForNext] = useState<Boolean>(false)
    const [parsedFileData, setParsedFileData] = useState<Array<ImportRespondentRowDto>>()
    const [createImport, { isLoading: importProcessing, isSuccess: importSuccess, data: importResults, isError: importError }] = useCreateRespondentImportMutation();
    const navigate = useNavigate();

    const handleNext = () => {
        if (activeStep === 1) {
            handleImport(selectedFile)
            setIsReadyForNext(true)
        }
        if (activeStep === 2) {
            handleClose();
            setActiveStep(0)
            return;
        }
        setActiveStep(activeStep + 1)
    }
    const handleClose = () => {
        if (redirectOnSuccess && importSuccess && importResults?.recordsAdded && importResults.recordsAdded > 0
        ) {
            navigate(`/companies/${companyId}`)
        }
        setIsImportVisible(false)
    }
    const handleDownloadSample = () => {
        window.open(`${window.origin}/sample-respondents.csv`);
    }

    const handleImport = (selectedFile: File | undefined) => {
        let importData: ImportDto = {
            id: emptyGuid,
            companyId: companyId,
            importType: "Respondent",
            fileName: selectedFile?.name ?? "",
            parsedFile: parsedFileData ?? [],
            recordsAdded: parsedFileData?.length ?? 0,
            addNewDepartments: addNewDepartments,
            isActive: true,
            createdOn: new Date(),
            recordsSkipped: 0,
            duplicateRecords: 0
        }
        createImport(importData);
    }

    const steps = [
        {
            content: (
                <ImportRespondentsStepOne selectedFile={selectedFile} setSelectedFile={setSelectedFile} setIsReadyForNext={setIsReadyForNext} />
            ),
            buttonText: "Next"
        },
        {
            content: (
                <ImportRespondentsStepTwo selectedFile={selectedFile} parsedFileData={parsedFileData} setParsedFileData={setParsedFileData} setIsReadyForNext={setIsReadyForNext} addNewDepartments={addNewDepartments} setAddNewDepartments={setAddNewDepartments}></ImportRespondentsStepTwo>
            ),
            buttonText: "Import"
        },
        {
            content: ((importProcessing && <LoadingIndicator />) ||
                (importError && <Typography sx={{ color: "#DF623B", fontWeight: "bold" }}>
                    Import failed, please try again.
                </Typography >) ||
                (!importProcessing && importResults !== undefined && importSuccess && < ImportRespondentsStepThree importResults={importResults!} isLoading={importProcessing} ></ImportRespondentsStepThree>)
            ),
            buttonText: "Close"
        }

    ]


    return (
        <Dialog
            open={isImportVisible}
            onClose={handleClose}
            aria-labelledby="modal-import-respondents"
            aria-describedby="modal-modal-import-respondents-by-csv"
            maxWidth="xl"
            PaperProps={{
                sx: {
                    backgroundColor: "background.paper", width: "100%", ml: 35, height: "70%"
                }
            }}

        >
            <DialogTitle sx={{ ml: 2 }} >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Import Respondents
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                    Respondents will be added to {companyName}.<br />
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ height: 700, overflowY: 'scroll' }}>
                <Box sx={{ backgroundColor: "background.paper" }} padding={2}>
                    <Grid container direction="column" justifyContent={"space-between"}  >
                        <Grid item xs={9}>
                            <Box>
                                {steps[activeStep].content}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions sx={{ mr: 4, py: 2 }} >
                {activeStep === 0 && <Button onClick={handleDownloadSample} size="large" sx={{ textAlign: "center", marginRight: 'auto', minWidth: 360 }}>
                    <Download fontSize="small" sx={{ color: "black", }} /> Download Respondents CSV template
                </Button>}
                {activeStep !== 2 && <Button variant="outlined" color='primary' size="medium" onClick={handleClose} > Cancel</Button>}
                <Button variant="contained" size="medium" disabled={!isReadyForNext} onClick={handleNext} sx={{ color: "secondary", backgroundColor: "primary" }}> {steps[activeStep].buttonText}</Button>
            </DialogActions>
        </Dialog >
    )
}