import { FC, useContext } from 'react';
import { ActivityStreamTable } from '../../../../../../Components/Forms/ActivityStream';
import { Grid } from '@mui/material';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';

export const StudyDashboardStreamTab: FC = () => {
    const { study } = useContext(StudyDashboardContext); 

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <ActivityStreamTable
                    hideHeader
                    hideSearch
                    hideFilterButton
                    isBorderVisible={false}
                    hideDateDropdownFilter={false}
                    headerText=''
                    headerAlignment='center'
                    companyId={study.companyId}
                    studyId={study.id}
                    initialDaysBack='90'
                />
            </Grid>
        </Grid>
    );
};