import { createContext } from "react";
import { SurveyPayloadDto } from "../../../dtos";
import _ from "lodash";
import { DEFAULT_SURVEY_PAYLOAD } from "../../../Views/types";

export type BCMInitializationType = 'import' | 'new';

export interface ISurveyBuilderContext {
    surveyPayload: SurveyPayloadDto;
    setSurveyPayload: (updatedSurveyPayload: SurveyPayloadDto) => void;
    surveyAlias: string;
    surveyAliasShort: string;
    bcmInitializationType?: BCMInitializationType;
    setBCMInitializationType: (updatedType: BCMInitializationType) => void;
    shouldForceFillOutByGroup: boolean;
}

export const SurveyBuilderContext = createContext<ISurveyBuilderContext>({
    surveyPayload: _.cloneDeep(DEFAULT_SURVEY_PAYLOAD),
    setSurveyPayload: () => {},
    surveyAlias: 'Survey',
    surveyAliasShort: 'Survey',
    setBCMInitializationType: () => {},
    shouldForceFillOutByGroup: false
})