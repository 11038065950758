import { Ballot } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { FC } from "react";
import { StandardDialogHeader } from "../../../CommonComponents";
import { SurveyForm } from "../../../Forms";
import { SurveyTemplate } from "../../../../models/survey";
import { ScheduleDetailDto, ScheduleRespondentAnswerDto } from "../../../../dtos";

export interface ISurveyPreviewModal {
    isPreviewVisible: boolean;
    setIsPreviewVisible: (isVisible: boolean) => void;
    surveyPreview: SurveyTemplate;
    previewScheduleDetail?: ScheduleDetailDto;
    previewAnswers?: ScheduleRespondentAnswerDto[];
}

export const SurveyPreviewModal: FC<ISurveyPreviewModal> = props => {
    const { isPreviewVisible, setIsPreviewVisible, surveyPreview, previewScheduleDetail, previewAnswers } = props;

    const handleClose = () => {
        setIsPreviewVisible(false);
    }

    return (
        <Dialog
            open={isPreviewVisible}
            onClose={handleClose}
            aria-labelledby="modal-survey-preview"
            aria-describedby="modal-survey-preview"
            maxWidth="xl"
            keepMounted={false}
            PaperProps={{
                sx: {
                    backgroundColor: "background.paper", width: "100%", ml: 35, height: "70%"
                }
            }}

        >
            <StandardDialogHeader icon={<Ballot />} text={`${surveyPreview.title} (Preview)`} />
            <DialogContent sx={{ height: 700, overflowY: 'scroll' }}>
                <Box>
                    <Grid container direction="column" justifyContent={"space-between"}  >
                        <Grid item xs={9} ml={4}>
                            <Box className="standard-survey-form-container">
                                <SurveyForm surveyTemplateData={surveyPreview} scheduleDetail={previewScheduleDetail} answers={previewAnswers} isPreview={true}></SurveyForm>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions sx={{ py: 2 }} >
                <Button variant="contained" size="medium" color="primary" onClick={handleClose} > Close</Button>
            </DialogActions>
        </Dialog >
    )
}