import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import theme from '../../../../../../Theme';
import { onlyShowOnMobile, textAlignCenterOnMobile } from '../../../util';

export interface QuestionTotal {
    label: string;
    identifier: string;
    total: number;
}

export interface ISectionTotalRowProps {
    label: string;
    alignment: string;
    // this is a very specific case but I am not sure what else to name it
    isLightBackgroundOnSmallDisplay: boolean;
    totals: QuestionTotal[];
    shouldValidateTotalIs100?: boolean;
}

export const SectionTotalRow: FC<ISectionTotalRowProps> = ({
    label,
    alignment,
    isLightBackgroundOnSmallDisplay,
    shouldValidateTotalIs100,
    totals,
}) => {

    const renderErrorMessageSectionTotals = (total: number) => {
        if (total === 0) {
            // right now we just return null if the total is 0 so the validation does not show up as soon as the page renders but this is not a very good way to handle this
            return null;
        }

        if (shouldValidateTotalIs100 && total !== 100) {
            return <Typography color='primary'>Total must equal exactly 100%.</Typography>;
        } else if (total > 100) {
            return <Typography color='primary'>Subtotal cannot be greater than 100%.</Typography>;
        }
    };

    return (
        <Grid
            item
            container
            direction='row'
            justifyContent={alignment === 'right' ? 'flex-end' : 'flex-start'}
            alignItems='end'
            sx={{
                fontWeight: 'bold',
                paddingRight: 3,
                [theme.breakpoints.down('sm')]: {
                    background: isLightBackgroundOnSmallDisplay ? '#eeeeee' : '#dddddd',
                    paddingY: 1,
                    marginY: 1,
                    justifyContent: 'center',
                    paddingRight: 0,
                },
            }}
            columnSpacing={5}>
            <Grid item xs={12} sm='auto' sx={{ marginBottom: 'auto', ...textAlignCenterOnMobile }}>
                {label}
            </Grid>
            {totals.map((total) => (
                <Grid sx={{ textAlign: 'right', [theme.breakpoints.down('sm')]: { textAlign: 'center' } }} key={total.identifier} item>
                    <Typography sx={{ py: 1, ...onlyShowOnMobile }}>{total.label}</Typography>
                    <Box sx={{ width: '100%', [theme.breakpoints.down('sm')]: { fontSize: '24px' } }}>
                        {total.total}%
                        <br />
                    </Box>
                    {renderErrorMessageSectionTotals(total.total)}
                </Grid>
            ))}
        </Grid>
    );
};
