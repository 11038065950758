import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { SurveyDto } from '../../dtos';
import { useGetFormTypesQuery } from '../../store';

export interface IFormTypeSelectProps {
    selectedFormTypeId: string;
    handleSelectedFormTypeChange: (selectedFormType?: SurveyDto) => void;
    disabled?: boolean;
    itemFilter?: (item: SurveyDto) => boolean;
}

// TODO: there are still several files that define their own drop-down for Form Type (If you search for files with useGetFormTypesQuery you will find them). It would be preferable to use this component everywhere.
export const FormTypeSelect: FC<IFormTypeSelectProps> = props => {
    const { selectedFormTypeId, handleSelectedFormTypeChange, disabled, itemFilter = () => true } = props;
    const { data: formTypes, isLoading: isLoadingFormTypes, isError: isErrorLoadingFormTypes } = useGetFormTypesQuery();

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: SurveyDto | null) => {
        handleSelectedFormTypeChange(value ?? undefined);
    }

    const getSelectedFormType = () => {
        if (selectedFormTypeId === '') {
            return null;
        }
        return formTypes?.find(x => x.id === selectedFormTypeId);
    }

    return (
        <FormControl error={isErrorLoadingFormTypes} fullWidth disabled={disabled}>
            <FormLabel>Form Type</FormLabel>
            <Autocomplete
                value={getSelectedFormType()}
                onChange={handleSelectChange}
                loading={isLoadingFormTypes}
                options={formTypes?.filter(itemFilter) ?? []}
                getOptionLabel={x => x?.name ?? ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
                disabled={disabled}
            />
            <FormHelperText>{isErrorLoadingFormTypes ? 'Failed to load form type options' : ''}</FormHelperText>
        </FormControl>
    )
}