import { ArrowDropDown, Delete, Email, FilterList, ListAlt, MoreVert, Speed } from '@mui/icons-material';
import { Chip, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Paper, Switch, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
    DataTableColumn,
    LoadingIndicator,
    NavBreadcrumbs,
    PaginatedDataTable,
    PaginatedProps,
    StandardSearchInput,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../Components/CoreLib/library';
import { ScheduleDetailDto, ScheduleStatus } from '../../dtos';
import {
    useArchiveScheduleDetailMutation,
    useGetScheduleDetailsQuery,
    useGetScheduleQuery,
    usePauseScheduleDetailMutation,
    useUnarchiveScheduleDetailMutation,
    useUnpauseScheduleDetailMutation,
} from '../../store/api/schedule-api';
import { useCreateAndUploadSurveyPdfMutation, useGetSurveyCSVMutation } from '../../store/api/survey-pdf-api';
import { downloadBase64File, formateDateStandard } from '../../util';
import { FilterScheduleDetailsDialog } from './FilterScheduleDetailsDialog';
import { SendEmailReminders } from './SendReminderEmails';
import { ActivityStreamTable } from '../../Components/Forms/ActivityStream';

export const SurveyScheduleDetailsView: FC = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const statusParam = searchParams.get('status');
    const { enqueueSnackbar } = useSnackbar();
    const { data: schedule, isLoading: scheduleLoading, isError: isScheduleError } = useGetScheduleQuery(id!);
    const [searchText, setSearchText] = useState('');
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [selectedScheduleDetails, setSelectedScheduleDetails] = useState<readonly ScheduleDetailDto[]>([]);
    const [showInactive, setShowInactive] = useState(false);
    const [sendReminderEmails, setSendReminderEmails] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [fromUpdatedOnDate, setFromUpdatedOnDate] = useState<Date | null>(null);
    const [toUpdatedOnDate, setToUpdatedOnDate] = useState<Date | null>(null);
    const [email, setEmail] = useState<string | undefined>();
    const [firstName, setFirstName] = useState<string | undefined>();
    const [lastName, setLastName] = useState<string | undefined>();
    const [formType, setFormType] = useState<string | undefined>();
    const [status, setStatus] = useState<string | undefined>(statusParam ? statusParam : undefined);
    const {
        data,
        isLoading,
        isError: isGetScheduleDetailError,
    } = useGetScheduleDetailsQuery({
        parentId: id!,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
            firstName: firstName ? encodeURIComponent(firstName) : undefined,
            lastName: lastName ? encodeURIComponent(lastName) : undefined,
            email: email ? encodeURIComponent(email) : undefined,
            formType: formType ? encodeURIComponent(formType) : undefined,
            fromDate: fromUpdatedOnDate ? fromUpdatedOnDate.toISOString() : '',
            toDate: toUpdatedOnDate ? toUpdatedOnDate.toISOString() : '',
            status: status && status !== '' ? parseInt(status) : undefined,
        },
    });
    const [createAndUploadPdf, { reset: resetCreate, isError: isCreatePdfError, error: createPdfError }] = useCreateAndUploadSurveyPdfMutation();

    useFailedActionSnackbar('retrieve', 'survey', isScheduleError);
    useFailedActionSnackbar('retrieve', 'survey instances', isGetScheduleDetailError);
    useFailedActionSnackbar('generating', 'PDF', isCreatePdfError, resetCreate, createPdfError);

    const navigateToSurvey = useCallback((row: ScheduleDetailDto) => {
        window.open(`/survey/schedule/${row.scheduleId}/detail/${row.id}`);
    }, []);

    const mapSurveyLink = useCallback(
        (row: ScheduleDetailDto) => {
            return (
                <Typography fontSize='13px' onClick={() => navigateToSurvey(row)} sx={{ color: '#DF623B', cursor: 'pointer' }}>
                    View
                </Typography>
            );
        },
        [navigateToSurvey]
    );

    const handleViewSurvey = useCallback(() => {
        if (!data || !data.pageResults || selectedScheduleDetails.length !== 1) {
            return;
        }

        const selectedRow = selectedScheduleDetails[0];
        navigateToSurvey(selectedRow);
    }, [data, navigateToSurvey, selectedScheduleDetails]);

    const mapTimeColumn = useCallback((row: ScheduleDetailDto) => {
        if (row.updatedOn) {
            let updatedDate = new Date(row.updatedOn);
            let timeString = updatedDate.toLocaleString('en-us');
            return timeString;
        }
    }, []);

    const mapStatusForTable = useCallback((row: ScheduleDetailDto) => {
        if (!row.isActive) {
            return 'Inactive';
        } else if (row.isPaused) {
            return 'Paused';
        } else {
            return ScheduleStatus[row.status]?.toString();
        }
    }, []);

    const mapStatusForChip = useCallback((status: string) => {
        return ScheduleStatus[parseInt(status)]?.toString();
    }, []);

    const tableColumns: DataTableColumn<ScheduleDetailDto>[] = useMemo(
        () => [
            { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME', fieldMapper: (scheduleDetail) => scheduleDetail.respondent.firstName },
            { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME', fieldMapper: (scheduleDetail) => scheduleDetail.respondent.lastName },
            { key: 'email', label: 'Email', sortKey: 'EMAIL', fieldMapper: (scheduleDetail) => scheduleDetail.respondent.email },
            { key: 'formType', label: 'Form Type', sortKey: 'FORM_TYPE', fieldMapper: (scheduleDetail) => scheduleDetail.schedule.formType },
            { key: 'survey', label: 'Survey', sortKey: 'SURVEY', fieldMapper: mapSurveyLink, unsorted: true },
            { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: mapStatusForTable },
            { key: 'updatedOn', label: 'Last Updated', sortKey: 'LAST_UPDATED', fieldMapper: mapTimeColumn },
        ],
        [mapStatusForTable, mapSurveyLink, mapTimeColumn]
    );

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [pauseScheduleDetail, { isError: isPauseError, isSuccess: isPauseSuccess, reset: resetPause }] = usePauseScheduleDetailMutation();
    const [unpauseScheduleDetail, { isError: isUnpauseError, isSuccess: isUnpauseSuccess, reset: resetUnpause }] = useUnpauseScheduleDetailMutation();
    const [archiveScheduleDetail, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveScheduleDetailMutation();
    const [unarchiveScheduleDetail, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveScheduleDetailMutation();
    const [getCSV, { isError: isGetCSVError, reset: resetGetCSV, data: csvData }] = useGetSurveyCSVMutation();

    useSuccessfulActionSnackbar('paused', 'survey instance(s)', isPauseSuccess, resetPause);
    useFailedActionSnackbar('pause', 'survey instance(s)', isPauseError, resetPause);
    useSuccessfulActionSnackbar('restarted', 'survey instance(s)', isUnpauseSuccess, resetUnpause);
    useFailedActionSnackbar('restart', 'survey instance(s)', isUnpauseError, resetUnpause);
    useSuccessfulActionSnackbar('inactivated', 'survey instance(s)', isArchiveSuccess, resetArchive);
    useFailedActionSnackbar('inactivate', 'survey instance(s)', isArchiveError, resetArchive);
    useSuccessfulActionSnackbar('activated', 'survey instance(s)', isUnarchiveSuccess, resetUnarchive);
    useFailedActionSnackbar('activate', 'survey instance(s)', isUnarchiveError, resetUnarchive);
    useFailedActionSnackbar('generate', 'CSV', isGetCSVError, resetGetCSV);

    const reminderSchedules = useMemo(() => {
        return selectedScheduleDetails.filter((x) => (x.status === ScheduleStatus.Issued || x.status === ScheduleStatus.Started) && !x.isPaused);
    }, [selectedScheduleDetails]);

    SendEmailReminders(reminderSchedules, sendReminderEmails, () => {
        setSendReminderEmails(false);
    });

    const handleSearchChange = (text: string) => {
        setSelectedScheduleDetails([]);
        setSearchText(text);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedScheduleDetails([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handlePauseSurvey = useCallback(() => {
        selectedScheduleDetails.forEach((selectedScheduleDetail) => {
            pauseScheduleDetail({
                parentId: id!,
                childId: selectedScheduleDetail.id!,
            });
        });
    }, [id, pauseScheduleDetail, selectedScheduleDetails]);

    const handleRestartSurvey = useCallback(() => {
        selectedScheduleDetails.forEach((selectedScheduleDetail) => {
            unpauseScheduleDetail({
                parentId: id!,
                childId: selectedScheduleDetail.id!,
            });
        });
    }, [id, selectedScheduleDetails, unpauseScheduleDetail]);

    const handleSetActive = useCallback(() => {
        selectedScheduleDetails.forEach((selectedScheduleDetail) => {
            unarchiveScheduleDetail({
                parentId: id!,
                childId: selectedScheduleDetail.id!,
            });
        });
    }, [id, selectedScheduleDetails, unarchiveScheduleDetail]);

    const handleSetInactive = useCallback(() => {
        selectedScheduleDetails.forEach((selectedScheduleDetail) => {
            archiveScheduleDetail({
                parentId: id!,
                childId: selectedScheduleDetail.id!,
            });
        });
    }, [archiveScheduleDetail, id, selectedScheduleDetails]);

    const handleFilter = useCallback(() => {
        setFilterOpen(true);
    }, []);

    const canSetActive = useMemo(() => {
        return selectedScheduleDetails.some((selectedScheduleDetail) => !selectedScheduleDetail.isActive);
    }, [selectedScheduleDetails]);

    const canSetInactive = useMemo(() => {
        return selectedScheduleDetails.some((selectedScheduleDetail) => selectedScheduleDetail.isActive);
    }, [selectedScheduleDetails]);

    const scheduleIsExpired = useMemo(() => schedule?.isExpired ?? false, [schedule?.isExpired]);
    const scheduleNotExpired = useMemo(() => !scheduleIsExpired, [scheduleIsExpired]);

    const canPause = useMemo(() => {
        return (
            scheduleNotExpired && selectedScheduleDetails.some((selectedScheduleDetail) => selectedScheduleDetail.isActive && !selectedScheduleDetail.isPaused)
        );
    }, [scheduleNotExpired, selectedScheduleDetails]);

    const canUnpause = useMemo(() => {
        return (
            scheduleNotExpired && selectedScheduleDetails.some((selectedScheduleDetail) => selectedScheduleDetail.isActive && selectedScheduleDetail.isPaused)
        );
    }, [scheduleNotExpired, selectedScheduleDetails]);

    const handleCopyLink = useCallback(() => {
        if (selectedScheduleDetails.length === 1) {
            const row = selectedScheduleDetails[0];
            navigator.clipboard.writeText(`${window.origin}/survey/${row?.schedule.survey.surveyTemplateId}`);
            enqueueSnackbar('Survey link copied to clipboard');
        }
    }, [enqueueSnackbar, selectedScheduleDetails]);

    const handleCreatePdf = useCallback(() => {
        if (selectedScheduleDetails.length > 0) {
            createAndUploadPdf({
                scheduleIds: data ? _.uniq(selectedScheduleDetails.map((selectedScheduleDetail) => selectedScheduleDetail.scheduleId)) : [],
                scheduleDetailIds: data ? selectedScheduleDetails.map((selectedScheduleDetail) => selectedScheduleDetail.id) : [],
            });
            enqueueSnackbar('PDF requested and will be delivered via email when complete', { variant: 'success' });
        }
    }, [createAndUploadPdf, data, enqueueSnackbar, selectedScheduleDetails]);

    const handleSendEmailReminder = () => {
        setSendReminderEmails(true);
    };

    const [downloadSubMenuAnchorEl, setDownloadSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const downloadSubMenuOpen = Boolean(downloadSubMenuAnchorEl);

    const handleExportSubMenuOpen = useCallback((event: React.MouseEvent<HTMLLIElement>) => {
        setDownloadSubMenuAnchorEl(event.currentTarget);
    }, []);
    const handleDownloadSubMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setDownloadSubMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    const handleGetCSV = useCallback(() => {
        getCSV({
            scheduleIds: [],
            scheduleDetailIds: data ? selectedScheduleDetails.map((selectedScheduleDetail) => selectedScheduleDetail.id) : [],
        });
    }, [data, getCSV, selectedScheduleDetails]);

    useEffect(() => {
        if (csvData) {
            downloadBase64File(csvData.contents, 'Surveys_' + new Date().toLocaleDateString() + '.csv', 'text/csv');
        }
    }, [csvData]);

    const canExport = useMemo(() => {
        return selectedScheduleDetails.length > 0;
    }, [selectedScheduleDetails]);

    const canExportPdf = useMemo(() => {
        return selectedScheduleDetails.length > 0 && selectedScheduleDetails.some(sd => sd.status === ScheduleStatus.Completed);
    }, [selectedScheduleDetails]);

    return (
        <>
            {!scheduleLoading ? (
                <>
                    <Grid container direction='column' spacing={3}>
                        <Grid item container direction='row' alignItems='center'>
                            <Grid item container direction='column' xs={6}>
                                <Typography variant='h1' noWrap sx={{ marginBottom: '8px', width: '100%' }}>
                                    <ListAlt />
                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>Survey Instances</div>
                                </Typography>
                                <NavBreadcrumbs
                                    links={[{ label: schedule?.companyShortName ?? 'Company', navLink: `/companies/${schedule?.companyId}` }]}
                                    currentPageLabel={schedule?.companyName + ' | ' + schedule?.period}
                                    editRoute={`/companies/${schedule?.companyId}/survey/${schedule?.surveyId}?originPage=SurveyInstance`}
                                />
                            </Grid>
                            <Grid item container direction='row' alignItems={'center'} xs={6} justifyContent={'end'}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                        label='Show Inactive'
                                        labelPlacement='start'
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title='Make Inactive'>
                                        <span>
                                            <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                                <Delete fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title='Send Email Reminder'>
                                        <span>
                                            <IconButton size='large' onClick={handleSendEmailReminder} disabled={reminderSchedules.length < 1}>
                                                <Email fontSize='inherit' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title={'Filter'}>
                                        <IconButton color='primary' size='large' onClick={handleFilter}>
                                            <FilterList fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <IconButton size='large' onClick={handleMenuOpen}>
                                        <MoreVert fontSize='inherit' />
                                    </IconButton>
                                    <Menu
                                        anchorEl={menuAnchorEl}
                                        open={menuOpen}
                                        onClose={handleMenuClose()}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>
                                        <MenuItem disabled={selectedScheduleDetails.length !== 1} onClick={handleMenuClose(handleViewSurvey)}>
                                            View Survey
                                        </MenuItem>
                                        <MenuItem disabled={!canPause} onClick={handleMenuClose(handlePauseSurvey)}>
                                            Pause Survey
                                        </MenuItem>
                                        <MenuItem disabled={!canUnpause} onClick={handleMenuClose(handleRestartSurvey)}>
                                            Restart Survey
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                            Make Active
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                            Make Inactive
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem disabled={reminderSchedules.length < 1} onClick={handleMenuClose(handleSendEmailReminder)}>
                                            Send Email Reminder
                                        </MenuItem>
                                        <MenuItem disabled={selectedScheduleDetails.length !== 1} onClick={handleMenuClose(handleCopyLink)}>
                                            Copy Link
                                        </MenuItem>
                                        <MenuItem onClick={handleExportSubMenuOpen} disabled={!canExport}>
                                            Export Surveys <ArrowDropDown style={{ transform: 'rotate(-90deg)', marginLeft: 'auto' }} />
                                        </MenuItem>
                                    </Menu>
                                    <Menu
                                        anchorEl={downloadSubMenuAnchorEl}
                                        open={downloadSubMenuOpen}
                                        onClose={handleDownloadSubMenuClose()}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}>
                                        <MenuItem onClick={handleMenuClose(handleDownloadSubMenuClose(handleGetCSV))} disabled={!canExport}>
                                            Download CSV
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuClose(handleDownloadSubMenuClose(handleCreatePdf))} disabled={!canExportPdf}>
                                            Email PDF
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                                <Grid item xs={4}>
                                    <StandardSearchInput searchText={searchText} handleSearchChange={handleSearchChange} isLoading={isLoading} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <>
                            <Grid
                                item
                                container
                                direction='row'
                                alignItems='center'
                                xs={6}
                                spacing={1}
                                sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
                                {fromUpdatedOnDate && toUpdatedOnDate && (
                                    <Grid item>
                                        <Chip
                                            label={`Last Updated: ${formateDateStandard(fromUpdatedOnDate)} - ${formateDateStandard(toUpdatedOnDate)} `}
                                            color='primary'
                                            onDelete={() => {
                                                setFromUpdatedOnDate(null);
                                                setToUpdatedOnDate(null);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {fromUpdatedOnDate && !toUpdatedOnDate && (
                                    <Grid item>
                                        <Chip
                                            label={`Last Updated: ${formateDateStandard(fromUpdatedOnDate)} - Present`}
                                            color='primary'
                                            onDelete={() => {
                                                setFromUpdatedOnDate(null);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {toUpdatedOnDate && !fromUpdatedOnDate && (
                                    <Grid item>
                                        <Chip
                                            label={`Last Updated: On or Before ${formateDateStandard(toUpdatedOnDate)} `}
                                            color='primary'
                                            onDelete={() => {
                                                setToUpdatedOnDate(null);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {firstName && (
                                    <Grid item>
                                        <Chip
                                            label={`First Name: ${firstName} `}
                                            color='primary'
                                            onDelete={() => {
                                                setFirstName(undefined);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {lastName && (
                                    <Grid item>
                                        <Chip
                                            label={`Last Name: ${lastName} `}
                                            color='primary'
                                            onDelete={() => {
                                                setLastName(undefined);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {formType && (
                                    <Grid item>
                                        <Chip
                                            label={`Form Type: ${formType} `}
                                            color='primary'
                                            onDelete={() => {
                                                setFormType(undefined);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {status && (
                                    <Grid item>
                                        <Chip
                                            label={`Status: ${mapStatusForChip(status)} `}
                                            color='primary'
                                            onDelete={() => {
                                                setStatus(undefined);
                                            }}
                                        />
                                    </Grid>
                                )}
                                {email && (
                                    <Grid item>
                                        <Chip
                                            label={`Email: ${email} `}
                                            color='primary'
                                            onDelete={() => {
                                                setEmail(undefined);
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <FilterScheduleDetailsDialog
                                open={filterOpen}
                                onClose={() => setFilterOpen(false)}
                                fromDateFilter={fromUpdatedOnDate}
                                toDateFilter={toUpdatedOnDate}
                                emailFilter={email}
                                firstNameFilter={firstName}
                                lastNameFilter={lastName}
                                formTypeFilter={formType}
                                statusFilter={status}
                                setFromDateFilter={setFromUpdatedOnDate!}
                                setToDateFilter={setToUpdatedOnDate!}
                                setFirstNameFilter={setFirstName!}
                                setLastNameFilter={setLastName!}
                                setFormTypeFilter={setFormType!}
                                setStatusFilter={setStatus!}
                                setEmailFilter={setEmail!}
                            />
                        </>
                        <Grid item>
                            <PaginatedDataTable
                                columns={tableColumns}
                                loading={isLoading}
                                queryResults={data}
                                defaultSortKey='FIRST_NAME'
                                setPagination={setPaginatedProps}
                                selectedRecords={selectedScheduleDetails}
                                setSelectedRecords={setSelectedScheduleDetails as any}
                            />
                        </Grid>
                        <Paper elevation={0} sx={{ border: 'solid 1px #AAAAAA', padding: '24px', margin: '24px 0px 24px 24px' }}>
                            <ActivityStreamTable
                                headerIcon={<Speed />}
                                headerVariant='h1'
                                headerAlignment='start'
                                companyId={schedule?.companyId}
                                companyName={schedule?.companyShortName}
                                period={schedule?.periods[0].surveyLabel}
                                surveyId={schedule?.surveyId}
                            />
                        </Paper>
                    </Grid>
                </>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};
