import { LoadingButton } from '@mui/lab';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import _ from 'lodash';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_SURVEY_PAYLOAD } from '../../Views/types';
import { CompanyDto, EmailTemplateDto, RespondentDto, SurveyPayloadDto } from '../../dtos';
import { LoadingIndicator } from '../CoreLib/library';
import {
    BCMInitializationType,
    SurveyBuilderContext,
    SurveyValidator,
    useStepRelatedDataLoader,
    useSurveyCreateUpdateManager,
    useSurveyPreviewer,
} from './utils';

export interface IStepRenderingContext {
    company: CompanyDto;
    emailTemplates: EmailTemplateDto[];
}

export interface ISurveyStepperStepConfiguration {
    label: string;
    renderer: (context: IStepRenderingContext) => JSX.Element;
    validationMethod: (surveyValidator: SurveyValidator) => boolean;
}

export interface ISurveyStepperProps {
    steps: ISurveyStepperStepConfiguration[];
    surveyAlias?: string;
    surveyAliasShort?: string;
    stepperIcon: JSX.Element;
    detailsRoute: string;
    initialSurveyPayload?: SurveyPayloadDto;
    initialBCMInitializationType?: BCMInitializationType;
    isLoading?: boolean;
}

export const SurveyStepper: FC<ISurveyStepperProps> = ({
    steps,
    stepperIcon,
    surveyAlias = 'Survey',
    surveyAliasShort = 'Survey',
    detailsRoute,
    initialSurveyPayload = DEFAULT_SURVEY_PAYLOAD,
    initialBCMInitializationType,
    isLoading,
}) => {
    const { surveyId: existingSurveyId, duplicate } = useParams();
    const isDuplicate = duplicate && duplicate === 'duplicate';
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [selectedPreviewRespondent, setSelectedPreviewRespondent] = useState<RespondentDto | null>(null);
    const [surveyPayload, setSurveyPayload] = useState<SurveyPayloadDto>(_.cloneDeep(initialSurveyPayload));
    const [bcmInitializationType, setBCMInitializationType] = useState(initialBCMInitializationType);
    const { emailTemplates, surveyCompany, existingSurvey } = useStepRelatedDataLoader(surveyAliasShort);
    const { showSurveyPreview, renderSurveyPreviewModal } = useSurveyPreviewer(surveyPayload, selectedPreviewRespondent, surveyAliasShort);
    const { createOrUpdateSurvey, isLoading: isCreatingOrUpdatingSurvey } = useSurveyCreateUpdateManager(surveyPayload, surveyAliasShort, detailsRoute);

    useEffect(() => {
        if (!existingSurvey) {
            return;
        }

        setSurveyPayload({
            ...initialSurveyPayload,
            ...existingSurvey,
            frequencyConfig: {
                ...existingSurvey.frequencyConfig,
                startDate: moment(existingSurvey.frequencyConfig.startDate).toDate(),
            },
        });
    }, [existingSurvey, initialSurveyPayload]);

    const isFirstStep = useMemo(() => activeStep === 0, [activeStep]);
    const isLastStep = useMemo(() => activeStep === steps.length - 1, [activeStep, steps.length]);

    const playValidator = useMemo(() => {
        return new SurveyValidator(surveyPayload, emailTemplates);
    }, [surveyPayload, emailTemplates]);

    const nextStep = useCallback(async () => {
        if (!steps[activeStep].validationMethod(playValidator)) {
            return;
        } else if (!isLastStep) {
            setActiveStep(activeStep + 1);
        } else {
            createOrUpdateSurvey();
        }
    }, [activeStep, createOrUpdateSurvey, isLastStep, playValidator, steps]);

    const previousStep = useCallback(async () => {
        if (!isFirstStep) {
            setActiveStep(activeStep - 1);
        }
    }, [activeStep, isFirstStep]);

    const handleCancel = () => {
        navigate(-1);
    };

    const titleActionName = useMemo(() => {
        if (isDuplicate) {
            return 'Duplicate';
        } else if (existingSurveyId) {
            return 'Edit';
        } else {
            return 'New';
        }
    }, [isDuplicate, existingSurveyId]);

    const previewRespondents = useMemo(() => {
        let respondents: RespondentDto[] = [];
        if (surveyPayload.distributionConfig.surveyDistribution === 'individualRecipient') {
            respondents = surveyPayload.distributionConfig.individualRecipient;
        } else {
            respondents = surveyPayload.respondentQueue;
        }
        return _.orderBy(respondents, ['firstName', 'lastName']);
    }, [surveyPayload.distributionConfig, surveyPayload.respondentQueue]);

    useEffect(() => {
        if (previewRespondents.length > 0) {
            setSelectedPreviewRespondent(previewRespondents[0]);
        } else {
            setSelectedPreviewRespondent(null);
        }
    }, [previewRespondents]);

    const shouldForceFillOutByGroup = useMemo(() => {
        var isMultipleTimePeriods = surveyPayload.timePeriodArray.length > 1;
        var isDistributedToOneIndividual = surveyPayload.distributionConfig.surveyDistribution === 'individualRecipient';
        return isMultipleTimePeriods && isDistributedToOneIndividual;
    }, [surveyPayload.distributionConfig.surveyDistribution, surveyPayload.timePeriodArray.length]);

    useEffect(() => {
        var currentIndividualOrGroupValue = surveyPayload.distributionConfig.fillOutSurveyValue;
        if (shouldForceFillOutByGroup && currentIndividualOrGroupValue !== 'Group') {
            setSurveyPayload((p) => {
                var updatedVal = _.cloneDeep(p);
                updatedVal.distributionConfig.fillOutSurveyValue = 'Group';
                return updatedVal;
            });
        }
    }, [shouldForceFillOutByGroup, surveyPayload.distributionConfig.fillOutSurveyValue]);

    if (!surveyCompany) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <SurveyBuilderContext.Provider
                value={{ surveyPayload, setSurveyPayload, surveyAlias, surveyAliasShort, bcmInitializationType, setBCMInitializationType, shouldForceFillOutByGroup }}>
                <Box borderColor={'gray'} sx={{ position: 'sticky', zIndex: 3, top: 72, background: 'default' }}>
                    <Grid container direction='column'>
                        <Grid item container direction='row' alignItems='center' pt={4} pb={2} sx={{ backgroundColor: '#F3F5F6' }}>
                            <Grid item xs={7}>
                                <Typography variant='h1'>
                                    {stepperIcon} {titleActionName} {surveyAlias} for {surveyCompany?.name}
                                </Typography>
                            </Grid>
                            <Grid item container direction='row' justifyContent='flex-end' alignItems='center' xs={5} spacing={1}>
                                {isLastStep && (
                                    <>
                                        <Grid item>
                                            <Autocomplete
                                                value={selectedPreviewRespondent}
                                                onChange={(_, value) => setSelectedPreviewRespondent(value)}
                                                options={previewRespondents}
                                                getOptionLabel={(x) => `${x.firstName} ${x.lastName}`}
                                                renderInput={(params) => <TextField {...params} />}
                                                size='small'
                                                sx={{ minWidth: '200px', backgroundColor: 'white' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained' onClick={showSurveyPreview} disabled={!selectedPreviewRespondent}>
                                                Preview
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {renderSurveyPreviewModal()}
                    <Box
                        width='inherit'
                        sx={{
                            backgroundColor: 'primary.contrastText',
                            pt: 6,
                            pb: 4,
                            borderBottom: 24,
                            borderColor: '#F3F5F6',
                        }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((step, index) => {
                                return (
                                    <Step key={index}>
                                        <StepLabel>{step.label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <Grid container direction='row-reverse' justifyContent='flex-start' alignItems='center' sx={{ pt: 4, pr: 9 }}>
                            <LoadingButton style={{ marginLeft: 24 }} variant='contained' onClick={() => nextStep()} loading={isCreatingOrUpdatingSurvey}>
                                {isLastStep ? 'Submit' : 'Next'}
                            </LoadingButton>
                            <Button style={{ marginLeft: 24 }} variant='contained' disabled={activeStep === 0} onClick={() => previousStep()}>
                                Previous
                            </Button>
                            <Button style={{ marginLeft: 24 }} variant='outlined' onClick={handleCancel} autoFocus={false}>
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                </Box>
                <Stack sx={{ width: '100%' }} spacing={4}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step sx={{ p: 0 }}>
                            {isLoading ? <LoadingIndicator /> : <div>{steps[activeStep].renderer({ company: surveyCompany, emailTemplates })}</div>}
                        </Step>
                    </Stepper>
                </Stack>
            </SurveyBuilderContext.Provider>
        </div>
    );
};
