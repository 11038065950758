import { AddCircle } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { AddEditWorkItemModal } from '../../Views/Studies/Components/AddEditWorkItemModal';
import { StudyDto, WorkItemDto, WorkItemType } from '../../dtos';

export interface IWorkItemQuickAddProps {
    study: StudyDto;
    tooltip?: string;
    afterCreate?: (createdRecord: WorkItemDto) => void
}

export const WorkItemQuickAdd: FC<IWorkItemQuickAddProps> = ({ study, tooltip, afterCreate }) => {
    const [addWorkItemIsVisible, setAddWorkItemIsVisible] = useState(false);
    
    return (
        <>
            <Tooltip title={tooltip}>
                <Box>
                    <IconButton color='primary' size='large' sx={{ pr: 0 }} onClick={() => setAddWorkItemIsVisible(true)}>
                        <AddCircle fontSize='inherit' />
                    </IconButton>
                </Box>
            </Tooltip>
            <AddEditWorkItemModal
                isOpen={addWorkItemIsVisible}
                close={() => setAddWorkItemIsVisible(false)}
                companyId={study.companyId}
                study={study}
                hideAssociatedRespondents
                fixedType={WorkItemType.BusinessComponent}
                itemTypeName='Business Component'
                afterSave={afterCreate}
            />
        </>
    );
};
