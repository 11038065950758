import { FC, useContext } from 'react';
import { WorkItemDetails } from '../../../WorkItemDetails';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';

export const StudyDashboardWorkTabSidebarContent: FC = () => {
    const { selectedComponentId } = useContext(StudyDashboardContext);

    if (!selectedComponentId) {
        return null;
    }

    return <WorkItemDetails componentId={selectedComponentId} />;
};