import { People } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { QueryResultsDto, RespondentDto } from '../../../../dtos';
import { PaginatedProps, DataTableColumn, PaginatedDataTable } from '../../../CoreLib/library';
import { SurveyBuilderContext } from '../../utils';

interface IAddRespondentsTableProps {
    respondentData: QueryResultsDto<RespondentDto>;
    isLoading: boolean;
    paginatedProps: PaginatedProps | null;
    setPaginatedProps: (pagProps: PaginatedProps | null) => void;
}
export const AddRespondentsTable: FC<IAddRespondentsTableProps> = props => {
    const { respondentData, isLoading, setPaginatedProps} = props;
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const tableColumns: DataTableColumn<RespondentDto>[] = [
        { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
        { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
        { key: 'title', label: 'Title', sortKey: 'TITLE' },
        { key: 'department', label: 'Department', sortKey: 'DEPARTMENT', fieldMapper: (row: RespondentDto) => row.department?.name },
        { key: 'manager', label: 'Manager', sortKey: 'MANAGER', fieldMapper: (row: RespondentDto) => row.manager ? `${row.manager?.firstName} ${row.manager?.lastName}` : '' },
        { key: 'formType', label: 'Form Type', sortKey: 'FORM_TYPE', fieldMapper: (row: RespondentDto) => row.surveyTemplate ? `${row.surveyTemplate?.name}` : '' }
    ];
    const [selectedRespondents, setSelectedRespondents] = useState<readonly RespondentDto[]>([]);

    const addMultipleRespondentsQueue = (respondents: readonly RespondentDto[]) => {
        let localArray = [...surveyPayload.respondentQueue];
        respondents.forEach((respondent) => {
            if (!localArray.some((localArray) => localArray.id === respondent.id)) {
                localArray = [...localArray, respondent];
            }
        });
        setSurveyPayload({
            ...surveyPayload,
            respondentQueue: localArray,
        });
    };

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="h1">
                        <People /> Results
                    </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end" alignItems="flex-end">
                    <Button
                        variant="contained"
                        disabled={selectedRespondents.length < 1}
                        onClick={() => addMultipleRespondentsQueue(selectedRespondents)}
                    >
                        Add To Respondents
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={respondentData}
                    defaultSortKey="FIRST_NAME"
                    setPagination={setPaginatedProps}
                    selectedRecords={selectedRespondents}
                    setSelectedRecords={setSelectedRespondents as any}
                />
            </Grid>
        </Grid>
    );
}