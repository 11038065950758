import { Speed } from "@mui/icons-material";
import { Paper } from "@mui/material";
import { FC } from "react";
import { ActivityStreamTable } from "../../../ActivityStream";
import { ICommunicationTableProps } from './types';

export const RespondentCommunicationTable: FC<ICommunicationTableProps> = props => {
    const { respondentId } = props;

    return (
        <Paper sx={{ padding: 3 }}>
            <ActivityStreamTable
                headerIcon={<Speed />}
                headerText='Activity Stream'
                isBorderVisible={true}
                respondentId={respondentId}
                headerAlignment='center'
            />
        </Paper>
    )
}