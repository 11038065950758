import { FC, useContext } from 'react';
import { Box } from '@mui/material';
import { CompanyDashboardContext } from '../../Utils';
import { CompanyDashboardOverviewTab, CompanyDashboardSurveysTab, CompanyDashboardBCMTab, CompanyDashboardRespondentsTab, CompanyDashboardDepartmentsTab, CompanyDashboardStreamTab } from './Tabs';
import { CompanyDashboardComponentsTab } from './Tabs/CompanyDashboardComponents';

const tabContentMap: Record<number, FC> = {
    0: CompanyDashboardOverviewTab,
    1: CompanyDashboardSurveysTab,
    2: CompanyDashboardBCMTab,
    3: CompanyDashboardComponentsTab,
    4: CompanyDashboardRespondentsTab,
    5: CompanyDashboardDepartmentsTab,
    6: CompanyDashboardStreamTab
};

export const CompanyDashboardTabContentViewer: FC = () => {
    const { selectedTab } = useContext(CompanyDashboardContext);
    const TabContent = tabContentMap[selectedTab];

    if (!TabContent) {
        return null;
    }

    return (
        <Box p={2}>
            <TabContent />
        </Box>
    );
};