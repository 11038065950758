import { FC } from 'react';
import { BusinessComponentDetailsMetadataDto, WorkItemDescriptionDisplayMode } from '../../../../dtos';
import { Box, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

const DEFAULT_BC: BusinessComponentDetailsMetadataDto = {
    name: 'Name Not Found',
    description: '',
    displayMode: WorkItemDescriptionDisplayMode.Hide
}

export interface IBusinessComponentLabelProps {
    bcInfo?: BusinessComponentDetailsMetadataDto,
    isBCMTaker?: boolean;
};

export const BusinessComponentLabel: FC<IBusinessComponentLabelProps> = ({ bcInfo = DEFAULT_BC, isBCMTaker }) => {
    if (!bcInfo.description) {
        return <Typography>{bcInfo.name}</Typography>
    }

    const displayMode = isBCMTaker && bcInfo.displayMode !== WorkItemDescriptionDisplayMode.Hide ? WorkItemDescriptionDisplayMode.Show : bcInfo.displayMode;

    switch (displayMode) {
        case WorkItemDescriptionDisplayMode.Hide:
            return <Typography>{bcInfo.name}</Typography>
        case WorkItemDescriptionDisplayMode.Show:
            return (
                <Box>
                    <Typography>{bcInfo.name}</Typography>
                    <ul>
                        {bcInfo.description.split(',').map((text, idx) => (<li key={`description-${idx}`}>{text.trim()}</li>))}
                    </ul>
                </Box>
            )
        case WorkItemDescriptionDisplayMode.Hover:
            return (
                <Box display='flex' gap={1} alignItems='center'>
                    <Box><Typography>{bcInfo.name}</Typography></Box><Tooltip title={bcInfo.description}><Info fontSize='small' /></Tooltip>
                </Box>
            )
        default:
            console.error('Invalid business component description display mode');
            return null;
    }
};