import { Divider, FormControl, FormLabel, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FunctionComponent, useMemo, useState } from "react";

export interface ITimePeriodInputProps {
    index?: number;
    showLabels?: boolean;
    defaultStartDate?: Date | null | string;
    defaultEndDate?: Date | null | string;
    handleDatesChanged: (startDate?: Date | null | string, endDate?: Date | null | string) => void;
    labelSize?: string;
    quarterOptionsEnabled?: boolean;
};

export const TimePeriodInput: FunctionComponent<ITimePeriodInputProps> = (props) => {
    const {
        index = 0,
        showLabels,
        defaultStartDate,
        defaultEndDate,
        handleDatesChanged,
        labelSize,
        quarterOptionsEnabled = true
    } = props;
    const [quickFillValue, setQuickFillValue] = useState<string>('');
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const quickFillValues = useMemo(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);
        const options = [''] as string[];

        options.push(`${currentYear}`);
        options.push(`${currentYear - 1}`);
        options.push(`${currentYear - 2}`);

        if (quarterOptionsEnabled) {
            for (let y = currentYear; y > currentYear - 3; y--) {
                options.push('-');
                let quarter = 4;
                if (y === currentYear) {
                    quarter = currentQuarter;
                }
                for (let i = quarter; i > 0; i--) {
                    options.push(`${y} Q${i}`);
                }
            }
        }

        return (
            options.map((x, idx) => {
                return x === '-' ? <Divider key={`${index}-q-${idx}`} /> : <MenuItem key={`${index}-q-${x}`} value={x}>{x}</MenuItem>;
            })
        );
    }, [index, quarterOptionsEnabled]);

    const handleQuickFillChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        setQuickFillValue(value);

        if (value !== '') {
            const split = value.split(' ');
            const year = parseInt(split[0]);
            let quarter = '';
            if (split.length > 1) {
                quarter = split[1];
            }

            const startDate = new Date()
            const endDate = new Date()

            startDate.setFullYear(year);
            endDate.setFullYear(year);

            switch (quarter) {
                case 'Q1':
                    startDate.setMonth(0);
                    startDate.setDate(1);

                    endDate.setMonth(2);
                    endDate.setDate(31);
                    break;
                case 'Q2':
                    startDate.setMonth(3);
                    startDate.setDate(1);

                    endDate.setMonth(5);
                    endDate.setDate(30);
                    break;
                case 'Q3':
                    startDate.setMonth(6);
                    startDate.setDate(1);

                    endDate.setMonth(8);
                    endDate.setDate(30);
                    break;
                case 'Q4':
                    startDate.setMonth(9);
                    startDate.setDate(1);

                    endDate.setMonth(11);
                    endDate.setDate(31);
                    break;
                default:
                    startDate.setMonth(0);
                    startDate.setDate(1);
                    endDate.setMonth(11);
                    endDate.setDate(31);
            }

            setStartDate(startDate);
            setEndDate(endDate);
            handleDatesChanged(startDate, endDate);
        }
    };

    return (
        <Grid item container direction='row' spacing={2} alignItems='end' wrap='nowrap' xs={12}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    {showLabels ? <FormLabel sx={{ fontSize: labelSize }}>Quick Fill</FormLabel> : <></>}
                    <Select
                        value={quickFillValue ?? ''}
                        onChange={handleQuickFillChange}>
                        <MenuItem value='' key={`${index}-q-none`}></MenuItem>
                        {quickFillValues}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item container direction='row' xs={9} wrap='nowrap' spacing={1}>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        {showLabels ? <FormLabel sx={{ fontSize: labelSize }}>Period Start Date</FormLabel> : <></>}
                        <DatePicker
                            value={startDate ?? null}
                            onChange={
                                (value) => {
                                    setQuickFillValue('');
                                    setStartDate(value);
                                    handleDatesChanged(value, endDate);
                                }
                            }
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item alignSelf='center' sx={showLabels ? { marginTop: '20px' } : undefined}>
                    <span>to</span>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        {showLabels ? <FormLabel sx={{ fontSize: labelSize }}>Period End Date</FormLabel> : <></>}
                        <DatePicker
                            value={endDate ?? null}
                            onChange={(value) => {
                                setQuickFillValue('');
                                setEndDate(value);
                                handleDatesChanged(startDate, value);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
            </Grid>

        </Grid >
    );
};