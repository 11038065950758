import { Delete, People } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { CompanyDto, EmployeeDto } from '../../../../../dtos';
import { IInMemoryTable, InMemoryTable } from '../../../../InMemoryTable';
import { StepperContentSectionTitle } from '../StepperContentSectionTitle';
import { AddEditEmployeeModal, EmployeeDetails } from './AddEditEmployeeModal';
import { SurveyBuilderContext } from '../../../utils';
import { emptyGuid } from '../../../../../models';

export interface IEmployeeListViewProps {
    company: CompanyDto;
}

export const EmployeeListView: FC<IEmployeeListViewProps> = ({ company }) => {
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState<EmployeeDetails>();

    const handleAddEditEmployee = useCallback(
        ({ employee, associatedBusinessComponents }: EmployeeDetails) => {
            const existingRecordIndex = surveyPayload.businessComponentMatrixConfig.employees.findIndex((bc) => bc.id === employee.id);
            var updatedSurveyPayload = _.cloneDeep(surveyPayload);

            if (existingRecordIndex === -1) {
                updatedSurveyPayload.businessComponentMatrixConfig.employees = [...updatedSurveyPayload.businessComponentMatrixConfig.employees, employee];
            } else {
                let updatedEmployees = updatedSurveyPayload.businessComponentMatrixConfig.employees;
                updatedEmployees.splice(existingRecordIndex, 1, employee);
                updatedSurveyPayload.businessComponentMatrixConfig.employees = updatedEmployees;
            }

            let otherBusinessComponentEmployees = updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees.filter((bce) => bce.employeeId !== employee.id);
            const maxOrderOfOtherJoiningRecords = _.maxBy(otherBusinessComponentEmployees, x => x.order)?.order ?? 0;
            const startingOrderForNewRecords = maxOrderOfOtherJoiningRecords + 1;
            updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees = [
                ...otherBusinessComponentEmployees,
                ...associatedBusinessComponents.map((businessComponentId, idx) => ({ order: startingOrderForNewRecords + idx, businessComponentId, employeeId: employee.id,  })),
            ];

            setSurveyPayload(updatedSurveyPayload);
            setSelectedEmployeeDetails(undefined);
        },
        [surveyPayload, setSurveyPayload]
    );

    const initializeNewEmployee = useCallback(() => {
        setSelectedEmployeeDetails({
            employee: { id: emptyGuid, name: '' },
            associatedBusinessComponents: [],
        });
    }, []);

    const editEmployee = useCallback(
        (bc: EmployeeDto) => {
            setSelectedEmployeeDetails({
                employee: bc,
                associatedBusinessComponents: surveyPayload.businessComponentMatrixConfig.businessComponentEmployees.filter((bce) => bce.employeeId === bc.id).map((bce) => bce.businessComponentId),
            });
        },
        [surveyPayload]
    );

    const deleteEmployee = useCallback(
        (id: string) => {
            var updatedSurveyPayload = _.cloneDeep(surveyPayload);
            updatedSurveyPayload.businessComponentMatrixConfig.employees = surveyPayload.businessComponentMatrixConfig.employees.filter((bc) => bc.id !== id);
            updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees = updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees.filter((bce) => bce.employeeId !== id);
            setSurveyPayload(updatedSurveyPayload);
        },
        [surveyPayload, setSurveyPayload]
    );

    const currentEmployees = useMemo(() => {
        return surveyPayload.businessComponentMatrixConfig.employees;
    }, [surveyPayload.businessComponentMatrixConfig.employees]);

    const componentTableProps: IInMemoryTable<EmployeeDto> = useMemo(() => {
        return {
            rows: currentEmployees,
            defaultSortKey: 'name',
            defaultSortDirection: 'asc',
            defaultRowsPerPage: 10,
            onRowClick: editEmployee,
            columnDefinitions: [
                {
                    key: 'name',
                    label: 'Component',
                    value: 'name',
                    isSortable: true,
                },
                {
                    key: '',
                    label: '',
                    value: (row) => (
                        <div className='hover-content'>
                            <Tooltip title={`Delete Employee`}>
                                <IconButton
                                    color='primary'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteEmployee(row.id);
                                    }}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ),
                    isSortable: false,
                    width: '48px',
                },
            ],
        };
    }, [currentEmployees, deleteEmployee, editEmployee]);

    return (
        <>
            <Grid container direction='column'>
                <StepperContentSectionTitle icon={<People />} title='Respondent' onAddClicked={initializeNewEmployee} />
                <Grid item container direction='row' sx={{ maxHeight: '496px', overflow: 'auto' }}>
                    <InMemoryTable {...componentTableProps} />
                </Grid>
            </Grid>
            <AddEditEmployeeModal
                handleUpdate={handleAddEditEmployee}
                allBusinessComponents={surveyPayload.businessComponentMatrixConfig.businessComponents}
                alreadyAddedRespondentIds={currentEmployees.map(ce => ce.id)}
                companyId={company.id}
                employeeDetails={selectedEmployeeDetails}
                onClose={() => setSelectedEmployeeDetails(undefined)}
            />
        </>
    );
};
