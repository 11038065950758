import { AddCircle, Person } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useState } from 'react';
import * as uuid from 'uuid';
import { ScheduleDetailAddedRespondentDto, ScheduleDetailDto } from '../../dtos';
import { useAddRespondentToScheduleDetailMutation } from '../../store/api/schedule-api';
import { StandardDialogHeader } from '../CommonComponents';
import { FormInput } from '../CoreLib/library';

export interface IScheduleDetailRespondentQuickAddProps {
    scheduleDetail: ScheduleDetailDto;
    companyName: string;
    disabled?: boolean;
    tooltip?: string;
    onAdd?: (recordId: string) => void;
}

export const ScheduleDetailRespondentQuickAdd: FC<IScheduleDetailRespondentQuickAddProps> = ({ scheduleDetail, tooltip = 'Add New', companyName, disabled, onAdd = () => {} }) => {
    const [addRespondent, { isLoading }] = useAddRespondentToScheduleDetailMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [addRespondentIsVisible, setAddRespondentIsVisible] = useState(false);
    const [newRespondentName, setNewRespondentName] = useState('');

    const handleClose = useCallback(() => {
        setAddRespondentIsVisible(false);
        setNewRespondentName('');
    }, []);

    const handleSave = useCallback(() => {
        let recordToAdd: ScheduleDetailAddedRespondentDto = {
            id: uuid.v4(),
            scheduleDetailId: scheduleDetail.id,
            name: newRespondentName,
        };

        addRespondent({
            scheduleId: scheduleDetail.scheduleId,
            scheduleDetailId: scheduleDetail.id,
            userAddedRespondent: recordToAdd,
        })
            .unwrap()
            .then(() => {
                onAdd(recordToAdd.id);
                handleClose();
            })
            .catch(() => {
                enqueueSnackbar('An error occurred while attempting to add employee', { variant: 'error' });
            });
    }, [addRespondent, enqueueSnackbar, handleClose, newRespondentName, onAdd, scheduleDetail.id, scheduleDetail.scheduleId]);

    return (
        <>
            <Tooltip title={tooltip}>
                <Box>
                    <IconButton disabled={disabled} color='primary' size='large' onClick={() => setAddRespondentIsVisible(true)}>
                        <AddCircle fontSize='inherit' />
                    </IconButton>
                </Box>
            </Tooltip>
            <Dialog fullWidth maxWidth='sm' open={addRespondentIsVisible} onClose={handleClose}>
                <StandardDialogHeader icon={<Person />} text='Add Employee' />
                <DialogContent sx={{ mt: 2 }}>
                    <Typography>Employee will be added to {companyName} only for this matrix.</Typography>
                    <FormInput value={newRespondentName} onChange={(e) => setNewRespondentName(e.target.value)} label='Name' required fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={handleSave} variant='contained' color='primary'>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
