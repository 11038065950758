import { Grid, Typography } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import { emptyGuid } from '../../../models';
import { useGetCompanySchedulesQuery } from '../../../store/api/schedule-api';
import { PaginatedProps, useFailedActionSnackbar } from '../../CoreLib/library';
import { ScheduleTableContext } from './ScheduleTableContext';
import { SchedulesTable } from './SchedulesTable';
import { useGetScheduleTableHeaders } from './utils/useGetScheduleTableHeaders';
import { CompanyDto } from '../../../dtos';
import { DataFilterContext } from '../../../Contexts';

export interface ISchedulesProps {
    company?: CompanyDto;
    studyId?: string;
    searchText?: string;
}

export const Schedules: FC<ISchedulesProps> = props => {
    const {
        company,
        studyId,
        searchText: externalSearchText,
    } = props;
    const [searchText, setSearchText] = useState('');
    const [showOnlyExpired, setShowOnlyExpired] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>({
        sortKey: "SCHEDULED",
        sortAsc: false,
        page: 0,
        pageSize: 25,
    });
    const {
        showInactive,
        scheduledFromDateFilter: fromDateFilter,
        scheduledToDateFilter: toDateFilter,
        periodStartDateFilter,
        periodEndDateFilter,
        formTypeFilter,
        reminderFilter,
        approvedByFilter,
        statusFilter,
        studyFilter
    } = useContext(DataFilterContext);

    const { data, isLoading, isError, refetch } = useGetCompanySchedulesQuery({
        parentId: company?.id ?? emptyGuid,
        searchText: externalSearchText ?? searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        isExpired: showOnlyExpired,
        fromDate: fromDateFilter?.toISOString(),
        toDate: toDateFilter?.toISOString(),
        formType: formTypeFilter ? encodeURIComponent(formTypeFilter) : undefined,
        status: statusFilter ? encodeURIComponent(statusFilter) : undefined,
        reminder: reminderFilter ? encodeURIComponent(reminderFilter) : undefined,
        approvedBy: approvedByFilter ? encodeURIComponent(approvedByFilter) : undefined,
        periodStartDate: periodStartDateFilter?.toISOString(),
        periodEndDate: periodEndDateFilter?.toISOString(),
        assignedToStudyId: studyId ?? studyFilter,
    });

    useFailedActionSnackbar('retrieve', 'company schedules', isError);

    const { tableHeaders } = useGetScheduleTableHeaders({
        companyId: company?.id,
        hideCompanyName: !!company,
        hideReminders: !!studyId,
        hideApprovedBy: true,
    });

    const tableKey = useMemo(() => {
        return (
            <Grid item justifyContent='flex-end'>
                <Typography variant='body2' align='right'>
                    CRE = Created, ISS = Issued, STA = Started, PAU = Paused, COM = Completed
                </Typography>
            </Grid>
        )
    }, []);

    return (
        <ScheduleTableContext.Provider value={{
            searchText,
            setSearchText,
            paginatedProps,
            setPaginatedProps,
            showOnlyExpired,
            setShowOnlyExpired,
            surveyAlias: 'Survey',
            surveyAliasShort: 'Survey'
        }}>
            {!isLoading && (
                <SchedulesTable
                    title=''
                    icon={<></>}
                    company={company}
                    schedules={data!}
                    tableColumns={tableHeaders}
                    isLoading={isLoading}
                    refetch={refetch}
                    underTableContent={tableKey}
                    editRoute='survey'
                    detailsRoute='schedule'
                    hideSearch
                    hideBorders
                />
            )}
        </ScheduleTableContext.Provider>
    );
}