import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Box, Button, Chip, Grid, TextField, Typography } from "@mui/material";
import { FunctionComponent, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { RespondentDto } from "../../../../dtos";
import { useGetCompanyRespondentsQuery } from "../../../../store";
import { RespondentQuickAdd } from "../../../QuickAdds";
import { SurveyBuilderContext } from "../../utils";

interface IDistributionCCBCCRecipientsProps {
    addToDistributionConfig: (arrayName: string, respondent: RespondentDto) => void;
    deleteDistributionConfig: (arrayName: string, respondent?: RespondentDto) => void;
}

export const DistributionCCBCCRecipients: FunctionComponent<IDistributionCCBCCRecipientsProps> = (props) => {
    const { addToDistributionConfig, deleteDistributionConfig } = props;
    const { surveyPayload } = useContext(SurveyBuilderContext);
    const [CCInputValue, setCCInputValue] = useState('');
    const [CCValue] = useState('');
    const [BCCValue] = useState('');
    const [BCCInputValue, setBCCInputValue] = useState('');
    const { id } = useParams();
    const { data: CCAutoCompleteData, isLoading: CCAutoCompleteLoading, refetch: CCRefetch } = useGetCompanyRespondentsQuery({
        parentId: id!,
        searchText: CCInputValue,
        page: 0,
        pageSize: 100
    });
    const { data: BCCAutoCompleteData, isLoading: BCCAutoCompleteLoading, refetch: BCCRefetch } = useGetCompanyRespondentsQuery({
        parentId: id!,
        searchText: BCCInputValue,
        page: 0,
        pageSize: 100
    });

    const handleAddChip = (arrayName: string, respondent: RespondentDto) => {
        addToDistributionConfig(arrayName, respondent);
        if (arrayName === "CCRecipient") {
            setCCInputValue("");
        }
        else {
            setBCCInputValue("");
        }
    }

    const handleDeleteChip = (arrayName: string, respondent?: RespondentDto) => {
        if (respondent) {
            deleteDistributionConfig(arrayName, respondent);
        }
        else {
            deleteDistributionConfig(arrayName);
        }

    }

    const handleRemoveAll = (arrayName: string) => {
        deleteDistributionConfig(arrayName);
    }

    return (
        <Box sx={{
            backgroundColor: 'primary.contrastText',
            py: 4,
            px: 4
        }}>
            <Grid item container direction="row" alignItems="start">
                <Grid item container direction="column" justifyContent="start" xs={6}>
                    <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                        Do you want to add any CC or BCC recipients?
                    </Typography>
                </Grid>
                <Grid item container direction="row" alignItems="center" justifyContent="flex-end" xs={6}>
                    <Grid item>
                        <RespondentQuickAdd companyId={id!} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 4 }}>
                <Grid item container direction="row" alignItems="flex-start" justifyContent="flex-start" xs={'auto'} >
                    <Typography variant='body1' sx={{ fontWeight: 'bold', marginRight: '10px' }}>
                        CC
                    </Typography>
                </Grid>
                <Grid item container direction="row" justifyContent="start" xs={'auto'}>
                    <Autocomplete
                        loading={CCAutoCompleteLoading}
                        freeSolo
                        id="CC-respondents-dropdown"
                        options={CCAutoCompleteData ? CCAutoCompleteData.pageResults.filter(x => !surveyPayload.distributionConfig.BCCRecipients.find(y => y.id === x.id) && !surveyPayload.distributionConfig.CCRecipients.find(y => y.id === x.id) && !surveyPayload.respondentQueue.find(y => y.id === x.id)).map((row, index) => {
                            return { label: `${row.firstName} ${row.lastName}`, value: row }
                        }) : []}
                        sx={{ width: 275 }}
                        value={CCValue}
                        inputValue={CCInputValue}
                        onChange={(event: any, newValue: any | null) => {
                            if (newValue) {
                                handleAddChip("CCRecipient", newValue.value);
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setCCInputValue(newInputValue);
                            CCRefetch();
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key === 'Enter') {
                                event.defaultMuiPrevented = true;
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select individual"
                            />
                        )}
                    />
                </Grid>
                <Grid item container direction="row" justifyContent="start" xs={6}>
                    {surveyPayload.distributionConfig.CCRecipients.map((respondent, index) => {
                        return (
                            <Grid item xs="auto" sx={{ mr: 2, mb: 1 }} key={index}>
                                <Chip
                                    label={`${respondent.firstName} ${respondent.lastName}`}
                                    size="small"
                                    variant="outlined"
                                    style={{ backgroundColor: "#E9E9E9", borderColor: "#707070", maxWidth: 150 }}
                                    onDelete={() => handleDeleteChip("CCRecipient", respondent)}
                                    deleteIcon={<CancelIcon style={{ color: "#333333" }} />}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container direction="row" alignItems="start" justifyContent="start" xs={'auto'} sx={{ marginLeft: 'auto' }} >
                    <Button
                        variant='contained'
                        onClick={() => handleRemoveAll("CCRecipient")}
                        sx={{ width: 175 }}
                    >
                        Remove All CC
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center" spacing={2}>
                <Grid item container direction="row" alignItems="flex-start" justifyContent="flex-start" xs={'auto'} >
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        BCC
                    </Typography>
                </Grid>
                <Grid item container direction="row" justifyContent="start" xs={'auto'}>
                    <Autocomplete
                        loading={BCCAutoCompleteLoading}
                        freeSolo
                        id="BCC-respondents-dropdown"
                        options={BCCAutoCompleteData ? BCCAutoCompleteData.pageResults.filter(x => !surveyPayload.distributionConfig.CCRecipients.find(y => y.id === x.id) && !surveyPayload.distributionConfig.BCCRecipients.find(y => y.id === x.id) && !surveyPayload.respondentQueue.find(y => y.id === x.id)).map((row, index) => {
                            return { label: `${row.firstName} ${row.lastName}`, value: row }
                        }) : []}
                        sx={{ width: 275 }}
                        value={BCCValue}
                        inputValue={BCCInputValue}
                        onChange={(event: any, newValue: any | null) => {
                            if (newValue) {
                                handleAddChip("BCCRecipient", newValue.value);
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setBCCInputValue(newInputValue);
                            BCCRefetch();
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key === 'Enter') {
                                event.defaultMuiPrevented = true;
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select individual"
                            />
                        )}
                    />
                </Grid>
                <Grid item container direction="row" justifyContent="start" xs={6}>
                    {surveyPayload.distributionConfig.BCCRecipients.map((respondent, index) => {
                        return (
                            <Grid item xs="auto" sx={{ mr: 2, mb: 1 }} key={index}>
                                <Chip
                                    label={`${respondent.firstName} ${respondent.lastName}`}
                                    size="small"
                                    variant="outlined"
                                    style={{ backgroundColor: "#E9E9E9", borderColor: "#707070", maxWidth: 150 }}
                                    onDelete={() => handleDeleteChip("BCCRecipient", respondent)}
                                    deleteIcon={<CancelIcon style={{ color: "#333333" }} />}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container direction="row" alignItems="start" justifyContent="start" xs={'auto'} sx={{ marginLeft: 'auto' }} >
                    <Button
                        variant='contained'
                        onClick={() => handleRemoveAll("BCCRecipient")}
                        sx={{ width: 175 }}
                    >
                        Remove All BCC
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}