import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    OutlinedInput,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from '@mui/material';
import { ArchiveDepartmentsDto, CompanyDto, DepartmentDto } from '../../../../dtos';
import { DepartmentSelect } from '../../../../Components/CommonInputs';
import { ArchiveActionType } from '../../../../dtos/generated/ArchiveActionType';
import { useActivateInactivateSnackbar, useFailedActionSnackbar } from '../../../../Components/CoreLib/library';
import { useBulkArchiveDepartmentsMutation } from '../../../../store';

export interface IArchiveDepartmentsModalProps {
    company: CompanyDto;
    departments: ArchiveDepartmentsDto[];
    isVisible: boolean;
    handleCancel: () => void;
}

export const ArchiveDepartmentsModal: FunctionComponent<IArchiveDepartmentsModalProps> = (props) => {
    const { company, departments, isVisible, handleCancel } = props;
    const [departmentsToArchive, setDepartmentsToArchive] = useState<ArchiveDepartmentsDto[]>([]);
    const [index, setIndex] = useState(0);
    const [departmentId, setDepartmentId] = useState('');
    const [departmentError, setDepartmentError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [doForAllRemaining, setDoForAllRemaining] = useState(false);
    const [selectedArchiveAction, setSelectedArchiveAction] = useState(ArchiveActionType.ClearExisting.toString());
    const [archiveRecords, { isError: isArchiveError, reset: resetArchive, data: archiveResult }] = useBulkArchiveDepartmentsMutation();

    useActivateInactivateSnackbar('Inactivate', 'department', 'departments', archiveResult, resetArchive);
    useFailedActionSnackbar('inactivate', 'departments', isArchiveError, resetArchive);

    const handleClose = () => {
        handleCancel();
        setIndex(0);
        setName('');
        setDepartmentId('');
        setNameError('');
    };

    useEffect(() => {
        setDepartmentsToArchive(departments);
    }, [departments]);

    const departmentsThatRequireAction = useMemo(() => {
        return departmentsToArchive.filter((x) => x.archiveAction !== ArchiveActionType.NoActionRequired);
    }, [departmentsToArchive]);

    const currentArchiveRequest = useMemo(() => {
        if (departmentsThatRequireAction.length === 0) {
            return;
        }
        if (departmentsThatRequireAction.length > index) {
            return departmentsThatRequireAction[index];
        } else {
            console.error('Index is out of range of the current list of departments that require action');
        }
    }, [departmentsThatRequireAction, index]);

    useEffect(() => {
        setDepartmentId(currentArchiveRequest?.replacementDepartmentId ?? '');
        setName(currentArchiveRequest?.newDepartmentName ?? '');
    }, [currentArchiveRequest]);

    const validateName = useCallback(() => {
        if (name.trim() === '') {
            setNameError('Name is required.');
            return false;
        }
        const existingDepartmentNames = company.departments.filter((x) => x.isActive).map((x) => x.name.toLowerCase());
        if (existingDepartmentNames.includes(name.trim().toLowerCase())) {
            setNameError('Department name already exists.');
            return false;
        }
        setNameError('');
        return true;
    }, [name, company.departments]);

    const validateDepartment = useCallback(() => {
        if (selectedArchiveAction === ArchiveActionType.ReassignToExisting.toString() && departmentId === '') {
            setDepartmentError('Department is required.');
            return false;
        }
        setDepartmentError('');
        return true;
    }, [departmentId, selectedArchiveAction]);

    const isCurrentStepValid = useMemo(() => {
        switch (selectedArchiveAction) {
            case ArchiveActionType.ClearExisting.toString():
                return true;
            case ArchiveActionType.ReassignToExisting.toString():
                return validateDepartment();
            case ArchiveActionType.CreateNew.toString():
                return validateName();
            default:
                console.error('Unable to validate current step because of invalid selectedArchiveAction');
                return false;
        }
    }, [selectedArchiveAction, validateName, validateDepartment]);

    const handleContinue = useCallback(() => {
        if (!isCurrentStepValid || !currentArchiveRequest) {
            return;
        }
        const isMoreDepartmentsToUpdate = departmentsThatRequireAction.length > index + 1;

        let updatedDepartmentsToArchive: ArchiveDepartmentsDto[] = [];
        if (doForAllRemaining) {
            const departmentIdsToUpdate = departmentsThatRequireAction.slice(index).map((x) => x.departmentId);
            updatedDepartmentsToArchive = departmentsToArchive.map((d) =>
                departmentIdsToUpdate.includes(d.departmentId)
                    ? {
                          ...d,
                          archiveAction: Number(selectedArchiveAction),
                          replacementDepartmentId: departmentId !== '' ? departmentId : undefined,
                          newDepartmentName: name !== '' ? name : undefined,
                      }
                    : d
            );
        } else {
            let updatedArchiveRequest: ArchiveDepartmentsDto = {
                ...currentArchiveRequest,
                archiveAction: Number(selectedArchiveAction),
                replacementDepartmentId: departmentId !== '' ? departmentId : undefined,
                newDepartmentName: name !== '' ? name : undefined,
            };
            updatedDepartmentsToArchive = departmentsToArchive.map((d) => (d.departmentId === currentArchiveRequest?.departmentId ? updatedArchiveRequest : d));
        }
        if (doForAllRemaining || !isMoreDepartmentsToUpdate) {
            archiveRecords({
                parentId: company.id,
                body: updatedDepartmentsToArchive,
            });
            handleCancel();
        } else {
            setDepartmentsToArchive(updatedDepartmentsToArchive);
            setSelectedArchiveAction(ArchiveActionType.ClearExisting.toString());
            setIndex(index + 1);
        }
    }, [
        isCurrentStepValid,
        currentArchiveRequest,
        departmentId,
        name,
        departmentsToArchive,
        departmentsThatRequireAction,
        index,
        archiveRecords,
        company.id,
        handleCancel,
        selectedArchiveAction,
        doForAllRemaining,
    ]);

    const handleSelectionChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        switch (value) {
            case ArchiveActionType.ClearExisting.toString():
                setDepartmentId('');
                setDepartmentError('');
                setName('');
                setNameError('');
                break;
            case ArchiveActionType.ReassignToExisting.toString():
                setName('');
                setNameError('');
                break;
            case ArchiveActionType.CreateNew.toString():
                setDepartmentId('');
                setDepartmentError('');
                break;
        }
        setSelectedArchiveAction(value);
    };

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDoForAllRemaining(event.target.checked);
    }, []);

    const onDepartmentChange = useCallback((department?: DepartmentDto) => {
        setDepartmentId(department?.id ?? '');
    }, []);

    const selectableDepartmentFilter = useCallback(
        (dpt: DepartmentDto) => {
            const archivingDepartmentIds = departments.map((x) => x.departmentId);
            const isDepartmentBeingArchived = archivingDepartmentIds.includes(dpt.id);
            return !isDepartmentBeingArchived;
        },
        [departments]
    );

    const respondentString = useMemo(() => {
        const department = company.departments.find((d) => d.id === currentArchiveRequest?.departmentId);
        if (department) {
            if (department.respondentCount! > 1) {
                return `There are currently ${department?.respondentCount} respondents assigned to the department "${department?.name}". What would you like to do with these respondents?`;
            } else {
                return `There is currently ${department?.respondentCount} respondent assigned to the department "${department?.name}". What would you like to do with this respondent?`;
            }
        } else {
            return '';
        }
    }, [currentArchiveRequest, company.departments]);

    return (
        <Dialog
            open={isVisible}
            onClose={() => {}}
            maxWidth='sm'
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: '100%',
                },
            }}>
            <DialogTitle>Reassign Department</DialogTitle>
            <DialogContent>
                <DialogContentText>{respondentString}</DialogContentText>
                <FormControl>
                    <RadioGroup value={selectedArchiveAction} onChange={handleSelectionChange}>
                        <FormControlLabel
                            value={ArchiveActionType.ClearExisting}
                            control={<Radio />}
                            label='Leave the respondent(s) with no department assigned to them.'
                        />
                        <FormControlLabel
                            value={ArchiveActionType.ReassignToExisting}
                            control={<Radio />}
                            label={
                                <Grid container direction='column' sx={{ mt: 5 }}>
                                    <Grid item>
                                        <Typography>Reassign the respondent(s) to the following department:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <DepartmentSelect
                                            companyId={company.id}
                                            selectedDepartmentId={departmentId}
                                            handleSelectedDepartmentChange={onDepartmentChange}
                                            departmentFilter={selectableDepartmentFilter}
                                            isRequired={selectedArchiveAction === ArchiveActionType.ReassignToExisting.toString()}
                                            errorMessage={departmentError}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />
                        <FormControlLabel
                            value={ArchiveActionType.CreateNew}
                            control={<Radio />}
                            label={
                                <Grid container direction='column' sx={{ mt: 5 }}>
                                    <Grid item>
                                        <Typography>Create a new department and assign the respondent(s) to it.</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl
                                            error={nameError !== ''}
                                            fullWidth
                                            disabled={selectedArchiveAction !== '3'}
                                            required={selectedArchiveAction === '3'}>
                                            <FormLabel>New Department</FormLabel>
                                            <OutlinedInput value={name} onChange={handleNameChange} onBlur={validateName} required />
                                            <FormHelperText>{nameError}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Switch checked={doForAllRemaining} onChange={handleToggleChange} />}
                            label={<Typography sx={{ fontSize: '12px' }}>Do this for all remaining departments</Typography>}
                            labelPlacement='end'
                        />
                    </Grid>
                    <Grid item container direction='row' xs={6} justifyContent='flex-end' spacing={2}>
                        <Grid item>
                            <Button onClick={handleClose} variant='outlined'>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleContinue} variant='contained' color='primary' disabled={!isCurrentStepValid}>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
