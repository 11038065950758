import { Ballot } from '@mui/icons-material';
import { FC } from 'react';
import { ISurveyStepperStepConfiguration, SurveyStepper } from '../Components/surveyStepper/SurveyStepper';
import {
    AddRespondentsStepContent,
    EmailStepContent,
    FormTypeStepContents,
    FrequencyStepContent,
    StandardDistributionContent,
    SurveySettingsStepContents,
    SurveySummaryStepContent,
    TimePeriodContent
} from '../Components/surveyStepper/steps';

export const SurveyStepperView: FC = () => {
    const steps: ISurveyStepperStepConfiguration[] = [
        {
            label: 'Settings',
            renderer: ({ company }) => <SurveySettingsStepContents companyId={company.id} />,
            validationMethod: (validator) => validator.isSettingsStepValid,
        },
        {
            label: 'Time Period',
            renderer: () => <TimePeriodContent allowMultipleTimePeriods={true}/>,
            validationMethod: (validator) => validator.isTimePeriodStepValid,
        },
        { label: 'Form', renderer: () => <FormTypeStepContents />, validationMethod: (validator) => validator.isFormStepValid },
        {
            label: 'Respondents',
            renderer: ({ company }) => <AddRespondentsStepContent company={company} />,
            validationMethod: (validator) => validator.isRespondentsStepValid,
        },
        {
            label: 'Distribution',
            renderer: () => <StandardDistributionContent />,
            validationMethod: (validator) => validator.isDistributionStepValid,
        },
        {
            label: 'Frequency',
            renderer: () => <FrequencyStepContent />,
            validationMethod: (validator) => validator.isFrequencyStepValid,
        },
        {
            label: 'Email',
            renderer: ({ emailTemplates }) => <EmailStepContent emailTemplates={emailTemplates} />,
            validationMethod: (validator) => validator.isEmailStepValid,
        },
        {
            label: 'Summary',
            renderer: ({ emailTemplates }) => <SurveySummaryStepContent emailTemplates={emailTemplates} />,
            validationMethod: (validator) => validator.isSummaryStepValid,
        },
    ];

    return <SurveyStepper steps={steps} stepperIcon={<Ballot />} detailsRoute='schedule'/>;
};
