import { Box, Checkbox, FormControlLabel, FormLabel, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { CompanySelect, StandardDatePicker, StandardSelect } from '../../../Components/CommonInputs';
import { FormInput, LoadingIndicator } from '../../../Components/CoreLib/library';
import { FormSection } from '../../../Components/Forms';
import { RespondentDto, WorkItemDescriptionDisplayMode, WorkItemType } from '../../../dtos';
import { useGetCompanyRespondentsQuery } from '../../../store';
import { WorkItemFormManger } from '../Hooks/useWorkItemForm';

export interface IAddEditWorkItemFormProps {
    formManager: WorkItemFormManger;
    companyId: string;
    hideAssociatedRespondents?: boolean;
}

const WORK_ITEM_TYPE_DISPLAY_VALUES = new Map<WorkItemType, string>([
    [WorkItemType.Activity, 'Activity'],
    [WorkItemType.BusinessComponent, 'Business Component'],
]);

const WORK_ITEM_DISPLAY_MODE_DISPLAY_VALUES = new Map<WorkItemDescriptionDisplayMode, string>([
    [WorkItemDescriptionDisplayMode.Show, 'Always Show'],
    [WorkItemDescriptionDisplayMode.Hide, 'Always Hide'],
    [WorkItemDescriptionDisplayMode.Hover, 'Show On Hover'],
]);

export const AddEditWorkItemForm: FC<IAddEditWorkItemFormProps> = ({ formManager, companyId, hideAssociatedRespondents }) => {
    const { data: companyRespondents, isLoading: isLoadingCompanyRespondents } = useGetCompanyRespondentsQuery({
        parentId: companyId,
        page: 0,
        pageSize: 5000,
    });

    const handleToggleRespondent = useCallback(
        (respondentId: string) => {
            const currentItems = formManager.associatedEmployeeIds;
            var isCurrentlySelected = currentItems.includes(respondentId);
            if (isCurrentlySelected) {
                formManager.handleAssociatedEmployeesIdChange(currentItems.filter((x) => x !== respondentId));
            } else {
                formManager.handleAssociatedEmployeesIdChange([...currentItems, respondentId]);
            }
        },
        [formManager]
    );

    const isEveryItemChecked = useMemo(
        () => formManager.associatedEmployeeIds.length === companyRespondents?.totalQueryResults,
        [formManager.associatedEmployeeIds, companyRespondents]
    );
    const isAtLeastOneItemChecked = useMemo(() => formManager.associatedEmployeeIds.length > 0, [formManager.associatedEmployeeIds]);
    const handleToggleSelectAll = useCallback(() => {
        if (!companyRespondents) {
            return;
        }
        if (isEveryItemChecked) {
            formManager.handleAssociatedEmployeesIdChange([]);
        } else {
            formManager.handleAssociatedEmployeesIdChange(companyRespondents.pageResults.map((respondent) => respondent.id));
        }
    }, [isEveryItemChecked, companyRespondents, formManager]);

    const renderEmployeeRow = useCallback(
        (item: RespondentDto): JSX.Element => {
            return (
                <ListItem key={item.id} component='div' disablePadding dense>
                    <ListItemButton onClick={() => handleToggleRespondent(item.id)} dense disabled={formManager.isActivity}>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                checked={!formManager.isActivity && formManager.associatedEmployeeIds.includes(item.id)}
                                tabIndex={-1}
                                disableRipple
                                size='small'
                                sx={{ height: 16 }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                    </ListItemButton>
                </ListItem>
            );
        },
        [handleToggleRespondent, formManager.associatedEmployeeIds, formManager.isActivity]
    );

    return (
        <>
            {formManager.isTypeSelectVisible && (
                <Grid item xs={12}>
                    <StandardSelect
                        label='Type'
                        isRequired
                        errorMessage={formManager.fieldErrors.get('type')}
                        selectedValue={(formManager.type !== null && WORK_ITEM_TYPE_DISPLAY_VALUES.get(formManager.type)) || ''}
                        handleSelectedValueChange={(value) => formManager.handleTypeChange(value)}
                        options={[
                            '',
                            WORK_ITEM_TYPE_DISPLAY_VALUES.get(WorkItemType.Activity)!,
                            WORK_ITEM_TYPE_DISPLAY_VALUES.get(WorkItemType.BusinessComponent)!,
                        ]}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormInput
                    value={formManager.name}
                    onChange={formManager.handleNameChange}
                    label='Name'
                    name='name'
                    errorText={formManager.fieldErrors.get('name')}
                    error={!!formManager.fieldErrors.get('name')}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    value={formManager.description}
                    onChange={formManager.handleDescriptionChange}
                    label='Description'
                    name='description'
                    errorText={formManager.fieldErrors.get('description')}
                    error={!!formManager.fieldErrors.get('description')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <StandardSelect
                    label='When to Display Description'
                    isRequired
                    errorMessage={formManager.fieldErrors.get('descriptionDisplayMode')}
                    selectedValue={
                        (formManager.descriptionDisplayMode !== null && WORK_ITEM_DISPLAY_MODE_DISPLAY_VALUES.get(formManager.descriptionDisplayMode)) || ''
                    }
                    handleSelectedValueChange={(value) => formManager.handleDescriptionDisplayModeChange(value)}
                    options={[
                        WORK_ITEM_DISPLAY_MODE_DISPLAY_VALUES.get(WorkItemDescriptionDisplayMode.Show)!,
                        WORK_ITEM_DISPLAY_MODE_DISPLAY_VALUES.get(WorkItemDescriptionDisplayMode.Hide)!,
                        WORK_ITEM_DISPLAY_MODE_DISPLAY_VALUES.get(WorkItemDescriptionDisplayMode.Hover)!,
                    ]}
                />
            </Grid>
            {formManager.isCompanySelectVisible && (
                <Grid item xs={12}>
                    <CompanySelect
                        selectedCompanyId={formManager.companyId}
                        handleSelectedCompanyChange={formManager.handleCompanyChange}
                        errorMessage={formManager.fieldErrors.get('companyId')}
                        isRequired
                    />
                </Grid>
            )}
            <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item xs={4}>
                    <StandardDatePicker
                        label='Start Date'
                        value={formManager.startDate}
                        handleDateChange={formManager.handleStartDateChange}
                        errorMessage={formManager.fieldErrors.get('startDate')}
                        required
                    />
                </Grid>
                <Grid item pt={3}>
                    <FormControlLabel
                        control={<Switch checked={formManager.isOngoing} onChange={(_, val) => formManager.handleIsOngoingChange(val)} />}
                        label='Ongoing'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={4}>
                    <StandardDatePicker
                        label='End Date'
                        value={formManager.endDate}
                        handleDateChange={formManager.handleEndDateChange}
                        errorMessage={formManager.fieldErrors.get('endDate')}
                        required={!formManager.isOngoing}
                        disabled={formManager.isOngoing}
                    />
                </Grid>
                {!hideAssociatedRespondents && (
                    <Grid item xs={12}>
                        <Box height={16} />
                        <FormLabel>Associated Respondents</FormLabel>
                        <FormSection sx={{ maxHeight: 240, overflowY: 'auto' }}>
                            {isLoadingCompanyRespondents && <LoadingIndicator />}
                            {!isLoadingCompanyRespondents && companyRespondents && (
                                <ListItem key='associated-employee-select-all' component='div' disablePadding dense>
                                    <ListItemButton onClick={() => handleToggleSelectAll()} dense disabled={formManager.isActivity}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge='start'
                                                checked={!formManager.isActivity && isEveryItemChecked}
                                                indeterminate={!formManager.isActivity && !isEveryItemChecked && isAtLeastOneItemChecked}
                                                tabIndex={-1}
                                                disableRipple
                                                size='small'
                                                sx={{ height: 16 }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary='Select All' />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {!isLoadingCompanyRespondents && companyRespondents?.pageResults.map(renderEmployeeRow)}
                        </FormSection>
                    </Grid>
                )}
            </Grid>
        </>
    );
};
