import { Box, Stack } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { CompanyDto } from '../../../dtos';
import { BusinessComponentListView } from './SubComponents';
import { EmployeeListView } from './SubComponents/Employees';
import { SurveyBuilderContext } from '../utils';
import { useLazyGetStudyBCMConfigByIdQuery } from '../../../store';
import { LoadingIndicator } from '../../CoreLib/library';
import _ from 'lodash';

export interface IDataStepContentsProps {
    company: CompanyDto;
}

export const DataStepContents: FC<IDataStepContentsProps> = ({ company }) => {
    const { surveyPayload, setSurveyPayload, bcmInitializationType } = useContext(SurveyBuilderContext);
    const [getBCMConfig, { data: studyBCMConfigData, isLoading: isLoadingStudyBCMConfig }] = useLazyGetStudyBCMConfigByIdQuery();

    const isDataDefinedInThisStep = useMemo(() => surveyPayload.businessComponentMatrixConfig.businessComponents.length > 0 || surveyPayload.businessComponentMatrixConfig.employees.length > 0, [surveyPayload.businessComponentMatrixConfig]);
    const shouldLoadBCMConfigFromStudy = useMemo(() => bcmInitializationType === 'import' && !isDataDefinedInThisStep, [bcmInitializationType, isDataDefinedInThisStep]);
    const isDataDefinedInStudyConfigResponse = useMemo(() => studyBCMConfigData && (studyBCMConfigData.businessComponents.length > 0 || studyBCMConfigData.employees.length > 0), [studyBCMConfigData]);

    const loadBCMConfigFromStudyIntoSurveyPayload = useCallback(() => {
        if (!studyBCMConfigData) {
            return;
        }
        let updatedSurveyPayload = _.cloneDeep(surveyPayload);
        updatedSurveyPayload.businessComponentMatrixConfig = studyBCMConfigData;
        setSurveyPayload(updatedSurveyPayload);
    }, [studyBCMConfigData, surveyPayload, setSurveyPayload]);

    useEffect(() => {
        if (shouldLoadBCMConfigFromStudy) {
            getBCMConfig({ id: surveyPayload.study!.id });
        }
    }, [getBCMConfig, shouldLoadBCMConfigFromStudy, surveyPayload.study]);

    useEffect(() => {
        if (!isDataDefinedInThisStep && isDataDefinedInStudyConfigResponse) {
            loadBCMConfigFromStudyIntoSurveyPayload();
        }
    }, [isDataDefinedInStudyConfigResponse, isDataDefinedInThisStep, loadBCMConfigFromStudyIntoSurveyPayload]);

    if (isLoadingStudyBCMConfig) {
        return (
            <Stack sx={{ width: '100%' }} spacing={4}>
                <Box
                    sx={{
                        backgroundColor: 'primary.contrastText',
                        py: 4,
                        px: 4,
                    }}>
                    <LoadingIndicator />
                </Box>
            </Stack>
        );
    }

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Box
                sx={{
                    backgroundColor: 'primary.contrastText',
                    py: 4,
                    px: 4,
                }}>
                <BusinessComponentListView company={company} />
            </Box>
            <Box
                sx={{
                    backgroundColor: 'primary.contrastText',
                    py: 4,
                    px: 4,
                }}>
                <EmployeeListView company={company} />
            </Box>
        </Stack>
    );
};
