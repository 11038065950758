import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { SurveyWithStatisticsDto } from '../../../../../../dtos/generated/SurveyWithStatisticsDto';
import { Grid, Typography } from '@mui/material';
import { formateDateStandard, formateDateTimeStandard } from '../../../../../../util';
import Highcharts, { SeriesClickEventObject } from 'highcharts';
import BarChart from 'highcharts-react-official';
import { DataFilterContext } from '../../../../../../Contexts';

export interface ICompanyDashboardRespondentStatusByFormTypeChartProps {
    survey: SurveyWithStatisticsDto;
}

const SORT_ORDER = new Map([
    ['Created', 1],
    ['Issued', 2],
    ['Started', 3],
    ['Paused', 4],
    ['Completed', 5],
]);

const COLOR_PALLET = new Map([
    ['Created', '#97CBD2'],
    ['Issued', '#BFBFBF'],
    ['Started', '#57585A'],
    ['Paused', '#DF623B'],
    ['Completed', '#50B432'],
]);

export const CompanyDashboardRespondentStatusByFormTypeChart: FC<ICompanyDashboardRespondentStatusByFormTypeChartProps> = ({ survey }) => {
    const [graphRenderKey, setGraphRenderKey] = useState(0);
    const { setStatusFilter } = useContext(DataFilterContext);
    
    const handleSliceClicked = useCallback(
        (event: SeriesClickEventObject) => {
            setStatusFilter(event.point.series.name);
        },
        [setStatusFilter]
    );

    const chartOptions: Highcharts.Options = useMemo(() => {
        setGraphRenderKey((x) => x + 1);
        return {
            title: { text: '' },
            legend: { enabled: false },
            xAxis: { visible: false },
            yAxis: { visible: false },
            chart: { height: 100 },
            tooltip: {
                followPointer: true,
                format: '{series.name}: {y}',
            },
            plotOptions: {
                bar: {
                    stacking: 'percent',
                    dataLabels: {
                        formatter: function () {
                            if (this.percentage < 5) {
                                return this.y;
                            }
                            return `${this.y} ${this.series.name}`;
                        },
                        enabled: true
                    },
                    events: {
                        click: handleSliceClicked
                    }
                },
            },
            series: [...survey.statistics]
                .sort((left, right) => (SORT_ORDER.get(right.name) ?? 0) - (SORT_ORDER.get(left.name) ?? 0))
                .map((s) => ({
                    name: s.name,
                    type: 'bar',
                    color: COLOR_PALLET.get(s.name),
                    data: [s.aggregate],
                })),
        };
    }, [survey, handleSliceClicked]);

    return (
        <Grid container direction='column' pt={2} pb={2}>
            <Grid container direction='row'>
                <Grid item xs={3}>
                    <Typography sx={{ fontWeight: 'bold' }}>Period</Typography> {formateDateStandard(survey.periodStart)} -{' '}
                    {formateDateStandard(survey.periodEnd)}
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={{ fontWeight: 'bold' }}>Form Type</Typography> {survey.formType}
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={{ fontWeight: 'bold' }}>Scheduled</Typography> {formateDateTimeStandard(survey.scheduled)}
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={{ fontWeight: 'bold' }}>Status</Typography> {survey.status}
                </Grid>
            </Grid>
            <BarChart sx={{ border: 1, borderColor: '#FF0000' }} key={`${survey.id}-${graphRenderKey}`} highcharts={Highcharts} options={chartOptions} />
        </Grid>
    );
};
