import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { CompanyDashboardContext } from '../../../../Utils';
import { useGetRespondentQuery } from '../../../../../../store';
import { useNavigate } from 'react-router-dom';
import { LoadingIndicator } from '../../../../../../Components/CoreLib/library';

export const CompanyDashboardRespondentsTabSidebarContent: FC = () => {
    const navigate = useNavigate();
    const { company, selectedRespondentId } = useContext(CompanyDashboardContext);
    const { data: respondent } = useGetRespondentQuery({ parentId: company.id, childId: selectedRespondentId });

    const navigateToRespondentEdit = useCallback(() => {
        navigate(`/companies/${company.id}/respondents/${selectedRespondentId}`);
    }, [navigate, company, selectedRespondentId]);

    const renderRespondentDetails = useMemo(() => {
        if (!respondent)
        {
            return <LoadingIndicator />
        }

        return (
            <>
                <Box display='flex' alignItems='center' pb={1}>
                    <Typography fontSize={18} fontWeight='bold'>
                        Respondent Details
                    </Typography>
                    <IconButton size='small' sx={{ ml: 1 }} onClick={navigateToRespondentEdit}>
                        <Edit />
                    </IconButton>
                </Box>
                <Box display='flex' flexDirection={'column'}>
                    <Typography>First Name: {respondent.firstName}</Typography>
                    <Typography>Last Name: {respondent.lastName}</Typography>
                    <Typography>Title: {respondent.title}</Typography>
                    <Typography>Department: {respondent.department?.name}</Typography>
                    <Typography>Email: {respondent.email}</Typography>
                    <Typography>Phone: {respondent.phone}</Typography>
                    <Typography>Emails Forwarded to Manager: {respondent.shouldForwardEmailsToManager ? 'Yes' : 'No'}</Typography>
                </Box>
            </>
        );
    }, [respondent, navigateToRespondentEdit]);

    if (!respondent) {
        return null;
    }

    return (
        <Box display='flex' flexDirection='column' rowGap={0.5}>
            {!!selectedRespondentId && renderRespondentDetails}
        </Box>
    );
};
