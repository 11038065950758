import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { onlyShowOnDesktop } from '../util';

export interface IDesktopViewSaveSubmitButtonsProps {
    handleSaveAnswers: () => void;
    isSaveDisabled: boolean;
    handleSubmitAnswers: () => void;
    isSubmitDisabled: boolean;
}

export const DesktopViewSaveSubmitButtons: FC<IDesktopViewSaveSubmitButtonsProps> = props => {
    const {
        handleSaveAnswers,
        isSaveDisabled,
        handleSubmitAnswers,
        isSubmitDisabled
    } = props;

    return (
        <Grid container item direction='row' xs={3} justifyContent='end' spacing={1} sx={onlyShowOnDesktop}>
            <Grid item>
                <Button
                    onClick={handleSaveAnswers}
                    disabled={isSaveDisabled}
                    variant='contained'
                    color='primary'>
                    Save Progress
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={handleSubmitAnswers}
                    disabled={isSubmitDisabled}
                    variant='contained'
                    color='primary'>
                    Submit
                </Button>
            </Grid>
        </Grid>
    )
}