import { Ballot, MoreVert } from '@mui/icons-material';
import { FormControlLabel, Grid, IconButton, Menu, MenuItem, Switch, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    DataTableColumn,
    NavBreadcrumbs,
    PaginatedDataTable,
    PaginatedProps,
    StandardSearchInput,
    useFailedActionSnackbar,
} from '../Components/CoreLib/library';
import { useSurveyPreviewer } from '../Components/surveyStepper/utils';
import { RespondentDto, SurveyDto, SurveyPayloadDto } from '../dtos';
import { useGetSurveysQuery, useLazyGetSurveyQuery } from '../store/api/survey-api';
import { mapTimeColumn } from '../util/mapTimeColumn';
import { BCM_ID, DEFAULT_SURVEY_PAYLOAD, TAQ_BY_BC_ID } from './types';
import { useSnackbar } from 'notistack';

const PREVIEW_RESPONDENT: RespondentDto = {
    firstName: 'John',
    lastName: 'Doe',
    title: 'Employee',
    nickname: 'John',
    email: 'sample@robosource.us',
    areaCode: '317',
    phone: '867-5309',
    isManager: false,
    fieldTesting: '',
    companyId: '',
    hasBeenEmailed: false,
    isContactOnly: false,
    workItemCount: 0,
    shouldForwardEmailsToManager: false,
    id: '066519bc-27e9-4f62-9da9-16aa54a8cd6e',
    isActive: true,
    createdOn: new Date(),
};

export const SurveysListView: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const tableColumns: DataTableColumn<SurveyDto>[] = useMemo(
        () => [
            { key: 'name', label: 'Form Type', sortKey: 'NAME' },
            { key: 'createdOn', label: 'Created', sortKey: 'CREATED', fieldMapper: mapTimeColumn },
            { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: (row: SurveyDto) => (row.isActive ? 'Active' : 'Inactive') },
        ],
        []
    );
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, isLoading, isError } = useGetSurveysQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    useFailedActionSnackbar('retrieve', 'surveys', isError);
    const [getSurveyById] = useLazyGetSurveyQuery();
    const [selectedSurveys, setSelectedSurveys] = useState<readonly SurveyDto[]>([]);
    const [selectedSurveyToPreview, setSelectedSurveyToPreview] = useState<SurveyDto>();
    const surveyPreviewPayload = useMemo<SurveyPayloadDto>(() => {
        let payload = _.cloneDeep(DEFAULT_SURVEY_PAYLOAD);
        if (selectedSurveyToPreview) {
            payload.selectedFormType = selectedSurveyToPreview;
        }

        const currentYear = new Date().getFullYear();
        payload.timePeriodArray = [{ startDate: new Date(currentYear - 1, 0, 0), endDate: new Date(currentYear, 0, 0), surveyLabel: 'Sample Time Range' }];

        return payload;
    }, [selectedSurveyToPreview]);

    const { showSurveyPreview, renderSurveyPreviewModal, isSurveyPreviewVisible } = useSurveyPreviewer(surveyPreviewPayload, PREVIEW_RESPONDENT, 'Survey', () => {
        setSelectedSurveyToPreview(undefined);
    });

    const previewSurvey = useCallback(
        (survey: SurveyDto) => {
            if ([TAQ_BY_BC_ID, BCM_ID].includes(survey.id)) {
                enqueueSnackbar('This Form Type can only be previewed while creating a Survey/BCM.', { variant: 'warning' });
                return;
            }
            setSelectedSurveyToPreview(survey);
        },
        [enqueueSnackbar]
    );

    const handlePreview = useCallback(() => {
        if (selectedSurveys.length === 1) {
            const selected = selectedSurveys[0];
            if (selected) {
                previewSurvey(selected);
            }
        }
    }, [selectedSurveys, previewSurvey]);

    useEffect(() => {
        if (selectedSurveyToPreview && surveyPreviewPayload.selectedFormType.id === selectedSurveyToPreview.id && !isSurveyPreviewVisible) {
            showSurveyPreview();
        }
    }, [showSurveyPreview, selectedSurveyToPreview, surveyPreviewPayload.selectedFormType, isSurveyPreviewVisible]);

    useEffect(() => {
        const urlFormTypeId = searchParams.get('id');
        if (urlFormTypeId) {
            getSurveyById(urlFormTypeId).then((x) => x.data && previewSurvey(x.data));
            setSearchParams(undefined);
        }
    }, [searchParams, previewSurvey, setSearchParams, getSurveyById]);

    const handleSearchChange = useCallback((text: string) => {
        setSearchText(text);
    }, []);

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInactive(event.target.checked);
    }, []);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) afterClose();
        },
        []
    );

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={7}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Ballot /> Form Types
                    </Typography>
                    <NavBreadcrumbs links={[{ label: 'Admin', navLink: '', isText: true }]} currentPageLabel='Form Types' />
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={5}>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <Grid item>
                        <IconButton size='large' onClick={handleMenuOpen}>
                            <MoreVert fontSize='inherit' />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={!!menuAnchorEl}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <MenuItem onClick={handleMenuClose(handlePreview)} disabled={selectedSurveys.length !== 1}>
                                Preview
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs>
                        <StandardSearchInput searchText={searchText} handleSearchChange={handleSearchChange} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
            {renderSurveyPreviewModal()}
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='NAME'
                    setPagination={setPaginatedProps}
                    selectedRecords={selectedSurveys}
                    setSelectedRecords={setSelectedSurveys as any}
                    isViewOnly={true}
                    editToolTip='Preview'
                    onEdit={(_, survey: SurveyDto) => previewSurvey(survey)}
                />
            </Grid>
        </Grid>
    );
};
