import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { SurveyPayloadDto } from "../../../dtos";
import { emptyGuid } from "../../../models";
import { useCreateNewSurveyMutation, useUpdateExistingSurveyMutation } from "../../../store";
import { useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulActionSnackbar, useSuccessfulUpdateSnackbar } from "../../CoreLib/library";

export const useSurveyCreateUpdateManager = (surveyPayload: SurveyPayloadDto, surveyAliasShort: string, detailsRoute: string) => {
    const [urlParams] = useSearchParams();
    const { surveyId: existingSurveyId, duplicate } = useParams();
    const isDuplicate = (duplicate && duplicate === 'duplicate')
    const [createNewSurvey, { isSuccess: isCreateSurveySuccess, isLoading: isCreatingSurvey, isError: isCreateSurveyError, reset: resetCreateSurvey }] = useCreateNewSurveyMutation();
    const [updateExistingSurvey, { data: newScheduleId, isSuccess: isUpdateSurveySuccess, isLoading: isUpdatingSurvey, isError: isUpdateSurveyError, reset: resetUpdateSurvey }] = useUpdateExistingSurveyMutation();
    const navigate = useNavigate();
    
    useSuccessfulActionSnackbar('submitted', surveyAliasShort.toLowerCase(), isCreateSurveySuccess, () => {
        resetCreateSurvey();
        navigate(-1);
    });
    useFailedActionSnackbar('submit', surveyAliasShort.toLowerCase(), isCreateSurveyError, resetCreateSurvey);
    useSuccessfulUpdateSnackbar(surveyAliasShort.toLowerCase(), isUpdateSurveySuccess, () => {
        resetUpdateSurvey();
        if (urlParams.get('originPage') === 'SurveyInstance' && newScheduleId) {
            navigate(`/${detailsRoute}s/${newScheduleId}`);
        } else {
            navigate(-1);
        }
    });
    useFailedUpdateSnackbar(surveyAliasShort.toLowerCase(), isUpdateSurveyError, resetUpdateSurvey)
    
    const createOrUpdateSurvey = useCallback(() => {
        if (existingSurveyId === undefined || isDuplicate) {
            createNewSurvey({
                id: emptyGuid,
                studyId: surveyPayload.study?.id ?? emptyGuid,
                surveyTemplateId: surveyPayload.selectedFormType!.id,
                surveyPayload: JSON.stringify(surveyPayload),
                isActive: true,
                createdBy: emptyGuid,
                createdOn: new Date()
            });
        } else {
            updateExistingSurvey({
                id: existingSurveyId,
                studyId: surveyPayload.study?.id ?? emptyGuid,
                surveyTemplateId: surveyPayload.selectedFormType!.id,
                surveyPayload: JSON.stringify(surveyPayload),
                isActive: true,
                createdBy: emptyGuid,
                createdOn: new Date()
            });
        }
    }, [createNewSurvey, existingSurveyId, isDuplicate, surveyPayload, updateExistingSurvey]);

    const isLoading = useMemo(() => {
        return isCreatingSurvey || isUpdatingSurvey;
    }, [isCreatingSurvey, isUpdatingSurvey])

    return { createOrUpdateSurvey, isLoading }
}