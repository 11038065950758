import { Grid, Typography } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingIndicator, PaginatedProps, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { SchedulesTable } from '../../Components/Forms/Schedule/SchedulesTable';
import { useGetSurveySchedulesQuery } from '../../store/api/schedule-api';
import { ScheduleTableContext } from '../../Components/Forms/Schedule/ScheduleTableContext';
import { useGetScheduleTableHeaders } from '../../Components/Forms/Schedule/utils/useGetScheduleTableHeaders';
import { ListAlt } from '@mui/icons-material';
import { DataFilterContext } from '../../Contexts';

export const SurveyScheduleListView: FC = () => {
    const [searchParams] = useSearchParams();
    const urlCompanyId = searchParams.get('companyId');
    const [searchText, setSearchText] = useState('');
    const [showOnlyExpired, setShowOnlyExpired] = useState(false);
    const [companyFilter, setCompanyFilter] = useState<string | undefined>(urlCompanyId ?? undefined);

    const {
        showInactive,
        scheduledFromDateFilter: fromDateFilter,
        scheduledToDateFilter: toDateFilter,
        periodStartDateFilter,
        periodEndDateFilter,
        statusFilter,
        reminderFilter,
        approvedByFilter,
        formTypeFilter
    } = useContext(DataFilterContext);

    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>({
        sortKey: "SCHEDULED",
        sortAsc: false,
        page: 0,
        pageSize: 25
    });
    const { data, isLoading, isError, refetch } = useGetSurveySchedulesQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        isExpired: showOnlyExpired,
        fromDate: fromDateFilter?.toISOString(),
        toDate: toDateFilter?.toISOString(),
        // TODO: this is currently configured to use the company name. However, we should likely refactor this to use the company Id
        company: companyFilter ? encodeURIComponent(companyFilter) : undefined,
        formType: formTypeFilter ? encodeURIComponent(formTypeFilter) : undefined,
        status: statusFilter ? encodeURIComponent(statusFilter) : undefined,
        reminder: reminderFilter ? encodeURIComponent(reminderFilter) : undefined,
        approvedBy: approvedByFilter ? encodeURIComponent(approvedByFilter) : undefined,
        periodStartDate: periodStartDateFilter?.toISOString(),
        periodEndDate: periodEndDateFilter?.toISOString()
    });

    useFailedActionSnackbar('retrieve', 'surveys', isError)

    const { tableHeaders } = useGetScheduleTableHeaders({});

    const tableKey = useMemo(() => {
        return (
            <Grid item justifyContent='flex-end'>
                <Typography variant='body2' align='right'>
                    CRE = Created, ISS = Issued, STA = Started, PAU = Paused, COM = Completed
                </Typography>
            </Grid>
        )
    }, []);

    return (
        <ScheduleTableContext.Provider value={{
            searchText,
            setSearchText,
            paginatedProps,
            setPaginatedProps,
            showOnlyExpired,
            setShowOnlyExpired,
            companyFilter,
            setCompanyFilter,
            surveyAlias: 'Survey',
            surveyAliasShort: 'Survey'
        }}>
            {!isLoading ? (
                <Grid container direction="column">
                    <Grid item container direction="column" spacing={3}>
                        <Grid item>
                            {!isLoading &&
                                <SchedulesTable
                                    title='Surveys'
                                    icon={<ListAlt />}
                                    breadcrumbs={[{
                                        label: 'Home',
                                        navLink: '/'
                                    }]}
                                    schedules={data!}
                                    tableColumns={tableHeaders}
                                    isLoading={isLoading}
                                    refetch={refetch}
                                    underTableContent={tableKey}
                                    editRoute='survey'
                                    detailsRoute='schedule'
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            ) : <LoadingIndicator />}
        </ ScheduleTableContext.Provider>
    );
}