import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, SyntheticEvent, useMemo } from 'react';
import { RespondentDto } from '../../dtos';
import { GetRespondentsQueryParams, useGetCompanyRespondentsQuery } from '../../store';

export interface ICompanyRespondentSelectProps {
    companyId: string;
    selectedRespondentId: string;
    handleSelectedRespondentChange: (selectedRespondent?: RespondentDto) => void;
    additionalQueryParameters?: Partial<GetRespondentsQueryParams>;
    errorMessage?: string;
    label?: string;
    required?: boolean;
    itemFilter?: (item: RespondentDto) => boolean;
}

export const CompanyRespondentSelect: FC<ICompanyRespondentSelectProps> = ({
    companyId,
    selectedRespondentId,
    handleSelectedRespondentChange,
    additionalQueryParameters = {},
    errorMessage,
    label,
    required,
    itemFilter = () => true,
}) => {
    const { data: respondents, isLoading: isLoadingRespondents } = useGetCompanyRespondentsQuery({
        parentId: companyId,
        page: 0,
        pageSize: 5000,
        ...additionalQueryParameters,
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: RespondentDto | null) => {
        handleSelectedRespondentChange(value ?? undefined);
    };

    const selectedRespondent = useMemo(() => {
        if (selectedRespondentId === '') {
            return null;
        }
        return respondents?.pageResults?.find((x) => x.id === selectedRespondentId);
    }, [respondents?.pageResults, selectedRespondentId]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={required}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                value={selectedRespondent ?? null}
                onChange={handleSelectChange}
                loading={isLoadingRespondents}
                options={respondents?.pageResults.filter(itemFilter) ?? []}
                getOptionLabel={(x) => (x ? `${x.firstName} ${x.lastName}` : '')}
                renderInput={(params) => <TextField {...params} placeholder='Select individual' />}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    );
};
