import { SearchQueryParams, serverApi } from '..';
import { NewSurveyDto, QueryResultsDto, ScheduleRespondentAnswerDto, SurveyDto, SurveyPayloadDto, TAQByBCAnswerPreviewRequestDto } from '../../dtos';
import { GroupByDto } from '../../dtos/generated/GroupByDto';
import { SurveyWithStatisticsDto } from '../../dtos/generated/SurveyWithStatisticsDto';
import { SurveyTemplate } from '../../models/survey';
import { buildSearchQueryRoute } from '../../util';

export interface GetRespondentStatusBySurveyParameters {
    companyId: string;
    searchParams: {
        daysBack: number;
        formType?: string;
        status?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
    }
}

export interface GetRespondentStatusByFormTypeParameters {
    companyId: string;
    searchParams: {
        daysBack: number;
        formType?: string;
        status?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
    }
}

export interface GetScheduleDetailsByStatusParameters {
    companyId: string;
    searchParams: {
        daysBack: number;
        formType?: string;
        status?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
    }
}

const surveyApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getSurveys: builder.query<QueryResultsDto<SurveyDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('survey', params),
            providesTags: [{ type: 'Survey' }],
        }),
        getFormTypes: builder.query<SurveyDto[], void>({
            query: () => ({ url: 'survey/formtypes' }),
            providesTags: [{ type: 'Survey' }],
        }),
        getSurvey: builder.query<SurveyDto, string>({
            query: (surveyId) => ({ url: `survey/${surveyId}` }),
            providesTags: [{ type: 'Survey' }],
        }),
        getSurveyPayload: builder.query<SurveyPayloadDto, string>({
            query: (surveyId) => ({ url: `survey/${surveyId}/payload` }),
            providesTags: [{ type: 'Survey' }],
        }),
        getSurveyPreview: builder.query<SurveyTemplate, NewSurveyDto>({
            query: (newSurveyDto) => ({
                url: 'survey/preview',
                method: 'POST',
                body: newSurveyDto,
            }),
            providesTags: [{ type: 'Survey' }],
        }),
        previewTaqByBcAnswers: builder.query<ScheduleRespondentAnswerDto[], TAQByBCAnswerPreviewRequestDto>({
            query: (payload) => ({
                url: 'survey/preview-taq-by-bc-answers',
                method: 'POST',
                body: payload,
            }),
            providesTags: [{ type: 'Survey' }],
        }),
        getRespondentStatusBySurvey: builder.query<SurveyWithStatisticsDto[], GetRespondentStatusBySurveyParameters>({
            query: (params) => buildSearchQueryRoute(`company/${params.companyId}/statistics/respondent-status-by-survey`, params.searchParams),
            providesTags: [{ type: 'Company' }, { type: 'Survey' }, { type: 'Schedule' }, { type: 'Respondent' }],
        }),
        getRespondentStatusByFormType: builder.query<GroupByDto[], GetRespondentStatusByFormTypeParameters>({
            query: (params) => buildSearchQueryRoute(`company/${params.companyId}/statistics/respondent-status-by-form-type`, params.searchParams),
            providesTags: [{ type: 'Company' }, { type: 'Survey' }, { type: 'Schedule' }, { type: 'Respondent' }],
        }),
        getScheduleDetailsByStatus: builder.query<GroupByDto[], GetScheduleDetailsByStatusParameters>({
            query: (params) => buildSearchQueryRoute(`company/${params.companyId}/statistics/schedule-details-by-status`, params.searchParams),
            providesTags: [{ type: 'Company' }, { type: 'Survey' }, { type: 'Schedule' }, { type: 'Respondent' }],
        }),
        getSurveyTemplate: builder.mutation<any, any>({
            query: (surveyTemplateDto) => ({
                url: `survey/createSurveyFromTemplate`,
                method: 'POST',
                body: surveyTemplateDto,
            }),
            invalidatesTags: [{ type: 'SurveyTemplate' }],
        }),
        createSurvey: builder.mutation<SurveyDto, SurveyDto>({
            query: (surveyDto) => ({
                url: 'survey',
                method: 'POST',
                body: surveyDto,
            }),
            invalidatesTags: [{ type: 'Survey' }],
        }),
        updateExistingSurvey: builder.mutation<NewSurveyDto, NewSurveyDto>({
            query: (newSurveyDto) => ({
                url: `survey/new/${newSurveyDto.id!}`,
                method: 'PUT',
                body: newSurveyDto,
            }),
            invalidatesTags: [{ type: 'Survey' }, { type: 'Schedule' }],
        }),
        createNewSurvey: builder.mutation<NewSurveyDto, NewSurveyDto>({
            query: (newSurveyDto) => ({
                url: 'survey/new',
                method: 'POST',
                body: newSurveyDto,
            }),
            invalidatesTags: [{ type: 'Survey' }, { type: 'Schedule' }],
        }),
        updateSurvey: builder.mutation<void, SurveyDto>({
            query: (surveyDto) => ({
                url: 'survey',
                method: 'PUT',
                body: surveyDto,
            }),
            invalidatesTags: [{ type: 'Survey' }],
        }),
    }),
});

export const {
    useGetSurveysQuery,
    useGetFormTypesQuery,
    useGetSurveyQuery,
    useGetRespondentStatusBySurveyQuery,
    useGetRespondentStatusByFormTypeQuery,
    useGetScheduleDetailsByStatusQuery,
    useLazyGetSurveyPayloadQuery,
    useLazyGetSurveyPreviewQuery,
    useCreateSurveyMutation,
    useUpdateSurveyMutation,
    useGetSurveyTemplateMutation,
    useCreateNewSurveyMutation,
    useUpdateExistingSurveyMutation,
    useLazyGetSurveyQuery,
    usePreviewTaqByBcAnswersQuery,
    useLazyPreviewTaqByBcAnswersQuery
} = surveyApi;
