import { FC } from 'react';
import {
    Grid,
    FormHelperText,
    FormLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { FormInput } from '../../../Components/CoreLib/library';
import { CompanyFormManger } from './useCompanyForm';
import { patterns } from '../../../Components/Forms/FormFields';

export interface IAddEditCompanyFormProps {
    formManager: CompanyFormManger;
}

export const AddEditCompanyForm: FC<IAddEditCompanyFormProps> = ({ formManager }) => {
    return (
        <>
            <Grid item xs={12}>
                <FormInput
                    value={formManager.name}
                    onChange={formManager.handleNameChange}
                    label='Name'
                    name='name'
                    errorText={formManager.fieldErrors.NAME}
                    error={!!formManager.fieldErrors.NAME}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    value={formManager.shortName}
                    onChange={formManager.handleShortNameChange}
                    label='Short Name'
                    name='shortName'
                    errorText={formManager.fieldErrors.SHORT_NAME}
                    error={!!formManager.fieldErrors.SHORT_NAME}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item container direction='row' spacing={2} alignItems='center'>
                <Grid item xs={2}>
                    <FormLabel>Area Code</FormLabel>
                    <OutlinedInput
                        inputProps={{ inputMode: 'numeric', pattern: patterns.AreaCode.input.source }}
                        value={formManager.areaCode}
                        onChange={formManager.handleAreaCodeChange}
                        onBlur={formManager.onFieldBlur('AREA_CODE')}
                    />
                    <FormHelperText>{formManager.fieldErrors.AREA_CODE}</FormHelperText>
                </Grid>
                <Grid item xs={3}>
                    <FormLabel>Phone</FormLabel>
                    <OutlinedInput 
                        value={formManager.phone} 
                        onChange={formManager.handlePhoneChange} 
                        onBlur={formManager.onFieldBlur('PHONE')} 
                    />       
                    <FormHelperText>{formManager.fieldErrors.PHONE}</FormHelperText>
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={8} alignItems='center'>
                <Grid item xs={6}>
                    <FormLabel id='representative-label'>H&amp;K Representative</FormLabel>
                    <Select fullWidth value={formManager.representativeId} onChange={formManager.handleRepresentativeChange} onBlur={formManager.onFieldBlur('REPRESENTATIVE')}>
                        {formManager.users?.pageResults.map((rep) => (
                            <MenuItem key={rep.id} value={rep.id}>
                                {rep.firstName} {rep.lastName}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formManager.fieldErrors.REPRESENTATIVE}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel error={formManager.fieldErrors.YEAR_END !== ''} focused={formManager.yearEndFocus}>
                        Year End - Month/Day
                    </FormLabel>
                    <Grid container direction='row' alignItems='center' spacing={1}>
                        <Grid item xs={3}>
                            <TextField
                                inputProps={{ inputMode: 'numeric', pattern: patterns.Month.input.source }}
                                error={formManager.fieldErrors.YEAR_END !== ''}
                                onFocus={formManager.onYearEndFocus}
                                onBlur={formManager.onYearEndBlur}
                                value={formManager.yearEndMonth}
                                onChange={formManager.handleYearEndMonthChange}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <Typography>/</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                inputProps={{ inputMode: 'numeric', pattern: patterns.MonthDate.input.source }}
                                error={formManager.fieldErrors.YEAR_END !== ''}
                                onFocus={formManager.onYearEndFocus}
                                onBlur={formManager.onYearEndBlur}
                                value={formManager.yearEndDay}
                                onChange={formManager.handleYearEndDayChange}
                                required
                            />
                        </Grid>
                    </Grid>
                    <FormHelperText error={formManager.fieldErrors.YEAR_END !== ''}>{formManager.fieldErrors.YEAR_END}</FormHelperText>

                </Grid>
            
            </Grid>
        </>
    );
};
