export function convertToDateIfNeeded(value: string | Date | null) {
    if (value === null || typeof value === 'object') {
        return value;
    }
    
    if (value === '') {
        return null;
    }

    return new Date(Date.parse(value));
}