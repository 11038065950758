import { serverApi } from "..";
import { ImportDto, ImportWorkItemRowDto } from "../../dtos";

const importApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        createRespondentImport: builder.mutation<ImportDto, ImportDto>({
            query: (importDto) => ({
                url: 'import',
                method: 'POST',
                body: importDto
            }),
            invalidatesTags: [{ type: 'Import' }, { type: 'Respondent' }, { type: 'Department' }]
        }),
        importWorkItems: builder.mutation<ImportWorkItemRowDto[], ImportWorkItemRowDto[]>({
            query: (importDto) => ({
                url: 'import/work-items',
                method: 'POST',
                body: importDto
            }),
            invalidatesTags: [{ type: 'Import' }, { type: 'Respondent' }, { type: 'WorkItem' }]
        }),
        validateWorkItemsImport: builder.mutation<ImportWorkItemRowDto[], ImportWorkItemRowDto[]>({
            query: (importDto) => ({
                url: 'import/work-items/validate',
                method: 'POST',
                body: importDto
            })
        }),
    })
});

export const {
    useCreateRespondentImportMutation,
    useImportWorkItemsMutation,
    useValidateWorkItemsImportMutation
} = importApi;