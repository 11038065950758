import { Chip } from '@mui/material';
import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { eventNameMapper } from '../Components/Forms/ActivityStream/utils';
import { useGetAndCacheCompany, useGetAndCacheDepartment, useGetAndCacheFormType, useGetAndCacheRespondent, useGetAndCacheStudy, useGetAndCacheUser } from '../Hooks';
import { WorkItemType } from '../dtos';
import { formateDateStandard, isGuid, snakeCaseToPrint } from '../util';

type ActivityStatusOptions = 'Active' | 'Inactive' | '';

export interface IDataFilterContext {
    showInactive: boolean;
    setShowInactive: (filterValue: boolean) => void;
    scheduledFromDateFilter: Date | null;
    setScheduledFromDateFilter: (filterValue: Date | null) => void;
    scheduledToDateFilter: Date | null;
    setScheduledToDateFilter: (filterValue: Date | null) => void;
    createdFromDateFilter: Date | undefined;
    setCreatedFromDateFilter: (filterValue: Date | undefined) => void;
    createdToDateFilter: Date | undefined;
    setCreatedToDateFilter: (filterValue: Date | undefined) => void;
    periodStartDateFilter: Date | null;
    setPeriodStartDateFilter: (filterValue: Date | null) => void;
    periodEndDateFilter: Date | null;
    setPeriodEndDateFilter: (filterValue: Date | null) => void;
    formTypeFilter: string | undefined;
    setFormTypeFilter: (filterValue: string | undefined) => void;
    companyIdFilter: string | undefined;
    setCompanyIdFilter: (filterValue: string | undefined) => void;
    reminderFilter: string | undefined;
    setReminderFilter: (filterValue: string | undefined) => void;
    approvedByFilter: string | undefined;
    setApprovedByFilter: (filterValue: string | undefined) => void;
    statusFilter: string | undefined;
    setStatusFilter: (filterValue: string | undefined) => void;
    activeStatusFilter: ActivityStatusOptions | undefined;
    setActiveStatusFilter: (filterValue: ActivityStatusOptions | undefined) => void;
    itemTypeFilter: WorkItemType | undefined;
    setItemTypeFilter: (filterValue: WorkItemType | undefined) => void;
    studyFilter: string | undefined;
    setStudyFilter: (filterValue: string | undefined) => void;
    firstNameFilter: string | undefined;
    setFirstNameFilter: (filterValue: string | undefined) => void;
    lastNameFilter: string | undefined;
    setLastNameFilter: (filterValue: string | undefined) => void;
    titleFilter: string | undefined;
    setTitleFilter: (filterValue: string | undefined) => void;
    departmentIdFilter: string | undefined;
    setDepartmentIdFilter: (filterValue: string | undefined) => void;
    isNotAssignedToDepartmentFilter: boolean | undefined;
    setIsNotAssignedToDepartmentFilter: (filterValue: boolean | undefined) => void;
    managerIdFilter: string | undefined;
    setManagerIdFilter: (filterValue: string | undefined) => void;
    isNoRespondentsFilter: boolean | undefined;
    setIsNoRespondentsFilter: (filterValue: boolean | undefined) => void;
    periodNameFilter: string | undefined;
    setPeriodNameFilter: (filterValue: string | undefined) => void;
    activityTypeFilter: string | undefined;
    setActivityTypeFilter: (filterValue: string | undefined) => void;
    suppressTableFiltering?: boolean;
    clearFilters: () => void;
}

const PLACEHOLDER_METHOD = () => {};

export const DataFilterContext = createContext<IDataFilterContext>({
    showInactive: false,
    setShowInactive: PLACEHOLDER_METHOD,
    scheduledFromDateFilter: null,
    setScheduledFromDateFilter: PLACEHOLDER_METHOD,
    scheduledToDateFilter: null,
    setScheduledToDateFilter: PLACEHOLDER_METHOD,
    createdFromDateFilter: undefined,
    setCreatedFromDateFilter: PLACEHOLDER_METHOD,
    createdToDateFilter: undefined,
    setCreatedToDateFilter: PLACEHOLDER_METHOD,
    periodStartDateFilter: null,
    setPeriodStartDateFilter: PLACEHOLDER_METHOD,
    periodEndDateFilter: null,
    setPeriodEndDateFilter: PLACEHOLDER_METHOD,
    formTypeFilter: undefined,
    setFormTypeFilter: PLACEHOLDER_METHOD,
    companyIdFilter: undefined,
    setCompanyIdFilter: PLACEHOLDER_METHOD,
    reminderFilter: undefined,
    setReminderFilter: PLACEHOLDER_METHOD,
    approvedByFilter: undefined,
    setApprovedByFilter: PLACEHOLDER_METHOD,
    statusFilter: undefined,
    setStatusFilter: PLACEHOLDER_METHOD,
    activeStatusFilter: undefined,
    setActiveStatusFilter: PLACEHOLDER_METHOD,
    itemTypeFilter: undefined,
    setItemTypeFilter: PLACEHOLDER_METHOD,
    studyFilter: undefined,
    setStudyFilter: PLACEHOLDER_METHOD,
    firstNameFilter: undefined,
    setFirstNameFilter: PLACEHOLDER_METHOD,
    lastNameFilter: undefined,
    setLastNameFilter: PLACEHOLDER_METHOD,
    titleFilter: undefined,
    setTitleFilter: PLACEHOLDER_METHOD,
    departmentIdFilter: undefined,
    setDepartmentIdFilter: PLACEHOLDER_METHOD,
    isNotAssignedToDepartmentFilter: undefined,
    setIsNotAssignedToDepartmentFilter: PLACEHOLDER_METHOD,
    managerIdFilter: undefined,
    setManagerIdFilter: PLACEHOLDER_METHOD,
    isNoRespondentsFilter: undefined,
    setIsNoRespondentsFilter: PLACEHOLDER_METHOD,
    periodNameFilter: undefined,
    setPeriodNameFilter: PLACEHOLDER_METHOD,
    activityTypeFilter: undefined,
    setActivityTypeFilter: PLACEHOLDER_METHOD,
    clearFilters: PLACEHOLDER_METHOD,
});

export function useInitializeDataFilterContext(suppressTableFiltering?: boolean): IDataFilterContext {
    const [searchParams] = useSearchParams();
    const urlPeriodStartDate = searchParams.get('periodStartDate');
    const urlPeriodEndDate = searchParams.get('periodEndDate');

    const [showInactive, setShowInactive] = useState(false);
    const [scheduledFromDateFilter, setScheduledFromDateFilter] = useState<Date | null>(null);
    const [scheduledToDateFilter, setScheduledToDateFilter] = useState<Date | null>(null);
    const [createdFromDateFilter, setCreatedFromDateFilter] = useState<Date | undefined>(undefined);
    const [createdToDateFilter, setCreatedToDateFilter] = useState<Date | undefined>(undefined);
    const [periodStartDateFilter, setPeriodStartDateFilter] = useState<Date | null>(urlPeriodStartDate ? new Date(urlPeriodStartDate) : null);
    const [periodEndDateFilter, setPeriodEndDateFilter] = useState<Date | null>(urlPeriodEndDate ? new Date(urlPeriodEndDate) : null);
    const [statusFilter, setStatusFilter] = useState<string | undefined>();
    const [reminderFilter, setReminderFilter] = useState<string | undefined>();
    const [formTypeFilter, setFormTypeFilter] = useState<string | undefined>();
    const [companyIdFilter, setCompanyIdFilter] = useState<string | undefined>();
    const [approvedByFilter, setApprovedByFilter] = useState<string | undefined>();
    const [activeStatusFilter, setActiveStatusFilter] = useState<ActivityStatusOptions | undefined>();
    const [itemTypeFilter, setItemTypeFilter] = useState<WorkItemType | undefined>();
    const [studyFilter, setStudyFilter] = useState<string | undefined>();
    const [firstNameFilter, setFirstNameFilter] = useState<string | undefined>();
    const [lastNameFilter, setLastNameFilter] = useState<string | undefined>();
    const [titleFilter, setTitleFilter] = useState<string | undefined>();
    const [departmentIdFilter, setDepartmentIdFilter] = useState<string | undefined>();
    const [isNotAssignedToDepartmentFilter, setIsNotAssignedToDepartmentFilter] = useState<boolean | undefined>();
    const [managerIdFilter, setManagerIdFilter] = useState<string | undefined>();
    const [isNoRespondentsFilter, setIsNoRespondentsFilter] = useState<boolean | undefined>();
    const [periodNameFilter, setPeriodNameFilter] = useState<string | undefined>();
    const [activityTypeFilter, setActivityTypeFilter] = useState<string | undefined>();

    const clearFilters = useCallback(() => {
        setShowInactive(false);
        setScheduledFromDateFilter(null);
        setScheduledToDateFilter(null);
        setCreatedFromDateFilter(undefined);
        setCreatedToDateFilter(undefined);
        setPeriodStartDateFilter(null);
        setPeriodEndDateFilter(null);
        setStatusFilter(undefined);
        setReminderFilter(undefined);
        setFormTypeFilter(undefined);
        setCompanyIdFilter(undefined);
        setApprovedByFilter(undefined);
        setActiveStatusFilter(undefined);
        setItemTypeFilter(undefined);
        setStudyFilter(undefined);
        setFirstNameFilter(undefined);
        setLastNameFilter(undefined);
        setTitleFilter(undefined);
        setDepartmentIdFilter(undefined);
        setIsNotAssignedToDepartmentFilter(undefined);
        setManagerIdFilter(undefined);
        setIsNoRespondentsFilter(undefined);
        setPeriodNameFilter(undefined);
        setActivityTypeFilter(undefined);
    }, []);

    return {
        showInactive,
        setShowInactive,
        formTypeFilter,
        setFormTypeFilter,
        companyIdFilter,
        setCompanyIdFilter,
        scheduledFromDateFilter,
        setScheduledFromDateFilter,
        scheduledToDateFilter,
        setScheduledToDateFilter,
        createdFromDateFilter,
        setCreatedFromDateFilter,
        createdToDateFilter,
        setCreatedToDateFilter,
        periodStartDateFilter,
        setPeriodStartDateFilter,
        periodEndDateFilter,
        setPeriodEndDateFilter,
        statusFilter,
        setStatusFilter,
        reminderFilter,
        setReminderFilter,
        approvedByFilter,
        setApprovedByFilter,
        activeStatusFilter,
        setActiveStatusFilter,
        itemTypeFilter,
        setItemTypeFilter,
        studyFilter,
        setStudyFilter,
        firstNameFilter,
        setFirstNameFilter,
        lastNameFilter,
        setLastNameFilter,
        titleFilter,
        setTitleFilter,
        departmentIdFilter,
        setDepartmentIdFilter,
        isNotAssignedToDepartmentFilter,
        setIsNotAssignedToDepartmentFilter,
        managerIdFilter,
        setManagerIdFilter,
        isNoRespondentsFilter,
        setIsNoRespondentsFilter,
        periodNameFilter,
        setPeriodNameFilter,
        activityTypeFilter,
        setActivityTypeFilter,
        suppressTableFiltering,
        clearFilters,
    };
}

export const DataFilterContextWrapper: FC<PropsWithChildren> = ({ children }) => {
    const filterContext = useInitializeDataFilterContext();

    return <DataFilterContext.Provider value={filterContext}>{children}</DataFilterContext.Provider>;
};

// TODO: just calling this 'nameOverrides' is pretty generic and could confuse people later but that is the fastest solution for now
export interface IDataFilterContextChipsProps {
    nameOverrides?: Map<string, string>;
}

export const DataFilterContextChips: FC<IDataFilterContextChipsProps> = ({ nameOverrides = new Map<string, string>() }) => {
    const {
        scheduledFromDateFilter,
        setScheduledFromDateFilter,
        scheduledToDateFilter,
        setScheduledToDateFilter,
        createdFromDateFilter,
        setCreatedFromDateFilter,
        createdToDateFilter,
        setCreatedToDateFilter,
        periodStartDateFilter,
        setPeriodStartDateFilter,
        periodEndDateFilter,
        setPeriodEndDateFilter,
        formTypeFilter,
        setFormTypeFilter,
        companyIdFilter,
        setCompanyIdFilter,
        reminderFilter,
        setReminderFilter,
        approvedByFilter,
        setApprovedByFilter,
        statusFilter,
        setStatusFilter,
        activeStatusFilter,
        setActiveStatusFilter,
        itemTypeFilter,
        setItemTypeFilter,
        studyFilter,
        setStudyFilter,
        firstNameFilter,
        setFirstNameFilter,
        lastNameFilter,
        setLastNameFilter,
        titleFilter,
        setTitleFilter,
        departmentIdFilter,
        setDepartmentIdFilter,
        isNotAssignedToDepartmentFilter,
        setIsNotAssignedToDepartmentFilter,
        managerIdFilter,
        setManagerIdFilter,
        isNoRespondentsFilter,
        setIsNoRespondentsFilter,
        periodNameFilter,
        setPeriodNameFilter,
        activityTypeFilter,
        setActivityTypeFilter
    } = useContext(DataFilterContext);

    const { studyName } = useGetAndCacheStudy(studyFilter);
    const { companyName } = useGetAndCacheCompany(companyIdFilter);
    const { departmentName } = useGetAndCacheDepartment(departmentIdFilter);
    const { respondentName: managerName } = useGetAndCacheRespondent(managerIdFilter);
    const { userName: approvedByName } = useGetAndCacheUser(approvedByFilter);
    const { formTypeName } = useGetAndCacheFormType(formTypeFilter && isGuid(formTypeFilter) ? formTypeFilter : undefined);

    const mapReminderLabel = useCallback((value: string) => {
        switch (value) {
            case 'manually':
                return 'Manually';
            case 'every-day':
                return 'Every Day';
            case 'every-2-days':
                return 'Every 2 Days';
            case 'every-3-days':
                return 'Every 3 Days';
            case 'every-week':
                return 'Every Week';
            default:
                return 'None';
        }
    }, []);

    const overrideOrOriginal = useCallback((name: string) => nameOverrides.get(name) ?? name, [nameOverrides]);

    const buildChip = useCallback((label: string, onDelete: () => void) => {
        return <Chip label={label} color='primary' onDelete={onDelete} />;
    }, []);

    const ActiveChips = useMemo<JSX.Element[]>(() => {
        const chips: JSX.Element[] = [];
        const addChip = (label: string, onDelete: () => void) => {
            chips.push(buildChip(label, onDelete));
        };

        if (scheduledFromDateFilter && scheduledToDateFilter) {
            addChip(`Scheduled: ${formateDateStandard(scheduledFromDateFilter)} - ${formateDateStandard(scheduledToDateFilter)}`, () => {
                setScheduledFromDateFilter(null);
                setScheduledToDateFilter(null);
            });
        } else {
            scheduledFromDateFilter && addChip(`Scheduled: ${formateDateStandard(scheduledFromDateFilter)} - Present`, () => setScheduledFromDateFilter(null));
            scheduledToDateFilter && addChip(`Scheduled: On or Before ${formateDateStandard(scheduledToDateFilter)}`, () => setScheduledToDateFilter(null));
        }

        if (createdFromDateFilter && createdToDateFilter) {
            addChip(`${overrideOrOriginal('Created')}: ${formateDateStandard(createdFromDateFilter)} - ${formateDateStandard(createdToDateFilter)}`, () => {
                setCreatedFromDateFilter(undefined);
                setCreatedToDateFilter(undefined);
            });
        } else {
            createdFromDateFilter && addChip(`${overrideOrOriginal('Created')}: ${formateDateStandard(createdFromDateFilter)} - Present`, () => setCreatedFromDateFilter(undefined));
            createdToDateFilter && addChip(`${overrideOrOriginal('Created')}: On or Before ${formateDateStandard(createdToDateFilter)}`, () => setCreatedToDateFilter(undefined));
        }

        if (periodStartDateFilter && periodEndDateFilter) {
            addChip(`${overrideOrOriginal('Period')}: ${formateDateStandard(periodStartDateFilter)} - ${formateDateStandard(periodEndDateFilter)}`, () => {
                setPeriodStartDateFilter(null);
                setPeriodEndDateFilter(null);
            });
        } else {
            periodStartDateFilter && addChip(`${overrideOrOriginal('Period')}: ${formateDateStandard(periodStartDateFilter)} - Present`, () => setPeriodStartDateFilter(null));
            periodEndDateFilter && addChip(`${overrideOrOriginal('Period')}: On or Before ${formateDateStandard(periodEndDateFilter)}`, () => setPeriodEndDateFilter(null));
        }

        formTypeFilter && addChip(`Form Type: ${isGuid(formTypeFilter) ? formTypeName : formTypeFilter}`, () => setFormTypeFilter(undefined));
        companyIdFilter && addChip(`Company: ${companyName}`, () => setCompanyIdFilter(undefined));
        statusFilter && addChip(`Status: ${statusFilter}`, () => setStatusFilter(undefined));
        reminderFilter && addChip(`Reminders: ${mapReminderLabel(reminderFilter)}`, () => setReminderFilter(undefined));
        approvedByFilter && addChip(`Approved By: ${approvedByName}`, () => setApprovedByFilter(undefined));
        activeStatusFilter && addChip(`Activity Status: ${activeStatusFilter}`, () => setActiveStatusFilter(undefined));
        itemTypeFilter && addChip(`Type: ${itemTypeFilter.toString()}`, () => setItemTypeFilter(undefined));
        studyFilter && addChip(`Study: ${studyName}`, () => setStudyFilter(undefined));
        firstNameFilter && addChip(`First Name: ${firstNameFilter}`, () => setFirstNameFilter(undefined));
        lastNameFilter && addChip(`Last Name: ${lastNameFilter}`, () => setLastNameFilter(undefined));
        titleFilter && addChip(`Title: ${titleFilter}`, () => setTitleFilter(undefined));
        departmentIdFilter && addChip(`Department: ${departmentName}`, () => setDepartmentIdFilter(undefined));
        isNotAssignedToDepartmentFilter && addChip(`Department: Not Assigned`, () => setIsNotAssignedToDepartmentFilter(undefined));
        managerIdFilter && addChip(`Manager: ${managerName}`, () => setManagerIdFilter(undefined));
        isNoRespondentsFilter && addChip(`Respondents: No Respondents`, () => setIsNoRespondentsFilter(undefined));
        periodNameFilter && addChip(`Period: ${periodNameFilter}`, () => setPeriodNameFilter(undefined));
        activityTypeFilter && addChip(`Activity Type: ${snakeCaseToPrint(eventNameMapper(activityTypeFilter))}`, () => setActivityTypeFilter(undefined));

        return chips;
    }, [
        buildChip,
        mapReminderLabel,
        formTypeName,
        studyName,
        companyName,
        approvedByName,
        departmentName,
        managerName,
        scheduledFromDateFilter,
        setScheduledFromDateFilter,
        scheduledToDateFilter,
        setScheduledToDateFilter,
        createdFromDateFilter,
        setCreatedFromDateFilter,
        createdToDateFilter,
        setCreatedToDateFilter,
        periodStartDateFilter,
        setPeriodStartDateFilter,
        periodEndDateFilter,
        setPeriodEndDateFilter,
        formTypeFilter,
        setFormTypeFilter,
        companyIdFilter,
        setCompanyIdFilter,
        reminderFilter,
        setReminderFilter,
        approvedByFilter,
        setApprovedByFilter,
        statusFilter,
        setStatusFilter,
        activeStatusFilter,
        setActiveStatusFilter,
        itemTypeFilter,
        setItemTypeFilter,
        studyFilter,
        setStudyFilter,
        firstNameFilter,
        setFirstNameFilter,
        lastNameFilter,
        setLastNameFilter,
        titleFilter,
        setTitleFilter,
        departmentIdFilter,
        setDepartmentIdFilter,
        isNotAssignedToDepartmentFilter,
        setIsNotAssignedToDepartmentFilter,
        isNoRespondentsFilter,
        setIsNoRespondentsFilter,
        managerIdFilter,
        setManagerIdFilter,
        periodNameFilter,
        setPeriodNameFilter,
        activityTypeFilter,
        setActivityTypeFilter,
        overrideOrOriginal
    ]);

    return <>{ActiveChips}</>;
};
