import { IRoute } from './types';
import { DashboardView, CompaniesListView, SurveysListView, SurveyStepperView, UsersListView, AddUserView, EditUserView, BusinessComponentStepperView, StudyListView, StudyDashboard } from '../Views';
import { CreateCompanyView, CompanyDashboard } from '../Views/Company';
import { SurveyScheduleListView } from '../Views/Schedule/SurveyScheduleListView';
import { CreateRespondentView, EditRespondentView } from '../Views/Company/Respondent';
import { SurveyView } from '../Views/Survey/SurveyView';
import { BusinessComponentScheduleDetailsView, BusinessComponentScheduleListView, SurveyScheduleDetailsView } from '../Views/Schedule';
import { EmailTemplatesListView } from '../Views/EmailTemplatesListView';
import { ExportSurveyView } from '../Views/Survey/ExportSurveyView';
import { CustomerServiceFormView } from '../Views/Customer Service/CustomerServiceFormView';
import { DataFilterContextWrapper } from '../Contexts';

export const routes: IRoute[] = [
    {
        component: <DataFilterContextWrapper><DashboardView /></DataFilterContextWrapper>,
        path: '/',
    },
    {
        component: <CompaniesListView />,
        path: '/companies',
    },
    {
        component: <CreateCompanyView />,
        path: '/companies/create',
    },
    {
        component: <CompanyDashboard />,
        path: '/companies/:id',
    },
    {
        component: <CreateRespondentView />,
        path: '/companies/:id/respondents/create',
    },
    {
        component: <DataFilterContextWrapper><EditRespondentView /></DataFilterContextWrapper>,
        path: '/companies/:id/respondents/:respondentId',
    },
    {
        component: <SurveysListView />,
        path: '/form-types',
    },
    {
        component: <EmailTemplatesListView />,
        path: '/email-templates',
    },
    {
        component: <SurveyStepperView />,
        path: '/companies/:id/survey/:surveyId/:duplicate',
    },
    {
        component: <SurveyStepperView />,
        path: '/companies/:id/survey/:surveyId',
    },
    {
        component: <SurveyStepperView />,
        path: '/companies/:id/survey',
    },
    {
        component: <BusinessComponentStepperView />,
        path: '/companies/:id/business-component/:surveyId/:duplicate',
    },
    {
        component: <BusinessComponentStepperView />,
        path: '/companies/:id/business-component/:surveyId',
    },
    {
        component: <BusinessComponentStepperView />,
        path: '/companies/:id/business-component',
    },
    {
        component: <DataFilterContextWrapper><SurveyScheduleListView /></DataFilterContextWrapper>,
        path: '/schedules',
    },
    {
        component: <DataFilterContextWrapper><BusinessComponentScheduleListView /></DataFilterContextWrapper>,
        path: '/business-components',
    },
    {
        component: <DataFilterContextWrapper><SurveyScheduleDetailsView /></DataFilterContextWrapper>,
        path: '/schedules/:id',
    },
    {
        component: <BusinessComponentScheduleDetailsView />,
        path: '/business-components/:id',
    },
    {
        component: <BusinessComponentScheduleDetailsView />,
        path: '/companies/:companyId/business-components/:id',
    },
    {
        component: <DataFilterContextWrapper><SurveyScheduleDetailsView /></DataFilterContextWrapper>,
        path: '/companies/:companyId/schedules/:id',
    },
    {
        component: <BusinessComponentScheduleDetailsView />,
        path: '/companies/:companyId/business-component/:id',
    },
    {
        component: <ExportSurveyView />,
        path: '/exportSurvey/schedule/:scheduleId/detail/:detailId/fieldGroup/:fieldGroupIndex',
    },
    {
        component: <ExportSurveyView />,
        path: '/exportSurvey/schedule/:scheduleId/detail/:detailId/timePeriod/:timePeriodIndex',
    },
    {
        component: <UsersListView />,
        path: '/users',
        requiredPermissions: ['read:users']
    },
    {
        component: <AddUserView />,
        path: '/users/create',
        requiredPermissions: ['create:users']
    },
    {
        component: <EditUserView />,
        path: '/users/:id',
        requiredPermissions: ['edit:users']
    },
    {
        component: <StudyListView />,
        path: '/studies',
        requiredPermissions: ['read:studies']
    },
    {
        component: <StudyDashboard />,
        path: '/studies/:id',
        requiredPermissions: ['read:studies']
    },
];

export const publicRoutes: IRoute[] = [
    {
        component: <SurveyView />,
        path: '/survey/schedule/:scheduleId/detail/:id',
    },
    {
        component: <CustomerServiceFormView />,
        path: '/service',
    },
];
