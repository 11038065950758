import { useEffect } from 'react';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { useSendReminderEmailMutation } from '../../store/api/schedule-api';
import { ScheduleDetailDto } from '../../dtos';

export function SendEmailReminders(scheduleDetails: ScheduleDetailDto[], shouldSend: boolean, resetShouldSend: () => void) {
    const [sendReminder, { isSuccess: isReminderSent, isError: isReminderError, reset }] = useSendReminderEmailMutation();

    const subject = scheduleDetails.length > 1 ? 'reminders' : 'reminder';
    useSuccessfulActionSnackbar('sent', `email ${subject}`, isReminderSent, reset);
    useFailedActionSnackbar('send', `email ${subject}`, isReminderError, reset);

    useEffect(() => {
        if (scheduleDetails.length > 0 && shouldSend) {
            for (let i = 0; i < scheduleDetails.length; i++) {
                const scheduleDetail = scheduleDetails[i];
                sendReminder({
                    id: scheduleDetail.id,
                    cancelNext: true,
                });
            }
            resetShouldSend();
        }
    }, [resetShouldSend, scheduleDetails, sendReminder, shouldSend]);
}
