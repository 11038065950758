import { FC, useCallback, useContext, useState } from 'react';
import { CompanyDashboardContext } from '../../../../Utils';
import { CompanyRespondentsListView } from '../../../../../../Components/Forms/CompanyForm';
import { Warning } from '@mui/icons-material';
import { Typography, Paper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RespondentDto } from '../../../../../../dtos';
import {
    useGetCompanyRespondentsWithNoDepartmentQuery,
    BulkUpdateWithParentId,
    useBulkArchiveRespondentMutation,
    useBulkUnarchiveRespondentMutation,
    useBulkRespondentEmailForwardingMutation,
} from '../../../../../../store';
import { ImportRespondentsModal, VerifyRespondentsModal } from '../../../../../../Components/Forms';
import { BulkReassignModal } from '../../../../../../Components/Forms/CompanyForm/RespondentForm/BulkReassignModal';
import { DataFilterContext } from '../../../../../../Contexts';
import { ConfirmationModalWrapper, useBulkActionSnackbar, useFailedActionSnackbar } from '../../../../../../Components/CoreLib/library';

export const CompanyDashboardRespondentsTab: FC = () => {
    const { company, searchText } = useContext(CompanyDashboardContext);
    const {
        firstNameFilter,
        lastNameFilter,
        titleFilter,
        departmentIdFilter,
        setDepartmentIdFilter,
        isNotAssignedToDepartmentFilter,
        setIsNotAssignedToDepartmentFilter,
        managerIdFilter,
        formTypeFilter,
    } = useContext(DataFilterContext);
    const navigate = useNavigate();
    const [selectedRespondents, setSelectedRespondents] = useState<RespondentDto[]>([]);
    const [targetForwardingStatus, setTargetForwardingStatus] = useState<boolean>(true);
    const [isImportVisible, setIsImportVisible] = useState(false);
    const [isVerifyVisible, setIsVerifyVisible] = useState(false);
    const [isEmailForwardingUpdateModalVisible, setIsEmailForwardingUpdateModalVisible] = useState(false);
    const [isMoveDepartmentVisible, setIsMoveDepartmentVisible] = useState(false);
    const { data: respondentsWithNoDepartment } = useGetCompanyRespondentsWithNoDepartmentQuery(company.id);
    const [
        bulkSetRespondentEmailForwarding,
        {
            data: updateRespondentEmailForwardingResult,
            isLoading: isUpdatingEmailForwarding,
            isError: isErrorUpdatingEmailForwarding,
            reset: resetUpdateEmailForwarding,
        },
    ] = useBulkRespondentEmailForwardingMutation();
    useFailedActionSnackbar('update', 'respondent(s) email forwarding', isErrorUpdatingEmailForwarding, resetUpdateEmailForwarding);
    useBulkActionSnackbar(
        targetForwardingStatus ? 'started forwarding emails' : 'stopped forwarding emails',
        targetForwardingStatus ? 'start forwarding emails for' : 'stop forwarding emails for',
        'respondent',
        'respondents',
        updateRespondentEmailForwardingResult,
        resetUpdateEmailForwarding
    );

    const handleCreate = () => {
        navigate(`/companies/${company.id}/respondents/create`);
    };

    const handleEdit = (selectedRespondentId: string) => {
        if (selectedRespondentId) {
            navigate(`/companies/${company.id}/respondents/${selectedRespondentId}`);
        }
    };

    const handleImport = () => {
        setIsImportVisible(true);
    };

    const handleVerifyRespondents = () => {
        setIsVerifyVisible(true);
    };

    const handleBulkReassignClicked = (respondents: RespondentDto[]) => {
        setSelectedRespondents(respondents);
        setIsMoveDepartmentVisible(true);
    };

    const handleBulkUpdateEmailForwardingClicked = (targetForwardingStatus: boolean) => (respondents: RespondentDto[]) => {
        setTargetForwardingStatus(targetForwardingStatus);
        setSelectedRespondents(respondents);
        setIsEmailForwardingUpdateModalVisible(true);
    };

    const handleCancelEmailForwarding = useCallback(() => {
        setIsEmailForwardingUpdateModalVisible(false);
        setSelectedRespondents([]);
    }, []);

    const handleConfirmEmailForwarding = useCallback(() => {
        const respondentIds = selectedRespondents.filter(r => r.shouldForwardEmailsToManager !== targetForwardingStatus).map((r) => r.id);
        bulkSetRespondentEmailForwarding({ companyId: company.id, payload: { targetForwardingStatus, respondentIds } });
        handleCancelEmailForwarding();
    }, [bulkSetRespondentEmailForwarding, company.id, handleCancelEmailForwarding, selectedRespondents, targetForwardingStatus]);

    const addParentDataToMutationParams = (records: RespondentDto[], executeMutation: (payload: BulkUpdateWithParentId) => void) => {
        const payload: BulkUpdateWithParentId = {
            parentId: company.id,
            recordIds: records.map((x) => x.id),
        };
        executeMutation(payload);
    };

    const setNoDepartmentFilter = useCallback(() => {
        setDepartmentIdFilter(undefined);
        setIsNotAssignedToDepartmentFilter(true);
    }, [setDepartmentIdFilter, setIsNotAssignedToDepartmentFilter]);

    const renderTableWarning = () => {
        if (respondentsWithNoDepartment && respondentsWithNoDepartment.length > 0) {
            const numRespondentsWithNoDepartment = respondentsWithNoDepartment.length;
            const pluralizedContent = numRespondentsWithNoDepartment === 1 ? 'respondent is' : 'respondents are';
            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', paddingBottom: '8px' }}>
                    <Warning color='primary' style={{ cursor: 'pointer' }} onClick={setNoDepartmentFilter} />
                    <Typography color='primary' style={{ cursor: 'pointer' }} onClick={setNoDepartmentFilter}>
                        {`${numRespondentsWithNoDepartment} ${pluralizedContent} not assigned to a department`}
                    </Typography>
                </div>
            );
        }
    };

    return (
        <Paper elevation={0}>
            <Grid container direction='column' spacing={3}>
                <Grid item>
                    <CompanyRespondentsListView
                        companyId={company.id}
                        customTableProps={{
                            additionalQueryParameters: {
                                searchText: searchText,
                                firstName: firstNameFilter,
                                lastName: lastNameFilter,
                                title: titleFilter,
                                departmentId: departmentIdFilter,
                                isNotAssignedToDepartment: isNotAssignedToDepartmentFilter,
                                managerId: managerIdFilter,
                                formType: formTypeFilter,
                            },
                            archiveMutation: useBulkArchiveRespondentMutation,
                            archiveIntercept: addParentDataToMutationParams,
                            recoverMutation: useBulkUnarchiveRespondentMutation,
                            recoverIntercept: addParentDataToMutationParams,
                            additionalMenuItems: [
                                {
                                    text: 'Bulk Reassign',
                                    onClick: handleBulkReassignClicked,
                                    enabledCheck: (selectedItems: RespondentDto[]) => selectedItems.length > 0,
                                },
                                {
                                    text: 'Import',
                                    onClick: handleImport,
                                },
                                {
                                    text: 'Verify Respondents',
                                    onClick: handleVerifyRespondents,
                                },
                                {
                                    text: 'Forward Emails',
                                    onClick: handleBulkUpdateEmailForwardingClicked(true),
                                    enabledCheck: (respondents: RespondentDto[]) => !isUpdatingEmailForwarding && respondents.some(r => !r.shouldForwardEmailsToManager),
                                    clearSelectedItems: true
                                },
                                {
                                    text: 'Stop Email Forwarding',
                                    onClick: handleBulkUpdateEmailForwardingClicked(false),
                                    enabledCheck: (respondents: RespondentDto[]) => !isUpdatingEmailForwarding && respondents.some(r => r.shouldForwardEmailsToManager),
                                    clearSelectedItems: true
                                },
                            ],
                            handleEdit: handleEdit,
                            handleAdd: handleCreate,
                            subtitleComponentOverride: renderTableWarning(),
                        }}
                    />
                </Grid>
            </Grid>
            {isMoveDepartmentVisible && (
                <BulkReassignModal
                    selectedRespondents={selectedRespondents}
                    isMoveDepartmentModalVisible={isMoveDepartmentVisible}
                    setIsMoveDepartmentModalVisible={setIsMoveDepartmentVisible}
                    companyId={company.id}
                />
            )}
            {isImportVisible && company.name && (
                <ImportRespondentsModal
                    isImportVisible={isImportVisible}
                    setIsImportVisible={setIsImportVisible}
                    companyId={company.id}
                    companyName={company.name}></ImportRespondentsModal>
            )}
            {isVerifyVisible && company.userId && (
                <VerifyRespondentsModal
                    isVerifyVisible={isVerifyVisible}
                    setIsVerifyVisible={setIsVerifyVisible}
                    companyId={company.id}
                    companyUserId={company.userId}
                />
            )}
            <ConfirmationModalWrapper
                isOpen={isEmailForwardingUpdateModalVisible}
                title='Email Forwarding Warning'
                cancel={handleCancelEmailForwarding}
                confirm={handleConfirmEmailForwarding}>
                <Typography>
                    {targetForwardingStatus
                        ? 'By forwarding emails to the associated managers, the respondents themselves will no longer receive the emails.'
                        : 'By stopping email forwarding, the respondents themselves will receive emails. Managers will not be notified.'}
                </Typography>
                <br />
                <Typography>
                    {targetForwardingStatus
                        ? 'Are you sure you want to FORWARD future emails?'
                        : 'Are you sure you want to STOP FORWARDING future emails?'}
                </Typography>
            </ConfirmationModalWrapper>
        </Paper>
    );
};
