import { ActivityStreamDto } from "../../../dtos";
import { BaseMessageType } from "./BaseMessageType";

export class RespondentCrudMessage extends BaseMessageType {
    _action: string;

    constructor(streamEntry: ActivityStreamDto, action: string) {
        super(streamEntry);
        this._action = action;
    }

    getMessage(): JSX.Element[] {
        const { respondent, company } = this._streamEntry;
        return this.generateMessageFromPayload({
            noun: 'Respondent',
            properNoun: `${respondent?.firstName} ${respondent?.lastName}`,
            properNounRoute: `/companies/${company?.id}/respondents/${respondent?.id}`,
            pastTenseAction: this._action,
            companyJoiningText: 'for',
            companyName: company?.shortName || company?.name,
            companyRoute: `/companies/${company?.id}`,
        });
    }
}