import { useMemo } from "react";
import { ScheduleDto } from "../../../../dtos";

// TODO: I have made a minimally invasive change to this hook but it could definitely be cleaned up more. However, unit tests should be written around it first.
export function useSelectedScheduleFilters(selectedSchedules: readonly ScheduleDto[]) {
    const reminderSchedules = useMemo(() => {
        return selectedSchedules.filter(x => x.issued > 0 || x.started > 0);
    }, [selectedSchedules]);
    
    const selectedItemsThatCanBeDelayed = useMemo((): (ScheduleDto | undefined)[] => {
        return selectedSchedules
            .map((selectedSchedule) => {
                if (selectedSchedule) {
                    const status = selectedSchedule.status;
                    if (status && status.toLowerCase() === 'pending') {
                        return selectedSchedule;
                    }
                }
                return undefined;
            })
            .filter((x) => x !== undefined);
    }, [selectedSchedules]);

    const selectedItemsThatCanBeEdited = useMemo((): (ScheduleDto | undefined)[] => {
        return selectedSchedules
            .map((selectedSchedule) => {
                if (selectedSchedule) {
                    const status = selectedSchedule.status ?? '';
                    if (status.toLowerCase() === 'pending' || status.toLowerCase() === 'scheduled') {
                        return selectedSchedule;
                    }
                }
                return undefined;
            })
            .filter((x) => x !== undefined);
    }, [selectedSchedules]);

    const selectedItemsThatCanBeScheduled = useMemo((): (ScheduleDto | undefined)[] => {
        return selectedSchedules
            .map((selectedSchedule) => {
                if (selectedSchedule) {
                    const status = selectedSchedule.status;
                    const approvedBy = selectedSchedule.approvedByUserId;
                    if (status && status.toLowerCase() === 'scheduled' && approvedBy && approvedBy !== '' && selectedSchedule.scheduled) {
                        return selectedSchedule;
                    }
                }
                return undefined;
            })
            .filter((x) => x !== undefined);
    }, [selectedSchedules]);

    const selectedItemsThatCanBeActivated = useMemo((): (ScheduleDto | undefined)[] => {
        return selectedSchedules
            .map((selectedSchedule) => {
                if (selectedSchedule) {
                    if (selectedSchedule.isActive === false) {
                        return selectedSchedule;
                    }
                }
                return undefined;
            })
            .filter((x) => x !== undefined);
    }, [selectedSchedules]);

    const selectedItemsThatCanBeDisabled = useMemo((): (ScheduleDto | undefined)[] => {
        return selectedSchedules
            .map((selectedSchedule) => {
                if (selectedSchedule) {
                    if (selectedSchedule.isActive) {
                        return selectedSchedule;
                    }
                }
                return undefined;
            })
            .filter((x) => x !== undefined);
    }, [selectedSchedules]);

    return {
        selectedSchedules,
        reminderSchedules,
        selectedItemsThatCanBeDelayed,
        selectedItemsThatCanBeEdited,
        selectedItemsThatCanBeScheduled,
        selectedItemsThatCanBeActivated,
        selectedItemsThatCanBeDisabled
    }
}