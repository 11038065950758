import { Tooltip } from "@mui/material";
import { useCallback, useMemo } from "react";
import { ScheduleDto, ScheduleStatus } from "../../../../dtos";
import { DataTableColumn } from "../../../CoreLib/library";
import { getFilterNavigation } from "./getFilterNavigation";
import { formateDateTimeStandard } from "../../../../util";

export interface ScheduleTableHeaderConfig {
    companyId?: string;
    hideCompanyName?: boolean;
    hideReminders?: boolean;
    hideApprovedBy?: boolean;
    hideFormType?: boolean;
    hideScheduleDetailStatusCounts?: boolean;
}

export function useGetScheduleTableHeaders(headerConfig: ScheduleTableHeaderConfig) {
    const {
        companyId,
        hideCompanyName = false,
        hideFormType = false,
        hideReminders = false,
        hideApprovedBy = false,
        hideScheduleDetailStatusCounts = false
    } = headerConfig;

    const formatHyphenString = (stringToFormat: string) => {
        let newStringToFormat = '';
        if (stringToFormat.includes('-')) {
            let stringToFormatSplit = stringToFormat.split('-');
            stringToFormatSplit.forEach((word) => {
                newStringToFormat += word.charAt(0).toUpperCase() + word.slice(1) + ' ';
            });
            return newStringToFormat;
        } else {
            return stringToFormat.charAt(0).toUpperCase() + stringToFormat.slice(1);
        }
    };

    const buildStatusColumn = useCallback((statusName: 'Created' | 'Issued' | 'Started' | 'Paused' | 'Completed'): DataTableColumn<ScheduleDto> => {
        return {
            key: statusName.toLowerCase(),
            label: statusName.substring(0, 3).toUpperCase(),
            sortKey: statusName.toUpperCase(),
            unsorted: true,
            align: 'right',
            fieldMapper: (row: ScheduleDto) => (
                <Tooltip title={statusName}>
                    <span>{getFilterNavigation(row, ScheduleStatus[statusName], companyId)}</span>
                </Tooltip>
            ),
        }
    }, [companyId]);

    const tableHeaders = useMemo(() => {
        const tableHeaders: DataTableColumn<ScheduleDto>[] = [];
        hideCompanyName || tableHeaders.push({ key: 'company', label: 'Company (Short)', sortKey: 'COMPANY', fieldMapper: (row: ScheduleDto) => row.companyShortName });
        tableHeaders.push({ key: 'period', label: 'Period', sortKey: 'PERIOD', fieldMapper: (row: ScheduleDto) => row.period });
        hideFormType || tableHeaders.push({ key: 'formType', label: 'Form Type', sortKey: 'FORM_TYPE' });
        tableHeaders.push({ key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: (row: ScheduleDto) => (row.isExpired ? 'Expired' : row.status) });
        hideReminders || tableHeaders.push({ key: 'reminders', label: 'Reminders', sortKey: 'REMINDERS', fieldMapper: (row: ScheduleDto) => formatHyphenString(row.reminders) });
        hideApprovedBy || tableHeaders.push({ key: 'approvedBy', label: 'Approved By', sortKey: 'APPROVED_BY', fieldMapper: (row: ScheduleDto) => (row.approvedBy ? row.approvedBy.displayName : 'Pending') });
        tableHeaders.push({ key: 'scheduled', label: 'Scheduled', sortKey: 'SCHEDULED', fieldMapper: (row: ScheduleDto) => formateDateTimeStandard(row.scheduled) });
        if (!hideScheduleDetailStatusCounts) {
            tableHeaders.push(buildStatusColumn('Created'));
            tableHeaders.push(buildStatusColumn('Issued'));
            tableHeaders.push(buildStatusColumn('Started'));
            tableHeaders.push(buildStatusColumn('Paused'));
            tableHeaders.push(buildStatusColumn('Completed'));
        }
        return tableHeaders;
    }, [buildStatusColumn, hideApprovedBy, hideCompanyName, hideFormType, hideReminders, hideScheduleDetailStatusCounts]);

    return { tableHeaders }
}