import { ImportExport } from "@mui/icons-material";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { ImportWorkItemRowDto } from "../../../dtos";
import { useImportWorkItemsMutation, useValidateWorkItemsImportMutation } from "../../../store/api/import-api";
import { StandardDialogHeader } from "../../CommonComponents";
import { ImportWorkItemsFileSelect } from "./ImportWorkItemsFileSelect";
import { ImportWorkItemsVerifyImport } from "./ImportWorkItemsVerifyImport";
import { ImportWorkItemsImportResult } from "./ImportWorkItemsImportResult";

export interface IImportWorkItemsProps {
    companyId: string;
    companyName: string;
    onClose: () => void;
    isImportVisible: boolean;
}

export const ImportWorkItemsModal: FC<IImportWorkItemsProps> = ({ onClose, isImportVisible, companyId, companyName }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [validateImportRecords, { isLoading: isValidating, data: validatedImportRecords, isError: isErrorValidating }] = useValidateWorkItemsImportMutation();
    const [importWorkItems, { isLoading: isImporting, data: importResults, isError: isErrorImporting }] = useImportWorkItemsMutation();
    
    const loadRecordsToImport = useCallback((records: ImportWorkItemRowDto[]) => {
        validateImportRecords(records)
        setActiveStep(1);
    }, [validateImportRecords]);

    const commitToImport = useCallback(() => {
        if (!validatedImportRecords) {
            return;
        }
        importWorkItems(validatedImportRecords);
        setActiveStep(2);
    }, [validatedImportRecords, importWorkItems]);

    const handleClose = useCallback(() => {
        setActiveStep(0);
        onClose();
    }, [onClose]);

    const steps = useMemo(() => [
            <ImportWorkItemsFileSelect onCancelClicked={handleClose} loadSelectedFileData={loadRecordsToImport} companyId={companyId} />,
            <ImportWorkItemsVerifyImport onCancelClicked={handleClose} onCommitToImport={commitToImport} recordsToImport={validatedImportRecords ?? []} isLoading={isValidating} isError={isErrorValidating} />,
            <ImportWorkItemsImportResult onCloseClicked={handleClose} importResults={importResults ?? []} isLoading={isImporting} isError={isErrorImporting} />
    ], [commitToImport, companyId, handleClose, importResults, isImporting, isValidating, loadRecordsToImport, validatedImportRecords, isErrorValidating, isErrorImporting]);


    return (
        <Dialog
            open={isImportVisible}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
        >
            <StandardDialogHeader icon={<ImportExport />} text='Import Components' />
            <Typography ml={3} mt={3}>Components will be added to {companyName}</Typography>
            <DialogContent sx={{ maxHeight: 700, overflowY: 'auto' }}>
                {steps[activeStep]}
            </DialogContent>
        </Dialog >
    )
}