import { SearchQueryParams, serverApi } from '..';
import { QueryResultsDto } from '../../dtos';
import { ActivityStreamDto } from '../../dtos/generated/ActivityStreamDto';
import { buildSearchQueryRoute } from '../../util';

export interface GetActivityStreamQueryParams extends SearchQueryParams {
  companyId: string;
  firstName: string;
  lastName: string;
  activityType: string;
  formType: string;
  period: string;
  fromDate: string;
  toDate: string;
}

const activityStreamApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityStream: builder.query<QueryResultsDto<ActivityStreamDto>, GetActivityStreamQueryParams>({
      query: (params) => buildSearchQueryRoute(`activityStream`, params),
      providesTags: [{ type: 'Respondent' }, { type: 'ScheduleDetails' }, { type: 'Company' }],
    }),
    getActivityTypes: builder.query<string[], void>({
      query: () => ({
        url: `activityStream/meta/activityTypes`,
        method: 'GET',
      })
    }),
  })
});

export const { useGetActivityStreamQuery, useGetActivityTypesQuery } = activityStreamApi;
