import { EmailTemplateDto, SurveyPayloadDto, SurveyPayloadSurveyPeriodConfig } from "../../../dtos";
import { emptyGuid } from "../../../models";

export class SurveyValidator {
    private readonly surveyPayload: SurveyPayloadDto;
    private readonly emailTemplates: EmailTemplateDto[];

    constructor(surveyPayload: SurveyPayloadDto, emailTemplates: EmailTemplateDto[]) {
        this.surveyPayload = surveyPayload;
        this.emailTemplates = emailTemplates;
    }

    public get isFormStepValid() {
        return !!this.surveyPayload.selectedFormType.id && this.surveyPayload.selectedFormType.id !== emptyGuid;
    }

    public get isRespondentsStepValid() {
        return this.surveyPayload.respondentQueue.length > 0;
    }
    
    public get isDistributionStepValid() {
        const distributionConfig = this.surveyPayload.distributionConfig;
        if (distributionConfig.surveyDistribution === "individualRecipient" && distributionConfig.individualRecipient.length === 0) {
            return false;
        }
        else if (distributionConfig.fillOutSurveyValue === "Group" && distributionConfig.groupName === "" && distributionConfig.surveyDistribution === "individualRecipient") {
            return false;
        }
        else {
            return true;
        }
    }

    public get isFrequencyStepValid() {
        if(this.surveyPayload.frequencyConfig.radioStartDate === "immediately")
        {
            return true;
        }
        else if(!this.surveyPayload.frequencyConfig.startDate)
        {
            return false;
        }
        else if (this.surveyPayload.frequencyConfig.startDate < new Date())
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    public get isTimePeriodStepValid() {
        const frequencyConfig = this.surveyPayload.frequencyConfig;

        const isOneTime = frequencyConfig.radioOneTimeOrRecurring === 'one-time';
        const isEveryTimePeriodFilledOut = this.surveyPayload.timePeriodArray.every(tp => tp.startDate && tp.endDate && tp.surveyLabel);
        if (isOneTime && !isEveryTimePeriodFilledOut) {
            return false;
        }

        const isRecurring = frequencyConfig.radioOneTimeOrRecurring === 'recurring';
        const isYearly = frequencyConfig.frequencyValue === 'every-year';
        const isQuarterly = !isYearly;
        if (isRecurring && isYearly) {
            return this.isPeriodConfigValid(frequencyConfig.surveyPeriods[0])
        } else if (isRecurring && isQuarterly) {
            return (
                this.isPeriodConfigValid(frequencyConfig.surveyPeriods[0]) &&
                this.isPeriodConfigValid(frequencyConfig.surveyPeriods[1]) &&
                this.isPeriodConfigValid(frequencyConfig.surveyPeriods[2]) &&
                this.isPeriodConfigValid(frequencyConfig.surveyPeriods[3])
            )
        }
        
        return true;
    }

    public get isEmailStepValid() {
        if (this.surveyPayload.emailConfig.emailTemplate === this.emailTemplates.find(template => template.name === "Client Contact Email")?.id) {
            if (this.surveyPayload.emailConfig.reminderValue !== "" && this.surveyPayload.emailConfig.companyContactName !== "" && this.surveyPayload.emailConfig.companyContactTitle !== "")
            {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public get isSettingsStepValid() {
        return !!this.surveyPayload.study;
    }

    public get isSummaryStepValid() {
        return true;
    }

    public get isDataStepValid() {
        return true;
    }

    private isPeriodConfigValid(config: SurveyPayloadSurveyPeriodConfig) {
        const areAllRequiredFieldsPopulated = (
            config.periodStartMonth !== undefined &&
            config.periodStartDay !== undefined &&
            config.periodEndMonth !== undefined &&
            config.periodEndDay !== undefined
        );
        return areAllRequiredFieldsPopulated;
    }
}