import { Box, FormControlLabel, Paper, Switch, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { DataTableColumn, StandardListViewV2 } from '../../../../../../Components/CoreLib/library';
import { ImportWorkItemsModal } from '../../../../../../Components/Forms/ImportWorkItems';
import { DataFilterContext } from '../../../../../../Contexts';
import { WorkItemDto, WorkItemType } from '../../../../../../dtos';
import { useGetWorkItemQuery, useWorkItemBulkArchiveMutation, useWorkItemBulkUnarchiveMutation } from '../../../../../../store';
import { AddEditWorkItemModal } from '../../../../../Studies/Components/AddEditWorkItemModal';
import { DEFAULT_WORK_ITEM } from '../../../../../Studies/Hooks';
import { CompanyDashboardContext } from '../../../../Utils';
import { formateDateStandard, toWords } from '../../../../../../util';

const DEFAULT_PAGINATION = { searchText: '', sortKey: 'NAME', sortAsc: true, page: 0, pageSize: 25, includeInactive: false };
const TABLE_COLUMNS: DataTableColumn<WorkItemDto>[] = [
    { key: 'name', label: 'Name', sortKey: 'NAME' },
    { key: 'type', label: 'Type', sortKey: 'TYPE', fieldMapper: (row) => toWords(WorkItemType[row.type]?.toString()) },
    { key: 'employeeCount', label: 'Employees', sortKey: '', unsorted: true },
    { key: 'startDate', label: 'Start Date', sortKey: 'START_DATE', fieldMapper: (row) => formateDateStandard(row.startDate) },
    { key: 'endDate', label: 'End Date', sortKey: 'END_DATE', fieldMapper: (row) => formateDateStandard(row.endDate, 'Ongoing') }
];

type StandardListViewV2EditHandler<T> = (id: string, record: T, event?: any) => void;

export const CompanyDashboardComponentsTab: FC = () => {
    const {company, setSelectedComponentId} = useContext(CompanyDashboardContext);
    const [isCompletedVisible, setIsCompletedVisible] = useState(true);
    const [isImportWorkItemModalVisible, setIsImportWorkItemModalVisible] = useState(false);
    const [selectedWorkItem, setSelectedWorkItem] = useState<WorkItemDto>();
    const {
        activeStatusFilter,
        itemTypeFilter,
        periodStartDateFilter,
        periodEndDateFilter
    } = useContext(DataFilterContext);

    const customHeaderContent = useMemo(() => {
        return (
            <FormControlLabel
                style={{ marginRight: 1 }}
                control={<Switch size='small' checked={isCompletedVisible} onChange={(_, val) => setIsCompletedVisible(val)} />}
                label='Show Completed'
                labelPlacement='start'
            />
        );
    }, [isCompletedVisible]);

    const handleAdd = () => {
        setSelectedWorkItem(DEFAULT_WORK_ITEM);
    };

    const handleEdit: StandardListViewV2EditHandler<WorkItemDto> = (_, workItem) => {
        setSelectedWorkItem(workItem);
    };

    const handleRowClick: StandardListViewV2EditHandler<WorkItemDto> = useCallback((_, workItem) => {
        if(workItem && setSelectedComponentId) {
            setSelectedComponentId(workItem.id);
        }
    }, [setSelectedComponentId]);

    return (
        <Paper elevation={0}>
            <Box height={16}></Box>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                titleFontSize='32px'
                defaultPaginationProps={DEFAULT_PAGINATION}
                getDataQuery={useGetWorkItemQuery}
                isShowActiveToggleVisible
                activateMutation={useWorkItemBulkUnarchiveMutation}
                inactivateMutation={useWorkItemBulkArchiveMutation}
                inactivateConfirmationDialog={{
                    title: 'Delete Component Warning',
                    content: (
                        <>
                            <Typography>Deleting this component cannot be undone or recovered.</Typography>
                            <br />
                            <Typography>Are you sure you want to DELETE this component?</Typography>
                        </>
                    ),
                }}
                tableColumns={TABLE_COLUMNS}
                entityNameSingular='Component'
                permissionName='workItems'
                spacing={3}
                additionalQueryParameters={{
                    companyId: company.id,
                    showCompleted: isCompletedVisible,
                    activeStatus: activeStatusFilter,
                    itemType: itemTypeFilter,
                    periodStartDate: periodStartDateFilter?.toISOString(),
                    periodEndDate: periodEndDateFilter?.toISOString(),
                }}
                preActionItemSlot={customHeaderContent}
                handleAdd={handleAdd}
                handleEdit={handleEdit}
                handleRowClick={handleRowClick}
                handleImportClicked={() => setIsImportWorkItemModalVisible(true)}
                hideMenuDivider
                hideSearch
                hideFilterButton
                disableGutters
                hideBorder
            />
            <AddEditWorkItemModal
                isOpen={!!selectedWorkItem}
                workItemToUpdate={selectedWorkItem}
                close={() => setSelectedWorkItem(undefined)}
                companyId={company.id}
            />
            <ImportWorkItemsModal
                companyId={company.id}
                companyName={company.name}
                onClose={() => setIsImportWorkItemModalVisible(false)}
                isImportVisible={isImportWorkItemModalVisible}
            />
        </Paper>
    );
}