import { createContext } from 'react';
import { PaginatedProps } from '../../CoreLib/library';

interface IScheduleTableContext {
    searchText: string;
    setSearchText: (value: string) => void;
    paginatedProps: PaginatedProps;
    setPaginatedProps: (value: PaginatedProps) => void;
    showOnlyExpired?: boolean;
    setShowOnlyExpired?: (value: boolean) => void;
    companyFilter?: string;
    setCompanyFilter?: (companyId?: string) => void;
    surveyAlias: string;
    surveyAliasShort: string;
}

export const ScheduleTableContext = createContext<IScheduleTableContext>({
    searchText: '',
    surveyAlias: 'Survey',
    surveyAliasShort: 'Survey',
    setSearchText: () => {},
    paginatedProps: {
        sortKey: 'SCHEDULED',
        sortAsc: false,
        page: 0,
        pageSize: 25,
    },
    setPaginatedProps: () => {},
});
