import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { DataTableColumn, IStandardListViewExtraItems, StandardListViewV2, useActivateInactivateSnackbar } from '../../../../../../Components/CoreLib/library';
import { BaseDto, RespondentDto } from '../../../../../../dtos';
import { useGetCompanyRespondentsQuery, useRemoveRespondentsFromStudyMutation } from '../../../../../../store';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';
import { AddEditRespondentInvolvementModal } from '../../../AddEditRespondentInvolvementModal';
import { useNavigate } from 'react-router-dom';
import { AddCircle, Delete } from '@mui/icons-material';
import { ConfirmationModalWrapper } from '../../../../../../Components/CommonComponents/ConfirmationModalWrapper';
import { DataFilterContext } from '../../../../../../Contexts';

const DEFAULT_PAGINATION = { searchText: '', sortKey: 'FIRST_NAME', sortAsc: true, page: 0, pageSize: 25, includeInactive: false };
const TABLE_COLUMNS: DataTableColumn<RespondentDto>[] = [
    { key: 'name', label: 'Name', sortKey: 'FIRST_NAME', fieldMapper: (row) => `${row.firstName} ${row.lastName}` },
    { key: 'workItemCount', label: 'Components', sortKey: 'WORK_ITEM_COUNT' },
    { key: 'title', label: 'Title', sortKey: 'TITLE' }
];

export const StudyDashboardRespondentsTab: FC = () => {
    const { study, setSelectedEmployeeId, searchText } = useContext(StudyDashboardContext);
    const [isAddCompanyRespondentsModalOpen, setIsAddCompanyRespondentsModalOpen] = useState(false);
    const [removeRespondentFromStudy, { isLoading: isRemovingRespondentFromStudy, data: isRespondentRemovedResult, reset: resetRemoveRespondent }] = useRemoveRespondentsFromStudyMutation();
    useActivateInactivateSnackbar('Remove', 'Employee', 'Employees', isRespondentRemovedResult, resetRemoveRespondent);

    const [selectedRespondentId, setSelectedRespondentId] = useState<string>();
    const {
        firstNameFilter,
        lastNameFilter,
        titleFilter,
        departmentIdFilter,
        isNotAssignedToDepartmentFilter,
        managerIdFilter,
        formTypeFilter,
    } = useContext(DataFilterContext);
    const navigate = useNavigate();
    const [removeEmployeeIds, setRemoveEmployeeIds] = useState<string[]>();

    const navigateToRespondentEdit = useCallback((employeeId: string) => {
        navigate(`/companies/${study.companyId}/respondents/${employeeId}`)
    }, [navigate, study.companyId]);

    const handleAdd = () => {
        setIsAddCompanyRespondentsModalOpen(true);
    }

    const handleEdit = useCallback((employeeId: string) => {
        setSelectedRespondentId(employeeId);
        setIsAddCompanyRespondentsModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsAddCompanyRespondentsModalOpen(false)
        setSelectedRespondentId(undefined);
    }, []);

    const handleRemoveEmployeeFromStudyClicked = useCallback(async (selected: any) => {
        const recordIds = selected
            .filter((record: BaseDto) => record.isActive)
            .map((record: BaseDto) => record.id);

        setRemoveEmployeeIds(recordIds);
    }, []);

    const removeEmployeeFromStudy = useCallback(() => {
        if (isRemovingRespondentFromStudy || !removeEmployeeIds) {
            return;
        }
        removeRespondentFromStudy({
            studyId: study.id,
            respondentIds: removeEmployeeIds
        });
        setRemoveEmployeeIds(undefined);
    }, [isRemovingRespondentFromStudy, study.id, removeRespondentFromStudy, removeEmployeeIds]);

    const customTitleActions: IStandardListViewExtraItems[] = useMemo(() => [
        {
            enabledCheck: (selectedItems) => selectedItems.length > 0,
            onClick: (selectedItems) => handleRemoveEmployeeFromStudyClicked(selectedItems),
            text: 'Remove From Study',
            buttonIsIconButton: true,
            icon: <Delete fontSize='inherit' />
        }
    ], [handleRemoveEmployeeFromStudyClicked])

    const customMenuActions: IStandardListViewExtraItems[] = useMemo(() => [
        {
            enabledCheck: (selectedItems) => selectedItems.length === 1,
            onClick: (selectedItems) => navigateToRespondentEdit(selectedItems[0].id),
            text: 'Edit Respondent'
        },
        {
            enabledCheck: (selectedItems) => selectedItems.length === 1,
            onClick: (selectedItems) => handleEdit(selectedItems[0].id),
            text: 'Edit Involvement'
        },
        {
            enabledCheck: (selectedItems) => selectedItems.length > 0,
            onClick: (selectedItems) => handleRemoveEmployeeFromStudyClicked(selectedItems),
            text: 'Remove From Study'
        }
    ], [handleEdit, handleRemoveEmployeeFromStudyClicked, navigateToRespondentEdit])

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle=''
                    defaultPaginationProps={DEFAULT_PAGINATION}
                    getDataQuery={useGetCompanyRespondentsQuery}
                    isShowActiveToggleVisible={false}
                    tableColumns={TABLE_COLUMNS}
                    entityNameSingular='Respondents'
                    permissionName='respondents'
                    spacing={3}
                    addButton={(
                        <Tooltip title='Add New'>
                            <IconButton color="primary" size="large" onClick={handleAdd}>
                                <AddCircle fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    )}
                    additionalQueryParameters={{
                        searchText: searchText,
                        parentId: study.companyId,
                        assignedToStudyId: study.id,
                        firstName: firstNameFilter,
                        lastName: lastNameFilter,
                        title: titleFilter,
                        departmentId: departmentIdFilter,
                        isNotAssignedToDepartment: isNotAssignedToDepartmentFilter,
                        managerId: managerIdFilter,
                        formType: formTypeFilter,
                    }}
                    additionalActionItems={customTitleActions}
                    additionalMenuItems={customMenuActions}
                    handleRowClick={setSelectedEmployeeId}
                    hideSearch
                    disableGutters
                    hideBorder
                    editToolTip='Edit Involvement'
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    hideDefaultMenuItems
                    hideMenuDivider
                />
            </Grid>
            <AddEditRespondentInvolvementModal isOpen={isAddCompanyRespondentsModalOpen} close={handleCloseModal} companyId={study.companyId} studyId={study.id} selectedRespondentId={selectedRespondentId} />
            <ConfirmationModalWrapper
                isOpen={!!removeEmployeeIds}
                title='Remove Respondent From Study Warning'
                confirmText='Remove'
                cancel={() => setRemoveEmployeeIds(undefined)}
                confirm={removeEmployeeFromStudy}
            >
                <Typography>Removing these Respondents from <Typography component='span' color='primary'>{study.name}</Typography> will disassociate it from any components. While this respondent can be added back to the study later, you will have to recreate any associations with components.</Typography>
                <br />
                <Typography>Are you sure you want to REMOVE this respondent?</Typography>
            </ConfirmationModalWrapper>
        </Grid>
    );
};