import { FC, useContext } from 'react';
import { StudyDashboardContext } from '../../Utils/StudyDashboardContext';
import { Box } from '@mui/material';
import { StudyDashboardBCMTab } from './Tabs/StudyDashboardBCM';
import { StudyDashboardRespondentsTab } from './Tabs/StudyDashboardRespondents';
import { StudyDashboardOverviewTab } from './Tabs/StudyDashboardOverview';
import { StudyDashboardStreamTab } from './Tabs/StudyDashboardStream';
import { StudyDashboardSurveysTab } from './Tabs/StudyDashboardSurveys';
import { StudyDashboardComponentsTab } from './Tabs/StudyDashboardComponents';

const tabContentMap: Record<number, FC> = {
    0: StudyDashboardOverviewTab,
    1: StudyDashboardComponentsTab,
    2: StudyDashboardRespondentsTab,
    3: StudyDashboardSurveysTab,
    4: StudyDashboardBCMTab,
    5: StudyDashboardStreamTab,
};

export const StudyDashboardTabContentViewer: FC = () => {
    const { selectedTab } = useContext(StudyDashboardContext);
    const TabContent = tabContentMap[selectedTab];

    if (!TabContent) {
        return null;
    }

    return (
        <Box p={2}>
            <TabContent />
        </Box>
    );
};