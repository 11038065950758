import { Grid, Typography, FormControl, FormLabel, MenuItem, Select, SelectChangeEvent, Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { CompanyDto } from '../../../dtos';
import { useFailedActionSnackbar, LoadingIndicator } from '../../CoreLib/library';
import { useGetCompaniesQuery } from '../../../store';
import { ImportRespondentsModal } from '../ImportRespondents';

export const ImportRespondents: FunctionComponent = () => {
    const [companyId, setCompanyId] = useState<string>('');
    const [company, setCompany] = useState<CompanyDto | undefined>();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { data: companies, isLoading: isCompaniesLoading, isError: companiesError } = useGetCompaniesQuery({ page: 0, pageSize: 9999 });

    useEffect(() => {
        if (companyId) {
            setCompany(companies?.pageResults.find(x => x.id === companyId));
        } else {
            setCompany(undefined);
        }
    }, [companies?.pageResults, companyId]);

    useFailedActionSnackbar('retrieve', 'companies', companiesError);

    if (isCompaniesLoading) {
        return <LoadingIndicator />;
    }
    return (

        <Grid container direction="column">
            <Grid item
                pb={1}
            >
                <Typography variant='h3'>Import Respondents</Typography>
            </Grid>
            <Grid item container direction='row' alignItems='center' spacing={2} wrap='nowrap'>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <FormLabel sx={{ fontSize: '14px' }}>Company</FormLabel>
                        <Select
                            value={companyId ?? ''}
                            onChange={(e: SelectChangeEvent) => {
                                setCompanyId(e.target.value)
                            }}>
                            <MenuItem value='' key='company-none'>None</MenuItem>
                            {companies?.pageResults.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sx={{ mt: 3 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={company === undefined}
                        onClick={() => setIsModalVisible(true)}>
                        Import
                    </Button>
                </Grid>
            </Grid>
            {company && isModalVisible && < ImportRespondentsModal company={company} setIsImportVisible={setIsModalVisible} isImportVisible={isModalVisible} redirectOnSuccess={true} />}

        </Grid >
    );
};


