import { DialogTitle, Grid, Typography } from '@mui/material';
import { FC } from 'react';

export interface IStandardDialogHeaderProps {
    icon?: JSX.Element;
    text: string;
}

export const StandardDialogHeader: FC<IStandardDialogHeaderProps> = props => {
    const { icon, text } = props;

    return (
        <DialogTitle sx={{ backgroundColor: '#DF623B', color: '#ffffff', fontWeight: 'bold' }}>
            <Grid container direction='row' alignItems='center' gap={1}>
                { icon }
                <Typography fontSize='inherit' fontWeight='inherit'>
                    { text }
                </Typography>
            </Grid>
        </DialogTitle>
    )
}