import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import _ from "lodash";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FormInput, IFormFieldValidationConfig, isNotBlank, isValidEmail, runFieldValidation, runFormValidation, useFailedActionSnackbar } from "../../Components/CoreLib/library";
import { CustomerServiceSubmissionView } from "./CustomerServiceSubmissionView";
import { ScheduleDetailServiceFormSubmissionDto } from '../../dtos/generated/ScheduleDetailServiceFormSubmissionDto';
import { useSubmitScheduleDetailFeedbackMutation } from '../../store/api/feedback-api';

const TEXT_FIELDS = ['name', 'email', 'otherDescription'];
const CHECKBOX_FIELDS = ['isUnintendedRecipient', 'isAlreadyReceived', 'isAlreadyFilledOut', 'isOther'];

const FORM_VALIDATION_RULES = new Map<keyof ScheduleDetailServiceFormSubmissionDto, IFormFieldValidationConfig>([
    [
        'name',
        {
            validators: [isNotBlank],
            errorMessageEntityName: 'Name',
        },
    ],
    [
        'email',
        {
            validators: [isNotBlank, isValidEmail],
            errorMessageEntityName: 'Email',
        },
    ]
]);

export const CustomerServiceFormView: FC = () => {
    const [searchParams] = useSearchParams();
    const [submitFeedback, { isLoading: isSubmittingFeedback, isError: isErrorSubmittingFeedback, reset: resetSubmitFeedback }] = useSubmitScheduleDetailFeedbackMutation();
    useFailedActionSnackbar('submitting', 'feedback', isErrorSubmittingFeedback, resetSubmitFeedback);
    const urlSurveyInstanceId = searchParams.get('surveyInstanceId');
    const urlRepUserId = searchParams.get('repUserId');
    const [formValues, setFormValues] = useState<ScheduleDetailServiceFormSubmissionDto>({
        scheduleDetailId: urlSurveyInstanceId ?? '',
        representativeId: urlRepUserId ?? '',
        name: searchParams.get('name') ?? '',
        email: searchParams.get('email') ?? '',
        isUnintendedRecipient: false,
        isAlreadyReceived: false,
        isAlreadyFilledOut: false,
        isOther: false,
        otherDescription: '',
        isOptedOutOfReminders: false,
    })
    const [fieldErrors, setFieldErrors] = useState<Map<keyof ScheduleDetailServiceFormSubmissionDto, string>>(new Map());
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateForm = useCallback(() => {
        const validationResult = runFormValidation<ScheduleDetailServiceFormSubmissionDto>(formValues, FORM_VALIDATION_RULES);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formValues]);

    const validateField = useCallback((fieldName: keyof ScheduleDetailServiceFormSubmissionDto) => {
        const validationConfig = FORM_VALIDATION_RULES.get(fieldName);
        if (validationConfig) {
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    }, [formValues, fieldErrors]);

    const handleTextFieldChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        let targetName = event.target.name as keyof ScheduleDetailServiceFormSubmissionDto;
        if (!TEXT_FIELDS.includes(targetName)) {
            console.error(`attempted to change a text value with invalid name ${targetName}`);
            return;
        }
        setFormValues(formValues => ({..._.set(formValues, targetName, event.target.value)}));
    }, [])

    const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let targetName = event.target.name as keyof ScheduleDetailServiceFormSubmissionDto;
        if (!CHECKBOX_FIELDS.includes(targetName)) {
            console.error(`attempted to check checkbox value with invalid name ${targetName}`);
            return;
        }
        setFormValues(formValues => ({..._.set(formValues, targetName, checked)}));
    }, [])

    const handleOptOutOfEmailChange = useCallback((event: SelectChangeEvent<'yes' | 'no'>) => {
        setFormValues(fvs => ({...fvs, isOptedOutOfReminders: event.target.value === 'yes'}));
    }, []);

    const handleSubmit = useCallback(async () => {
        const isValid = validateForm();
        if (isValid) {
            await submitFeedback(formValues);
            setFormValues(fvs => ({...fvs, submittedOn: new Date()}));
            setIsSubmitted(true);
        }
    }, [validateForm, submitFeedback, formValues]);

    useEffect(() => {
        if (!formValues.isOther) {
            setFormValues(fvs => ({...fvs, otherDescription: ''}));
        }
    }, [formValues.isOther]);

    if (isSubmitted) {
        return <CustomerServiceSubmissionView submissionDetails={formValues} />;
    }

    if (!urlSurveyInstanceId || !urlRepUserId) {
        return <Typography>Invalid Url</Typography>
    }

    return (
        <Paper sx={{maxWidth:'700px', marginLeft:'auto', marginRight:'auto'}}>
            <Grid item container direction='column' padding={4} rowSpacing={1}>
                <Grid item textAlign={'center'}>
                    <Typography variant='h1'>Contact Customer Support</Typography>
                </Grid>
                <Grid item>
                    <FormInput
                        required
                        label='Name'
                        value={formValues.name}
                        onChange={handleTextFieldChange}
                        onBlur={() => validateField('name')}
                        name='name'
                        error={!!fieldErrors.get('name')}
                        errorText={fieldErrors.get('name')}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <FormInput
                        required
                        label='Email'
                        value={formValues.email}
                        onChange={handleTextFieldChange}
                        onBlur={() => validateField('email')}
                        name='email'
                        error={!!fieldErrors.get('email')}
                        errorText={fieldErrors.get('email')}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Typography>Please choose the options that best describe the issue.</Typography>
                </Grid>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox sx={{ pl: 0, py: 0 }} checked={formValues.isUnintendedRecipient} onChange={handleCheckboxChange} name="isUnintendedRecipient"/>} label='I am not the intended recipient or should not receive this.' sx={{padding:'8px'}}/>
                        <FormControlLabel control={<Checkbox sx={{ pl: 0, py: 0 }} checked={formValues.isAlreadyReceived} onChange={handleCheckboxChange} name="isAlreadyReceived"/>} label='I already received this.' sx={{padding:'8px'}}/>
                        <FormControlLabel control={<Checkbox sx={{ pl: 0, py: 0 }} checked={formValues.isAlreadyFilledOut} onChange={handleCheckboxChange} name="isAlreadyFilledOut"/>} label='I already filled this out.' sx={{padding:'8px'}}/>
                        <FormControlLabel control={<Checkbox sx={{ pl: 0, py: 0 }} checked={formValues.isOther} onChange={handleCheckboxChange} name="isOther"/>} label='Other' sx={{padding:'8px'}}/>
                    </FormGroup>
                    <FormInput
                        label=''
                        value={formValues.otherDescription}
                        onChange={handleTextFieldChange}
                        name='otherDescription'
                        error={!!fieldErrors.get('otherDescription')}
                        errorText={fieldErrors.get('otherDescription')}
                        disabled={!formValues.isOther}
                        fullWidth
                        sx={{ ml: 4 }}
                    />
                </Grid>
                <Grid item>
                    <Typography>Would you like to opt out of future reminders?</Typography>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <Select value={formValues.isOptedOutOfReminders ? 'yes' : 'no'} onChange={handleOptOutOfEmailChange}>
                            <MenuItem value={'yes'}>Yes</MenuItem>
                            <MenuItem value={'no'}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {formValues.isOptedOutOfReminders && 
                <Grid item> 
                    <Typography color='primary'>Note: Your employer may require Hull & Knarr to continue sending reminders.</Typography>
                </Grid>}
                <Grid item display='flex' justifyContent='center' mt={2}>
                    <Button onClick={handleSubmit} variant='contained' disabled={isSubmittingFeedback}>Submit</Button>
                </Grid>
            </Grid>
        </Paper>
    );
}