import { Box, IconButton, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { StudyDashboardContext } from '../Utils/StudyDashboardContext';
import { Edit } from '@mui/icons-material';
import { AddEditStudyModal } from './AddEditStudyModal';
import { formateDateStandard } from '../../../util';

export const StudyDetails: FC = () => {
    const { study } = useContext(StudyDashboardContext);
    const [isNewStudyModalOpen, setIsNewStudyModalOpen] = useState(false);
    const handleEditClicked = () => {
        setIsNewStudyModalOpen(true);
    };

    return (
        <>
            <Box display='flex' flexDirection='column' rowGap={0.5}>
                <Box display='flex' alignItems='center' pb={1}>
                    <Typography fontSize={18} fontWeight='bold'>
                        Study Details
                    </Typography>
                    <IconButton size='small' sx={{ ml: 1 }} onClick={handleEditClicked}>
                        <Edit />
                    </IconButton>
                </Box>
                <Typography fontWeight='bold'>{study.name}</Typography>
                <Typography>Company: {study.companyName}</Typography>
                <Typography>Start Date: {formateDateStandard(study.startDate)}</Typography>
                <Typography>End Date: {formateDateStandard(study.endDate, 'Ongoing')}</Typography>
            </Box>
            <AddEditStudyModal isOpen={isNewStudyModalOpen} close={() => setIsNewStudyModalOpen(false)} studyToUpdate={study} />
        </>
    );
};
