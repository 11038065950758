import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, SyntheticEvent, useMemo } from 'react';
import { StudyDto } from '../../dtos';
import { useGetStudiesQuery } from '../../store';
import { LoadingIndicator } from '../CoreLib/library';

export interface IStudySelectProps {
    companyId: string;
    selectedStudyId: string;
    handleSelectedStudyChange: (selectedStudy?: StudyDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
}

export const StudySelect: FC<IStudySelectProps> = ({ companyId, selectedStudyId , handleSelectedStudyChange, required, errorMessage, disabled }) => {
    const { data: studies, isLoading: isLoadingStudies } = useGetStudiesQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
        companyId: companyId
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: StudyDto | null) => {
        handleSelectedStudyChange(value ?? undefined);
    }

    const selectedStudy = useMemo(() => {
        if (selectedStudyId === '') {
            return null;
        }
        return studies?.pageResults.find(x => x.id === selectedStudyId);
    }, [selectedStudyId, studies])

    if (isLoadingStudies) {
        return <LoadingIndicator />
    }

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Study</FormLabel>
            <Autocomplete
                value={selectedStudy}
                onChange={handleSelectChange}
                options={studies?.pageResults ?? []}
                getOptionLabel={x => x?.name ?? ''}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}