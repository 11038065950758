import { FC, useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { emptyGuid } from '../../models';
import { ApiError, LoadingIndicator, SlimPageHeader, StandardSearchInput } from '../../Components/CoreLib/library';
import { Box, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { FilterList, MenuBook } from '@mui/icons-material';
import { useGetStudyByIdQuery } from '../../store';
import { StudyDashboardContext } from './Utils/StudyDashboardContext';
import { StudyDashboardSidebarTabContentViewer, StudyDashboardTabsContainer, StudyDetails, WorkItemFilterModal } from './Components';
import { ScheduleTableFilterChips } from '../../Components/Forms/Schedule/ScheduleTableFilterChips';
import { FilterScheduleDialog } from '../../Components/Forms/Schedule';
import { DataFilterContext, useInitializeDataFilterContext } from '../../Contexts';
import { RespondentFilterDialog } from '../../Components/FilterDialogs';
import { ActivityStreamFilterDialog } from '../../Components/FilterDialogs/ActivityStreams';

// Cannot search the Overview or Stream tabs
const UNSEARCHABLE_TABS = [0, 5];

export const StudyDashboard: FC = () => {
    const { id: studyId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlTab = searchParams.get('tab');
    const { data: study, isLoading: isLoadingStudy, isError: isGetStudyError, refetch: resetGetStudy } = useGetStudyByIdQuery({ id: studyId ?? emptyGuid });
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [selectedComponentId, setSelectedComponentId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedTab, setSelectedTab] = useState<number>(urlTab ? Number(urlTab) : 0);
    const isSearchDisabled = useMemo(() => UNSEARCHABLE_TABS.includes(selectedTab), [selectedTab]);
    const surveyFilterContext = useInitializeDataFilterContext(true);
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
    const scheduleFilterDialogTitle = useMemo(() => (selectedTab === 2 ? 'BCMs' : 'Surveys'), [selectedTab]);

    const handleTabChange = useCallback(
        (newTab: number) => {
            setSelectedTab(newTab);
            surveyFilterContext.clearFilters();
            setSearchParams({ tab: newTab.toString() });
        },
        [surveyFilterContext, setSearchParams]
    );

    if (isLoadingStudy) {
        return <LoadingIndicator />;
    }

    if (isGetStudyError || !study) {
        return <ApiError onReloadClick={resetGetStudy} />;
    }

    return (
        <StudyDashboardContext.Provider
            value={{
                study,
                selectedEmployeeId,
                setSelectedEmployeeId,
                selectedComponentId,
                setSelectedComponentId,
                searchText,
                setSearchText,
                selectedTab,
                setSelectedTab: handleTabChange,
            }}>
            <DataFilterContext.Provider value={surveyFilterContext}>
                <Grid container direction='row' spacing={3}>
                    <Grid item xs={12}>
                        <SlimPageHeader
                            icon={<MenuBook />}
                            title={study.name}
                            breadCrumbProps={{
                                currentPageLabel: study.name,
                                links: [
                                    { label: 'Home', navLink: '/' },
                                    { label: 'Studies', navLink: '/studies' },
                                ],
                            }}
                            color='transparent'
                            disableGutters
                            endSlot={
                                <Box display='flex' gap={2}>
                                    <Tooltip title={'Filter'}>
                                        <IconButton color='primary' size='large' onClick={() => setIsFilterDialogOpen(true)}>
                                            <FilterList fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                    <StandardSearchInput
                                        searchText={searchText}
                                        handleSearchChange={setSearchText}
                                        textFieldProps={{ disabled: isSearchDisabled, sx: { width: 400 } }}
                                    />
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ScheduleTableFilterChips />
                    </Grid>
                    <Grid item xs={4}>
                        <Paper
                            elevation={0}
                            sx={{ border: 'solid 1px #AAAAAA', height: '100%', padding: 2, display: 'flex', flexDirection: 'column', rowGap: 3 }}>
                            <StudyDetails />
                            <StudyDashboardSidebarTabContentViewer />
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper elevation={0} sx={{ border: 'solid 1px #AAAAAA', height: '100%' }}>
                            <StudyDashboardTabsContainer />
                        </Paper>
                    </Grid>
                </Grid>
                <FilterScheduleDialog
                    open={isFilterDialogOpen && [0, 3, 4].includes(selectedTab)}
                    onClose={() => setIsFilterDialogOpen(false)}
                    title={scheduleFilterDialogTitle}
                    companyId={study.companyId}
                    hideRemindersFilter={selectedTab === 0 || selectedTab === 4}
                    hideApprovedByFilter={selectedTab === 0 || selectedTab === 4}
                    hideFormTypeFilter={selectedTab === 4}
                    hideStudyFilter
                />
                <WorkItemFilterModal
                    open={isFilterDialogOpen && selectedTab === 1}
                    onClose={() => setIsFilterDialogOpen(false)}
                />
                <RespondentFilterDialog
                    open={isFilterDialogOpen && selectedTab === 2}
                    onClose={() => setIsFilterDialogOpen(false)}
                    companyId={study.companyId}
                />
                <ActivityStreamFilterDialog
                    open={isFilterDialogOpen && selectedTab === 5}
                    onClose={() => setIsFilterDialogOpen(false)}
                    hideCompanyFilter
                />
            </DataFilterContext.Provider>
        </StudyDashboardContext.Provider>
    );
};
