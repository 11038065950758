import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingIndicator, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { SurveyForm } from '../../Components/Forms';
import { SurveyTemplate } from '../../models/survey';
import { useGetScheduleDetailQuery, useGetScheduleRespondentAnswersQuery } from '../../store/api/schedule-api';

export const SurveyView: FC = () => {
    const { scheduleId, id } = useParams();
    // TODO: this query cant take a long time because it includes the schedule detail and all the related entities including the original survey with the survey payload.
    const {
        data: scheduleDetail,
        isLoading: scheduleDetailIsLoading,
        isError: isScheduleDetailError,
    } = useGetScheduleDetailQuery({
        parentId: scheduleId!,
        childId: id!,
    });
    const {
        data: scheduleDetailAnswers,
        isError: isGetAnswersError,
        isLoading: isGetAnswersLoading,
    } = useGetScheduleRespondentAnswersQuery({
        parentId: scheduleId!,
        childId: id!,
    });
    const [surveyTemplateData, setSurveyTemplateData] = useState<SurveyTemplate | undefined>(undefined);

    useFailedActionSnackbar('retrieve', 'survey instance', isScheduleDetailError);
    useFailedActionSnackbar('retrieve', 'respondent answers', isGetAnswersError);

    useEffect(() => {
        if (!scheduleDetailIsLoading && !surveyTemplateData && scheduleDetail && scheduleDetail.respondentSurveyTemplate) {
            setSurveyTemplateData(JSON.parse(scheduleDetail.respondentSurveyTemplate));
        }
    }, [surveyTemplateData, setSurveyTemplateData, scheduleDetail, scheduleDetailIsLoading]);

    if (scheduleDetailIsLoading || isGetAnswersLoading || !surveyTemplateData) {
        return <LoadingIndicator />;
    } else if (isScheduleDetailError) {
        return <Typography color='primary'>Failed to load Survey Instance details.</Typography>;
    } else if (scheduleDetail) {
        return (
            <Grid container>
                <Grid item xs={12} className='standard-survey-form-container'>
                    <SurveyForm scheduleDetail={scheduleDetail} surveyTemplateData={surveyTemplateData} answers={scheduleDetailAnswers} />
                </Grid>
            </Grid>
        );
    } else {
        return <p>No schedule detail found</p>;
    }
};
