import { Breakpoint, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { StandardDialogHeader } from './CommonComponents';

export interface IStandardDialogProps {
    isVisible: boolean;
    onClose: () => void;
    icon: JSX.Element;
    title: string;
    confirmText?: string;
    confirmAction: () => void;
    cancelText?: string;
    cancelAction?: () => void;
    maxWidth?: Breakpoint;
}

export const StandardDialog: FC<PropsWithChildren<IStandardDialogProps>> = ({
    isVisible,
    onClose,
    maxWidth = 'md',
    icon,
    title,
    children: dialogContent,
    confirmText = 'Confirm',
    confirmAction,
    cancelText = 'Cancel',
    cancelAction = onClose
}) => {

    return (
        <Dialog open={isVisible} onClose={onClose} PaperProps={{ sx: { width: '100%' } }} maxWidth={maxWidth}>
            <StandardDialogHeader icon={icon} text={title} />
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Button onClick={cancelAction} variant='outlined'>
                    {cancelText}
                </Button>
                <Button onClick={confirmAction} variant='contained' color='primary'>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}