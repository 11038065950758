import { Ballot, Menu as MenuIcon } from '@mui/icons-material';
import { Button, Fab, FormControl, FormLabel, Grid, Menu, MenuItem, OutlinedInput, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import '../../../css/PrintSurveyForm.css';
import '../../../css/StandardSurveyForm.css';
import { ScheduleDetailDto, ScheduleRespondentAnswerDto, ScheduleStatus } from '../../../dtos';
import { emptyGuid } from '../../../models';
import { useSaveScheduleRespondentAnswersMutation } from '../../../store/api/schedule-api';
import { LoadingIndicator } from '../../CoreLib/library';
import { SurveyFormErrorMessage, SurveyHeaderDetails } from '../types';
import { BCMSurveyContentRenderer, DesktopViewSaveSubmitButtons, ResponsiveSurveyHeader, TAQByBCSurveyContentRenderer } from './Components';
import { onlyShowOnDesktop, onlyShowOnMobile, textAlignCenterOnMobile } from './util';
import { StandardFormTypeRenderer } from './Components/FormTypeRenderers/StandardFormTypeRenderer';
import { SurveyTemplate } from '../../../models/survey';

export interface ISurveyForm {
    scheduleDetail?: ScheduleDetailDto;
    surveyTemplateData: SurveyTemplate;
    answers?: ScheduleRespondentAnswerDto[];
    isPreview?: boolean;
    isPdfView?: boolean;
    fieldGroupIndex?: number;
    timePeriodIndex?: number;
}

// TODO: split this up into smaller components that are more readable, maintainable, and overall cleaner.
export const SurveyForm: FC<ISurveyForm> = (props) => {
    const { scheduleDetail, isPreview = false, surveyTemplateData, answers, isPdfView = false, fieldGroupIndex, timePeriodIndex } = props;
    const [completedBy, setCompletedBy] = useState(scheduleDetail?.respondent.firstName + ' ' + scheduleDetail?.respondent.lastName);
    const [errorMessages, setErrorMessages] = useState<SurveyFormErrorMessage[]>([]);
    const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
    const [isAnswersLoaded, setIsAnswersLoaded] = useState(false);
    const [saveSurveyAnswers, { isLoading: isSavingAnswers }] = useSaveScheduleRespondentAnswersMutation();
    const [respondentAnswers, setRespondentAnswers] = useState<ScheduleRespondentAnswerDto[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const theme = useTheme();
    const isNew = useMemo(() => scheduleDetail?.status === ScheduleStatus.Created, [scheduleDetail]);
    const isComplete = useMemo(() => scheduleDetail?.status === ScheduleStatus.Completed, [scheduleDetail]);
    const isDeleted = useMemo(() => scheduleDetail?.isActive === false, [scheduleDetail]);
    const isReadOnly = useMemo(() => isSavingAnswers || isNew || isComplete || isDeleted, [isSavingAnswers, isNew, isComplete, isDeleted]);

    const handleSaveAnswers = () => {
        if (isPreview) {
            return;
        }

        saveSurveyAnswers({
            parentId: scheduleDetail?.schedule.id!,
            childId: scheduleDetail?.id!,
            answers: respondentAnswers,
            isSubmit: false,
        });
        if (!isSavingAnswers) {
            enqueueSnackbar('Progress has been saved.');
            setIsUnsavedChanges(false);
        }
    };

    const handleSubmitAnswers = () => {
        if (isPreview) {
            return;
        }

        if (errorMessages.length === 0) {
            saveSurveyAnswers({
                parentId: scheduleDetail?.schedule.id!,
                childId: scheduleDetail?.id!,
                answers: respondentAnswers,
                isSubmit: true,
            });
            if (!isSavingAnswers) {
                enqueueSnackbar('Survey has been submitted.');
                setIsUnsavedChanges(false);
            }
        } else {
            enqueueSnackbar('Invalid value(s) entered. Please change value(s) and try again.');
        }
    };
    //prevents user from leaving before saving. isUnsavedChanges will need to be set to false on submit as well.
    window.onbeforeunload = () => (isUnsavedChanges ? '' : null);

    const headerData: SurveyHeaderDetails = useMemo(() => {
        return {
            company: scheduleDetail?.schedule.companyName ?? '',
            employee: scheduleDetail ? `${scheduleDetail.respondent.firstName} ${scheduleDetail.respondent.lastName}` : '',
            department: scheduleDetail?.schedule.formType ?? '',
            title: scheduleDetail?.respondent.title ?? '',
            taxPeriod: scheduleDetail && scheduleDetail.schedule.periods.length > 1 ? 'Multiple' : scheduleDetail?.schedule.periods[0].surveyLabel ?? '',
        };
    }, [scheduleDetail]);

    useEffect(() => {
        if (isPreview) {
            setIsAnswersLoaded(true);
            setRespondentAnswers(answers ?? []);
        }
        if (!isPreview && surveyTemplateData && answers) {
            setRespondentAnswers(answers);
            setIsAnswersLoaded(true);
        }
    }, [surveyTemplateData, setRespondentAnswers, isPreview, setIsAnswersLoaded, answers]);

    const setQuestionAnswer = useCallback((fieldName: string, value: string | number) => {
        setIsUnsavedChanges(true);
        setRespondentAnswers((currentAnswers) => {
            let updatedRespondentAnswers = [...currentAnswers];
            const respondentAnswer = currentAnswers.find((ra) => ra.fieldKey === fieldName);
            if (!respondentAnswer) {
                let newQuestionAnswer: ScheduleRespondentAnswerDto = {
                    id: emptyGuid,
                    scheduleDetailId: scheduleDetail?.id!,
                    fieldKey: fieldName,
                    answerValue: value,
                    isActive: true,
                    createdOn: new Date(),
                };
                updatedRespondentAnswers.push(newQuestionAnswer);
            } else {
                updatedRespondentAnswers = currentAnswers.map((ra) => {
                    if (ra.fieldKey === fieldName) {
                        return { ...ra, answerValue: value };
                    } else {
                        return ra;
                    }
                });
            }
            return updatedRespondentAnswers;
        });
    }, [scheduleDetail?.id]);

    const getLastSavedOn = () => {
        let timeString = '';
        const hasSurveyBeenStartedYet = !!scheduleDetail && scheduleDetail.status > 1;
        if (hasSurveyBeenStartedYet) {
            const localDate = new Date(scheduleDetail!.schedule.updatedOn!).toLocaleTimeString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            });
            const splitLocalDate = localDate.split(',');
            timeString += splitLocalDate.join(' at ');
        } else {
            timeString += 'Has not been saved yet';
        }
        return timeString;
    };

    const handleCompletedByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompletedBy(event.target.value);
    };

    const handleCompletedByError = () => {
        if (completedBy === '') {
            return 'Completed by is required.';
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const surveyContent = useMemo(() => {
        // TODO: the number of props being passed here could be reduced if we used a context or a hook similar to a form manager which we have in a few places.
        switch (scheduleDetail?.schedule.formType) {
            case 'BCM':
                return (
                    <BCMSurveyContentRenderer
                        isPreview={isPreview}
                        scheduleDetail={scheduleDetail}
                        respondentAnswers={respondentAnswers}
                        setRespondentAnswers={setRespondentAnswers}
                        setQuestionAnswer={setQuestionAnswer}
                        isReadOnly={isReadOnly}
                        isSavingAnswers={isSavingAnswers}
                    />
                );
            case 'TAQ by Business Component':
                return (
                    <TAQByBCSurveyContentRenderer
                        isPreview={isPreview}
                        respondentAnswers={respondentAnswers}
                        setQuestionAnswer={setQuestionAnswer}
                        isReadOnly={isReadOnly}
                        isSavingAnswers={isSavingAnswers}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                    />
                );
            default:
                return (
                    <StandardFormTypeRenderer
                        isPreview={isPreview}
                        respondentAnswers={respondentAnswers}
                        setQuestionAnswer={setQuestionAnswer}
                        scheduleDetail={scheduleDetail}
                        completedBy={completedBy}
                        surveyTemplateData={surveyTemplateData}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                        isReadOnly={isReadOnly}
                        isSavingAnswers={isSavingAnswers}
                        headerData={headerData}
                        isPdfView={isPdfView}
                        fieldGroupIndex={fieldGroupIndex}
                        timePeriodIndex={timePeriodIndex}
                    />
                );
        }
    }, [
        completedBy,
        errorMessages,
        fieldGroupIndex,
        headerData,
        isPdfView,
        isPreview,
        isReadOnly,
        isSavingAnswers,
        respondentAnswers,
        scheduleDetail,
        setQuestionAnswer,
        surveyTemplateData,
        timePeriodIndex,
    ]);

    return (
        <>
            {surveyTemplateData && isAnswersLoaded ? (
                <Grid container direction='column' rowSpacing={0} columnSpacing={4} sx={{ [theme.breakpoints.up('sm')]: { marginTop: 1 } }}>
                    {isPdfView ? (
                        <Grid item container direction='row' alignItems='center' justifyContent='space-between'>
                            <Grid item sx={{ display: 'flex', fontSize: '14px', pb: 1 }} alignItems='center'>
                                <span style={{ fontWeight: 'bold' }}>{surveyTemplateData.title}:</span> {surveyTemplateData.subtitle}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            container
                            direction='row'
                            justifyContent='space-between'
                            alignItems='top'
                            position='sticky'
                            top={72}
                            zIndex={3}
                            sx={isPreview ? undefined : { background: '#F3F5F6', pt: 1, [theme.breakpoints.down('sm')]: { top: 42 } }}>
                            {!isPreview && (
                                <Grid item container direction='row' xs={12} sm={9}>
                                    <Grid item xs={12} sx={textAlignCenterOnMobile}>
                                        <Typography variant='h1' sx={{ marginBottom: '8px', [theme.breakpoints.down('sm')]: { fontSize: '20px' } }}>
                                            <Ballot sx={onlyShowOnDesktop} /> {surveyTemplateData.title}
                                        </Typography>
                                        <Typography variant='subtitle1' sx={{ [theme.breakpoints.down('sm')]: { fontSize: '16px' } }}>
                                            {surveyTemplateData.subtitle}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {!isPreview && (
                                <Grid item direction='column' container xs={12} sm={3}>
                                    {!isComplete && (
                                        <DesktopViewSaveSubmitButtons
                                            handleSaveAnswers={handleSaveAnswers}
                                            isSaveDisabled={isReadOnly || isSavingAnswers}
                                            handleSubmitAnswers={handleSubmitAnswers}
                                            isSubmitDisabled={isReadOnly || isSavingAnswers || errorMessages.length > 0}
                                        />
                                    )}
                                    {isComplete && (
                                        <Grid item sx={{ textAlign: 'end', ...textAlignCenterOnMobile }}>
                                            <Typography variant='h1' color='primary'>
                                                COMPLETE
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item sx={{ textAlign: 'end', ...textAlignCenterOnMobile }}>
                                        {(!isNew || isDeleted) && (
                                            <Typography variant='subtitle1' fontStyle='italic' fontSize='16px' marginTop={1}>
                                                Last Saved: {getLastSavedOn()}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <Grid item container direction='row' justifyContent='space-between' sx={textAlignCenterOnMobile}>
                        <ResponsiveSurveyHeader label='Company' value={headerData.company} />
                        <ResponsiveSurveyHeader label='Employee' value={headerData.employee} />
                        <ResponsiveSurveyHeader label='Department' value={headerData.department} />
                        <ResponsiveSurveyHeader label='Title' value={headerData.title} />
                        <ResponsiveSurveyHeader label='Tax Period' value={headerData.taxPeriod} />
                    </Grid>
                    {!(isNew || isDeleted) && (
                        <>
                            <Grid item container direction='column'>
                                {surveyContent}
                            </Grid>
                            <Grid item container direction='row' xs={12}>
                                <Grid item xs={12} sm='auto' sx={{ [theme.breakpoints.down('sm')]: { mx: 2 } }}>
                                    <FormControl fullWidth>
                                        <FormLabel>Completed by</FormLabel>
                                        <OutlinedInput
                                            inputProps={{ className: 'standard-outlined-input' }}
                                            value={isPreview ? '' : completedBy}
                                            error={completedBy === ''}
                                            onChange={handleCompletedByChange}
                                            disabled={isReadOnly}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item container direction='row' alignItems='flex-start'>
                                <Grid item>
                                    <Typography variant='body1' color='primary' sx={{ marginTop: '-24px' }}>
                                        {handleCompletedByError()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid item container direction='column' alignItems='center' justifyContent='center'>
                        {isNew && !isDeleted && <Typography variant='subtitle1'>This survey has not been issued yet.</Typography>}
                        {isDeleted && <Typography variant='subtitle1'>This survey is no longer active.</Typography>}
                    </Grid>
                    <Grid item sx={{ display: 'flex', justifyContent: 'center', ...onlyShowOnMobile }} mt={3}>
                        <Button
                            onClick={handleSaveAnswers}
                            disabled={isReadOnly || isSavingAnswers}
                            variant='contained'
                            color='primary'
                            fullWidth
                            sx={{ maxWidth: '200px' }}>
                            Save Progress
                        </Button>
                    </Grid>
                    <Grid item sx={{ display: 'flex', justifyContent: 'center', ...onlyShowOnMobile }} mt={3}>
                        <Button
                            onClick={handleSubmitAnswers}
                            disabled={isReadOnly || isSavingAnswers || errorMessages.length > 0}
                            variant='contained'
                            color='primary'
                            fullWidth
                            sx={{ maxWidth: '200px' }}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <LoadingIndicator />
            )}
            <Fab color='primary' sx={{ position: 'fixed', right: 8, bottom: 8, ...onlyShowOnMobile }} onClick={handleMenuOpen}>
                <MenuIcon />
            </Fab>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <MenuItem onClick={handleSaveAnswers} disabled={isReadOnly || isSavingAnswers}>
                    Save Progress
                </MenuItem>
                <MenuItem onClick={handleSubmitAnswers} disabled={isReadOnly || isSavingAnswers || errorMessages.length > 0}>
                    Submit
                </MenuItem>
            </Menu>
        </>
    );
};
