import { useEffect, useMemo, useState } from "react";
import { CompanyDto, DepartmentDto, RespondentDto, StudyDto, SurveyDto, UserDto } from "../dtos";
import { useLazyGetCompanyDepartmentByIdQuery, useLazyGetCompanyQuery, useLazyGetRespondentQuery, useLazyGetStudyByIdQuery, useLazyGetSurveyQuery } from "../store";
import { emptyGuid } from "../models";
import { useLazyGetUserQuery } from "../store/api/user-api";

export function useGetAndCacheStudy(requestedStudyId?: string) {
    const [cachedStudy, setCachedStudy] = useState<StudyDto>();
    const [getStudyById, { data: requestedStudy }] = useLazyGetStudyByIdQuery();

    useEffect(() => {
        setCachedStudy(requestedStudy);
    }, [requestedStudy]);

    useEffect(() => {
        if (requestedStudyId) {
            getStudyById({ id: requestedStudyId });
        }
    }, [requestedStudyId, getStudyById]);

    const studyName = useMemo(() => {
        if (cachedStudy && cachedStudy.id === requestedStudyId) {
            return cachedStudy.name;
        } else {
            return 'Loading...';
        }
    }, [cachedStudy, requestedStudyId]);

    return {
        studyName
    }
}

export function useGetAndCacheCompany(requestedCompanyId?: string) {
    const [cachedCompany, setCachedCompany] = useState<CompanyDto>();
    const [getCompanyById, { data: requestedCompany }] = useLazyGetCompanyQuery();

    useEffect(() => {
        setCachedCompany(requestedCompany);
    }, [requestedCompany]);

    useEffect(() => {
        if (requestedCompanyId) {
            getCompanyById(requestedCompanyId);
        }
    }, [requestedCompanyId, getCompanyById]);

    const companyName = useMemo(() => {
        if (cachedCompany && cachedCompany.id === requestedCompanyId) {
            return cachedCompany.name;
        } else {
            return 'Loading...';
        }
    }, [cachedCompany, requestedCompanyId]);

    return {
        companyName
    }
}

export function useGetAndCacheDepartment(requestedDepartmentId?: string) {
    const [cachedDepartment, setCachedDepartment] = useState<DepartmentDto>();
    const [getDepartmentById, { data: requestedDepartment }] = useLazyGetCompanyDepartmentByIdQuery();

    useEffect(() => {
        setCachedDepartment(requestedDepartment);
    }, [requestedDepartment]);

    useEffect(() => {
        if (requestedDepartmentId) {
            getDepartmentById({ 
                companyId: emptyGuid, // This id is not actually checked in the request right now so I am leaving it blank rather than trying to pass it into the hook
                departmentId: requestedDepartmentId
            });
        }
    }, [requestedDepartmentId, getDepartmentById]);

    const departmentName = useMemo(() => {
        if (cachedDepartment && cachedDepartment.id === requestedDepartmentId) {
            return cachedDepartment.name;
        } else {
            return 'Loading...';
        }
    }, [cachedDepartment, requestedDepartmentId]);

    return {
        departmentName
    }
}

export function useGetAndCacheRespondent(requestedRespondentId?: string) {
    const [cachedRespondent, setCachedRespondent] = useState<RespondentDto>();
    const [getRespondentById, { data: requestedRespondent }] = useLazyGetRespondentQuery();

    useEffect(() => {
        setCachedRespondent(requestedRespondent);
    }, [requestedRespondent]);

    useEffect(() => {
        if (requestedRespondentId) {
            getRespondentById({
                parentId: emptyGuid, // This id is not actually checked in the request right now so I am leaving it blank rather than trying to pass it into the hook
                childId: requestedRespondentId
            });
        }
    }, [requestedRespondentId, getRespondentById]);

    const respondentName = useMemo(() => {
        if (cachedRespondent && cachedRespondent.id === requestedRespondentId) {
            return `${cachedRespondent.firstName} ${cachedRespondent.lastName}`;
        } else {
            return 'Loading...';
        }
    }, [cachedRespondent, requestedRespondentId]);

    return {
        respondentName
    }
}

export function useGetAndCacheUser(requestedUserId?: string) {
    const [cachedUser, setCachedUser] = useState<UserDto>();
    const [getUserById, { data: requestedUser }] = useLazyGetUserQuery();

    useEffect(() => {
        setCachedUser(requestedUser);
    }, [requestedUser]);

    useEffect(() => {
        if (requestedUserId) {
            getUserById(requestedUserId);
        }
    }, [requestedUserId, getUserById]);

    const userName = useMemo(() => {
        if (cachedUser && cachedUser.id === requestedUserId) {
            return `${cachedUser.firstName} ${cachedUser.lastName}`;
        } else {
            return 'Loading...';
        }
    }, [cachedUser, requestedUserId]);

    return {
        userName
    }
}

export function useGetAndCacheFormType(requestedFormTypeId?: string) {
    const [cachedFormType, setCachedFormType] = useState<SurveyDto>();
    const [getFormTypeById, { data: requestedFormType }] = useLazyGetSurveyQuery();

    useEffect(() => {
        setCachedFormType(requestedFormType);
    }, [requestedFormType]);

    useEffect(() => {
        if (requestedFormTypeId) {
            getFormTypeById(requestedFormTypeId);
        }
    }, [requestedFormTypeId, getFormTypeById]);

    const formTypeName = useMemo(() => {
        if (cachedFormType && cachedFormType.id === requestedFormTypeId) {
            return cachedFormType.name;
        } else {
            return 'Loading...';
        }
    }, [cachedFormType, requestedFormTypeId]);

    return {
        formTypeName
    }
}