import { Stack } from '@mui/material';
import { FC } from 'react';
import { FrequencyStartDateCard } from './SubComponents';

export const IssueDateStepContent: FC = () => {    
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <FrequencyStartDateCard hideRecurringOptions />
        </Stack>
    );
}