import { createTheme } from "@mui/material/styles";
import type { } from '@mui/lab/themeAugmentation';

const theme = createTheme({
    palette: {
        primary: { main: "#DF623B", contrastText: "#ffffff" },
        secondary: { main: '#2B698E', contrastText: '#333333' },
        info: { main: '#094061', contrastText: '#ffffff' },
        error: { main: '#EE4035', contrastText: "#ffffff" },
        success: { main: '#299C62', contrastText: "#ffffff" },
        background: {
            default: "#F3F5F6",
        },
        text: {
            primary: "#58585A",
        },
    },
    components: {
        MuiTableRow: {
			styleOverrides: {
				root: {
					"& .MuiTableCell-root .hover-actions": {
						display: 'none'
					},
					"&:hover .MuiTableCell-root .hover-actions": {
						display: 'block'
					}
				}
			}
		},
        MuiTypography: {
            styleOverrides: {
                h1: {
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    '& .MuiSvgIcon-root': {
                        fontSize: '32px',
                        marginRight: '12px'
                    }
                },
                subtitle1: {
                    fontSize: '20px',
                    fontWeight: 'normal'
                },
                h3: {
                    fontSize: '24px',
                    fontWeight: 'bold'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    color: "#2e394c",
                }
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#DF623B',
                        color: '#ffffff',

                    },
                    '& .MuiDrawer-paper > *': {
                        padding: '0px 20px'
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root': {
                        color: '#ffffff',
                        fontSize: '16px',
                        textTransform: 'none'
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button': {
                        padding: '8px',
                        marginLeft: '-8px',
                        justifyContent: 'start'
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button.full-width': {
                        padding: '8px 12px',
                        marginLeft: '-12px',
                        width: 'calc(100% + 12px)'
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root.nav-button:hover': {
                        backgroundColor: '#FFAA8F'
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root .MuiSvgIcon-root': {
                        fontSize: '32px'
                    },
                    '& .MuiDrawer-paper .MuiButtonBase-root .MuiButton-iconSizeMedium': {
                        marginRight: '24px'
                    }
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                    '& .MuiButton-root': {
                        width: '100px'
                    },
                    '& > :not(:first-of-type)': {
                        marginLeft: '24px'
                    }
                }
            }
        }
    },
});

export default theme;
