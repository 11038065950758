import { Button, Divider, Grid, Link, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataFilterContext } from '../../../../../../Contexts';
import { useGetRespondentStatusByFormTypeQuery, useGetRespondentStatusBySurveyQuery } from '../../../../../../store';
import { CompanyDashboardContext } from '../../../../Utils';
import { CompanyDashboardRespondentStatusByFormTypeChart } from './CompanyDashboardRespondentStatusByFormTypeChart';
import { AddEditStudyModal } from '../../../../../Studies/Components';

export const CompanyDashboardOverviewTab: FC = () => {
    const navigate = useNavigate();
    const { company, overviewFilters } = useContext(CompanyDashboardContext);
    const [isNewStudyModalOpen, setIsNewStudyModalOpen] = useState(false);
    const {
        formTypeFilter,
        setFormTypeFilter,
        statusFilter,
        scheduledFromDateFilter: fromDateFilter,
        scheduledToDateFilter: toDateFilter,
        periodStartDateFilter,
        periodEndDateFilter,
    } = useContext(DataFilterContext);
    const { data: respondentStatusBySurvey } = useGetRespondentStatusBySurveyQuery({
        companyId: company.id,
        searchParams: {
            daysBack: overviewFilters.daysBack,
            formType: formTypeFilter ?? '',
            status: statusFilter,
            fromDate: fromDateFilter ?? undefined,
            toDate: toDateFilter ?? undefined,
            periodStartDate: periodStartDateFilter ?? undefined,
            periodEndDate: periodEndDateFilter ?? undefined,
        },
    });
    const { data: respondentStatusByFormType } = useGetRespondentStatusByFormTypeQuery({
        companyId: company.id,
        searchParams: {
            daysBack: overviewFilters.daysBack,
            formType: formTypeFilter ?? '',
            status: statusFilter,
            fromDate: fromDateFilter ?? undefined,
            toDate: toDateFilter ?? undefined,
            periodStartDate: periodStartDateFilter ?? undefined,
            periodEndDate: periodEndDateFilter ?? undefined,
        },
    });

    const handleStartSurvey = () => {
        navigate(`/companies/${company.id}/survey`);
    };

    const handleCreateStudy = () => {
        setIsNewStudyModalOpen(true)
    };

    const handleDaysChange = (event: SelectChangeEvent) => {
        let newDaysBack = parseInt(event.target.value);
        overviewFilters.setDaysBack(newDaysBack);
    };

    const buildRespondentCountLineItem = useCallback(
        (numRespondents: number, formType: string) => {
            const isPlural = numRespondents !== 1;
            return (
                <Typography>
                    {`${numRespondents} respondent${isPlural ? 's are' : ' is'} assigned to the `}
                    <Link underline='none' href={'#'} onClick={() => setFormTypeFilter(formType)}>
                        {formType}
                    </Link>{' '}
                    form.
                </Typography>
            );
        },
        [setFormTypeFilter]
    );

    return (
        <Grid container direction='column'>
            <Grid item container direction='row' justifyContent='space-between'>
                <Grid item xs={9} container direction='column'>
                    <Grid item mb={2}>
                        <Typography>
                            {company.name} currently has {company.respondentCount} active respondent{company.respondentCount !== 1 ? 's' : ''}.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='column' spacing={1} xs={3}>
                    <Grid item>
                        <Button onClick={handleStartSurvey} variant='contained' color='primary' fullWidth>
                            Start New Survey
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleCreateStudy} variant='contained' color='primary' fullWidth>
                            Create Study
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='column'>
                {respondentStatusByFormType &&
                    respondentStatusByFormType.map((s, idx) => (
                        <Grid item key={`${idx}-${s.name}-count`}>
                            {buildRespondentCountLineItem(s.aggregate, s.name)}
                        </Grid>
                    ))}
            </Grid>
            <Grid item container direction='column' mt={2}>
                <Divider />
                <Grid item container direction='row' mt={2} mb={1}>
                    <Grid item>
                        <Typography>Surveys sent in the past</Typography>
                    </Grid>
                    <Grid item>
                        <Select value={`${overviewFilters.daysBack}`} onChange={handleDaysChange} style={{ maxHeight: 24, marginLeft: 8, marginRight: 8 }}>
                            {[1, 10, 30, 90, 360].map((numDays) => (
                                <MenuItem key={numDays} value={numDays}>
                                    {numDays}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item>
                        <Typography> days:</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row'>
                    {respondentStatusBySurvey?.length === 0 && (
                        <Typography width='100%' textAlign='center' pt={2} fontWeight='bold'>
                            There were no surveys sent in the past {overviewFilters.daysBack} day{overviewFilters.daysBack !== 1 ? 's' : ''}
                        </Typography>
                    )}
                    {respondentStatusBySurvey &&
                        respondentStatusBySurvey.map((s, idx) => <CompanyDashboardRespondentStatusByFormTypeChart key={`${idx}-${s.id}-chart`} survey={s} />)}
                </Grid>
            </Grid>
            <AddEditStudyModal isOpen={isNewStudyModalOpen} close={() => setIsNewStudyModalOpen(false)} openInNewTabOnCreate companyId={company.id} />
        </Grid>
    );
};
