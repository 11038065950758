import { Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LoadingIndicator, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../../Components/CoreLib/library';
import { RespondentForm } from '../../../Components/Forms/CompanyForm/RespondentForm';
import { RespondentCommunicationTable } from '../../../Components/Forms/CompanyForm/RespondentForm/CommunicationTable/RespondentCommunicationTable';
import { SentSurveysTable } from '../../../Components/Forms/CompanyForm/RespondentForm/SentSurveysTable';
import { RespondentDto } from '../../../dtos';
import { useGetRespondentQuery, useUpdateRespondentMutation } from '../../../store';

export const EditRespondentView: FC = () => {
    const { id: companyId, respondentId } = useParams();
    const navigate = useNavigate();
    const { data: respondent, isLoading: loadingRespondent, isError: isRespondentError } = useGetRespondentQuery({ parentId: companyId!, childId: respondentId! });
    const [updateRespondent, { isSuccess, isError, reset, error: updateRespondentError }] = useUpdateRespondentMutation();

    useFailedActionSnackbar('retrieve', 'respondent', isRespondentError);
    useSuccessfulUpdateSnackbar('respondent', isSuccess, () => {
        reset();
        navigate(`/companies/${companyId}`);
    });
    useFailedUpdateSnackbar('respondent', isError, reset, updateRespondentError);

    const handleSave = (respondent: RespondentDto) => {
        updateRespondent(respondent);
    }

    const handleCancel = () => {
        navigate(`/companies/${companyId}`);
    }

    return (
        <>
            {!loadingRespondent ? (
                <Grid container direction="column" gap={2}>
                    <Grid item>
                        <RespondentForm companyId={companyId!} save={handleSave} cancel={handleCancel} initValues={respondent} />
                    </Grid>
                    <Grid item>
                        <SentSurveysTable companyId={companyId!} respondentId={respondentId!}></SentSurveysTable>
                    </Grid>
                    <Grid item>
                        <RespondentCommunicationTable respondentId={respondentId!}></RespondentCommunicationTable>
                    </Grid>
                </Grid>

            ) : <LoadingIndicator />}
        </>
    );
}