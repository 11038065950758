import { FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useCallback } from 'react';

export interface IStandardSelectProps {
    label: string;
    selectedValue: string;
    handleSelectedValueChange: (newValue: string) => void;
    options: string[];
    isRequired?: boolean;
    errorMessage?: string;
};

export const StandardSelect: FC<IStandardSelectProps> = ({
    label,
    selectedValue,
    handleSelectedValueChange,
    options,
    isRequired,
    errorMessage
}) => {
    const onChange = useCallback((e: SelectChangeEvent<any>) => handleSelectedValueChange(e.target.value), [handleSelectedValueChange]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={isRequired}>
            <FormLabel>{label}</FormLabel>
            <Select
                value={selectedValue}
                onChange={onChange}
            >
                {options.map((op, idx) => (
                    <MenuItem key={`${idx}-${op}`} value={op}>
                        <div style={{ minHeight: '24px' }}>
                            {op}
                        </div>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    );
};