import { AddCircle, Delete, Email, MoreVert } from '@mui/icons-material';
import {
    Divider, FormControlLabel, Grid, IconButton,
    Menu,
    MenuItem,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import { FC, useState } from "react";
import { DataTableColumn, NavBreadcrumbs, PaginatedDataTable, PaginatedProps, StandardSearchInput, useFailedActionSnackbar } from "../Components/CoreLib/library";
import { EmailTemplateDto } from "../dtos";
import { useGetEmailTemplatesQuery } from "../store/api/email-template-api";

export const EmailTemplatesListView: FC = () => {

    const mapTimeColumn = (row: EmailTemplateDto) => {
        if (row.createdOn) {
            let createdDate = new Date(row.createdOn)
            let timeString = createdDate.toLocaleDateString("en-us");
            return timeString;
        }
    }
    const tableColumns: DataTableColumn<EmailTemplateDto>[] = [
        { key: 'name', label: 'Email Template', sortKey: 'NAME' },
        { key: 'createdOn', label: 'Created', sortKey: 'CREATED', fieldMapper: mapTimeColumn },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: (row: EmailTemplateDto) => row.isActive ? 'Active' : 'Inactive' }
    ];

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [selectedEmailTemplates, setSelectedEmailTemplates] = useState<readonly EmailTemplateDto[]>([]);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const { data, isLoading, isError } = useGetEmailTemplatesQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive
    });
    useFailedActionSnackbar('retrieve', 'email templates', isError);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    }

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInactive(event.target.checked);
    }

    const handleCreateNew = () => {

    }

    const handleDelete = () => {

    }

    const handleMenuEdit = () => {
        //const selectedSurveyId = selectedIds[0].id;
    }

    const handleSearchChange = (text: string) => {
        setSearchText(text);
    };

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" alignItems="center">
                <Grid item container direction="column" justifyContent="start" xs={7}>
                    <Typography variant="h1" sx={{ marginBottom: '8px' }}>
                        <Email /> Email Templates
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Admin', navLink: '', isText: true }
                        ]}
                        currentPageLabel="Email Templates"
                    />
                </Grid>
                <Grid item container direction="row" alignItems="center" xs={5}>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label="Show Inactive"
                            labelPlacement="start"
                        />

                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title=" Delete">
                            <IconButton size="large" onClick={handleDelete} disabled={true}>
                                <Delete fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Add New">
                            <IconButton size="large" onClick={handleCreateNew} disabled={true}>
                                <AddCircle fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <IconButton size="large" onClick={handleMenuOpen}>
                            <MoreVert fontSize="inherit" />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleMenuClose(handleCreateNew)} disabled={true}>Add New</MenuItem>
                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={true}>Edit</MenuItem>
                            <Divider />
                            <MenuItem onClick={handleMenuClose()} disabled={true}>Make Active</MenuItem>
                            <MenuItem onClick={handleMenuClose()} disabled={true}>Make Inactive</MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs>
                        <StandardSearchInput searchText={searchText} handleSearchChange={handleSearchChange} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey="NAME"
                    setPagination={setPaginatedProps}
                    selectedRecords={selectedEmailTemplates}
                    setSelectedRecords={setSelectedEmailTemplates as any}
                />
            </Grid>
        </Grid>
    );
}