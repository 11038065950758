import { ImportRowStatus, ImportWorkItemRowDto } from "../../../dtos";
import { IInMemoryTableColumnDefinition } from "../../InMemoryTable";
import { formateDateStandard } from "../../../util";

const renderStatusCell = (status: ImportRowStatus = ImportRowStatus.Good, message: string = ''): JSX.Element => {
    let cellColor = 'inherit';
    switch (status) {
        case ImportRowStatus.Warning:
        case ImportRowStatus.Skipped:
            cellColor = '#ffa726';
            break;
        case ImportRowStatus.Error:
        case ImportRowStatus.Duplicate:
            cellColor = '#DF623B';
            break;
    }

    return (
        <span style={{ color: cellColor }}>{message}</span>
    )
}

export const WORK_ITEM_IMPORT_COLUMN_DEFINITION: IInMemoryTableColumnDefinition<ImportWorkItemRowDto>[] = [
    { key: 'NAME', label: 'Component Name', value: 'businessComponentName', isSortable: true },
    { key: 'EMPLOYEE', label: 'Employee', value: 'employeeName', isSortable: true },
    { key: 'DESCRIPTION', label: 'Description', value: 'businessComponentDescription', isSortable: true },
    { key: 'START_DATE', label: 'Start Date', value: (row) => formateDateStandard(row.businessComponentStartDate), isSortable: false },
    { key: 'END_DATE', label: 'End Date', value: (row) => formateDateStandard(row.businessComponentEndDate), isSortable: false },
    { key: 'STATUS', label: 'Status', value: (row) => renderStatusCell(row.status, row.statusMessage), isSortable: true },
];