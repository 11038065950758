import { serverApi } from '..';
import { EmailMessageDto } from '../../dtos';

const emailApi = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation<void, EmailMessageDto>({
      query: (emailMessage) => ({
        url: 'email',
        method: 'POST',
        body: emailMessage,
      }),
    }),
  }),
});

export const { useSendEmailMutation } = emailApi;
