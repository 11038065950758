import { AddCircle } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

export interface IStepperContentSectionTitleProps {
    icon: JSX.Element;
    title: string;
    onAddClicked: MouseEventHandler<HTMLButtonElement>;
}

export const StepperContentSectionTitle: FC<IStepperContentSectionTitleProps> = ({ icon, title, onAddClicked }) => {
    return (
        <Grid item container direction='row' alignItems='center'>
            <Grid item mr={1}>
                {icon}
            </Grid>
            <Grid item>
                <Typography variant='h5' pb={0.5}>
                    {title}s
                </Typography>
            </Grid>
            <Grid item marginLeft={'auto'}>
                <Tooltip title={`Add New ${title}`}>
                    <IconButton color='primary' onClick={onAddClicked}>
                        <AddCircle />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}