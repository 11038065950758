import { Button, Grid, TextField } from "@mui/material";
import { FunctionComponent } from "react";
import { ImportRespondentsStepOneProps } from ".";



export const ImportRespondentsStepOne: FunctionComponent<ImportRespondentsStepOneProps> = props => {
    const { selectedFile, setSelectedFile, setIsReadyForNext } = props;


    const handleChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files instanceof FileList) {
            setSelectedFile(event.target.files[0])
            setIsReadyForNext(true);
        }
    };


    return (
        <Grid container direction="column" justifyContent={"space-between"}>
            <Grid item container direction="row" xs={6} >
                <Grid item sx={{ pr: 2 }}>
                    <label htmlFor="choose-file">
                        <input
                            name="choose-file"
                            id="choose-file"
                            type="file"
                            accept=".csv"
                            style={{ display: 'none' }}
                            onChange={handleChooseFile}
                        />

                        <Button variant="contained" component="span" size="medium" sx={{ color: "secondary", backgroundColor: "primary", ml: 0 }}>Choose File</Button>
                    </label>
                </Grid>
                <Grid item sx={{ flex: 1, maxWidth: 400 }}>
                    <TextField size="small" fullWidth label="File Name" value={selectedFile?.name} InputLabelProps={{ shrink: true }}>{selectedFile?.name}</TextField>
                </Grid>
            </Grid>
        </Grid>
    );
}