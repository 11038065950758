import { Warning } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { DataTableColumn, StandardListView } from '../../../../../../Components/CoreLib/library';
import { DataFilterContext } from '../../../../../../Contexts';
import { ArchiveActionType, ArchiveDepartmentsDto, DepartmentDto } from '../../../../../../dtos';
import { BulkDepartmentArchiveParams, BulkUpdateWithParentId, useBulkArchiveDepartmentsMutation, useBulkUnarchiveDepartmentsMutation, useGetCompanyDepartmentsWithNoRespondentsQuery, useGetDepartmentsQuery } from '../../../../../../store';
import { mapTimeColumn } from '../../../../../../util/mapTimeColumn';
import { AddEditDepartmentModal } from '../../../../Departments/AddEditDepartmentModal';
import { ArchiveDepartmentsModal } from '../../../../Departments/ArchiveDepartmentsModal';
import { CompanyDashboardContext } from '../../../../Utils';

export const CompanyDashboardDepartmentsTab: FC = () => {
    const { company } = useContext(CompanyDashboardContext);
    const {
        isNoRespondentsFilter,
        setIsNoRespondentsFilter,
        createdFromDateFilter,
        createdToDateFilter
    } = useContext(DataFilterContext)
    const tableColumns: DataTableColumn<DepartmentDto>[] = [
        { key: 'name', label: 'Name', sortKey: 'NAME' },
        { key: 'respondentCount', align: 'right', label: 'Respondents', sortKey: 'RESPONDENTS' },
        { key: '', label: '', sortKey: '', unsorted: true, width: '64px' },
        { key: 'createdOn', label: 'Created', sortKey: 'CREATED', fieldMapper: mapTimeColumn },
    ];
    const [addDepartmentIsVisible, setAddDepartmentIsVisible] = useState(false);
    const [department, setDepartment] = useState<DepartmentDto>();
    const [departmentsToArchive, setDepartmentsToArchive] = useState<ArchiveDepartmentsDto[]>([]);
    const { data: departmentsWithNoRespondents } = useGetCompanyDepartmentsWithNoRespondentsQuery(company.id);
    const { setSelectedDepartmentId } = useContext(CompanyDashboardContext);

    const handleCreate = () => {
        setDepartment(undefined);
        setAddDepartmentIsVisible(true);
    };

    const handleEdit = (_: string, record: DepartmentDto) => {
        setDepartment(record);
        setAddDepartmentIsVisible(true);
    };

    const handleRowClick = useCallback((id: string) => {
        if (id && setSelectedDepartmentId) {
            setSelectedDepartmentId(id);
        }
    }, [setSelectedDepartmentId]);

    const renderTableWarning = () => {
        if (departmentsWithNoRespondents && departmentsWithNoRespondents.length > 0) {
            const numDepartmentlessRespondents = departmentsWithNoRespondents.length;
            const pluralizedContent = numDepartmentlessRespondents === 1 ? 'department does' : 'departments do';
            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', paddingBottom: '8px' }}>
                    <Warning color='primary' style={{ cursor: 'pointer' }} onClick={() => setIsNoRespondentsFilter(true)} />
                    <Typography color='primary' style={{ cursor: 'pointer' }} onClick={() => setIsNoRespondentsFilter(true)}>
                        {`${numDepartmentlessRespondents} ${pluralizedContent} not have any respondents`}
                    </Typography>
                </div>
            )
        }
    }

    const handleArchiveIntercept = (records: DepartmentDto[], archiveMutation: (params: BulkDepartmentArchiveParams) => void) => {
        let initialArchivePayload: ArchiveDepartmentsDto[] = records.map(depart => {
            return {
                departmentId: depart.id,
                archiveAction: depart.respondentCount === 0 ? ArchiveActionType.NoActionRequired : ArchiveActionType.ClearExisting
            }
        });
        const isAnyActionRequired = initialArchivePayload.some(x => x.archiveAction !== ArchiveActionType.NoActionRequired);
        if (!isAnyActionRequired) {
            archiveMutation({
                parentId: company.id,
                body: initialArchivePayload
            });
        } else {
            setDepartmentsToArchive(initialArchivePayload);
        }
    }

    const handleRecoverIntercept = (records: DepartmentDto[], recoverMutation: (params: BulkUpdateWithParentId) => void) => {
        let payload: BulkUpdateWithParentId = {
            parentId: company.id,
            recordIds: records.map(x => x.id)
        }
        recoverMutation(payload);
    }

    const handleArchiveModalCancel = () => {
        setDepartmentsToArchive([]);
    }

    return (
        <Paper elevation={0}>
            <StandardListView
                defaultPaginationProps={{
                    sortKey: 'NAME',
                    sortAsc: true,
                    page: 0,
                    pageSize: 25
                }}
                getDataQuery={useGetDepartmentsQuery}
                additionalQueryParameters={{
                    parentId: company.id,
                    hasNoRespondents: isNoRespondentsFilter,
                    createdFrom: createdFromDateFilter?.toISOString(),
                    createdTo: createdToDateFilter?.toISOString()
                }}
                archiveMutation={useBulkArchiveDepartmentsMutation}
                archiveIntercept={handleArchiveIntercept}
                recoverMutation={useBulkUnarchiveDepartmentsMutation}
                recoverIntercept={handleRecoverIntercept}
                tableColumns={tableColumns}
                entityNameSingular='Department'
                entityNamePlural='Departments'
                headerTitle=''
                permissionName='departments'
                headerIcon={<></>}
                handleEdit={handleEdit}
                handleAdd={handleCreate}
                subtitleComponentOverride={renderTableWarning()}
                showSearch={false}
                showFilterButton={false}
                handleRowClick={handleRowClick}
                isBorderVisible={false}
            />
            <AddEditDepartmentModal company={company} department={department} isVisible={addDepartmentIsVisible} setIsVisible={setAddDepartmentIsVisible} />
            <ArchiveDepartmentsModal company={company} departments={departmentsToArchive} isVisible={departmentsToArchive.length > 0} handleCancel={handleArchiveModalCancel} />
        </Paper>
    );
};