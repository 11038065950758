import {
    BulkUpdateResult,
    ChildQueryParams,
    CompanyScheduleQueryParams,
    ParentQueryParams,
    ScheduleArchiveQueryParams,
    ScheduleDetailParentSearchQueryParams,
    ScheduleSearchQueryParams,
    SendReminderEmailQueryParams,
    serverApi,
} from '..';
import {
    QueryResultsDto,
    ScheduleDetailAddedBusinessComponentDto,
    ScheduleDetailAddedRespondentDto,
    ScheduleDetailDto,
    ScheduleDto,
    ScheduleRespondentAnswerDto,
} from '../../dtos';
import { buildSearchQueryRoute } from '../../util';
import { ISurveyQuestionAnswerPayload } from '../../Views/types';

export interface AddRespondentToScheduleDetailParameters {
    scheduleId: string;
    scheduleDetailId: string;
    userAddedRespondent: ScheduleDetailAddedRespondentDto;
}

export interface AddBusinessComponentToScheduleDetailParameters {
    scheduleId: string;
    scheduleDetailId: string;
    userAddedBusinessComponent: ScheduleDetailAddedBusinessComponentDto;
}

const scheduleApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getSurveySchedules: builder.query<QueryResultsDto<ScheduleDto>, ScheduleSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('schedule', params),
            providesTags: [{ type: 'Schedule' }],
        }),
        getBusinessComponentSchedules: builder.query<QueryResultsDto<ScheduleDto>, ScheduleSearchQueryParams>({
            query: (params) => buildSearchQueryRoute('schedule/businessComponents', params),
            providesTags: [{ type: 'Schedule' }],
        }),
        getCompanySchedules: builder.query<QueryResultsDto<ScheduleDto>, CompanyScheduleQueryParams>({
            query: (params) => buildSearchQueryRoute(`schedule/company/${params.parentId}`, params),
            providesTags: [{ type: 'Schedule' }],
        }),
        getSchedule: builder.query<ScheduleDto, string>({
            query: (scheduleId) => ({ url: `schedule/${scheduleId}` }),
            providesTags: [{ type: 'Schedule' }],
        }),
        archiveSchedule: builder.mutation<void, ScheduleArchiveQueryParams>({
            query: (params) => ({
                url: `schedule/${params.scheduleId}`,
                method: 'DELETE',
                body: params.isSkipping,
            }),
            invalidatesTags: [{ type: 'Schedule' }, { type: 'ScheduleDetails' }],
        }),
        unarchiveSchedule: builder.mutation<void, string>({
            query: (scheduleId) => ({
                url: `schedule/${scheduleId}`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: 'Schedule' }, { type: 'ScheduleDetails' }],
        }),
        getScheduleDetails: builder.query<QueryResultsDto<ScheduleDetailDto>, ScheduleDetailParentSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`schedule/${params.parentId}/detail`, params.params),
            providesTags: [{ type: 'ScheduleDetails' }, { type: 'Respondent' }],
        }),
        getScheduleDetail: builder.query<ScheduleDetailDto, ChildQueryParams>({
            query: (params) => ({ url: `schedule/${params.parentId}/detail/${params.childId}` }),
            providesTags: [{ type: 'ScheduleDetails' }],
        }),
        addRespondentToScheduleDetail: builder.mutation<void, AddRespondentToScheduleDetailParameters>({
            query: (params) => ({
                url: `schedule/${params.scheduleId}/detail/${params.scheduleDetailId}/addRespondent`,
                method: 'POST',
                body: params.userAddedRespondent,
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }],
        }),
        addBusinessComponentToScheduleDetail: builder.mutation<void, AddBusinessComponentToScheduleDetailParameters>({
            query: (params) => ({
                url: `schedule/${params.scheduleId}/detail/${params.scheduleDetailId}/addBusinessComponent`,
                method: 'POST',
                body: params.userAddedBusinessComponent,
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }],
        }),
        pauseScheduleDetail: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/detail/${params.childId}/pause`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        unpauseScheduleDetail: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/detail/${params.childId}/unpause`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        pauseAllScheduleDetails: builder.mutation<void, ParentQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/pause`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        unpauseAllScheduleDetails: builder.mutation<void, ParentQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/unpause`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        reopenSchedule: builder.mutation<void, ParentQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/reopen`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        archiveScheduleDetail: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/detail/${params.childId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        unarchiveScheduleDetail: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/detail/${params.childId}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        getScheduleRespondentAnswers: builder.query<ScheduleRespondentAnswerDto[], ChildQueryParams>({
            query: (params) => ({
                url: `schedule/${params.parentId}/detail/${params.childId}/answers`,
                method: 'GET',
            }),
            providesTags: [{ type: 'ScheduleRespondentAnswers' }],
        }),
        sendReminderEmail: builder.mutation<void, SendReminderEmailQueryParams>({
            query: (params) => ({
                url: `schedule/notify/${params.id}`,
                method: 'POST',
                body: params.cancelNext,
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }],
        }),
        sendReminderEmailForSchedule: builder.mutation<void, SendReminderEmailQueryParams>({
            query: (params) => ({
                url: `schedule/notify/schedule/${params.id}`,
                method: 'POST',
                body: params.cancelNext,
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }],
        }),
        saveScheduleRespondentAnswers: builder.mutation<void, ISurveyQuestionAnswerPayload>({
            query: (params) => ({
                url: `schedule/${params.parentId}/detail/${params.childId}/answers?isSubmit=${params.isSubmit}`,
                method: 'POST',
                body: params.answers,
            }),
            invalidatesTags: [{ type: 'ScheduleRespondentAnswers' }, { type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        updateSchedule: builder.mutation<void, ScheduleDto>({
            query: (scheduleDto) => ({
                url: `schedule/update/${scheduleDto.id}`,
                method: 'PUT',
                body: scheduleDto,
            }),
        }),
        approveSchedules: builder.mutation<BulkUpdateResult, string[]>({
            query: (scheduleIds) => ({
                url: `schedule/bulkApprove`,
                method: 'PUT',
                body: scheduleIds,
            }),
            invalidatesTags: [{ type: 'Schedule' }],
        }),
        unapproveSchedules: builder.mutation<BulkUpdateResult, string[]>({
            query: (scheduleIds) => ({
                url: `schedule/bulkUnapprove`,
                method: 'PUT',
                body: scheduleIds,
            }),
            invalidatesTags: [{ type: 'Schedule' }],
        }),
    }),
});

export const {
    useGetSurveySchedulesQuery,
    useGetBusinessComponentSchedulesQuery,
    useGetCompanySchedulesQuery,
    useGetScheduleQuery,
    useArchiveScheduleMutation,
    useUnarchiveScheduleMutation,
    useGetScheduleDetailsQuery,
    usePauseScheduleDetailMutation,
    useUnpauseScheduleDetailMutation,
    useArchiveScheduleDetailMutation,
    useUnarchiveScheduleDetailMutation,
    useGetScheduleRespondentAnswersQuery,
    useSaveScheduleRespondentAnswersMutation,
    useUpdateScheduleMutation,
    useGetScheduleDetailQuery,
    useSendReminderEmailMutation,
    useSendReminderEmailForScheduleMutation,
    usePauseAllScheduleDetailsMutation,
    useUnpauseAllScheduleDetailsMutation,
    useReopenScheduleMutation,
    useApproveSchedulesMutation,
    useUnapproveSchedulesMutation,
    useLazyGetSurveySchedulesQuery,
    useAddRespondentToScheduleDetailMutation,
    useAddBusinessComponentToScheduleDetailMutation,
} = scheduleApi;
