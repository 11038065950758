import { FilterList } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormLabel, Grid, OutlinedInput } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { StandardDialogHeader } from '../../CommonComponents';
import { ActivityTypeSelect, CompanySelect, FormTypeSelect } from '../../CommonInputs';
import { StandardDatePicker } from '../../CommonInputs/StandardDatePicker';
import { useActivityStreamFilterDialogManager } from './useActivityStreamFilterDialogManager';

export interface IActivityStreamFilterDialogProps {
    open: boolean;
    onClose: () => void;
    hideRespondentNameFilters?: boolean;
    hideCompanyFilter?: boolean;
    hidePeriodFilter?: boolean;
}

export const ActivityStreamFilterDialog: FC<IActivityStreamFilterDialogProps> = ({
    open,
    onClose,
    hideRespondentNameFilters = false,
    hideCompanyFilter = false,
    hidePeriodFilter = false,
}) => {
    const {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty
    } = useActivityStreamFilterDialogManager();

    const handleApplyFilters = useCallback(() => {
        applyDialogFilters();
        onClose();
    }, [applyDialogFilters, onClose]);

    const handleClose = useCallback(() => {
        loadAppliedFiltersIntoDialog();
        onClose();
    }, [loadAppliedFiltersIntoDialog, onClose])

    useEffect(() => {
        if (open) {
            loadAppliedFiltersIntoDialog();
        }
    }, [loadAppliedFiltersIntoDialog, open]);

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '100%' } }} maxWidth='lg'>
            <StandardDialogHeader icon={<FilterList />} text='Filter Activity Stream' />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    {!hideRespondentNameFilters && (
                        <>
                            {!hideCompanyFilter && (
                                <Grid item xs={4}>
                                    <CompanySelect selectedCompanyId={dialogFilters.companyId ?? ''} handleSelectedCompanyChange={(c) => handleDialogFilterValueChange('companyId', c?.id)} />
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Respondent First Name</FormLabel>
                                    <OutlinedInput value={dialogFilters.respondentFirstName ?? ''} onChange={(e) => handleDialogFilterValueChange('respondentFirstName', e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Respondent Last Name</FormLabel>
                                    <OutlinedInput value={dialogFilters.respondentLastName ?? ''} onChange={(e) => handleDialogFilterValueChange('respondentLastName', e.target.value)} />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={4}>
                        <ActivityTypeSelect selectedActivityType={dialogFilters.activityType ?? ''} handleSelectedActivityTypeChange={at => handleDialogFilterValueChange('activityType', at)} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTypeSelect selectedFormTypeId={dialogFilters.formTypeId ?? ''} handleSelectedFormTypeChange={(ft) => handleDialogFilterValueChange('formTypeId', ft?.id)} />
                    </Grid>
                    <Grid item xs={4}>
                        {!hidePeriodFilter &&
                        (<FormControl fullWidth>
                            <FormLabel>Period</FormLabel>
                            <OutlinedInput value={dialogFilters.period} onChange={(e) => handleDialogFilterValueChange('period', e.target.value)} />
                        </FormControl>)}
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel>Activity Date</FormLabel>
                    </Grid>
                    <Grid item container direction='row' xs={8} spacing={2} alignItems='center' pt={0} style={{ paddingTop: 0 }}>
                        <Grid item xs='auto'>
                            <StandardDatePicker value={dialogFilters.createdFrom ?? null} handleDateChange={(d) => handleDialogFilterValueChange('createdFrom', d)} />
                        </Grid>
                        <Grid item xs='auto'>
                            to
                        </Grid>
                        <Grid item xs='auto'>
                            <StandardDatePicker value={dialogFilters.createdTo ?? null} handleDateChange={(d) => handleDialogFilterValueChange('createdTo', d)} />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClose}>Clear All</Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleApplyFilters} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
