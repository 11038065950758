import { FC, PropsWithChildren } from 'react';
import { Grid, Paper, SxProps, Theme } from '@mui/material';

export interface IFormSectionProps extends PropsWithChildren {
    sx?: SxProps<Theme>;
}

export const FormSection: FC<IFormSectionProps> = ({sx = {}, children}) => {
    return (
        <Paper elevation={0} sx={{ border: 'solid 1px #AAAAAA', padding: '24px', ...sx }}>
            <Grid container direction="column" spacing={2}>
                {children}
            </Grid>
        </Paper>
    );
}