import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSuccessfulUpdateSnackbar, useFailedUpdateSnackbar, LoadingIndicator, ApiError } from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { useGetUserQuery, useUpdateUserMutation } from '../../store/api/user-api';
import { UserForm } from './UserForm';

export const EditUserView: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetUserQuery(id!);
    const [updateUser, { isSuccess, isError, error: updateError, reset, isLoading: isUpdating }] = useUpdateUserMutation();

    const handleSuccessfulUpdate = () => {
        reset();
    };

    useSuccessfulUpdateSnackbar('User', isSuccess, handleSuccessfulUpdate);
    useFailedUpdateSnackbar('User', isError, reset, updateError);

    const handleSave = (values: UserDto) => {
        updateUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <UserForm save={handleSave} cancel={handleCancel} initValues={data} isLoading={isLoading || isUpdating} />;
};
