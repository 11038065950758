import { MenuItem, Select, SelectChangeEvent, SxProps, TableCell, TableRow, TextField, Theme, Typography } from '@mui/material';
import moment from 'moment';
import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { SurveyPayloadSurveyPeriodConfig } from '../../../../dtos';

interface IFrequencySurveyPeriodProps {
    surveyPeriodConfig: SurveyPayloadSurveyPeriodConfig;
    updateSurveyConfig: (config: SurveyPayloadSurveyPeriodConfig, index: number) => void;
    surveyPeriodIndex: number;
    frequency: string;
}
export const FrequencySurveyPeriod: FC<IFrequencySurveyPeriodProps> = (props) => {
    const { surveyPeriodConfig, updateSurveyConfig, surveyPeriodIndex, frequency } = props;
    const previousYear = moment().add(-1, 'y').toDate();
    const months = useMemo(() => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], []);
    let days: string[] = [];
    for (let i = 1; i < 32; i++) {
        days.push(i.toString().padStart(2, '0'));
    }

    const validateMonthYearCombination = useCallback((monthIndex: number, day: number) => {
        const monthMaxDays = new Map<number, number>([
            [0, 31],
            [1, 28], // we are not considering the 29th (leap-day) a valid option
            [2, 31],
            [3, 30],
            [4, 31],
            [5, 30],
            [6, 31],
            [7, 31],
            [8, 30],
            [9, 31],
            [10, 30],
            [11, 31],
        ]);
        
        const numDaysInMonth = monthMaxDays.get(monthIndex)!;
        if (day > numDaysInMonth) {
            return numDaysInMonth;
        }
        return day;
    }, []);

    const getDefaultPeriodEnding = (month: number, day: number) => {
        let periodEndDate = new Date();
        periodEndDate.setMonth(month);
        periodEndDate.setDate(day);
        switch (frequency) {
            case 'every-year':
                periodEndDate.setFullYear(periodEndDate.getFullYear() + 1);
                periodEndDate.setDate(periodEndDate.getDate() - 1);
                break;
            case 'every-three-months':
            default:
                periodEndDate.setMonth(periodEndDate.getMonth() + 3);
                periodEndDate.setDate(periodEndDate.getDate() - 1);
                break;
        }
        return periodEndDate;
    }

    const handlePeriodStartMonthChange = (event: SelectChangeEvent) => {
        const updatedValue = event.target.value === '' ? undefined : Number(event.target.value);
        let updatedConfig = { ...surveyPeriodConfig, periodStartMonth: updatedValue };
        if (updatedConfig.periodStartMonth !== undefined && updatedConfig.periodStartDay !== undefined && !updatedConfig.periodEndMonth && !updatedConfig.periodEndDay) {
            let periodEndDate = getDefaultPeriodEnding(updatedConfig.periodStartMonth, updatedConfig.periodStartDay);
            updatedConfig.periodEndMonth = periodEndDate.getMonth();
            updatedConfig.periodEndDay = periodEndDate.getDate();
        }
        updateSurveyConfig(updatedConfig, surveyPeriodIndex);
    };

    const handlePeriodStartDayChange = (event: SelectChangeEvent) => {
        const updatedValue = event.target.value === '' ? undefined : Number(event.target.value);
        let updatedConfig = { ...surveyPeriodConfig, periodStartDay: updatedValue };
        if (updatedConfig.periodStartMonth !== undefined && updatedConfig.periodStartDay !== undefined && !updatedConfig.periodEndMonth && !updatedConfig.periodEndDay) {
            let periodEndDate = getDefaultPeriodEnding(updatedConfig.periodStartMonth, updatedConfig.periodStartDay);
            updatedConfig.periodEndMonth = periodEndDate.getMonth();
            updatedConfig.periodEndDay = periodEndDate.getDate();
        }
        updateSurveyConfig(updatedConfig, surveyPeriodIndex);
    };

    const handlePeriodEndMonthChange = (event: SelectChangeEvent) => {
        const updatedValue = event.target.value === '' ? undefined : Number(event.target.value);
        updateSurveyConfig({ ...surveyPeriodConfig, periodEndMonth: updatedValue }, surveyPeriodIndex);
    };

    const handlePeriodEndDayChange = (event: SelectChangeEvent) => {
        const updatedValue = event.target.value === '' ? undefined : Number(event.target.value);
        updateSurveyConfig({ ...surveyPeriodConfig, periodEndDay: updatedValue }, surveyPeriodIndex);
    };

    const handleMonthChange = (event: SelectChangeEvent) => {
        updateSurveyConfig({ ...surveyPeriodConfig, month: event.target.value }, surveyPeriodIndex);
    };

    const handleDayChange = (event: SelectChangeEvent) => {
        updateSurveyConfig({ ...surveyPeriodConfig, day: event.target.value }, surveyPeriodIndex);
    };

    const handleSurveyLabelPrefixChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateSurveyConfig({ ...surveyPeriodConfig, surveyLabelPrefix: event.target.value }, surveyPeriodIndex);
    };

    const handleSurveyLabelSuffixChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateSurveyConfig({ ...surveyPeriodConfig, surveyLabelSuffix: event.target.value }, surveyPeriodIndex);
    };

    const handleYearLabelChange = (event: SelectChangeEvent) => {
        updateSurveyConfig({ ...surveyPeriodConfig, yearLabel: event.target.value }, surveyPeriodIndex);
    };

    useEffect(() => {
        let selectedDay = surveyPeriodConfig.day;
        let selectedMonth = surveyPeriodConfig.month;
        if (selectedDay && selectedMonth) {
            let dayNum = Number(selectedDay);
            let monthNum = months.findIndex(x => x === selectedMonth)!;
            let validDay = validateMonthYearCombination(monthNum, dayNum);
            if (dayNum !== validDay) {
                updateSurveyConfig({ ...surveyPeriodConfig, day: validDay.toString() }, surveyPeriodIndex);
            }
        }
    }, [surveyPeriodConfig, surveyPeriodIndex, updateSurveyConfig, validateMonthYearCombination, months]);

    useEffect(() => {
        let selectedDay = surveyPeriodConfig.periodStartDay;
        let selectedMonth = surveyPeriodConfig.periodStartMonth;
        if (selectedDay && selectedMonth) {
            let validDay = validateMonthYearCombination(selectedMonth, selectedDay);
            if (selectedDay !== validDay) {
                updateSurveyConfig({ ...surveyPeriodConfig, periodStartDay: validDay }, surveyPeriodIndex);
            }
        }
    }, [surveyPeriodConfig, surveyPeriodIndex, updateSurveyConfig, validateMonthYearCombination]);

    useEffect(() => {
        let selectedDay = surveyPeriodConfig.periodEndDay;
        let selectedMonth = surveyPeriodConfig.periodEndMonth;
        if (selectedDay && selectedMonth) {
            let validDay = validateMonthYearCombination(selectedMonth, selectedDay);
            if (selectedDay !== validDay) {
                updateSurveyConfig({ ...surveyPeriodConfig, periodEndDay: validDay }, surveyPeriodIndex);
            }
        }
    }, [surveyPeriodConfig, surveyPeriodIndex, updateSurveyConfig, validateMonthYearCombination]);

    const buildOccurrenceString = () => {
        const newSurveyPeriodIndex = surveyPeriodIndex;
        switch (newSurveyPeriodIndex) {
            case 0:
                return '1st Occurrence';
            case 1:
                return '2nd Occurrence';
            case 2:
                return '3rd Occurrence';
            case 3:
                return '4th Occurrence';
            default:
                return `${newSurveyPeriodIndex + 1}th Occurrence`;
        }
    };

    const sharedTableCellStyles: SxProps<Theme> = { border: 'none', pb: 1, pr: 1, background: '#eeeeee' };


    return (
        <TableRow>
            <TableCell sx={{ ...sharedTableCellStyles, background: 'none' }}>
                <Typography variant='body1'>{buildOccurrenceString()}</Typography>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles, pl: 2 }}>
                <Select value={surveyPeriodConfig.month} onChange={handleMonthChange} fullWidth sx={{ background: 'white' }}>
                    {months.map((month) => (
                        <MenuItem key={month} value={month}>
                            {month}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles, pr: 2 }}>
                <Select value={surveyPeriodConfig.day} onChange={handleDayChange} fullWidth sx={{ background: 'white' }}>
                    {days.map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell style={{ border: 'none' }} />
            <TableCell sx={{ ...sharedTableCellStyles, pl: 2 }}>
                <Select value={surveyPeriodConfig.periodStartMonth?.toString() ?? ''} onChange={handlePeriodStartMonthChange} fullWidth sx={{ background: 'white' }}>
                    <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                    {months.map((month, index) => (
                        <MenuItem key={month} value={index}>
                            {month}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles }}>
                <Select value={surveyPeriodConfig.periodStartDay?.toString().padStart(2, '0') ?? ''} onChange={handlePeriodStartDayChange} fullWidth sx={{ background: 'white' }}>
                    <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                    {days.map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles }}>
                <Select value={surveyPeriodConfig.periodEndMonth?.toString() ?? ''} onChange={handlePeriodEndMonthChange} fullWidth sx={{ background: 'white' }}>
                    <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                    {months.map((month, index) => (
                        <MenuItem key={month} value={index}>
                            {month}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles, pr: 2 }}>
                <Select value={surveyPeriodConfig.periodEndDay?.toString().padStart(2, '0') ?? ''} onChange={handlePeriodEndDayChange} fullWidth sx={{ background: 'white' }}>
                <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                    {days.map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell style={{ border: 'none' }} />
            <TableCell sx={{ ...sharedTableCellStyles, pl: 2 }}>
                <TextField value={surveyPeriodConfig.surveyLabelPrefix} onChange={handleSurveyLabelPrefixChange} inputProps={{ maxLength: 20 }} fullWidth sx={{ background: 'white' }}/>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles }}>
                <Select value={surveyPeriodConfig.yearLabel} onChange={handleYearLabelChange} fullWidth sx={{ background: 'white' }}>
                    <MenuItem value={'current-year'}>Current Year</MenuItem>
                    <MenuItem value={'previous-year'}>Previous Year</MenuItem>
                </Select>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles, pr: 2 }}>
                <TextField value={surveyPeriodConfig.surveyLabelSuffix} onChange={handleSurveyLabelSuffixChange} inputProps={{ maxLength: 20 }} fullWidth sx={{ background: 'white' }}/>
            </TableCell>
            <TableCell sx={{ ...sharedTableCellStyles, background: 'none', pl: 1, pr: 0}}>
                <Typography variant='body1'>
                    {surveyPeriodConfig.surveyLabelPrefix}
                    {surveyPeriodConfig.yearLabel === 'current-year' ? new Date().getFullYear().toString() : previousYear.getFullYear().toString()}
                    {surveyPeriodConfig.surveyLabelSuffix}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
