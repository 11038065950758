import { ActivityStreamDto } from "../../dtos/generated/ActivityStreamDto";
import { BaseMessageType, CompanyCrudMessage, RespondentCrudMessage, ScheduleDetailCrudMessage, EmailMessage, FallbackMessage } from "./messageTypes";

function _activityMessageFactory(streamEntry: ActivityStreamDto): BaseMessageType {
    switch (streamEntry.eventType) {
        case "COMPANY_ACTIVATED":
            return new CompanyCrudMessage(streamEntry, 'activated');
        case "COMPANY_CREATED":
            return new CompanyCrudMessage(streamEntry, 'created');
        case "COMPANY_INACTIVATED":
            return new CompanyCrudMessage(streamEntry, 'inactivated');
        case "COMPANY_UPDATED":
            return new CompanyCrudMessage(streamEntry, 'updated');
        case "RESPONDENT_ACTIVATED":
            return new RespondentCrudMessage(streamEntry, 'activated');
        case "RESPONDENT_CREATED":
            return new RespondentCrudMessage(streamEntry, 'created');
        case "RESPONDENT_INACTIVATED":
            return new RespondentCrudMessage(streamEntry, 'inactivated');
        case "RESPONDENT_UPDATED":
            return new RespondentCrudMessage(streamEntry, 'updated');
        case "SCHEDULE_DETAIL_ACTIVATED":
            return new ScheduleDetailCrudMessage(streamEntry, 'activated');
        case "SCHEDULE_DETAIL_COMPLETED":
            return new ScheduleDetailCrudMessage(streamEntry, 'completed');
        case "SCHEDULE_DETAIL_CREATED":
            return new ScheduleDetailCrudMessage(streamEntry, 'created');
        case "SCHEDULE_DETAIL_INACTIVATED":
            return new ScheduleDetailCrudMessage(streamEntry, 'inactivated');
        case "SCHEDULE_DETAIL_PAUSED":
            return new ScheduleDetailCrudMessage(streamEntry, 'paused');
        case "SCHEDULE_DETAIL_RESTARTED":
            return new ScheduleDetailCrudMessage(streamEntry, 'restarted');
        case "SCHEDULE_DETAIL_STARTED":
            return new ScheduleDetailCrudMessage(streamEntry, 'started');
        case "COMPLETE_EMAIL_FAILED_TO_SEND":
            return new EmailMessage(streamEntry, 'Completion email', 'failed to send');
        case "COMPLETE_EMAIL_SENT":
            return new EmailMessage(streamEntry, 'Completion email', 'sent');
        case "FIRST_TIME_EMAIL_OPENED":
            return new EmailMessage(streamEntry, 'First time email', 'opened');
        case "FIRST_TIME_EMAIL_SENT":
            return new EmailMessage(streamEntry, 'First time email', 'sent');
        case "MANUAL_REMINDER_EMAIL_SENT":
            return new EmailMessage(streamEntry, 'Manual reminder email', 'sent');
        case "REMINDER_EMAIL_OPENED":
            return new EmailMessage(streamEntry, 'Reminder email', 'opened');
        case "SCHEDULED_REMINDER_EMAIL_SENT":
            return new EmailMessage(streamEntry, 'Scheduled reminder email', 'sent');
        case "WELCOME_EMAIL_OPENED":
            return new EmailMessage(streamEntry, 'Welcome email', 'opened');
        case "WELCOME_EMAIL_SENT":
            return new EmailMessage(streamEntry, 'Welcome email', 'sent');
        default:
            return new FallbackMessage(streamEntry);
    }
}

export function generateEventActivityMessage(streamEntry: ActivityStreamDto) {
    let eventMessageBuilder = _activityMessageFactory(streamEntry);
    return eventMessageBuilder.getMessage()
}