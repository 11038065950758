import { serverApi } from '..';
import { ExportSurveyDto } from '../../dtos/generated/ExportSurveyDto';
import { CloudStorageLink } from '../../models/CloudStorageLink';

const surveyPdfApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getSurveyPdfLink: builder.query<CloudStorageLink, string>({
            query: (surveyPdfId) => ({ url: `surveyPdf/${surveyPdfId}/file` }),
            providesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        createAndUploadSurveyPdf: builder.mutation<void, ExportSurveyDto>({
            query: (createPdfDto) => ({
                url: `surveyPdf`,
                method: 'POST',
                body: createPdfDto,
            }),
            invalidatesTags: [{ type: 'ScheduleDetails' }, { type: 'Schedule' }],
        }),
        getSurveyCSV: builder.mutation<{ contents: string }, ExportSurveyDto>({
            query: (createPdfDto) => ({ url: `surveyPdf/csv`, method: 'POST', body: createPdfDto }),
        }),
        getBcmPdfLink: builder.query<CloudStorageLink, string>({
            query: (bcmId) => ({ url: `surveyPdf/bcm/${bcmId}/pdf` }),
        }),
        getBcmCsvLink: builder.query<CloudStorageLink, string>({
            query: (bcmId) => ({ url: `surveyPdf/bcm/${bcmId}/csv` }),
        }),
    }),
});

export const {
    useCreateAndUploadSurveyPdfMutation,
    useLazyGetSurveyPdfLinkQuery,
    useLazyGetBcmPdfLinkQuery,
    useLazyGetBcmCsvLinkQuery,
    useGetSurveyCSVMutation,
} = surveyPdfApi;
