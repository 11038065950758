import { MoreVert, ArrowDropDown } from '@mui/icons-material';
import { Grid, IconButton, Menu, MenuItem, Divider } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { ScheduleTableAction, ScheduleTableHandlers } from './utils';
import { ScheduleTableContext } from './ScheduleTableContext';

export interface IScheduleTableMenuProps {
    cant: (action: ScheduleTableAction) => boolean;
    handle: (action: ScheduleTableHandlers) => void | Promise<void>;
    reloadAfter: (action: () => Promise<void>) => Promise<void>;
    handleDelayChange: (delay: number) => Promise<void>;
    hidePauseResumeControls?: boolean;
}

export const ScheduleTableMenu: FC<IScheduleTableMenuProps> = ({ cant, handle, reloadAfter, handleDelayChange, hidePauseResumeControls }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [delaySubMenuAnchorEl, setDelaySubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [downloadSubMenuAnchorEl, setDownloadSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const { surveyAliasShort } = useContext(ScheduleTableContext);

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleDelaySubMenuOpen = useCallback((event: React.MouseEvent<HTMLLIElement>) => {
        setDelaySubMenuAnchorEl(event.currentTarget);
    }, []);
    
    const handleDownloadSubMenuOpen = useCallback((event: React.MouseEvent<HTMLLIElement>) => {
        setDownloadSubMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    const handleDelaySubMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setDelaySubMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    const handleDownloadSubMenuClose = useCallback(
        (afterClose?: () => void) => () => {
            setDownloadSubMenuAnchorEl(null);
            if (afterClose) {
                afterClose();
            }
        },
        []
    );

    return (
        <Grid item xs={'auto'}>
            <IconButton size='large' onClick={handleMenuOpen}>
                <MoreVert fontSize='inherit' />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={handleMenuClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem onClick={handleMenuClose(() => handle('CreateNew'))}>Add New</MenuItem>
                <MenuItem onClick={handleMenuClose(() => handle('View'))} disabled={cant('View')}>
                    View
                </MenuItem>
                <MenuItem onClick={handleMenuClose(() => handle('MenuEdit'))} disabled={cant('Edit')}>
                    Edit
                </MenuItem>
                <MenuItem onClick={handleMenuClose(() => handle('MenuDuplicate'))} disabled={cant('Duplicate')}>
                    Duplicate {surveyAliasShort}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleMenuClose(() => handle('SetApproved'))} disabled={cant('SetApproved')}>
                    Approve {surveyAliasShort}
                </MenuItem>
                <MenuItem onClick={handleMenuClose(() => handle('SetUnapproved'))} disabled={cant('SetUnapproved')}>
                    Unapprove {surveyAliasShort}
                </MenuItem>
                <MenuItem onClick={handleMenuClose(() => reloadAfter(async () => await handle('SendNow')))} disabled={cant('SendNow')}>
                    Send Now
                </MenuItem>
                {hidePauseResumeControls ||
                    <>
                        <MenuItem onClick={handleDelaySubMenuOpen} disabled={cant('SetDelay')}>
                            Delay {surveyAliasShort} <ArrowDropDown style={{ transform: 'rotate(-90deg)', marginLeft: 'auto' }} />
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose(() => reloadAfter(async () => await handle('PauseSurveys')))} disabled={cant('PauseSurveys')}>
                            Pause {surveyAliasShort}s
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose(() => reloadAfter(async () => await handle('RestartSurveys')))} disabled={cant('RestartSurveys')}>
                            Restart {surveyAliasShort}s
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose(() => reloadAfter(async () => await handle('ReopenSchedules')))} disabled={cant('ReopenSchedules')}>
                            Re-open {surveyAliasShort}s
                        </MenuItem>
                    </>
                }
                <Divider />
                <MenuItem disabled={cant('SendReminders')} onClick={handleMenuClose(() => handle('SendEmailReminder'))}>
                    Send Email Reminder
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleMenuClose(() => reloadAfter(async () => await handle('SetActive')))} disabled={cant('SetActive')}>
                    Make Active
                </MenuItem>
                <MenuItem onClick={handleMenuClose(() => reloadAfter(async () => await handle('SetInactive')))} disabled={cant('SetInactive')}>
                    Make Inactive
                </MenuItem>
                <MenuItem onClick={handleDownloadSubMenuOpen} disabled={cant('Export')}>
                    Export {surveyAliasShort}s <ArrowDropDown style={{ transform: 'rotate(-90deg)', marginLeft: 'auto' }} />
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={delaySubMenuAnchorEl}
                open={!!delaySubMenuAnchorEl}
                onClose={handleDelaySubMenuClose()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <MenuItem onClick={handleMenuClose(handleDelaySubMenuClose(() => reloadAfter(async () => await handleDelayChange(1))))} disabled={cant('SetDelay')}>
                    +1 Day
                </MenuItem>
                <MenuItem onClick={handleMenuClose(handleDelaySubMenuClose(() => reloadAfter(async () => await handleDelayChange(2))))} disabled={cant('SetDelay')}>
                    +2 Days
                </MenuItem>
                <MenuItem onClick={handleMenuClose(handleDelaySubMenuClose(() => reloadAfter(async () => await handleDelayChange(3))))} disabled={cant('SetDelay')}>
                    +3 Days
                </MenuItem>
                <MenuItem onClick={handleMenuClose(handleDelaySubMenuClose(() => reloadAfter(async () => await handleDelayChange(7))))} disabled={cant('SetDelay')}>
                    +1 Week
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={downloadSubMenuAnchorEl}
                open={!!downloadSubMenuAnchorEl}
                onClose={handleDownloadSubMenuClose()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <MenuItem onClick={handleMenuClose(handleDownloadSubMenuClose(() => handle('GetCSV')))} disabled={cant('Export')}>
                    Download CSV
                </MenuItem>
                <MenuItem onClick={handleMenuClose(handleDownloadSubMenuClose(() => handle('CreatePdf')))} disabled={cant('ExportPDF')}>
                    Email PDF
                </MenuItem>
            </Menu>
        </Grid>
    );
};
