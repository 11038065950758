import { Grid, Typography, useTheme } from '@mui/material';
import { Marked } from '@ts-stack/markdown';
import { FC } from 'react';
import { Section } from '../../../../models/survey';
import { convertToRomanNumeral } from '../../../../util';
import { textAlignCenterOnMobile } from '../util';

export interface IResponsiveSectionTitleProps {
    section: Section;
    depthLevel: number;
}

export const ResponsiveSectionTitle: FC<IResponsiveSectionTitleProps> = props => {
    const { section, depthLevel } = props;
    const { title, order, unordered } = section;
    const theme = useTheme();

    let titleElement;
    if (!unordered) {
        if (depthLevel === 0) {
            titleElement = (
                <Typography variant='h2' sx={{ fontSize: '20px', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#606162', padding: '4px 8px', ...textAlignCenterOnMobile }}>
                    {convertToRomanNumeral(order)}. {title}
                </Typography>
            );
        } else if (depthLevel === 1) {
            titleElement = (
                <Typography variant='h3' sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {String.fromCharCode(order + 64)}. {title}
                </Typography>
            );
        } else {
            // fallback in case style is unspecified
            titleElement = (
                <Typography variant='body1'>
                    <div dangerouslySetInnerHTML={{ __html: Marked.parse(title) }} />
                </Typography>
            );
        }
    } else {
        titleElement = (
            <Typography variant='body1'>
                <div dangerouslySetInnerHTML={{ __html: Marked.parse(title) }} />
            </Typography>
        );
    }
    return <Grid item xs={12} sm='auto' sx={{ [theme.breakpoints.down('sm')]: { marginY: 2}}}>{titleElement}</Grid>;
}