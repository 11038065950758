import { useMemo } from "react";
import { useSelectedScheduleFilters } from "./useSelectedScheduleFilters";
import { ScheduleDto } from "../../../../dtos";

export type ScheduleTableAction = 'SetDelay' | 'View' | 'Edit' | 'SetActive' | 'SendNow' | 'Duplicate' | 'Export' | 'ExportPDF' | 'PauseSurveys' | 'RestartSurveys' | 'ReopenSchedules' | 'SetInactive' | 'SetApproved' | 'SetUnapproved' | 'SendReminders'

export function useScheduleTableActionConditions(selectedSchedules: readonly ScheduleDto[]) {
    const {
        reminderSchedules,
        selectedItemsThatCanBeDelayed,
        selectedItemsThatCanBeEdited,
        selectedItemsThatCanBeScheduled,
        selectedItemsThatCanBeActivated
    } = useSelectedScheduleFilters(selectedSchedules);

    const areAllSelectedActiveOneTime = useMemo(() => {
        return selectedSchedules.every((selectedSchedule) => selectedSchedule.isActive && selectedSchedule.frequency === 'one-time');
    }, [selectedSchedules]);

    const isOnlyOneActiveRecurringSelected = useMemo(() => {
        return selectedSchedules.length === 1 && selectedSchedules[0].isActive && selectedSchedules[0].frequency !== 'one-time';
    }, [selectedSchedules]);

    const areAnySelectedActiveRecurringSelected = useMemo(() => {
        return selectedSchedules.some((selectedSchedule) => selectedSchedule.isActive && selectedSchedule.frequency !== 'one-time');
    }, [selectedSchedules]);

    const noExpiredSchedulesAreSelected = useMemo(() => {
        return selectedSchedules.every((selectedSchedule) => !selectedSchedule.isExpired)
    }, [selectedSchedules]);

    const canSetDelay = useMemo(() => {
        return selectedItemsThatCanBeDelayed.length > 0;
    }, [selectedItemsThatCanBeDelayed]);

    const canView = useMemo(() => {
        return selectedSchedules.length === 1;
    }, [selectedSchedules]);

    const canEdit = useMemo(() => {
        return selectedSchedules.length === 1 && selectedItemsThatCanBeEdited.length === 1;
    }, [selectedSchedules.length, selectedItemsThatCanBeEdited]);

    const canSetActive = useMemo(() => {
        return selectedItemsThatCanBeActivated.length > 0;
    }, [selectedItemsThatCanBeActivated]);
    
    const canSendNow = useMemo(() => {
        return selectedItemsThatCanBeScheduled.length > 0 && noExpiredSchedulesAreSelected;
    }, [noExpiredSchedulesAreSelected, selectedItemsThatCanBeScheduled]);

    const canDuplicate = useMemo(() => {
        return selectedSchedules.length === 1;
    }, [selectedSchedules.length]);

    const canExport = useMemo(() => {
        return selectedSchedules.length > 0;
    }, [selectedSchedules.length]);
    
    const canExportPDF = useMemo(() => {
        return selectedSchedules.length > 0 && selectedSchedules.some(schedule => schedule.completed > 0);
    }, [selectedSchedules]);

    const canPauseSurveys = useMemo(() => {
        return selectedSchedules.some((selectedSchedule) => selectedSchedule.isActive && !selectedSchedule.paused);
    }, [selectedSchedules]);

    const canRestartSurveys = useMemo(() => {
        return selectedSchedules.some((selectedSchedule) => selectedSchedule.isActive && selectedSchedule.paused);
    }, [selectedSchedules]);

    const canReopenSchedules = useMemo(() => {
        return selectedSchedules.some((selectedSchedule) => selectedSchedule.isActive && selectedSchedule.isExpired);
    }, [selectedSchedules]);

    const canSetInactive = useMemo(() => {
        if (areAllSelectedActiveOneTime) {
            return true;
        } else if (isOnlyOneActiveRecurringSelected) {
            return true;
        } else {
            return false;
        }
    }, [areAllSelectedActiveOneTime, isOnlyOneActiveRecurringSelected]);

    const canSetApproved = useMemo(() => {
        return (
            selectedSchedules.some((selectedSchedule) => selectedSchedule.approvedByUserId === '' || selectedSchedule.approvedByUserId === null) && noExpiredSchedulesAreSelected
        );
    }, [noExpiredSchedulesAreSelected, selectedSchedules]);

    const canSetUnapproved = useMemo(() => {
        return selectedSchedules.map((selectedSchedule) => selectedSchedule).some(x => x?.status.toLowerCase() === 'scheduled');
    }, [selectedSchedules]);

    const canSendReminders = useMemo(() => {
        return reminderSchedules.length > 0;
    }, [reminderSchedules]);

    const can = (action: ScheduleTableAction): boolean => {
        switch (action) {
            case 'SetDelay':
                return canSetDelay;
            case 'View':
                return canView;
            case 'Edit':
                return canEdit;
            case 'SetActive':
                return canSetActive;
            case 'SendNow':
                return canSendNow;
            case 'Duplicate':
                return canDuplicate;
            case 'Export':
                return canExport;
            case 'ExportPDF':
                return canExportPDF;
            case 'PauseSurveys':
                return canPauseSurveys;
            case 'RestartSurveys':
                return canRestartSurveys;
            case 'ReopenSchedules':
                return canReopenSchedules;
            case 'SetInactive':
                return canSetInactive;
            case 'SetApproved':
                return canSetApproved;
            case 'SetUnapproved':
                return canSetUnapproved;
            case 'SendReminders':
                return canSendReminders;
        
            default:
                return false
        }
    }

    const cant = (action: ScheduleTableAction): boolean => {
        return !can(action);
    }

    return {
        can,
        cant,
        // TODO: I am returning these for now because I don't want to edit the original component too much. However, this should be put into a method just like the others that states what these values are determining the user can or cannot do.
        isOnlyOneActiveRecurringSelected,
        areAnySelectedActiveRecurringSelected
    }
}