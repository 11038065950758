import { AddCircle, Ballot, MoreVert } from '@mui/icons-material';
import { Grid, IconButton, Menu, MenuItem, Paper, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleDetailDto, ScheduleStatus } from '../../../../../dtos';
import { useGetRespondentSurveysQuery } from '../../../../../store';
import { DataTableColumn, PaginatedDataTable, PaginatedProps, StandardSearchInput, useFailedActionSnackbar } from '../../../../CoreLib/library';
import { ISurveysTableViewProps } from './types';

export const SentSurveysTable: FC<ISurveysTableViewProps> = (props) => {
    const { companyId, respondentId } = props;
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [selectedScheduleDetails, setSelectedScheduleDetails] = useState<readonly ScheduleDetailDto[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const { data, isError, isLoading } = useGetRespondentSurveysQuery({
        parentId: companyId,
        childId: respondentId,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: true,
        },
    });

    useFailedActionSnackbar('retrieve', 'respondent surveys', isError);

    const mapSurveyLink = (row: ScheduleDetailDto) => {
        const handleSurveyClick = () => {
            navigate(`/survey/schedule/${row.schedule.id}/detail/${row.id}`);
        };
        return (
            <Typography fontSize='13px' onClick={handleSurveyClick} sx={{ color: '#DF623B', cursor: 'pointer' }}>
                View
            </Typography>
        );
    };

    const mapTimeColumn = (row: ScheduleDetailDto) => {
        if (row.updatedOn) {
            let updatedDate = new Date(row.updatedOn);
            let timeString = updatedDate.toLocaleString('en-us');
            return timeString;
        }
    };

    const mapStatus = (row: ScheduleDetailDto) => {
        if (!row.isActive) {
            return 'Inactive';
        } else if (row.isPaused) {
            return 'Paused';
        } else {
            return ScheduleStatus[row.status]?.toString();
        }
    };

    const tableColumns: DataTableColumn<ScheduleDetailDto>[] = [
        { key: 'createdOn', label: 'Date', sortKey: 'CREATED_ON', fieldMapper: mapTimeColumn },
        { key: 'formType', label: 'Form Type', sortKey: 'FORM_TYPE', fieldMapper: (scheduleDetail) => scheduleDetail.schedule?.formType },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: mapStatus },
        { key: 'survey', label: 'Survey', sortKey: 'SURVEY', fieldMapper: mapSurveyLink, unsorted: true },
    ];

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        navigate(`/companies/${companyId}/survey`);
    };

    const handleSearchChange = (text: string) => {
        setSelectedScheduleDetails([]);
        setSearchText(text);
    };

    return (
        <Paper sx={{ padding: 3 }}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                <Grid container item direction='row' xs={6}>
                    <Grid item>
                        <Ballot fontSize='medium' />
                    </Grid>
                    <Grid item display='flex' ml={2}>
                        <Typography variant='h3' fontWeight={'bold'}>
                            Surveys
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={6} justifyContent={'end'} spacing={4}>
                    <Grid item xs={1}>
                        <Tooltip title='Add New'>
                            <span>
                                <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                    <AddCircle fontSize='inherit' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton size='large' onClick={handleMenuOpen}>
                            <MoreVert fontSize='inherit' />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item>
                        <StandardSearchInput searchText={searchText} handleSearchChange={handleSearchChange} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
            <PaginatedDataTable
                columns={tableColumns}
                loading={isLoading}
                queryResults={data}
                defaultSortKey='CREATED_ON'
                setPagination={setPaginatedProps}
                selectedRecords={selectedScheduleDetails}
                setSelectedRecords={setSelectedScheduleDetails as any}
            />
        </Paper>
    );
};
