import { Box, FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useContext, useState} from 'react';
import { EmailTemplateDto, SurveyPayloadEmailConfigDto } from '../../../../dtos';
import { SurveyBuilderContext } from '../../utils';

export interface IEmailEmailTemplateCardProps {
    emailTemplates: EmailTemplateDto[];
}

export const EmailEmailTemplateCard: FC<IEmailEmailTemplateCardProps> = (props) => {
    const { emailTemplates } = props;
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const [fieldErrors] = useState({
        'CONTACT_NAME': '',
        'CONTACT_TITLE': ''
    });

    const updateEmailConfig = (emailConfig: SurveyPayloadEmailConfigDto) => {
        const newEmailConfig = emailConfig;
        setSurveyPayload({
            ...surveyPayload,
            emailConfig: newEmailConfig,
        });
    };

    const handleCompanyContactInfoChange = (newValue: string, nameOrTitle: string) => {
        switch(nameOrTitle){
            case 'name':
                surveyPayload.emailConfig.companyContactName = newValue;
                updateEmailConfig(surveyPayload.emailConfig);
                break;
            case 'title':
                surveyPayload.emailConfig.companyContactTitle = newValue;
                updateEmailConfig(surveyPayload.emailConfig);
                break;
        }
    }

    const addEmailTemplateValue = (newEmailTemplate: string) => {
        surveyPayload.emailConfig.emailTemplate = newEmailTemplate;
        updateEmailConfig(surveyPayload.emailConfig);
    }

    const handleCompanyContactNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleCompanyContactInfoChange(event.target.value, 'name');
    }

    const handleCompanyContactTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleCompanyContactInfoChange(event.target.value, 'title');
    }

    const handleEmailTemplateChange = (event: SelectChangeEvent) => {
        addEmailTemplateValue(event.target.value);
        handleCompanyContactInfoChange("", 'title');
        handleCompanyContactInfoChange("", 'name');
    }

    const handleNoCompanyContactInfo = () => {
        if (surveyPayload.emailConfig.emailTemplate === emailTemplates.find(template => template.name === "Client Contact Email")?.id) {
            if (surveyPayload.emailConfig.companyContactName === "" && surveyPayload.emailConfig.companyContactTitle === ""){
                return "Company contact name and company contact title are required for this template. "
            }
            else if (surveyPayload.emailConfig.companyContactName === ""){
                return "Company contact name is required. "
            }
            else if (surveyPayload.emailConfig.companyContactTitle === ""){
                return "Company contact title is required. "
            }
        }
    }
    return(
        <Box sx={{
            backgroundColor: 'primary.contrastText',
            py:4,
            px: 4
        }}>
            <Grid item container direction="row" alignItems="start">
                <Grid item container direction="column" justifyContent="start" xs={'auto'}>
                    <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                        Which Email Template do you want to use?
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item container direction="row" spacing={4}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel>Email Template</FormLabel>
                            <Select
                                value={surveyPayload.emailConfig.emailTemplate}
                                onChange={handleEmailTemplateChange}
                            >
                                {emailTemplates?.map(emailTemplate => (
                                    <MenuItem key={emailTemplate.id} value={emailTemplate.id}>{emailTemplate.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {surveyPayload.emailConfig.emailTemplate === emailTemplates.find(template => template.name === "Client Contact Email")?.id ? <>
                        <Grid item container direction="row" alignItems="center" justifyContent="center" xs={'auto'} sx={{marginLeft: 'auto'}}>
                            <Typography variant='body1'>
                                Who should be listed as the company contact?
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControl error={fieldErrors.CONTACT_NAME !== ''} fullWidth>
                                <FormLabel>Company Contact Name</FormLabel>
                                <OutlinedInput value={surveyPayload.emailConfig.companyContactName} disabled={surveyPayload.emailConfig.emailTemplate !== emailTemplates.find(template => template.name === "Client Contact Email")?.id} onChange={handleCompanyContactNameChange} />
                                <FormHelperText>{fieldErrors.CONTACT_NAME}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControl error={fieldErrors.CONTACT_TITLE !== ''} fullWidth>
                                <FormLabel>Company Contact Title</FormLabel>
                                <OutlinedInput value={surveyPayload.emailConfig.companyContactTitle} disabled={surveyPayload.emailConfig.emailTemplate !== emailTemplates.find(template => template.name === "Client Contact Email")?.id} onChange={handleCompanyContactTitleChange} />
                                <FormHelperText>{fieldErrors.CONTACT_TITLE}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </> : <></>}
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item container direction="row" justifyContent="flex-end" xs={"auto"}>
                    <Typography variant="body1" sx={{ pt: 2, color: '#DF623B'}}>
                      {handleNoCompanyContactInfo()}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}