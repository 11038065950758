import { Box, Stack } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RespondentDto } from '../../../dtos';
import { useGetCompanyDepartmentsQuery, useGetCompanyManagersQuery, useGetCompanyRespondentsQuery, useGetFormTypesQuery } from '../../../store';
import { LoadingIndicator, PaginatedProps, useFailedActionSnackbar } from '../../CoreLib/library';
import { SurveyBuilderContext } from '../utils/SurveyBuilderContext';
import { DistributionCCBCCRecipients, DistributionCCBCCTable, RespondentSearch, SurveyDistributionRespondents } from './SubComponents';

export const StandardDistributionContent: FC = () => {
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const { id: companyId } = useParams();
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [searchParams, setSearchParams] = useState('');
    const { data: departments, isLoading: departmentsLoading, isError: isDepartmentsError } = useGetCompanyDepartmentsQuery(companyId!);
    const { data: managers, isLoading: managersLoading, isError: isManagersError } = useGetCompanyManagersQuery(companyId!);
    const { data: formTypes, isLoading: formTypesLoading, isError: isFormTypesError } = useGetFormTypesQuery();
    const { data: stepThreeRespondentData, isLoading, isError: isCompanyRespondentsError } = useGetCompanyRespondentsQuery({
        parentId: companyId!,
        searchText: searchParams,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize
    });

    useFailedActionSnackbar('retrieve', 'departments', isDepartmentsError);
    useFailedActionSnackbar('retrieve', 'managers', isManagersError);
    useFailedActionSnackbar('retrieve', 'surveys', isFormTypesError);
    useFailedActionSnackbar('retrieve', 'respondents', isCompanyRespondentsError);

    const setSurveyDistribution = (distributionChoice: string) => {
        const newChoice = distributionChoice;
        setSurveyPayload({
            ...surveyPayload,
            distributionConfig: {
                ...surveyPayload.distributionConfig,
                surveyDistribution: newChoice,
            },
        });
    };

    const setFillOutSurveyValue = (SurveyValue: string) => {
        const newSurveyValue = SurveyValue;
        setSurveyPayload({
            ...surveyPayload,
            distributionConfig: {
                ...surveyPayload.distributionConfig,
                fillOutSurveyValue: newSurveyValue,
            },
        });
    };

    const setGroupName = (groupName: string) => {
        const newGroupName = groupName;
        setSurveyPayload({
            ...surveyPayload,
            distributionConfig: {
                ...surveyPayload.distributionConfig,
                groupName: newGroupName,
            },
        });
    };

    const addToDistributionConfig = (arrayName: string, respondent: RespondentDto) => {
        switch (arrayName) {
            case 'individualRecipient':
                const newArray = [...surveyPayload.distributionConfig.individualRecipient, respondent];
                setSurveyPayload({
                    ...surveyPayload,
                    distributionConfig: {
                        ...surveyPayload.distributionConfig,
                        individualRecipient: newArray,
                    },
                });
                break;
            case 'CCRecipient':
                if (!surveyPayload.distributionConfig.CCRecipients.some((CCRecipients: { id: string }) => CCRecipients.id === respondent.id)) {
                    const newCCArray = [...surveyPayload.distributionConfig.CCRecipients, respondent];
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            CCRecipients: newCCArray,
                        },
                    });
                }
                break;
            case 'BCCRecipient':
                if (!surveyPayload.distributionConfig.BCCRecipients.some((BCCRecipients: { id: string }) => BCCRecipients.id === respondent.id)) {
                    const newBCCArray = [...surveyPayload.distributionConfig.BCCRecipients, respondent];
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            BCCRecipients: newBCCArray,
                        },
                    });
                }
                break;
        }
    };

    const addMultipleDistributionConfig = (arrayName: string, respondents: RespondentDto[]) => {
        switch (arrayName) {
            case 'individualRecipient':
                let localArray = [...surveyPayload.distributionConfig.individualRecipient];
                if (surveyPayload.distributionConfig.individualRecipient.length === 0) {
                    respondents.forEach((respondent) => {
                        if (!localArray.some((localArray) => localArray.id === respondent.id)) {
                            localArray = [...localArray, respondent];
                        }
                    });
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            individualRecipient: localArray,
                        },
                    });

                    break;
                }
                break;
            case 'CCRecipient':
                let localCCArray = [...surveyPayload.distributionConfig.CCRecipients];
                respondents.forEach((respondent) => {
                    if (!localCCArray.some((localCCArray) => localCCArray.id === respondent.id)) {
                        localCCArray = [...localCCArray, respondent];
                    }
                });
                setSurveyPayload({
                    ...surveyPayload,
                    distributionConfig: {
                        ...surveyPayload.distributionConfig,
                        CCRecipients: localCCArray,
                    },
                });
                break;
            case 'BCCRecipient':
                let localBCCArray = [...surveyPayload.distributionConfig.BCCRecipients];
                respondents.forEach((respondent) => {
                    if (!localBCCArray.some((localBCCArray) => localBCCArray.id === respondent.id)) {
                        localBCCArray = [...localBCCArray, respondent];
                    }
                });
                setSurveyPayload({
                    ...surveyPayload,
                    distributionConfig: {
                        ...surveyPayload.distributionConfig,
                        BCCRecipients: localBCCArray,
                    },
                });
                break;
        }
    };

    const deleteDistributionConfig = (arrayName: string, respondent?: RespondentDto) => {
        if (respondent) {
            switch (arrayName) {
                case 'individualRecipient':
                    const newArray = surveyPayload.distributionConfig.individualRecipient.filter(
                        (respondents: { id: string }) => respondents.id !== respondent.id
                    );
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            individualRecipient: newArray,
                        },
                    });
                    break;
                case 'CCRecipient':
                    const newCCArray = surveyPayload.distributionConfig.CCRecipients.filter(
                        (CCRecipients: { id: string }) => CCRecipients.id !== respondent.id
                    );
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            CCRecipients: newCCArray,
                        },
                    });
                    break;
                case 'BCCRecipient':
                    const newBCCArray = surveyPayload.distributionConfig.BCCRecipients.filter(
                        (BCCRecipients: { id: string }) => BCCRecipients.id !== respondent.id
                    );
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            BCCRecipients: newBCCArray,
                        },
                    });
                    break;
            }
        } else {
            switch (arrayName) {
                case 'individualRecipient':
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            individualRecipient: [],
                        },
                    });
                    break;
                case 'CCRecipient':
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            CCRecipients: [],
                        },
                    });
                    break;
                case 'BCCRecipient':
                    setSurveyPayload({
                        ...surveyPayload,
                        distributionConfig: {
                            ...surveyPayload.distributionConfig,
                            BCCRecipients: [],
                        },
                    });
                    break;
            }
        }
    };

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <SurveyDistributionRespondents
                addToDistributionConfig={addToDistributionConfig}
                deleteDistributionConfig={deleteDistributionConfig}
                setSurveyDistribution={setSurveyDistribution}
                setFillOutSurveyValue={setFillOutSurveyValue}
                setGroupName={setGroupName}
            />
            <DistributionCCBCCRecipients
                addToDistributionConfig={addToDistributionConfig}
                deleteDistributionConfig={deleteDistributionConfig}
            />
            <Box sx={{
                backgroundColor: 'primary.contrastText',
                py: 4,
                px: 4
            }}>
                {!departmentsLoading && !managersLoading && !formTypesLoading ? (
                    <RespondentSearch setSearchParams={setSearchParams} departments={departments!} managers={managers!} formTypes={formTypes!} />)
                    : <LoadingIndicator />}
            </Box>
            <DistributionCCBCCTable stepThreeRespondentData={stepThreeRespondentData!} isLoading={isLoading} paginatedProps={paginatedProps} setPaginatedProps={setPaginatedProps} addMultipleDistributionConfig={addMultipleDistributionConfig} />
        </Stack >
    );
}