import { GridOn } from '@mui/icons-material';
import { FC } from 'react';
import { ISurveyStepperStepConfiguration, SurveyStepper } from '../Components/surveyStepper/SurveyStepper';
import {
    BusinessComponentSummaryStepContent,
    DataStepContents,
    EmailStepContent,
    IssueDateStepContent,
    BCMSettingsStepContents,
    SingleDistributionContent,
    TimePeriodContent,
} from '../Components/surveyStepper/steps';
import { SurveyPayloadDto } from '../dtos';
import { BCM_ID, DEFAULT_SURVEY_PAYLOAD } from './types';

const DEFAULT_BUSINESS_COMPONENT_SCHEDULE_PAYLOAD: SurveyPayloadDto = {
    ...DEFAULT_SURVEY_PAYLOAD,
    selectedFormType: {
        ...DEFAULT_SURVEY_PAYLOAD.selectedFormType,
        id: BCM_ID,
        name: 'BCM',
    },
    distributionConfig: {
        ...DEFAULT_SURVEY_PAYLOAD.distributionConfig,
        surveyDistribution: 'individualRecipient',
        fillOutSurveyValue: 'Individual'
    },
    frequencyConfig: {
        ...DEFAULT_SURVEY_PAYLOAD.frequencyConfig,
        radioStartDate: 'future'
    }
};

export const BusinessComponentStepperView: FC = () => {
    const businessMatrixSteps: ISurveyStepperStepConfiguration[] = [
        {
            label: 'Settings',
            renderer: ({ company }) => <BCMSettingsStepContents company={company} />,
            validationMethod: (validator) => validator.isSettingsStepValid,
        },
        {
            label: 'Time Period',
            renderer: () => <TimePeriodContent allowMultipleTimePeriods={true} isBCM={true} />,
            validationMethod: (validator) => validator.isTimePeriodStepValid,
        },
        {
            label: 'Data',
            renderer: ({ company }) => <DataStepContents company={company} />,
            validationMethod: (validator) => validator.isDataStepValid,
        },
        {
            label: 'Distribution',
            renderer: () => <SingleDistributionContent />,
            validationMethod: (validator) => validator.isDistributionStepValid,
        },
        {
            label: 'Issue Date',
            renderer: () => <IssueDateStepContent />,
            validationMethod: (validator) => validator.isFrequencyStepValid,
        },
        {
            label: 'Email',
            renderer: ({ emailTemplates }) => <EmailStepContent emailTemplates={emailTemplates} />,
            validationMethod: (validator) => validator.isEmailStepValid,
        },
        {
            label: 'Summary',
            renderer: ({ emailTemplates }) => <BusinessComponentSummaryStepContent emailTemplates={emailTemplates} />,
            validationMethod: (validator) => validator.isSummaryStepValid,
        },
    ];

    return (
        <SurveyStepper
            steps={businessMatrixSteps}
            stepperIcon={<GridOn />}
            surveyAlias='Business Component Matrix'
            surveyAliasShort='Matrix'
            detailsRoute='business-component'
            initialSurveyPayload={DEFAULT_BUSINESS_COMPONENT_SCHEDULE_PAYLOAD}
            initialBCMInitializationType='import'
        />
    );
};
