import { FC } from 'react';
import theme from '../../../../../../Theme';
import { Box, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { onlyShowOnDesktop, onlyShowOnMobile } from '../../../util';
import { Field, FieldGroup, Question } from '../../../../../../models/survey';
import { SurveyInputField } from './SurveyInputField';
import { SurveyFormErrorMessage } from '../../../../types';

export interface ICenteredQuestionInputFieldsProps {
    question: Question;
    isFirstQuestionOfSection: boolean;
    fieldGroupIndex?: number;
    timePeriodIndex?: number;
    getQuestionAnswer: (fieldName: string) => string | number;
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
    errorMessages: SurveyFormErrorMessage[];
    setErrorMessages: (updatedErrors: SurveyFormErrorMessage[]) => void;
    isPdfView?: boolean;
    isLoading?: boolean;
    recipientName: string;
}

export const CenteredQuestionInputFields: FC<ICenteredQuestionInputFieldsProps> = ({
    question,
    isFirstQuestionOfSection,
    fieldGroupIndex,
    timePeriodIndex,
    getQuestionAnswer,
    setQuestionAnswer,
    errorMessages,
    setErrorMessages,
    isPdfView,
    isLoading,
    recipientName,
}) => {
    const handleFieldError = (fields: Field[]) => {
        let fieldNames = fields.map((field) => field.fieldName);
        let groupErrorMessages = errorMessages.filter((errorMessage) => fieldNames.includes(errorMessage.identifier));
        let errorMessageSet = new Set(groupErrorMessages.map((error) => error.message));
        return Array.from(errorMessageSet).join(', ');
    };

    const handleFieldGroupError = (fieldGroups: FieldGroup[]) => {
        let fieldGroupErrors: string[] = [];
        fieldGroups.forEach((fieldGroup) => {
            let fieldErrors = handleFieldError(fieldGroup.fields);
            fieldGroupErrors = fieldGroupErrors.concat(fieldErrors);
        });
        let errorMessageSet = new Set(fieldGroupErrors);
        return Array.from(errorMessageSet).join(' ');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                [theme.breakpoints.up('sm')]: {
                    justifyContent: 'end',
                },
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                },
            }}>
            {question.fieldGroups.length === 1 || fieldGroupIndex !== undefined || timePeriodIndex !== undefined ? (
                question.fieldGroups
                    .filter((_, index) => fieldGroupIndex! === index || fieldGroupIndex === undefined)
                    .filter((fieldGroup) => fieldGroup.centerOnQuestion)
                    .map((fieldGroup, i) => (
                        <table key={`table-${i}`}>
                            <thead>
                                <TableRow>
                                    {(fieldGroup.fieldGroupLabel !== recipientName || isPdfView) && (
                                        <TableCell className='standard-input-cell' sx={{ border: 'none', ...onlyShowOnDesktop }}></TableCell>
                                    )}
                                    {fieldGroup.fields
                                        .filter((_, index) => timePeriodIndex === index || timePeriodIndex === undefined)
                                        .map((field) => (
                                            <TableCell key={field.fieldName} className='standard-input-cell' sx={{ border: 'none' }}>
                                                {fieldGroup.fields.length > 1 ? (
                                                    field.fieldLabel || ''
                                                ) : (
                                                    <Box sx={onlyShowOnMobile}>{fieldGroup.fieldGroupLabel}</Box>
                                                )}
                                            </TableCell>
                                        ))}
                                    <TableCell sx={{ border: 'none', ...onlyShowOnDesktop }}></TableCell>
                                </TableRow>
                            </thead>
                            <tbody>
                                <tr>
                                    {(fieldGroup.fieldGroupLabel !== recipientName || isPdfView) && (
                                        <TableCell sx={{ textAlign: 'right', border: 'none', ...onlyShowOnDesktop }}>{fieldGroup.fieldGroupLabel}</TableCell>
                                    )}
                                    {fieldGroup.fields
                                        .filter((_, index) => timePeriodIndex === index || timePeriodIndex === undefined)
                                        .map((field, i) => (
                                            <SurveyInputField
                                                field={field}
                                                isLastField={i === fieldGroup.fields.length - 1}
                                                getQuestionAnswer={getQuestionAnswer}
                                                setQuestionAnswer={setQuestionAnswer}
                                                errorMessages={errorMessages}
                                                setErrorMessages={setErrorMessages}
                                                isPdfView={isPdfView}
                                                isLoading={isLoading}
                                            />
                                        ))}
                                </tr>
                                <tr>
                                    <td colSpan={100}>
                                        <Typography key={`table-typography-${i}`} color='primary'>
                                            {handleFieldError(fieldGroup.fields)}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ))
            ) : (
                <table>
                    <TableHead
                        sx={{
                            display: isFirstQuestionOfSection ? 'table-header-group' : 'none',
                            [theme.breakpoints.down('sm')]: { display: 'table-header-group' },
                        }}>
                        <tr>
                            {question.fieldGroups
                                .filter((fieldGroup) => fieldGroup.centerOnQuestion)
                                .map((fieldGroup, i) => (
                                    <th key={`${fieldGroup.fieldGroupLabel}-${i}`} className='standard-input-cell'>
                                        {fieldGroup.fieldGroupLabel || ''}
                                    </th>
                                ))}
                            <th></th>
                        </tr>
                    </TableHead>
                    <tbody>
                        <tr>
                            {question.fieldGroups
                                .filter((fieldGroup) => fieldGroup.centerOnQuestion)
                                .flatMap((fieldGroup) => fieldGroup.fields)
                                .map((field, idx, allFields) => (
                                    <SurveyInputField
                                        field={field}
                                        isLastField={idx === allFields.length - 1}
                                        getQuestionAnswer={getQuestionAnswer}
                                        setQuestionAnswer={setQuestionAnswer}
                                        errorMessages={errorMessages}
                                        setErrorMessages={setErrorMessages}
                                        isPdfView={isPdfView}
                                        isLoading={isLoading}
                                    />
                                ))}
                        </tr>
                        <tr>
                            <td colSpan={100}>
                                <Typography color='primary'>{handleFieldGroupError(question.fieldGroups)}</Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </Box>
    );
};
