import { Box, Tab, Tabs } from '@mui/material';
import { FC, useContext } from 'react';
import { CompanyDashboardTabContentViewer } from './CompanyDashboardTabContentViewer';
import { CompanyDashboardContext } from '../../Utils';

export const CompanyDashboardTabsContainer: FC = () => {
    const { selectedTab, setSelectedTab } = useContext(CompanyDashboardContext);

    return (
        <Box>
            <Tabs
                variant='fullWidth'
                value={selectedTab}
                onChange={(_, newTabValue) => setSelectedTab(newTabValue)}>
                <Tab label='OVERVIEW' />
                <Tab label='SURVEYS' />
                <Tab label='BCM' />
                <Tab label='COMPONENTS'/>
                <Tab label='RESPONDENTS' />
                <Tab label='DEPARTMENTS' />
                <Tab label='STREAM' />
            </Tabs>
            <CompanyDashboardTabContentViewer />
        </Box>
    )
};