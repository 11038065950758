import { Button, FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { Search } from '@mui/icons-material';
import { IRespondentSearchProps } from '../../types';

export const RespondentSearch: FC<IRespondentSearchProps> = (props) => {
    const { setSearchParams, departments, managers, formTypes, defaultFormTypeId } = props;
    const [localSearchParams, setLocalSearchParams] = useState({
        firstName: '',
        lastName: '',
        title: '',
        department: '',
        manager: '',
        formType: defaultFormTypeId ?? ''
    });

    const search = () => {
        const searchString = `FirstName:${localSearchParams.firstName}|LastName:${localSearchParams.lastName}|Title:${localSearchParams.title}|Department:${localSearchParams.department}|Manager:${localSearchParams.manager}|FormType:${localSearchParams.formType}`
        setSearchParams(searchString);
    }

    const [fieldErrors] = useState({
        'FIRST_NAME': '',
        'LAST_NAME': ''
    });

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLocalSearchParams({
            ...localSearchParams,
            firstName: event.target.value
        });
    }

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLocalSearchParams({
            ...localSearchParams,
            lastName: event.target.value
        });
    }

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLocalSearchParams({
            ...localSearchParams,
            title: event.target.value
        });
    }

    const handleDepartmentChange = (event: SelectChangeEvent) => {
        setLocalSearchParams({
            ...localSearchParams,
            department: event.target.value
        });
    }

    const handleManagerChange = (event: SelectChangeEvent) => {
        setLocalSearchParams({
            ...localSearchParams,
            manager: event.target.value
        });
    }

    const handleFormTypeChange = (event: SelectChangeEvent) => {
        setLocalSearchParams({
            ...localSearchParams,
            formType: event.target.value
        });
    }


    const handleSearch = (event: any) => {
        event.preventDefault();
        search();
    }

    return (
        <>
            <Grid component="form" container direction="column" spacing={3} autoComplete="off" onSubmit={handleSearch}>
                <Grid item container direction="column" justifyContent="start" xs={6}>
                    <Typography variant="h1" >
                        <Search /> Search Respondents
                    </Typography>
                </Grid>
                <Grid item container direction="column" spacing={2}>
                    <Grid item container direction="row" spacing={6}>
                        <Grid item xs={3}>
                            <FormControl error={fieldErrors.FIRST_NAME !== ''} fullWidth>
                                <FormLabel>First Name</FormLabel>
                                <OutlinedInput value={localSearchParams.firstName} onChange={handleFirstNameChange} />
                                <FormHelperText>{fieldErrors.FIRST_NAME}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl error={fieldErrors.LAST_NAME !== ''} fullWidth>
                                <FormLabel>Last Name</FormLabel>
                                <OutlinedInput value={localSearchParams.lastName} onChange={handleLastNameChange} />
                                <FormHelperText>{fieldErrors.LAST_NAME}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel>Title</FormLabel>
                                <OutlinedInput value={localSearchParams.title} onChange={handleTitleChange} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel>Department</FormLabel>
                                <Select
                                    value={localSearchParams.department}
                                    onChange={handleDepartmentChange}
                                >
                                    <MenuItem key="" value="">None</MenuItem>
                                    {departments?.map(department => (
                                        <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={6}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel>Manager</FormLabel>
                                <Select
                                    value={localSearchParams.manager}
                                    onChange={handleManagerChange}
                                >
                                    <MenuItem key="" value="">None</MenuItem>
                                    {managers?.map(manager => (
                                        <MenuItem key={manager.id} value={manager.id}>{manager.firstName} {manager.lastName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel>Form Type</FormLabel>
                                <Select
                                    value={localSearchParams.formType}
                                    onChange={handleFormTypeChange}
                                >
                                    <MenuItem key="" value="">None</MenuItem>
                                    {formTypes?.map(formType => (
                                        <MenuItem key={formType.id} value={formType.id}>{formType.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item container xs={3} justifyContent="flex-end" alignItems="flex-end">
                            <FormControl>
                                <Button
                                    variant="contained"
                                    onClick={handleSearch}
                                    type='submit'
                                >
                                    Search
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}