import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import {
    IFormFieldValidationConfig,
    IFormProps,
    isNotBlank,
    isShorterThanMaxLength,
    isValidEmail,
    runFieldValidation,
    runFormValidation,
} from '../../../Components/CoreLib/library';
import { emptyGuid } from '../../../models';
import { UserDto } from '../../../dtos';

export interface IUserFormValues {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    phoneNumber: string;
    isActive: boolean;
    title: string;
}

const DEFAULT_USER: UserDto = {
    id: emptyGuid,
    auth0UserId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    isActive: true,
    createdOn: new Date(),
    displayName: '',
    title: '',
};

export function useUserForm(props: IFormProps<UserDto>) {
    const { save, cancel, initValues } = props;
    const [formFirstName, setFormFirstName] = useState('');
    const [formLastName, setFormLastName] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formPhoneNumber, setFormPhoneNumber] = useState('');
    const [formRole, setFormRole] = useState('');
    const [formIsActive, setFormIsActive] = useState(true);
    const [formTitle, setFormTitle] = useState('');

    useEffect(() => {
        setFormFirstName(initValues?.firstName ?? '');
        setFormLastName(initValues?.lastName ?? '');
        setFormEmail(initValues?.email ?? '');
        setFormPhoneNumber(initValues?.phoneNumber ?? '');
        setFormRole(initValues?.role ?? '');
        setFormIsActive(initValues?.isActive ?? true);
        setFormTitle(initValues?.title ?? '');
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof UserDto, string>>(
        new Map([
            ['firstName', ''],
            ['lastName', ''],
            ['email', ''],
            ['phoneNumber', ''],
            ['role', ''],
            ['title', ''],
        ])
    );

    const formFieldValidators = new Map<keyof UserDto, IFormFieldValidationConfig>([
        [
            'firstName',
            {
                validators: [isNotBlank, isShorterThanMaxLength(30)],
                errorMessageEntityName: 'First Name',
            },
        ],
        [
            'lastName',
            {
                validators: [isNotBlank, isShorterThanMaxLength(30)],
                errorMessageEntityName: 'Last Name',
            },
        ],
        [
            'email',
            {
                validators: [isNotBlank, isShorterThanMaxLength(50), isValidEmail],
                errorMessageEntityName: 'Email',
            },
        ],
        [
            'phoneNumber',
            {
                validators: [isShorterThanMaxLength(20)],
                errorMessageEntityName: 'Phone Number',
            },
        ],
        [
            'role',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Role',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<UserDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IUserFormValues => {
        return {
            firstName: formFirstName,
            lastName: formLastName,
            email: formEmail,
            phoneNumber: formPhoneNumber,
            role: formRole,
            isActive: formIsActive,
            title: formTitle,
        };
    };

    const validateField = (fieldName: keyof IUserFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        let isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formFirstName !== initValues?.firstName;
        isDirty = isDirty || formLastName !== initValues?.lastName;
        isDirty = isDirty || formRole !== initValues?.role;
        isDirty = isDirty || formEmail !== initValues?.email;
        isDirty = isDirty || formPhoneNumber !== initValues?.phoneNumber;
        isDirty = isDirty || formTitle !== initValues?.title;
        return isDirty;
    };

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormFirstName(event.target.value);
    };

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormLastName(event.target.value);
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormEmail(event.target.value);
    };

    const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormPhoneNumber(event.target.value);
    };

    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        setFormRole(event.target.value);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    };

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormTitle(event.target.value);
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_USER),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleFirstNameChange,
        handleLastNameChange,
        handleEmailChange,
        handlePhoneNumberChange,
        handleRoleChange,
        handleIsActiveChange,
        handleTitleChange,
        formFirstName,
        formLastName,
        formEmail,
        formPhoneNumber,
        formRole,
        formIsActive,
        formTitle,
        validateField,
    };
}
