import { FC, useContext } from 'react';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';
import { BCMs } from '../../../../../../Components/Forms/Schedule/BCMs';

export const StudyDashboardBCMTab: FC = () => {
    const { study, searchText } = useContext(StudyDashboardContext);

    return (
        <>
            {study.company ? (
                <BCMs
                    company={study.company}
                    studyId={study.id}
                    searchText={searchText}
                />
            ) : (
                <></>
            )}
        </>
    );
};
