import { ChildQueryParams, ChildSearchQueryParams, serverApi, ParentQueryParams, BulkUpdateWithParentId, GetRespondentsQueryParams } from '..';
import { BulkUpdateResultDto, QueryResultsDto, RespondentDto, ScheduleDetailDto, TimeRange } from '../../dtos';
import { BulkForwardRequestDto } from '../../dtos/generated/BulkForwardRequestDto';
import { CloudStorageLink } from '../../models/CloudStorageLink';
import { buildSearchQueryRoute } from '../../util';

export interface GetRespondentAssignedToWorkItemsQueryParams {
    companyId: string;
    periodTimeRanges: TimeRange[];
}

const respondentApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompanyRespondents: builder.query<QueryResultsDto<RespondentDto>, GetRespondentsQueryParams>({
            query: (params) => buildSearchQueryRoute(`company/${params.parentId}/respondent`, params),
            providesTags: [{ type: 'Respondent' }],
        }),
        getCompanyRespondentsAssignedToWorkItems: builder.query<RespondentDto[], GetRespondentAssignedToWorkItemsQueryParams>({
            query: (params) => ({
                url: `company/${params.companyId}/respondent/assignedToWorkItems`,
                method: 'POST',
                body: params.periodTimeRanges
            }),
            providesTags: [{ type: 'Respondent' }],
        }),
        getCompanyManagers: builder.query<RespondentDto[], string>({
            query: (companyId) => ({ url: `company/${companyId}/respondent/manager` }),
            providesTags: [{ type: 'Respondent' }],
        }),
        getCompanyRespondentsWithNoDepartment: builder.query<RespondentDto[], string>({
            query: (companyId) => ({ url: `company/${companyId}/respondent/noDepartment` }),
            providesTags: [{ type: 'Respondent' }],
        }),
        getRespondent: builder.query<RespondentDto, ChildQueryParams>({
            query: (params) => ({ url: `company/${params.parentId}/respondent/${params.childId}` }),
            providesTags: [{ type: 'Respondent' }],
        }),
        createRespondent: builder.mutation<RespondentDto, RespondentDto>({
            query: (respondentDto) => ({
                url: `company/${respondentDto.companyId}/respondent`,
                method: 'POST',
                body: respondentDto,
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        updateRespondent: builder.mutation<void, RespondentDto>({
            query: (respondentDto) => ({
                url: `company/${respondentDto.companyId}/respondent`,
                method: 'PUT',
                body: respondentDto,
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        archiveRespondent: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `company/${params.parentId}/respondent/${params.childId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        unarchiveRespondent: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `company/${params.parentId}/respondent/${params.childId}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        bulkArchiveRespondent: builder.mutation<BulkUpdateResultDto, BulkUpdateWithParentId>({
            query: (params) => ({
                url: `company/${params.parentId}/respondent/bulkArchive`,
                method: 'PUT',
                body: params.recordIds
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        bulkUnarchiveRespondent: builder.mutation<BulkUpdateResultDto, BulkUpdateWithParentId>({
            query: (params) => ({
                url: `company/${params.parentId}/respondent/bulkUnarchive`,
                method: 'PUT',
                body: params.recordIds
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        bulkRespondentEmailForwarding: builder.mutation<BulkUpdateResultDto, { companyId: string, payload: BulkForwardRequestDto }>({
            query: (params) => ({
                url: `company/${params.companyId}/respondent/bulkUpdateForwarding`,
                method: 'PUT',
                body: params.payload
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        getRespondentSurveys: builder.query<QueryResultsDto<ScheduleDetailDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`company/${params.parentId}/respondent/${params.childId}/survey`, params.params),
            providesTags: ['ScheduleDetails'],
        }),
        createAndUploadVerifyRespondentsCSV: builder.query<CloudStorageLink, ParentQueryParams>({
            query: (params) => ({
                url: `company/${params.parentId}/respondent/verify`,
                method: 'POST',
            }),
            providesTags: [{ type: 'Respondent' }],
        }),
    }),
});

export const {
    useGetCompanyRespondentsQuery,
    useGetCompanyManagersQuery,
    useGetRespondentQuery,
    useLazyGetRespondentQuery,
    useCreateRespondentMutation,
    useUpdateRespondentMutation,
    useArchiveRespondentMutation,
    useUnarchiveRespondentMutation,
    useGetRespondentSurveysQuery,
    useCreateAndUploadVerifyRespondentsCSVQuery,
    useGetCompanyRespondentsWithNoDepartmentQuery,
    useBulkArchiveRespondentMutation,
    useBulkUnarchiveRespondentMutation,
    useGetCompanyRespondentsAssignedToWorkItemsQuery,
    useLazyGetCompanyRespondentsAssignedToWorkItemsQuery,
    useBulkRespondentEmailForwardingMutation
} = respondentApi;
