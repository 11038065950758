import { Autocomplete, Box, Button, FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyDto } from '../../../dtos';
import { useGetCompaniesQuery } from '../../../store';
import { useFailedActionSnackbar } from '../../CoreLib/library';

export const StartNewSurvey: FC = () => {
    const navigate = useNavigate();
    const [companyId, setCompanyId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [fieldErrors, setFieldErrors] = useState({
        COMPANY: '',
    });
    const {
        data: allCompanies,
        isLoading: isAllCompaniesLoading,
        isError: isGetAllCompaniesError,
        refetch,
    } = useGetCompaniesQuery({
        searchText: '',
        includeInactive: false,
        page: 0,
        pageSize: 10000
    });

    const {
        data: recentCompanies,
        isLoading: isRecentCompaniesLoading,
        isError: isGetRecentCompaniesError,
    } = useGetCompaniesQuery({
        searchText: '',
        sortKey: 'MOST_RECENT_SURVEY_CREATED_ON',
        sortAsc: false,
        page: 0,
        pageSize: 10,
        includeInactive: false,
        isOnlyRecentlyActive: true,
    });

    useFailedActionSnackbar('retrieve', 'companies', isGetAllCompaniesError);
    useFailedActionSnackbar('retrieve', 'recent companies', isGetRecentCompaniesError);

    const handleStart = () => {
        if (companyId) {
            navigate(`/companies/${companyId}/survey`);
        } else {
            validate('COMPANY');
        }
    };
    const handleRecent = (id: string) => {
        navigate(`/companies/${id}/survey`);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'COMPANY') {
            if (companyId) {
                fieldErrors.COMPANY = '';
                isValid = true;
            } else if (companyId === '') {
                fieldErrors.COMPANY = '';
                isValid = true;
            } else {
                fieldErrors.COMPANY = 'Please enter a valid company name';
                isValid = false;
            }
        }
        setFieldErrors({
            COMPANY: fieldErrors.COMPANY,
        });
        return isValid;
    };

    return (
        <Box>
            <Typography variant='h3'>Start New Survey</Typography>
            <Grid container direction='column' spacing={1}>
                <Grid item>
                    <Typography py={1}>Which company will you be sending this survey to?</Typography>
                </Grid>
                <Grid item container direction='row' alignItems='center' spacing={2} wrap='nowrap'>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <FormLabel sx={{ fontSize: '14px' }}>Company</FormLabel>
                            <Autocomplete
                                loading={isAllCompaniesLoading}
                                id='companies-dropdown'
                                options={
                                    allCompanies
                                        ? allCompanies.pageResults.map((row) => {
                                            return { label: `${row.name}`, value: row };
                                        })
                                        : []
                                }
                                onChange={(_: any, newValue: any | null) => {
                                    if (newValue === null) {
                                        setInputValue('');
                                        return;
                                    }
                                    setCompanyId(newValue.value.id);
                                    validate('COMPANY');
                                }}
                                isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
                                sx={{ textTransform: 'none' }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                    refetch();
                                    validate('COMPANY');
                                }}
                                onKeyDown={(event: any) => {
                                    if (event.key === 'Enter') {
                                        event.defaultMuiPrevented = true;
                                    }
                                    validate('COMPANY');
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={fieldErrors.COMPANY !== ''} helperText={fieldErrors.COMPANY} />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ mt: 3 }}>
                        <Button variant='contained' size='medium' onClick={handleStart} color='primary'>
                            Start
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>Recent Companies:</Typography>
                </Grid>
                <Grid container direction='row' item spacing={1} alignItems='baseline' sx={{ padding: '0px' }}>
                    <Grid item xs={12}>
                        {!isRecentCompaniesLoading &&
                            recentCompanies?.pageResults.map((company: CompanyDto, idx: number) => {
                                return (
                                    <span key={idx}>
                                        <span style={{ color: '#DF623B', cursor: 'pointer' }} onClick={() => handleRecent(company.id)}>{company.name}</span> {idx < recentCompanies?.pageResults.length - 1 ? <>| </> : <></>}
                                    </span>
                                );
                            }
                            )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
