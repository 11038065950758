import { FilterList } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { FC } from 'react';
import { StandardDialogHeader } from '../../../Components/CommonComponents';
import { CompanySelect, StandardSelect } from '../../../Components/CommonInputs';
import { StandardDatePicker } from '../../../Components/CommonInputs/StandardDatePicker';
import { StudyFilterManager } from '../Hooks';

export interface IStudyFilterModalProps {
    filterManager: StudyFilterManager;
};

export const StudyFilterModal: FC<IStudyFilterModalProps> = ({ filterManager }) => {
    const { isFilterDialogOpen, cancelFilterChanges, isFormDirty, applyDialogFilters, dialogFilters, handleDialogFilterValueChange } = filterManager;

    return (
        <Dialog open={isFilterDialogOpen} onClose={cancelFilterChanges} fullWidth maxWidth='md'>
            <StandardDialogHeader icon={<FilterList />} text='Filter Studies' />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={8}>
                        <CompanySelect
                            selectedCompanyId={dialogFilters.companyId ?? ''}
                            handleSelectedCompanyChange={(c) => handleDialogFilterValueChange('companyId', c?.id)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StandardSelect
                            label='Completed Status'
                            selectedValue={dialogFilters.completedStatus ?? ''}
                            handleSelectedValueChange={(cs) => handleDialogFilterValueChange('completedStatus', cs)}
                            options={['', 'Completed', 'Ongoing']}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StandardDatePicker
                            label='Started After'
                            value={dialogFilters.timePeriodStart ?? null}
                            handleDateChange={(nd) => handleDialogFilterValueChange('timePeriodStart', nd)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <StandardDatePicker
                            label='Ended Before'
                            value={dialogFilters.timePeriodEnd ?? null}
                            handleDateChange={(nd) => handleDialogFilterValueChange('timePeriodEnd', nd)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={cancelFilterChanges}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={applyDialogFilters} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};