import { AddCircle } from '@mui/icons-material';
import { Box, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react';
import { DEFAULT_FORM_TYPE, DEFAULT_SURVEY_PAYLOAD, TAQ_BY_BC_ID } from '../../../Views';
import { AddEditStudyModal } from '../../../Views/Studies/Components';
import { StudyDto } from '../../../dtos';
import { useLazyGetSurveyQuery } from '../../../store';
import { StudySelect } from '../../CommonInputs';
import { SurveyBuilderContext } from '../utils';

const TAQ_TYPES = {
    TAQ: 'TAQ',
    TAQBC: 'TAQBC',
};

export interface ISurveySettingsStepContentsProps {
    companyId: string;
}

export const SurveySettingsStepContents: FC<ISurveySettingsStepContentsProps> = ({ companyId }) => {
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const [isNewStudyModalOpen, setIsNewStudyModalOpen] = useState(false);
    const [getFormTypeById, { isLoading: isLoadingSelectedFormType }] = useLazyGetSurveyQuery();

    const stepErrorMessage = useMemo(() => {
        if (!surveyPayload.study) {
            return 'Study is required.';
        }
    }, [surveyPayload.study]);

    const setStudy = useCallback(
        (study?: StudyDto) => {
            setSurveyPayload({
                ...surveyPayload,
                study: study,
            });
        },
        [surveyPayload, setSurveyPayload]
    );

    const handleCreateNew = useCallback(() => {
        setIsNewStudyModalOpen(true);
    }, []);

    const handleTAQTypeChange = useCallback(
        (__: ChangeEvent<HTMLInputElement>, value: string) => {
            switch (value) {
                case TAQ_TYPES.TAQ:
                    setSurveyPayload({
                        ...surveyPayload,
                        selectedFormType: _.cloneDeep(DEFAULT_FORM_TYPE),
                    });
                    break;
                case TAQ_TYPES.TAQBC:
                    getFormTypeById(TAQ_BY_BC_ID).then((response) => {
                        if (response.data) {
                            setSurveyPayload({
                                ...surveyPayload,
                                selectedFormType: _.cloneDeep(response.data),
                                distributionConfig: {
                                    ...DEFAULT_SURVEY_PAYLOAD.distributionConfig,
                                },
                                timePeriodArray: [surveyPayload.timePeriodArray[0]]
                            });
                        }
                    });
                    break;
                default:
                    console.error('Invalid TAQ type selected');
                    break;
            }
        },
        [getFormTypeById, setSurveyPayload, surveyPayload]
    );

    const selectedTAQType = useMemo(() => {
        return surveyPayload.selectedFormType.id === TAQ_BY_BC_ID ? TAQ_TYPES.TAQBC : TAQ_TYPES.TAQ;
    }, [surveyPayload.selectedFormType]);

    return (
        <Paper sx={{ p: 4 }} elevation={0}>
            <Grid container direction='column' spacing={2}>
                <Grid item container direction='row'>
                    <Grid item xs={12} sm={8} md={4} sx={{ display: 'flex', alignItems: 'start' }}>
                        <StudySelect
                            companyId={companyId}
                            selectedStudyId={surveyPayload.study?.id ?? ''}
                            handleSelectedStudyChange={setStudy}
                            required
                            errorMessage={stepErrorMessage}
                        />
                        <Box mt={3}>
                            <IconButton color='primary' onClick={handleCreateNew}>
                                <AddCircle fontSize='large' />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>What type of Survey would you like to send?</Typography>
                    <RadioGroup value={selectedTAQType} onChange={handleTAQTypeChange}>
                        <FormControlLabel
                            value={TAQ_TYPES.TAQ}
                            control={<Radio />}
                            label='Time Allocation Questionnaire (TAQ Survey).'
                            disabled={isLoadingSelectedFormType}
                        />
                        <FormControlLabel
                            value={TAQ_TYPES.TAQBC}
                            control={<Radio />}
                            label='Time Allocation Questionnaire by Business Component (TAQ by BC).'
                            disabled={isLoadingSelectedFormType}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <AddEditStudyModal isOpen={isNewStudyModalOpen} close={() => setIsNewStudyModalOpen(false)} companyId={companyId} afterCreate={setStudy} />
        </Paper>
    );
};
