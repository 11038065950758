import { Email, FileDownload, OpenInNew } from '@mui/icons-material';
import { Button, FormControl, FormLabel, Grid, IconButton, Link, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleDto } from '../../../dtos';
import { useGetCompaniesQuery } from '../../../store';
import { useLazyGetSurveySchedulesQuery } from '../../../store/api/schedule-api';
import { useCreateAndUploadSurveyPdfMutation, useGetSurveyCSVMutation } from '../../../store/api/survey-pdf-api';
import { downloadBase64File } from '../../../util';
import { TimePeriodInput } from '../../CommonInputs';
import { DataTableColumn, LoadingIndicator, PaginatedDataTable, PaginatedProps, useFailedActionSnackbar, useFailedCreateSnackbar } from '../../CoreLib/library';

export const ExportSurveys: FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedSchedules, setSelectedSchedules] = useState<readonly ScheduleDto[]>([]);
    const [company, setCompany] = useState<string | undefined>(undefined);
    const [periodStartDate, setPeriodStartDate] = useState<Date | null>(null);
    const [periodEndDate, setPeriodEndDate] = useState<Date | null>(null);
    const [createAndUploadPdf, { isError: createPdfError, reset: resetCreate }] = useCreateAndUploadSurveyPdfMutation();
    const [getCSV, { isError: getCSVError, reset: resetGetCSV, data: csvData }] = useGetSurveyCSVMutation();
    const [getSchedules, { data: schedulesData, isLoading: isSchedulesLoading, isError: isSchedulesError }] = useLazyGetSurveySchedulesQuery();

    useEffect(() => {
        if (csvData) {
            downloadBase64File(csvData.contents, 'Surveys_' + new Date().toLocaleDateString() + '.csv', 'text/csv');
        }
    }, [csvData]);

    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>({
        sortKey: "SCHEDULED",
        sortAsc: false,
        page: 0,
        pageSize: 10
    });

    const { data: companies, isLoading: isCompaniesLoading, isError: companiesError } = useGetCompaniesQuery({ page: 0, pageSize: 9999 });

    const tableColumns: DataTableColumn<ScheduleDto>[] = [
        { key: 'period', label: 'Period', sortKey: 'PERIOD', fieldMapper: (row: ScheduleDto) => row.period, width: '125px', },
        { key: 'formType', label: 'Form Type', sortKey: 'FORM_TYPE', width: '100px', },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: (row: ScheduleDto) => (row.isExpired ? 'Expired' : row.status), align: "right", width: '75px' },
    ];

    useFailedActionSnackbar('retrieve', 'surveys', isSchedulesError);
    useFailedActionSnackbar('retrieve', 'companies', companiesError);
    useFailedActionSnackbar('download', 'csv', getCSVError, resetGetCSV);
    useFailedCreateSnackbar('PDF', createPdfError, resetCreate)

    const search = useCallback(() => {
        getSchedules({
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: 10,
            includeInactive: false,
            company: company ? encodeURIComponent(company) : undefined,
            periodStartDate: periodStartDate?.toISOString(),
            periodEndDate: periodEndDate?.toISOString(),
        });
    }, [company, getSchedules, paginatedProps?.page, paginatedProps?.sortAsc, paginatedProps?.sortKey, periodEndDate, periodStartDate]);

    useEffect(() => {
        if (schedulesData && schedulesData.page !== paginatedProps.page) {
            setSelectedSchedules([]);
            search();
        }

    }, [paginatedProps, schedulesData, search]);

    const getFilterQueryParamsString = () => {
        let queryParams = '';
        if (company) {
            queryParams += `companyId=${company}&`;
        }
        if (periodStartDate) {
            queryParams += `periodStartDate=${periodStartDate?.toISOString()}&`;
        }
        if (periodEndDate) {
            queryParams += `periodEndDate=${periodEndDate?.toISOString()}`;
        }
        return queryParams;
    }

    const handleCreatePdf = useCallback(() => {
        const ids = selectedSchedules.map(selectedSchedule => selectedSchedule.id);
        createAndUploadPdf({
            scheduleIds: ids,
            scheduleDetailIds: []
        });
        enqueueSnackbar('PDF requested and will be delivered via email when complete');
    }, [createAndUploadPdf, enqueueSnackbar, selectedSchedules]);

    const handleGetCSV = useCallback(() => {
        const ids = selectedSchedules.map(selectedSchedule => selectedSchedule.id);
        getCSV({
            scheduleIds: ids,
            scheduleDetailIds: []
        });
    }, [getCSV, selectedSchedules]);

    const canExport = useMemo(() => {
        return selectedSchedules.length > 0;
    }, [selectedSchedules]);

    if (isCompaniesLoading) {
        return <LoadingIndicator />;
    }
    return (

        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant='h3' >Export Surveys</Typography>
            </Grid>
            <Grid item pb={1} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{ fontSize: '14px' }}>Company</FormLabel>
                    <Select value={company ?? ''} onChange={(e: SelectChangeEvent) => {
                        setCompany(e.target.value)
                    }}>
                        <MenuItem value='' key='company-none'>None</MenuItem>
                        {companies?.pageResults.map((company) => (
                            <MenuItem key={company.id} value={company.name}>
                                {company.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item container direction={"row"} pb={1} xs={12}>
                <Grid item xs={12}>
                    <TimePeriodInput
                        showLabels={true}
                        defaultStartDate={periodStartDate}
                        defaultEndDate={periodEndDate}
                        handleDatesChanged={(startDate, endDate) => {
                            if (typeof (startDate) === 'string') {
                                startDate = new Date(startDate);
                            };
                            if (typeof (endDate) === 'string') {
                                endDate = new Date(endDate);
                            };
                            setPeriodStartDate(startDate ?? null);
                            setPeriodEndDate(endDate ?? null);
                        }}
                        labelSize='14px'
                    />
                </Grid>
            </Grid>
            <Grid item container direction={"row"} pt={2.5} alignSelf="center" justifyContent={"right"} xs={3} >
                <Button variant='contained' color='primary' onClick={search} >
                    Search
                </Button>
            </Grid>
            {
                schedulesData && <>
                    <Grid item
                        pb={2.5}
                    >
                        <Grid item >
                            <FormLabel sx={{ fontSize: '14px' }}>Surveys</FormLabel>
                        </Grid>
                        <PaginatedDataTable
                            columns={tableColumns}
                            loading={isSchedulesLoading}
                            queryResults={schedulesData}
                            defaultSortKey={paginatedProps.sortKey}
                            setPagination={setPaginatedProps}
                            selectedRecords={selectedSchedules}
                            setSelectedRecords={setSelectedSchedules as any}
                            isViewOnly={false}
                            defaultSortDesc={!paginatedProps.sortAsc}
                            isDense={true}
                            isBorderVisible={false}
                            isRowsPerPageChangeable={false}
                            defaultPageSize={10}
                        />
                    </Grid>
                    <Grid container direction={"row"} justifyContent={"space-between"}>
                        <Grid item xs={2}>
                            <Tooltip title="Show All Surveys" sx={{ pl: 0 }}>
                                <Link href='#' sx={{ pl: 0 }}
                                    onClick={() => navigate(`/schedules?${getFilterQueryParamsString()}`)}
                                >
                                    <IconButton color='primary' sx={{ pl: 0 }}>
                                        <OpenInNew sx={{ pl: 0 }}>Show All Surveys</OpenInNew>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Grid>
                        <Grid item container direction={"row"} spacing={1} xs={10} alignSelf="center" justifyContent={"right"} >
                            <Grid item>
                                <Button
                                    variant='contained' color='primary' onClick={handleGetCSV} startIcon={<FileDownload />} disabled={!canExport}>
                                    Download CSV
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained' color='primary' onClick={handleCreatePdf} startIcon={<Email />} disabled={!canExport}>
                                    Email PDF
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    );
};


