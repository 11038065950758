import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

export interface IStepperSummaryListItemProps {
    label: string;
    value: string;
}

export const StepperSummaryListItem: FC<IStepperSummaryListItemProps> = ({ label, value }) => {
    return (
        <Grid item container direction='row' alignItems='start'>
            <Grid item container direction='column' justifyContent='start' xs={12}>
                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                    {label}:
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '8px' }}>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};
