import { ScheduleDto, ScheduleStatus } from "../../../../dtos";

export const getFilterNavigation = (row: ScheduleDto, status: ScheduleStatus, companyId?: string) => {
    let route = '';
    if (row) {
        if (companyId) {
            route = `/companies/${companyId}/schedules/${row.id}`;
        } else {
            route = `/schedules/${row.id}`;
        }
    }

    let count = 0;
    switch (status) {
        case ScheduleStatus.Created:
            count = row.created;
            break;
        case ScheduleStatus.Issued:
            count = row.issued;
            break;
        case ScheduleStatus.Started:
            count = row.started;
            break;
        case ScheduleStatus.Completed:
            count = row.completed;
            break;
        case ScheduleStatus.Paused:
            count = row.paused;
            break;
    }

    return count > 0 ? <a href={`${route}?status=${status}`} onClick={e => e.stopPropagation()} style={{ textDecoration: 'none', color: '#DF623B' }}>{count}</a> : <>{count}</>
};