import { emptyGuid } from '../models';

export interface IRouteDefinition {
    route: string;
    addedParam: boolean;
}

export const buildSearchQueryRoute = (baseRoute: string, params: any) => {
    let routeDefinition: IRouteDefinition = {
        route: baseRoute,
        addedParam: false,
    };

    routeDefinition = _addParamToRoute('activityType', params, routeDefinition);
    routeDefinition = _addParamToRoute('approvedBy', params, routeDefinition);
    routeDefinition = _addParamToRoute('company', params, routeDefinition);
    routeDefinition = _addParamToRoute('companyId', params, routeDefinition);
    routeDefinition = _addParamToRoute('createdFrom', params, routeDefinition);
    routeDefinition = _addParamToRoute('createdTo', params, routeDefinition);
    routeDefinition = _addParamToRoute('departmentId', params, routeDefinition);
    routeDefinition = _addParamToRoute('email', params, routeDefinition);
    routeDefinition = _addParamToRoute('firstName', params, routeDefinition);
    routeDefinition = _addParamToRoute('formType', params, routeDefinition);
    routeDefinition = _addParamToRoute('fromDate', params, routeDefinition);
    routeDefinition = _addParamToRoute('hasNoRespondents', params, routeDefinition);
    routeDefinition = _addParamToRoute('includeInactive', params, routeDefinition);
    routeDefinition = _addParamToRoute('isExpired', params, routeDefinition);
    routeDefinition = _addParamToRoute('isNotAssignedToDepartment', params, routeDefinition);
    routeDefinition = _addParamToRoute('isOnlyRecentlyActive', params, routeDefinition);
    routeDefinition = _addParamToRoute('lastName', params, routeDefinition);
    routeDefinition = _addParamToRoute('managerId', params, routeDefinition);
    routeDefinition = _addParamToRoute('page', params, routeDefinition);
    routeDefinition = _addParamToRoute('pageSize', params, routeDefinition);
    routeDefinition = _addParamToRoute('period', params, routeDefinition);
    routeDefinition = _addParamToRoute('reminder', params, routeDefinition);
    routeDefinition = _addParamToRoute('respondentId', params, routeDefinition);
    routeDefinition = _addParamToRoute('surveyId', params, routeDefinition);
    routeDefinition = _addParamToRoute('searchText', params, routeDefinition);
    routeDefinition = _addParamToRoute('sortAsc', params, routeDefinition);
    routeDefinition = _addParamToRoute('sortKey', params, routeDefinition);
    routeDefinition = _addParamToRoute('status', params, routeDefinition);
    routeDefinition = _addParamToRoute('title', params, routeDefinition);
    routeDefinition = _addParamToRoute('toDate', params, routeDefinition);
    routeDefinition = _addParamToRoute('periodStartDate', params, routeDefinition);
    routeDefinition = _addParamToRoute('periodEndDate', params, routeDefinition);
    routeDefinition = _addParamToRoute('showCompleted', params, routeDefinition);
    routeDefinition = _addParamToRoute('completedStatus', params, routeDefinition);
    routeDefinition = _addParamToRoute('activeStatus', params, routeDefinition);
    routeDefinition = _addParamToRoute('timePeriodStart', params, routeDefinition);
    routeDefinition = _addParamToRoute('timePeriodEnd', params, routeDefinition);
    routeDefinition = _addParamToRoute('assignedToStudyId', params, routeDefinition);
    routeDefinition = _addParamToRoute('notAssignedToStudyId', params, routeDefinition);
    routeDefinition = _addParamToRoute('studyId', params, routeDefinition);
    routeDefinition = _addParamToRoute('itemType', params, routeDefinition);
    routeDefinition = _addParamToRoute('periodTimeRanges', params, routeDefinition);
    routeDefinition = _addParamToRoute('daysBack', params, routeDefinition);

    return { url: routeDefinition.route };
};

function _addParamToRoute(paramName: string, params: any, routeDefinition: IRouteDefinition) {
    let { route, addedParam } = routeDefinition;

    if (params[paramName] !== undefined && params[paramName] !== emptyGuid) {
        if (addedParam) {
            route = route = `${route}&${paramName}=${params[paramName]}`;
        } else {
            route = route = `${route}?${paramName}=${params[paramName]}`;
            addedParam = true;
        }
    }
    return { addedParam, route };
}
