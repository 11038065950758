import { format } from "date-fns";
import moment from "moment";

export const snakeCaseToPrint = (snakeCaseText: string) => {
    let formattedText = snakeCaseText.toLowerCase().replaceAll('_', ' ');
    formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
    return formattedText;
};

// adds spaces before capital letters
export const toWords = (value: string): string => {
    return value.replace(/([A-Z])/g, ' $1').trim();
};

export const formateDateStandard = (date: Date | undefined, defaultValue: string = ''): string => {
    return date ? format(new Date(date.toString()), 'M/d/yyyy') : defaultValue;
}

export const formateDateTimeStandard = (date: Date | undefined, defaultValue: string = ''): string => {
    return date ? moment(date).format('M/D/YYYY h:mm A') : defaultValue;
}

export const formatDateTimeForReading = (date: Date | string | undefined, defaultValue: string = ''): string => {
    if (!date) {
        return defaultValue;
    }

    const momentDate = moment(date);
    return `on ${momentDate.format('M/D/YYYY')} at ${momentDate.format('h:mm A')}`;
}

export const formatDateTimeForActivityStream = (date: Date): string => {
    return format(new Date(date + 'Z'), 'M/d/yyyy h:mm aaaaa\'m\'')
}