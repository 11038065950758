import { FC } from 'react';
import { Field, Question } from '../../../../../../models/survey';
import { Grid } from '@mui/material';
import { SurveyFormErrorMessage } from '../../../../types';
import { SurveyInputField } from './SurveyInputField';

export interface INonCenteredQuestionInputFieldsProps {
    question: Question;
    fieldGroupIndex?: number;
    timePeriodIndex?: number;
    getQuestionAnswer: (fieldName: string) => string | number;
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
    errorMessages: SurveyFormErrorMessage[];
    setErrorMessages: (updatedErrors: SurveyFormErrorMessage[]) => void;
    isPdfView?: boolean;
    isLoading?: boolean;
}

export const NonCenteredQuestionInputFields: FC<INonCenteredQuestionInputFieldsProps> = ({
    question,
    fieldGroupIndex,
    timePeriodIndex,
    getQuestionAnswer,
    setQuestionAnswer,
    errorMessages,
    setErrorMessages,
    isPdfView,
    isLoading,
}) => {
    const getMultipleFieldName = (field: Field, index: number) => {
        let fieldNameParts = field.fieldName.split('|');
        fieldNameParts[0] += '_Multiple_' + index;
        return fieldNameParts.join('|');
    };

    return (
        <>
            {question.fieldGroups
                .filter((fieldGroup) => !fieldGroup.centerOnQuestion)
                .filter((_, index) => fieldGroupIndex === index || fieldGroupIndex === undefined)
                .map((fieldGroup, i) => (
                    <Grid
                        key={`${fieldGroup.fieldGroupLabel}-non-centered-field-${i}`}
                        item
                        container
                        direction={fieldGroup.direction === 'row' ? 'row' : 'column'}
                        justifyContent='space-between'
                        xs={4}>
                        <table width='100%'>
                            <tbody>
                                {fieldGroup.fields
                                    .filter((_, index) => timePeriodIndex! === index || !timePeriodIndex)
                                    .map((field) => {
                                        if (field.isMultiple) {
                                            const fieldName = getMultipleFieldName(field, i);
                                            return new Array(field.multipleCount).map((_, i) => {
                                                return (
                                                    <tr key={`table-row-${i}-${fieldName}`}>
                                                        <SurveyInputField
                                                            field={field}
                                                            fieldName={fieldName}
                                                            getQuestionAnswer={getQuestionAnswer}
                                                            setQuestionAnswer={setQuestionAnswer}
                                                            errorMessages={errorMessages}
                                                            setErrorMessages={setErrorMessages}
                                                            isPdfView={isPdfView}
                                                            isLoading={isLoading}
                                                        />
                                                    </tr>
                                                );
                                            });
                                        } else {
                                            return (
                                                <tr key={`table-row-${i}-${field.fieldName}`}>
                                                    <SurveyInputField
                                                        field={field}
                                                        getQuestionAnswer={getQuestionAnswer}
                                                        setQuestionAnswer={setQuestionAnswer}
                                                        errorMessages={errorMessages}
                                                        setErrorMessages={setErrorMessages}
                                                        isPdfView={isPdfView}
                                                        isLoading={isLoading}
                                                    />
                                                </tr>
                                            );
                                        }
                                    })}
                            </tbody>
                        </table>
                    </Grid>
                ))}
        </>
    );
};
