import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFilterChip } from '../../../Components/CoreLib/library';
import { useLazyGetCompanyQuery } from '../../../store';
import { formateDateStandard } from '../../../util';

type CompletedStatusOptions = 'Completed' | 'Ongoing' | '';
type ActivityStatusOptions = 'Active' | 'Inactive' | '';

export interface IStudyFilterValues {
    companyId?: string;
    completedStatus?: CompletedStatusOptions;
    activeStatus?: ActivityStatusOptions;
    timePeriodStart?: Date;
    timePeriodEnd?: Date;
}

const CHIP_KEY_MAP: { [key: string]: keyof IStudyFilterValues } = {
    Company: 'companyId',
    'Completed Status': 'completedStatus',
    'Activity Status': 'activeStatus',
    'Started After': 'timePeriodStart',
    'Ended Before': 'timePeriodEnd',
};

export function useStudyFilters(initialFilters: IStudyFilterValues = {}) {
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState(initialFilters);
    const [dialogFilters, setDialogFilters] = useState(initialFilters);
    const [getCompanyById, { currentData: loadedCompany }] = useLazyGetCompanyQuery();

    const handleDialogFilterValueChange = useCallback(
        (fieldName: keyof IStudyFilterValues, value: any) => {
            let updatedFilterValues = _.cloneDeep(dialogFilters);
            updatedFilterValues[fieldName] = value;
            setDialogFilters(updatedFilterValues);
        },
        [dialogFilters]
    );

    const applyDialogFilters = useCallback(() => {
        setAppliedFilters(_.cloneDeep(dialogFilters));
        setIsFilterDialogOpen(false);
    }, [dialogFilters]);

    const loadAppliedFiltersIntoDialog = useCallback(() => {
        setDialogFilters(_.cloneDeep(appliedFilters));
    }, [appliedFilters]);

    const openFilterDialog = useCallback(() => {
        loadAppliedFiltersIntoDialog();
        setIsFilterDialogOpen(true);
    }, [loadAppliedFiltersIntoDialog]);

    const cancelFilterChanges = useCallback(() => {
        loadAppliedFiltersIntoDialog();
        setIsFilterDialogOpen(false);
    }, [loadAppliedFiltersIntoDialog]);

    const isFormDirty = useMemo(() => JSON.stringify(appliedFilters) !== JSON.stringify(dialogFilters), [appliedFilters, dialogFilters]);

    const filterChips: IFilterChip[] = useMemo(() => {
        let chips: IFilterChip[] = [];
        appliedFilters.companyId && chips.push({ key: 'Company', text: loadedCompany?.shortName ?? 'Loading...' });
        appliedFilters.completedStatus && chips.push({ key: 'Completed Status', text: appliedFilters.completedStatus });
        appliedFilters.activeStatus && chips.push({ key: 'Activity Status', text: appliedFilters.activeStatus });
        appliedFilters.timePeriodStart && chips.push({ key: 'Started After', text: formateDateStandard(appliedFilters.timePeriodStart) });
        appliedFilters.timePeriodEnd && chips.push({ key: 'Ended Before', text: formateDateStandard(appliedFilters.timePeriodEnd) });
        return chips;
    }, [appliedFilters, loadedCompany]);

    const clearFilterByKey = useCallback(
        (chipKey: string) => {
            const keyToClear = CHIP_KEY_MAP[chipKey];
            let updatedFilters = _.cloneDeep(appliedFilters);
            updatedFilters[keyToClear] = undefined;
            setAppliedFilters(updatedFilters);
        },
        [appliedFilters]
    );

    useEffect(() => {
        if (appliedFilters.companyId && appliedFilters.companyId !== loadedCompany?.id) {
            getCompanyById(appliedFilters.companyId);
        }
    }, [appliedFilters.companyId, loadedCompany, getCompanyById]);

    return {
        appliedFilters,
        dialogFilters,
        applyDialogFilters,
        openFilterDialog,
        cancelFilterChanges,
        handleDialogFilterValueChange,
        isFilterDialogOpen,
        isFormDirty,
        filterChips,
        clearFilterByKey,
    };
}

export type StudyFilterManager = ReturnType<typeof useStudyFilters>;
