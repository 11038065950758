import { Grid, Paper, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';
import { DEFAULT_FORM_TYPE, TAQ_BY_BC_ID } from '../../../Views/types';
import { SurveyDto } from '../../../dtos';
import { FormTypeSelect } from '../../CommonInputs';
import { SurveyBuilderContext } from '../utils/SurveyBuilderContext';

export const FormTypeStepContents: FC = () => {
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);

    const stepErrorMessage = useMemo(() => {
        if (!surveyPayload.selectedFormType.id) {
            return 'Form type is required.';
        }
    }, [surveyPayload.selectedFormType]);

    const setFormType = (formType: SurveyDto | undefined) => {
        setSurveyPayload({
            ...surveyPayload,
            selectedFormType: formType ?? DEFAULT_FORM_TYPE,
        });
    };

    return (
        <Paper sx={{ p: 4 }} elevation={0}>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <Typography variant='h5' sx={{ marginBottom: '8px' }}>
                        What form should we send to the respondents?
                    </Typography>
                </Grid>
                <Grid item container direction='row' spacing={6}>
                    <Grid item xs={12} sm={8} md={4}>
                        <FormTypeSelect
                            selectedFormTypeId={surveyPayload.selectedFormType.id ?? ''}
                            handleSelectedFormTypeChange={setFormType}
                            disabled={surveyPayload.selectedFormType.id === TAQ_BY_BC_ID}
                            itemFilter={surveyPayload.selectedFormType.id !== TAQ_BY_BC_ID ? (item) => item.id !== TAQ_BY_BC_ID : undefined}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant='body1' color='primary'>
                        {stepErrorMessage}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
