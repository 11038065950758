import { Divider } from '@mui/material';
import { FC, useContext } from 'react';
import { CompanyDashboardContext } from '../../Utils';
import { CompanyDashboardBCMTabSidebarContent, CompanyDashboardDepartmentsTabSidebarContent, CompanyDashboardOverviewTabSidebarContent, CompanyDashboardRespondentsTabSidebarContent, CompanyDashboardSurveysTabSidebarContent } from './Tabs';
import { CompanyDashboardComponentsTabSidebarContent } from './Tabs/CompanyDashboardComponents';

const tabSidebarComponentMap: Record<number, FC> = {
    0: CompanyDashboardOverviewTabSidebarContent,
    1: CompanyDashboardSurveysTabSidebarContent,
    2: CompanyDashboardBCMTabSidebarContent,
    3: CompanyDashboardComponentsTabSidebarContent,
    4: CompanyDashboardRespondentsTabSidebarContent,
    5: CompanyDashboardDepartmentsTabSidebarContent
};

export const CompanyDashboardSidebarTabContentViewer: FC = () => {
    const { selectedTab } = useContext(CompanyDashboardContext);
    const TabSidebarContent = tabSidebarComponentMap[selectedTab];

    if (!TabSidebarContent) {
        return null;
    }

    return (
        <>
            <Divider />
            <TabSidebarContent />
        </>
    );
};