import { serverApi } from '..';
import { ScheduleDetailServiceFormSubmissionDto } from '../../dtos/generated/ScheduleDetailServiceFormSubmissionDto';

const feedbackApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        submitScheduleDetailFeedback: builder.mutation<void, ScheduleDetailServiceFormSubmissionDto>({
            query: (args) => ({
                url: `feedback/scheduleDetail`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: [],
        }),
    }),
});

export const {
    useSubmitScheduleDetailFeedbackMutation
} = feedbackApi;
