import { AddCircle, Business, Delete, MoreVert } from '@mui/icons-material';
import {
    Divider, FormControlLabel, Grid, IconButton,
    Menu,
    MenuItem,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { DataTableColumn, PaginatedDataTable, PaginatedProps, StandardSearchInput, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../Components/CoreLib/library';
import { CompanyDto } from '../dtos';
import { useArchiveCompanyMutation, useGetCompaniesQuery, useUnarchiveCompanyMutation } from '../store';

export const CompaniesListView: FC = () => {
    const tableColumns: DataTableColumn<CompanyDto>[] = [
        { key: 'name', label: 'Company Name', sortKey: 'NAME' },
        { key: 'shortName', label: 'Short Name', sortKey: 'SHORT_NAME' },
        { key: 'status', label: 'Status', sortKey: 'STATUS', fieldMapper: (row: CompanyDto) => row.isActive ? 'Active' : 'Inactive' },
        { key: 'phone', label: 'Phone', sortKey: 'PHONE', fieldMapper: (row: CompanyDto) => row.areaCode ? `(${row.areaCode}) ${row.phone}` : row.phone },
        { key: 'representative.name', label: 'H&K Representative', sortKey: 'REPRESENTATIVE_NAME' },
        { key: 'yearEnd', label: 'Year End', sortKey: 'YEAR_END', fieldMapper: (row: CompanyDto) => `${row.yearEndMonth}/${row.yearEndDay}` }
    ];
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>({ sortKey: "NAME", sortAsc: true, page: 0, pageSize: 25 });
    const { data, isLoading, isError: isGetCompaniesError } = useGetCompaniesQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive
    });
    const [archiveCompany, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveCompanyMutation();
    const [unarchiveCompany, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetInactivate }] = useUnarchiveCompanyMutation();
    const [selectedCompanies, setSelectedCompanies] = useState<readonly CompanyDto[]>([]);

    useFailedActionSnackbar('retrieve', 'companies', isGetCompaniesError);
    useSuccessfulActionSnackbar('inactivate', 'company(s)', isArchiveSuccess, resetArchive);
    useFailedActionSnackbar('inactivate', 'company(s)', isArchiveError, resetArchive);
    useSuccessfulActionSnackbar('activate', 'company(s)', isUnarchiveSuccess, resetInactivate);
    useFailedActionSnackbar('activate', 'company(s)', isUnarchiveError, resetInactivate);


    const handleSearchChange = (text: string) => {
        setSelectedCompanies([]);
        setSearchText(text);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCompanies([]);
        setShowInactive(event.target.checked);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    }

    const handleCreateNew = () => {
        navigate('/companies/create');
    }

    const handleMenuEdit = () => {
        const selectedCompanyId = selectedCompanies[0].id;
        if (selectedCompanyId) {
            navigate(`/companies/${selectedCompanyId}`);
        }
    }

    const handleTableEdit = (id: string) => {
        if (id) {
            navigate(`/companies/${id}`);
        }
    }

    const handleSetActive = () => {
        selectedCompanies.forEach(selectedCompany => {
            unarchiveCompany(selectedCompany.id);
        });
    }

    const handleSetInactive = () => {
        selectedCompanies.forEach(selectedCompany => {
            archiveCompany(selectedCompany.id);
        });
    }

    const canSetActive = () => {
        return selectedCompanies.some(selectedCompany => !selectedCompany.isActive);
    }

    const canSetInactive = () => {
        return selectedCompanies.some(selectedCompany => selectedCompany.isActive);
    }

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" alignItems="start">
                <Grid item container direction="column" justifyContent="start" xs={6}>
                    <Typography variant="h1" sx={{ marginBottom: '8px' }}>
                        <Business /> Companies
                    </Typography>
                </Grid>
                <Grid item container direction="row" alignItems="center" xs={6}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label="Show Inactive"
                            labelPlacement="start"
                        />

                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Make Inactive">
                            <span>
                                <IconButton size="large" onClick={handleSetInactive} disabled={!canSetInactive()}>
                                    <Delete fontSize="inherit" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Add New">
                            <span>
                                <IconButton color="primary" size="large" onClick={handleCreateNew}>
                                    <AddCircle fontSize="inherit" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton size="large" onClick={handleMenuOpen}>
                            <MoreVert fontSize="inherit" />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>
                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selectedCompanies.length !== 1}>Edit</MenuItem>
                            <Divider />
                            <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive()}>Make Active</MenuItem>
                            <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive()}>Make Inactive</MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs={6}>
                        <StandardSearchInput searchText={searchText} handleSearchChange={handleSearchChange} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey="NAME"
                    setPagination={setPaginatedProps}
                    selectedRecords={selectedCompanies}
                    setSelectedRecords={setSelectedCompanies as any}
                    onEdit={handleTableEdit}
                />
            </Grid>
        </Grid>
    );
}