import { FC, useCallback } from 'react';
import { useGetRespondentQuery } from '../../../store';
import { ApiError, LoadingIndicator } from '../../../Components/CoreLib/library';
import { Edit } from '@mui/icons-material';
import { Box, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface IRespondentDetailsProps {
    employeeId: string;
    companyId: string;
}

export const RespondentDetails: FC<IRespondentDetailsProps> = ({ employeeId, companyId }) => {
    const navigate = useNavigate();
    const {
        data: respondent,
        isLoading: isLoadingRespondent,
        isError: isErrorGettingRespondent,
        refetch: retryGettingRespondent,
    } = useGetRespondentQuery({ parentId: companyId, childId: employeeId });

    const navigateToRespondentEdit = useCallback(() => {
        navigate(`/companies/${companyId}/respondents/${employeeId}`)
    }, [navigate, companyId, employeeId]);

    if (isLoadingRespondent) {
        return <LoadingIndicator />;
    }

    if (isErrorGettingRespondent || !respondent) {
        return <ApiError onReloadClick={retryGettingRespondent} />;
    }

    return (
        <Box display='flex' flexDirection='column' rowGap={0.5}>
            <Box display='flex' alignItems='center'>
                <Typography fontSize={18} fontWeight='bold'>
                    Employee Details
                </Typography>
                <IconButton size='small' sx={{ ml: 1 }} onClick={navigateToRespondentEdit}>
                    <Edit />
                </IconButton>
            </Box>
            <Typography>First Name: {respondent.firstName}</Typography>
            <Typography>Last  Name: {respondent.lastName}</Typography>
            <Typography>Title: {respondent.title}</Typography>
            <Typography>Department: {respondent.department?.name}</Typography>
            <Typography>Email: {respondent.email}</Typography>
            <Typography>Phone: {respondent.phone}</Typography>
            <Typography>Emails Forwarded to Manager: {respondent.shouldForwardEmailsToManager ? 'Yes' : 'No'}</Typography>
        </Box>
    )
};
