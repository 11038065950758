import { FormControl, FormLabel, Select, MenuItem, FormHelperText, CircularProgress, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { CompanyDto } from '../../dtos';
import { useGetCompaniesQuery } from '../../store';

export interface ICompanySelectProps {
    selectedCompanyId: string;
    handleSelectedCompanyChange: (selectedCompany?: CompanyDto) => void;
    companyFilter?: (company: CompanyDto) => boolean;
    isRequired?: boolean;
    errorMessage?: string;
}

export const CompanySelect: FC<ICompanySelectProps> = props => {
    const { selectedCompanyId, handleSelectedCompanyChange, companyFilter = () => true, isRequired = false, errorMessage = ''} = props;
    const { data: companies, isLoading: isLoadingCompanies, isError: isErrorLoadingCompanies } = useGetCompaniesQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 50000,
        includeInactive: false
    });

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const selectedRecord = companies?.pageResults.find(x => x.id === event.target.value);
        handleSelectedCompanyChange(selectedRecord);
    }

    return (
        <FormControl error={isErrorLoadingCompanies || !!errorMessage} fullWidth required={isRequired}>
            <FormLabel>Company</FormLabel>
            <Select
                value={selectedCompanyId}
                onChange={handleSelectChange}
                endAdornment={(isLoadingCompanies && <div><CircularProgress size={24} /></div>)}
            >
                <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                {companies?.pageResults.filter(companyFilter).map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                        {company.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{isErrorLoadingCompanies ? 'Failed to load company options' : errorMessage}</FormHelperText>
        </FormControl>
    )
}