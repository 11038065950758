import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useArchiveScheduleMutation } from '../../../store/api/schedule-api';
import { StandardDialogHeader } from '../../CommonComponents';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../CoreLib/library';
import { IDeleteScheduleModalProps } from './types';
import { formateDateTimeStandard } from '../../../util';

export const DeleteScheduleModal: FC<IDeleteScheduleModalProps> = (props) => {
    const { isSetInactiveModalVisible, setIsSetInactiveModalVisible, scheduleData } = props;
    const [surveyDeleteSelection, setSurveyDeleteSelection] = useState('thisSurvey');
    const [archiveSchedule, { isError: isArchiveScheduleError, isSuccess: isArchiveScheduleSuccess, reset: resetArchiveSchedule }] =
        useArchiveScheduleMutation();

    const handleClose = () => {
        setIsSetInactiveModalVisible(false);
    };

    const handleSetInactive = () => {
        if (surveyDeleteSelection === 'allSurveys') {
            archiveSchedule({
                scheduleId: scheduleData.id,
                isSkipping: false,
            });
        } else {
            // get the next occurrence of the schedule/survey
            archiveSchedule({
                scheduleId: scheduleData.id,
                isSkipping: true,
            });
        }
        setIsSetInactiveModalVisible(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSurveyDeleteSelection(event.target.value);
    };

    useSuccessfulActionSnackbar('inactivated', 'schedule', isArchiveScheduleSuccess, resetArchiveSchedule);
    useFailedActionSnackbar('inactivate', 'schedule', isArchiveScheduleError, resetArchiveSchedule);

    return (
        <Dialog
            open={isSetInactiveModalVisible}
            onClose={handleClose}
            aria-labelledby='modal-survey-delete'
            aria-describedby='modal-survey-delete'
            fullWidth
            maxWidth='lg'
            keepMounted={false}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    ml: 35,
                },
            }}>
            <StandardDialogHeader icon={<Delete />} text='Delete Recurring Survey' />
            <DialogContent>
                <Grid container direction='row' justifyContent='center' alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='body1' sx={{ marginY: '8px', fontWeight: 'bold' }}>
                            Period
                        </Typography>
                        <Typography variant='body1' sx={{ marginBottom: '8px' }}>
                            {scheduleData.period}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1' sx={{ marginY: '8px', fontWeight: 'bold' }}>
                            Company (Short)
                        </Typography>
                        <Typography variant='body1' sx={{ marginBottom: '8px' }}>
                            {scheduleData.companyShortName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1' sx={{ marginY: '8px', fontWeight: 'bold' }}>
                            Scheduled
                        </Typography>
                        <Typography variant='body1' sx={{ marginBottom: '8px' }}>
                            {formateDateTimeStandard(scheduleData.scheduled)}
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant='h6' sx={{ marginY: '16px' }}>
                    What would you like to do?
                </Typography>
                <FormControl component='fieldset'>
                    <RadioGroup aria-label='emailReminderSelection' value={surveyDeleteSelection} onChange={handleChange}>
                        <FormControlLabel value='thisSurvey' control={<Radio size='small' />} label='Delete this survey only.' />
                        <FormControlLabel
                            value='allSurveys'
                            control={<Radio size='small' />}
                            label='Delete this survey and all following surveys in the series.'
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>
                    CANCEL
                </Button>
                <Button onClick={handleSetInactive} variant='contained' color='primary'>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};
