import { Link } from "@mui/material";
import { ActivityStreamDto } from "../../../dtos";
import { IStandardActionPayload } from "../types";

export abstract class BaseMessageType {
    _streamEntry: ActivityStreamDto;

    constructor(streamEntry: ActivityStreamDto){
        this._streamEntry = streamEntry;
    }

    abstract getMessage(): JSX.Element[];

    // Depending on what data is provided not all sections will be added but the general format is
    // {noun} ({properNoun}) {pastTenseAction} (by {performedBy}) ([to|for|by] {targetProperNoun}) ([for|at] {companyName}) (for {formTypeName} survey) (for {periodText})
    protected generateMessageFromPayload(payload: IStandardActionPayload) {
        const {
            noun,
            properNoun,
            properNounRoute,
            pastTenseAction,
            performedBy,
            performedByRoute,
            targetJoiningText = 'by',
            targetProperNoun,
            targetProperNounRoute,
            companyJoiningText = 'for',
            companyName,
            companyRoute,
            formTypeName,
            formTypeRoute,
            periodText,
            periodTextRoute
        } = payload;
    
        let messageParts: (JSX.Element)[] = [];
        messageParts.push(<span key={messageParts.length}>{ noun }</span>);
        if (properNoun && properNounRoute) {
            messageParts = this._addMessagePart(messageParts, '', properNoun, properNounRoute)
        }
        messageParts.push(<span key={messageParts.length}> { pastTenseAction }</span>);
        if (performedBy && performedByRoute) {
            messageParts = this._addMessagePart(messageParts, ' by', performedBy, performedByRoute)
        }
        if (targetProperNoun && targetProperNounRoute) {
            messageParts = this._addMessagePart(messageParts, ` ${targetJoiningText}`, targetProperNoun, targetProperNounRoute)
        }
        if (companyName && companyRoute) {
            messageParts = this._addMessagePart(messageParts, ` ${companyJoiningText}`, companyName, companyRoute)
        }
        if (formTypeName && formTypeRoute) {
            messageParts = this._addMessagePart(messageParts, ` for`, formTypeName, formTypeRoute, 'survey')
        }
        if (periodText && periodTextRoute) {
            messageParts = this._addMessagePart(messageParts, ` for`, periodText, periodTextRoute)
        }
        return messageParts;
    }

    private _addMessagePart(messageParts: JSX.Element[], prefix: string, text: string, route: string, suffix?: string) {
        messageParts.push(<span key={`message-part-${messageParts.length}-prefix`}>{ prefix } </span>);
        messageParts.push(
            <Link href={route} onClick={(e) => e.stopPropagation()} key={`message-part-${messageParts.length}`}>
                {text}
            </Link>
        );
        if (suffix) {
            messageParts.push(<span key={`message-part-${messageParts.length}-suffix`}> {suffix}</span>);
        }
        return messageParts;
    }
}