import { Send } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { LimitedUserDto, RespondentDto } from "../../../dtos";
import { useSendEmailMutation } from "../../../store/api/email-api";
import { useCreateAndUploadVerifyRespondentsCSVQuery, useGetCompanyRespondentsQuery } from '../../../store/api/respondent-api';
import { useGetUsersLimitedQuery } from "../../../store/api/user-api";
import { useSuccessfulActionSnackbar } from "../../CoreLib/library";
import { useFailedActionSnackbar } from "../../CoreLib/library";
import { LoadingIndicator } from "../../CoreLib/library";
import { VerifyRespondentsStepOne } from './VerifyRespondentsStepOne';
import { VerifyRespondentsStepTwo } from './VerifyRespondentsStepTwo';

export interface IVerifyRespondentsProps {
    setIsVerifyVisible: Function;
    isVerifyVisible: boolean;
    companyId: string;
    companyUserId: string;
}

export const VerifyRespondentsModal: FC<IVerifyRespondentsProps> = ({ setIsVerifyVisible, isVerifyVisible, companyId, companyUserId }) => {
    const [activeStep, setActiveStep] = useState(0)
    const [backWarningIsVisible, setBackWarningIsVisible] = useState<boolean>(false);
    const [isReadyForNext, setIsReadyForNext] = useState<Boolean>(false)
    const [stepOneIsValid, setStepOneIsValid] = useState<Boolean>(false)
    const [stepTwoIsValid, setStepTwoIsValid] = useState<Boolean>(false)
    const { data: respondents, isLoading: respondentsLoading, isError: isRespondentsError } = useGetCompanyRespondentsQuery({
        parentId: companyId,
        pageSize: 10000
    });
    const { data: representatives, isLoading: representativesLoading, isError: isRepresentativesError } = useGetUsersLimitedQuery();
    const { isError: isCSVLinkError, isLoading: isCSVLinkLoading, data: csvLink } = useCreateAndUploadVerifyRespondentsCSVQuery({ parentId: companyId });
    const [sendEmail, { isError: isSendEmailError, isSuccess: isSendEmailSuccess, reset: resetSendEmail }] = useSendEmailMutation();
    const [respondent, setRespondent] = useState<RespondentDto>();
    const [representative, setRepresentative] = useState<LimitedUserDto>();
    const [taxPeriod, setTaxPeriod] = useState<string>("");
    const [subject, setSubject] = useState<string>("Research Tax Credit Survey Respondents List");
    const [body, setBody] = useState<string>("");

    useFailedActionSnackbar('retrieve', 'respondents', isRespondentsError);
    useFailedActionSnackbar('retrieve', 'representatives', isRepresentativesError);
    useFailedActionSnackbar('retrieve', 'respondent csv', isCSVLinkError);
    useSuccessfulActionSnackbar('sent', 'email', isSendEmailSuccess, () => {
        resetSendEmail();
        handleClose();
    });
    useFailedActionSnackbar('send', 'email', isSendEmailError, resetSendEmail);

    const validateStepOne = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'TAX_PERIOD') {
            if (taxPeriod) {
                stepOneFieldErrors.TAX_PERIOD = '';
                isValid = true;
            } else {
                stepOneFieldErrors.TAX_PERIOD = 'Tax Period is required';
                isValid = false;
            }
        }
        else if (fieldName === 'RESPONDENT') {
            if (respondent) {
                stepOneFieldErrors.RESPONDENT = '';
                isValid = true;
            } else {
                stepOneFieldErrors.RESPONDENT = 'To is required';
                isValid = false;
            }
        }
        else if (fieldName === 'REPRESENTATIVE') {
            if (representative) {
                stepOneFieldErrors.REPRESENTATIVE = '';
                isValid = true;
            } else {
                stepOneFieldErrors.REPRESENTATIVE = 'Reply to is required';
                isValid = false;
            }
        } else {
            isValid = true;
        }
        setStepOneFieldErrors(stepOneFieldErrors);
        return isValid;
    };

    const validateStepTwo = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'SUBJECT') {
            if (subject) {
                stepTwoFieldErrors.SUBJECT = '';
                isValid = true;
            } else {
                stepTwoFieldErrors.SUBJECT = 'Subject is required';
                isValid = false;
            }
        }
        else if (fieldName === 'BODY') {
            if (body) {
                stepTwoFieldErrors.BODY = '';
                isValid = true;
            } else {
                stepTwoFieldErrors.BODY = 'Body is required';
                isValid = false;
            }
        } else {
            isValid = true;
        }
        setStepTwoFieldErrors(stepTwoFieldErrors);
        return isValid;
    };

    const [stepOneFieldErrors, setStepOneFieldErrors] = useState({
        TAX_PERIOD: '',
        RESPONDENT: '',
        REPRESENTATIVE: ''
    });

    const [stepTwoFieldErrors, setStepTwoFieldErrors] = useState({
        SUBJECT: '',
        BODY: ''
    });

    useEffect(() => {
        setIsReadyForNext((stepOneIsValid && activeStep === 0) || (stepTwoIsValid && activeStep === 1));
    }, [stepOneIsValid, stepTwoIsValid, activeStep]);

    useEffect(() => {
        if (respondents && !respondent) {
            setRespondent(respondents?.pageResults.find(x => x.isContactOnly));
        }
        if (representatives && !representative) {
            setRepresentative(representatives?.pageResults.find(x => x.id === companyUserId));
        }
    }, [representatives, respondents, companyUserId, representative, respondent]);


    useEffect(() => {
        const updateBody = () => {
            setBody(
                (respondent?.firstName ?? "") + ",\n\n" +
                "We’re in the process of updating the list of employees who receive the Research Tax Credit Survey before we send new survey requests. " +
                "Could you please look over the attached list and provide any additions or deletions to reflect the employees involved in R&D for " + taxPeriod + ". " +
                "Please note if there are new employees, provide title, date employed, and email address, or for terminated employees, provide termination date." +
                "\n\n" +
                (representative?.displayName ? representative?.displayName + "\n" : "") +
                (representative?.title ? representative?.title + "\n" : "") +
                "Hull & Knarr LLP" +
                (representative?.phoneNumber ? "\n" + representative?.phoneNumber : ""));
        }
        updateBody();
    }, [respondent, representative, taxPeriod]);

    const isBackVisible = activeStep === 1;

    const hideWarning = () => {
        setBackWarningIsVisible(false);
    }

    const showWarning = () => {
        setBackWarningIsVisible(true);
    }

    const handleBack = () => {
        hideWarning();
        setActiveStep(activeStep - 1)
    }

    const createAndSendEmail = () => {
        sendEmail({
            subject: subject,
            body: body,
            replyTo: representative!.email!,
            email: respondent!.email!,
            attachmentName: csvLink!.name!,
            attachmentContent: csvLink!.contents!
        });
    }


    const handleNext = () => {
        if (activeStep === 1) {
            createAndSendEmail();
        }
        else {
            setActiveStep(activeStep + 1)
        }
    }

    const handleClose = () => {
        setIsVerifyVisible(false);
        setActiveStep(0);
    }

    const steps = [
        {
            content: (
                <VerifyRespondentsStepOne isVerifyVisible={isVerifyVisible} validate={validateStepOne} fieldErrors={stepOneFieldErrors} setIsValid={setStepOneIsValid} respondents={respondents} respondent={respondent} setRespondent={setRespondent} representatives={representatives?.pageResults} representative={representative} setRepresentative={setRepresentative} taxPeriod={taxPeriod} setTaxPeriod={setTaxPeriod} />
            ),
            buttonText: "Next"
        },
        {
            content: (
                <VerifyRespondentsStepTwo validate={validateStepTwo} fieldErrors={stepTwoFieldErrors} csvLink={csvLink?.link!} body={body!} setBody={setBody} subject={subject} setSubject={setSubject} setIsValid={setStepTwoIsValid} respondent={respondent!} representative={representative!}></VerifyRespondentsStepTwo>
            ),
            buttonText: "Send",
            buttonIcon: <Send></Send>
        }
    ]

    return (
        <>
            {!respondentsLoading && !representativesLoading && !isCSVLinkLoading ? (<Dialog
                open={isVerifyVisible}
                onClose={handleClose}
                aria-labelledby="modal-verify-respondents"
                aria-describedby="modal-modal-verify-respondents-by-csv"
                PaperProps={{
                    sx: {
                        backgroundColor: "background.paper", width: "100%", ml: 35, height: "85%"
                    }
                }}
            >
                <DialogTitle sx={{ m: 2 }} >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Verify Respondents
                    </Typography>
                </DialogTitle>

                <DialogContent sx={{ overflowY: 'scroll' }}>
                    <Box sx={{ backgroundColor: "background.paper" }} paddingX={2} >
                        <Grid container item direction="column" justifyContent={"space-between"}  >
                            {steps[activeStep].content}
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions sx={{ m: 2, py: 2, justifyContent: 'space-between' }} >
                    <Button variant="outlined" size="medium" onClick={handleClose} color='primary' >Cancel</Button>
                    <Grid item direction="row" sx={{ justifyContent: 'flex-end' }}>
                        {isBackVisible ? <Button variant="contained" size="medium" onClick={showWarning} color={"primary"} sx={{ mr: 2 }}>Back</Button> : <></>}
                        <Button variant="contained" size="medium" startIcon={steps[activeStep].buttonIcon ?? <></>} disabled={!isReadyForNext} onClick={handleNext} color={"primary"}>{steps[activeStep].buttonText}</Button>
                    </Grid>
                </DialogActions>
            </Dialog >) : (
                <LoadingIndicator />
            )}
            <Dialog open={backWarningIsVisible}           >
                <DialogTitle sx={{ m: 2 }} >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Body Text Overwrite Warning
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ backgroundColor: "background.paper" }} padding={2}>
                        <Grid container direction="column" justifyContent={"space-between"}  >
                            <Grid item direction="row">
                                <Typography>Changing information on the previous panel may cause the body text of the email to be overwritten when you return to this panel.</Typography>
                            </Grid>
                            <br />
                            <Grid item direction="row">
                                <Typography>Click CANCEL to stay on the current page or CONTINUE to return to the previous panel.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ m: 2, py: 2, justifyContent: 'flex-end' }} >
                    <Grid item direction="row" sx={{ justifyContent: 'flex-end' }}>
                        <Button variant="outlined" size="medium" onClick={hideWarning} color={"primary"} sx={{ mr: 2 }} >Cancel</Button>
                        <Button variant="contained" size="medium" onClick={handleBack} color={"primary"}>Confirm</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
};

