import { FC, useContext } from 'react';
import { GroupedDataPieChart } from '../../../../../../Components/Charts';
import { emptyGuid } from '../../../../../../models';
import { useGetStudyWorkItemsByTypeQuery } from '../../../../../../store/api/study-api';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';

const COLOR_PALLET = new Map([
    ['Activity', '#DF623B'],
    ['BusinessComponent', '#50B432']
]);

export const StudyDashboardOverviewTabSidebarContent: FC = () => {
    const { study } = useContext(StudyDashboardContext);
    const { data: workItemStats, isLoading } = useGetStudyWorkItemsByTypeQuery({ id: study.id ?? emptyGuid });

    return <GroupedDataPieChart name='Component Type' groupedData={workItemStats ?? []} colorMap={COLOR_PALLET} isLoading={isLoading} />;
};