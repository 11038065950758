import { FC } from 'react';
import { useNavigate } from 'react-router';
import { LoadingIndicator, useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { CompanyForm } from '../../Components/Forms/CompanyForm';
import { CompanyDto } from '../../dtos';
import { useCreateCompanyMutation } from '../../store';

export const CreateCompanyView: FC = () => {
    const navigate = useNavigate();
    const [createCompany, { isSuccess, isLoading, isError, reset }] = useCreateCompanyMutation();
    useSuccessfulCreateSnackbar('company', isSuccess, () => {
        reset();
        navigate('/companies');
    });
    useFailedCreateSnackbar('company', isError, reset);

    const handleSave = (company: CompanyDto) => {
        createCompany(company);
    }

    return (
        <>
            {!isLoading ? (
                <CompanyForm save={handleSave} />
            ) : <LoadingIndicator />}
        </>
    );
}