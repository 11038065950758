import { Box, IconButton, Typography } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import { CompanyDashboardContext } from '../Utils/CompanyDashboardContext';
import { Edit } from '@mui/icons-material';
import { AddEditCompanyModal } from './AddEditCompanyModal';

export const CompanyDetails: FC = () => {
    const { company } = useContext(CompanyDashboardContext);
    const [isNewCompanyModalOpen, setIsNewCompanyModalOpen] = useState(false);
    const handleEditClicked = () => {
        setIsNewCompanyModalOpen(true);
    };

    const companyPhoneNumberText = useMemo(() => company.phone ? `(${company.areaCode}) ${company.phone}` : 'No Phone', [company]);

    return (
        <>
            <Box display='flex' flexDirection='column' rowGap={0.5}>
                <Box display='flex' alignItems='center' pb={1}>
                    <Typography fontSize={18} fontWeight='bold'>
                        Company Details
                    </Typography>
                    <IconButton size='small' sx={{ ml: 1 }} onClick={handleEditClicked}>
                        <Edit />
                    </IconButton>
                </Box>
                <Typography fontWeight='bold'>{company.name}</Typography>
                <Typography>{companyPhoneNumberText}</Typography>
                <Typography>H&K Representative: {company.representative?.firstName} {company.representative?.lastName}</Typography>
                <Typography>Year End: {company.yearEndMonth}/{company.yearEndDay}</Typography>
            </Box>
            <AddEditCompanyModal isOpen={isNewCompanyModalOpen} close={() => setIsNewCompanyModalOpen(false)} companyToUpdate={company} />
        </>
    );
};
