import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';
import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SurveyPayloadFrequencyConfigDto } from '../../../../dtos';
import { convertToDateIfNeeded } from '../../../../util';
import { SurveyBuilderContext } from '../../utils';

const HOURS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const MINUTES = ['00', '15', '30', '45'];

export interface IFrequencyStartDateCardProps {
    hideRecurringOptions?: boolean;
}

export const FrequencyStartDateCard: FC<IFrequencyStartDateCardProps> = ({ hideRecurringOptions }) => {
    const { surveyPayload, setSurveyPayload, surveyAliasShort } = useContext(SurveyBuilderContext);
    const tomorrow = moment().add(1, 'd').minutes(0).toDate();
    const [date, setDate] = useState<Date | null>(convertToDateIfNeeded(surveyPayload.frequencyConfig.startDate));
    const [hour, setHour] = useState(
        ((convertToDateIfNeeded(surveyPayload.frequencyConfig.startDate)?.getHours() ?? 0) % 12 || 12).toString().padStart(2, '0')
    );
    const [minute, setMinute] = useState(convertToDateIfNeeded(surveyPayload.frequencyConfig.startDate)?.getMinutes().toString().padStart(2, '0'));
    const [amPmButton, setAmPmButton] = useState(
        (convertToDateIfNeeded(surveyPayload.frequencyConfig.startDate)?.getHours() ?? 0) > 12 ||
            convertToDateIfNeeded(surveyPayload.frequencyConfig.startDate)?.getHours() === 0
            ? 'PM'
            : 'AM'
    );

    const updateFrequencyConfig = useCallback(
        (frequencyConfig: SurveyPayloadFrequencyConfigDto) => {
            setSurveyPayload({ ...surveyPayload, frequencyConfig });
        },
        [surveyPayload, setSurveyPayload]
    );

    useEffect(() => {
        const adjustedDate = date?.toLocaleDateString();
        const newDate = new Date(`${adjustedDate} ${hour}:${minute}:00 ${amPmButton}`);
        if (
            newDate.toString() !== 'Invalid Date' &&
            newDate.toISOString().slice(0, 16) !== convertToDateIfNeeded(surveyPayload.frequencyConfig.startDate)?.toISOString().slice(0, 16)
        ) {
            let updatedConfig = _.cloneDeep(surveyPayload.frequencyConfig);
            updatedConfig.startDate = moment(newDate).utc().toDate();
            updateFrequencyConfig(updatedConfig);
        }
    }, [date, hour, minute, amPmButton, updateFrequencyConfig, surveyPayload.frequencyConfig]);

    const handleRadioStartDateChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newSelectedButton = event.target.value;
            let updatedConfig = _.cloneDeep(surveyPayload.frequencyConfig);
            updatedConfig.radioStartDate = newSelectedButton;
            if (newSelectedButton === 'immediately') {
                setHour('01');
                setMinute('00');
                setAmPmButton('AM');
                setDate(tomorrow);
                updateFrequencyConfig(updatedConfig);
            } else {
                updateFrequencyConfig(updatedConfig);
            }
        },
        [surveyPayload.frequencyConfig, updateFrequencyConfig, tomorrow]
    );

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedButton = event.target.value;
        let updatedConfig = _.cloneDeep(surveyPayload.frequencyConfig);
        updatedConfig.radioOneTimeOrRecurring = newSelectedButton;
        updatedConfig.radioStartDate = 'immediately';
        updateFrequencyConfig(updatedConfig);
    };

    const handleFrequencyChange = (event: SelectChangeEvent) => {
        const newFrequencyValue = event.target.value;
        let updatedConfig = _.cloneDeep(surveyPayload.frequencyConfig);
        updatedConfig.frequencyValue = newFrequencyValue;
        updateFrequencyConfig(updatedConfig);
    };

    const handleHourChange = (event: SelectChangeEvent) => {
        let newHour = event.target.value;
        setHour(newHour);
    };

    const handleMinuteChange = (event: SelectChangeEvent) => {
        let newMinute = event.target.value;
        setMinute(newMinute);
    };

    const handleAMClick = () => {
        setAmPmButton('AM');
    };

    const handlePMClick = () => {
        setAmPmButton('PM');
    };

    const isDateValid = (date: Date) => {
        return new Date(date).toString() !== 'Invalid Date';
    };

    const OneTimeConfigOptions = useMemo(
        () => (
            <>
                <Grid item container direction='column' justifyContent='start' xs='auto'>
                    <FormControl component='fieldset'>
                        <RadioGroup row name='survey-start-date' value={surveyPayload.frequencyConfig.radioStartDate} onChange={handleRadioStartDateChange}>
                            <FormControlLabel
                                value='immediately'
                                control={<Radio />}
                                label='Send immediately'
                                sx={{ marginRight: 4 }}
                                disabled={surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'}
                            />
                            <FormControlLabel
                                value='future'
                                control={<Radio />}
                                label='Send on a future date'
                                disabled={surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item container direction='column' justifyContent='start' xs='auto'>
                    <DatePicker
                        label='Start Date'
                        value={date}
                        disabled={
                            surveyPayload.frequencyConfig.radioStartDate === 'immediately' ||
                            surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'
                        }
                        onChange={(e: any) => {
                            let newDate = e?.$d;
                            if (newDate === null) {
                                setDate(tomorrow);
                            } else {
                                setDate(newDate);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <Typography color='primary' sx={{ height: 0 }}>{date && !isDateValid(date) && 'Start date must be a valid date'}</Typography>
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='center' xs='auto'>
                    <Typography variant='body1' sx={{ marginLeft: '16px', marginRight: '16px' }}>
                        at
                    </Typography>
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='center' xs='auto'>
                    <FormControl>
                        <InputLabel htmlFor='Hour'>Hour</InputLabel>
                        <Select
                            defaultValue={hour}
                            value={hour}
                            onChange={handleHourChange}
                            disabled={
                                surveyPayload.frequencyConfig.radioStartDate === 'immediately' ||
                                surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'
                            }
                            sx={{ width: 75 }}
                            label='Hour'>
                            {HOURS.map((hour) => {
                                return (
                                    <MenuItem key={hour} value={hour}>
                                        {hour}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='center' xs='auto'>
                    <Typography variant='body1' sx={{ marginLeft: '8px', marginRight: '8px' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='center' xs='auto'>
                    <FormControl>
                        <InputLabel htmlFor='Minute'>Minute</InputLabel>
                        <Select
                            defaultValue={minute}
                            value={minute}
                            onChange={handleMinuteChange}
                            disabled={
                                surveyPayload.frequencyConfig.radioStartDate === 'immediately' ||
                                surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'
                            }
                            sx={{ width: 75 }}
                            label='Minute'>
                            {MINUTES.map((minute) => {
                                return (
                                    <MenuItem key={minute} value={minute}>
                                        {minute}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='center' xs='auto'>
                    <ButtonGroup orientation='vertical' aria-label='AM-PM-buttons'>
                        <Button
                            color={amPmButton === 'AM' ? 'primary' : 'inherit'}
                            style={
                                surveyPayload.frequencyConfig.radioStartDate === 'immediately' ? { marginLeft: 24 } : { marginLeft: 24, borderColor: '#DF623B' }
                            }
                            disabled={
                                surveyPayload.frequencyConfig.radioStartDate === 'immediately' ||
                                surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'
                            }
                            variant={amPmButton === 'AM' ? 'contained' : 'outlined'}
                            size='small'
                            onClick={handleAMClick}>
                            AM
                        </Button>
                        <Button
                            style={
                                surveyPayload.frequencyConfig.radioStartDate === 'immediately' ? { marginLeft: 24 } : { marginLeft: 24, borderColor: '#DF623B' }
                            }
                            disabled={
                                surveyPayload.frequencyConfig.radioStartDate === 'immediately' ||
                                surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'
                            }
                            variant={amPmButton === 'PM' ? 'contained' : 'outlined'}
                            size='small'
                            onClick={handlePMClick}
                            color={amPmButton === 'PM' ? 'primary' : 'inherit'}>
                            PM
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item container direction='row' justifyContent='end' xs={12}>
                    <Typography variant='body1' sx={{ my: 2, color: '#DF623B' }}>
                        Will be sent in application time zone, not respondent's time zone
                    </Typography>
                </Grid>
            </>
        ),
        [
            amPmButton,
            date,
            handleRadioStartDateChange,
            hour,
            minute,
            surveyPayload.frequencyConfig.radioOneTimeOrRecurring,
            surveyPayload.frequencyConfig.radioStartDate,
            tomorrow,
        ]
    );

    return (
        <Box
            sx={{
                backgroundColor: 'primary.contrastText',
                py: 4,
                px: 4,
            }}>
            <Grid item container direction='row' alignItems='start'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h5' sx={{ marginBottom: '8px' }}>
                        {hideRecurringOptions
                            ? `What date should the ${surveyAliasShort.toLowerCase()} be sent?`
                            : `Is this a one-time or recurring ${surveyAliasShort.toLowerCase()}?`}
                    </Typography>
                </Grid>
            </Grid>
            {hideRecurringOptions ? (
                <Grid item container direction='row' alignItems='center'>
                    {OneTimeConfigOptions}
                </Grid>
            ) : (
                <>
                    <Grid item container direction='row' alignItems='center'>
                        <Grid item container direction='column' justifyContent='start' xs='auto'>
                            <Radio
                                checked={surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'one-time'}
                                onChange={handleRadioChange}
                                value='one-time'
                                name='one-time-or-recurring-button-group'
                            />
                        </Grid>
                        <Grid item container direction='column' alignItems='center' justifyContent='center' xs='auto'>
                            <Typography variant='body1' sx={{ marginRight: 16 }}>
                                This is a one-time {surveyAliasShort.toLowerCase()} that should be sent...
                            </Typography>
                        </Grid>
                        {OneTimeConfigOptions}
                    </Grid>
                    <Grid item container direction='row' alignItems='center'>
                        <Grid item container direction='column' justifyContent='start' xs='auto'>
                            <Radio
                                checked={surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring'}
                                onChange={handleRadioChange}
                                value='recurring'
                                name='one-time-or-recurring-button-group'
                            />
                        </Grid>
                        <Grid item container direction='column' alignItems='center' justifyContent='center' xs='auto'>
                            <Typography variant='body1' sx={{ marginRight: 3 }}>
                                This is a recurring {surveyAliasShort.toLowerCase()} (details will be set in the next step).
                            </Typography>
                        </Grid>
                        <Grid item container direction='column' alignItems='start' justifyContent='start' xs='auto' sx={{ marginRight: 'auto' }}>
                            <FormControl sx={{ width: 250 }}>
                                <InputLabel>Frequency</InputLabel>
                                <Select
                                    value={surveyPayload.frequencyConfig.frequencyValue}
                                    disabled={surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'one-time'}
                                    onChange={handleFrequencyChange}
                                    label='Frequency'>
                                    <MenuItem value={'every-year'}>Every year</MenuItem>
                                    <MenuItem value={'every-three-months'}>Every 3 months</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
};
