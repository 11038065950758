import { Delete, Work } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { BusinessComponentDto, CompanyDto } from '../../../../../dtos';
import { emptyGuid } from '../../../../../models';
import { IInMemoryTable, InMemoryTable } from '../../../../InMemoryTable';
import { SurveyBuilderContext } from '../../../utils';
import { StepperContentSectionTitle } from '../StepperContentSectionTitle';
import { AddEditBusinessComponentModal, BusinessComponentDetails } from './AddEditBusinessComponentModal';

export interface IBusinessComponentListViewProps {
    company: CompanyDto;
}

export const BusinessComponentListView: FC<IBusinessComponentListViewProps> = ({ company }) => {
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);
    const [selectedBusinessComponentDetails, setSelectedBusinessComponentDetails] = useState<BusinessComponentDetails>();

    const handleAddEditBusinessComponent = useCallback(
        ({ businessComponent, associatedEmployees }: BusinessComponentDetails) => {
            const existingRecordIndex = surveyPayload.businessComponentMatrixConfig.businessComponents.findIndex((bc) => bc.id === businessComponent.id);
            var updatedSurveyPayload = _.cloneDeep(surveyPayload);

            if (existingRecordIndex === -1) {
                updatedSurveyPayload.businessComponentMatrixConfig.businessComponents = [
                    ...updatedSurveyPayload.businessComponentMatrixConfig.businessComponents,
                    businessComponent,
                ];
            } else {
                let updatedBusinessComponents = updatedSurveyPayload.businessComponentMatrixConfig.businessComponents;
                updatedBusinessComponents.splice(existingRecordIndex, 1, businessComponent);
                updatedSurveyPayload.businessComponentMatrixConfig.businessComponents = updatedBusinessComponents;
            }

            let otherBusinessComponentEmployees = updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees.filter(
                (bce) => bce.businessComponentId !== businessComponent.id
            );
            const maxOrderOfOtherJoiningRecords = _.maxBy(otherBusinessComponentEmployees, (x) => x.order)?.order ?? 0;
            const startingOrderForNewRecords = maxOrderOfOtherJoiningRecords + 1;
            updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees = [
                ...otherBusinessComponentEmployees,
                ...associatedEmployees.map((employeeId, idx) => ({
                    order: startingOrderForNewRecords + idx,
                    employeeId,
                    businessComponentId: businessComponent.id,
                })),
            ];

            setSurveyPayload(updatedSurveyPayload);
            setSelectedBusinessComponentDetails(undefined);
        },
        [surveyPayload, setSurveyPayload]
    );

    const initializeNewBusinessComponent = useCallback(() => {
        setSelectedBusinessComponentDetails({
            businessComponent: { id: emptyGuid, name: '' },
            associatedEmployees: [],
        });
    }, []);

    const editBusinessComponent = useCallback(
        (bc: BusinessComponentDto) => {
            setSelectedBusinessComponentDetails({
                businessComponent: bc,
                associatedEmployees: surveyPayload.businessComponentMatrixConfig.businessComponentEmployees
                    .filter((bce) => bce.businessComponentId === bc.id)
                    .map((bce) => bce.employeeId),
            });
        },
        [surveyPayload]
    );

    const deleteBusinessComponent = useCallback(
        (id: string) => {
            var updatedSurveyPayload = _.cloneDeep(surveyPayload);
            updatedSurveyPayload.businessComponentMatrixConfig.businessComponents =
                updatedSurveyPayload.businessComponentMatrixConfig.businessComponents.filter((bc) => bc.id !== id);
            updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees =
                updatedSurveyPayload.businessComponentMatrixConfig.businessComponentEmployees.filter((bce) => bce.businessComponentId !== id);
            setSurveyPayload(updatedSurveyPayload);
        },
        [surveyPayload, setSurveyPayload]
    );

    const currentBusinessComponents = useMemo(() => {
        return surveyPayload.businessComponentMatrixConfig.businessComponents;
    }, [surveyPayload.businessComponentMatrixConfig.businessComponents]);

    const componentTableProps: IInMemoryTable<BusinessComponentDto> = useMemo(() => {
        return {
            rows: currentBusinessComponents,
            defaultSortKey: 'name',
            defaultSortDirection: 'asc',
            defaultRowsPerPage: 10,
            onRowClick: editBusinessComponent,
            columnDefinitions: [
                {
                    key: 'name',
                    label: 'Component',
                    value: 'name',
                    isSortable: true,
                },
                {
                    key: '',
                    label: '',
                    value: (row) => (
                        <div className='hover-content'>
                            <Tooltip title={`Delete Business Component`}>
                                <IconButton
                                    color='primary'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteBusinessComponent(row.id);
                                    }}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ),
                    isSortable: false,
                    width: '48px',
                },
            ],
        };
    }, [currentBusinessComponents, deleteBusinessComponent, editBusinessComponent]);

    if (!surveyPayload.study) {
        return (
            <Grid item>
                <Typography>A Study must be selected prior to completing this step. Please select a Study and then revisit this step.</Typography>
            </Grid>
        );
    }

    return (
        <>
            <Grid container direction='column'>
                <StepperContentSectionTitle icon={<Work />} title='Business Component' onAddClicked={initializeNewBusinessComponent} />
                <Grid item container direction='row' sx={{ maxHeight: '496px', overflow: 'auto' }}>
                    <InMemoryTable {...componentTableProps} />
                </Grid>
            </Grid>
            <AddEditBusinessComponentModal
                handleUpdate={handleAddEditBusinessComponent}
                allEmployees={surveyPayload.businessComponentMatrixConfig.employees}
                alreadyAddedWorkItemIds={currentBusinessComponents.map(x => x.id)}
                study={surveyPayload.study}
                businessComponentDetails={selectedBusinessComponentDetails}
                onClose={() => setSelectedBusinessComponentDetails(undefined)}
            />
        </>
    );
};
