import { AddCircle, MenuBook, Visibility } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { DataTableColumn, IStandardListViewExtraItems, StandardListViewV2 } from '../../Components/CoreLib/library';
import { StudyDto } from '../../dtos';
import { Box, FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { AddEditStudyModal, StudyFilterModal } from './Components';
import { useNavigate } from 'react-router-dom';
import { useStudyFilters } from './Hooks';
import { useGetStudiesQuery, useStudyBulkUnarchiveMutation, useStudyBulkArchiveMutation } from '../../store';
import { formateDateStandard } from '../../util';

const DEFAULT_PAGINATION = { searchText: '', sortKey: 'NAME', sortAsc: true, page: 0, pageSize: 25, includeInactive: false };
const TABLE_COLUMNS: DataTableColumn<StudyDto>[] = [
    { key: 'name', label: 'Study Name', sortKey: 'NAME' },
    { key: 'companyName', label: 'Company', sortKey: 'COMPANY_NAME' },
    { key: 'workItemCount', label: 'Components', sortKey: '', unsorted: true },
    { key: 'respondentCount', label: 'Respondents', sortKey: '', unsorted: true },
    { key: 'startDate', label: 'Start Date', sortKey: 'START_DATE', fieldMapper: (row) => formateDateStandard(row.startDate) },
    { key: 'endDate', label: 'End Date', sortKey: 'END_DATE', fieldMapper: (row) => formateDateStandard(row.endDate, 'Ongoing') },
    { key: 'status', label: 'Status', sortKey: '', unsorted: true },
];

export const StudyListView: FC = () => {
    const [isCompletedVisible, setIsCompletedVisible] = useState(true);
    const [isNewStudyModalOpen, setIsNewStudyModalOpen] = useState(false);
    const filterManager = useStudyFilters();
    const { openFilterDialog, filterChips, clearFilterByKey, appliedFilters } = filterManager;
    const navigate = useNavigate();

    const handleCreateNew = () => {
        setIsNewStudyModalOpen(true);
    };

    const navigateToDashboardPage = useCallback((recordId: string) => {
        navigate(`/studies/${recordId}`);
    }, [navigate]);

    const customMenuItems = useMemo<IStandardListViewExtraItems[]>(
        () => [
            {
                onClick: (selectedItems) => navigateToDashboardPage(selectedItems[0].id),
                text: 'View Dashboard',
                enabledCheck: (selectedItems) => selectedItems.length === 1,
            },
        ],
        [navigateToDashboardPage]
    );

    const customHeaderContent = useMemo(() => {
        return (
            <FormControlLabel
                style={{ marginRight: 1 }}
                control={<Switch size='small' checked={isCompletedVisible} onChange={(_, val) => setIsCompletedVisible(val)} />}
                label='Show Completed'
                labelPlacement='start'
            />
        );
    }, [isCompletedVisible]);

    return (
        <Box pt={2}>
            <StandardListViewV2
                headerIcon={<MenuBook />}
                headerTitle='Studies'
                headerColor='transparent'
                breadCrumbs={[{ label: 'Home', navLink: '/' }]}
                defaultPaginationProps={DEFAULT_PAGINATION}
                getDataQuery={useGetStudiesQuery}
                isShowActiveToggleVisible={true}
                activateMutation={useStudyBulkUnarchiveMutation}
                inactivateMutation={useStudyBulkArchiveMutation}
                tableColumns={TABLE_COLUMNS}
                entityNameSingular='Study'
                entityNamePlural='Studies'
                permissionName='studies'
                handleAdd={handleCreateNew}
                handleEdit={navigateToDashboardPage}
                spacing={4}
                disableGutters
                titleFontSize={32}
                preActionItemSlot={customHeaderContent}
                additionalQueryParameters={{
                    showCompleted: isCompletedVisible,
                    companyId: appliedFilters.companyId,
                    completedStatus: appliedFilters.completedStatus,
                    activeStatus: appliedFilters.activeStatus,
                    timePeriodStart: appliedFilters.timePeriodStart?.toISOString(),
                    timePeriodEnd: appliedFilters.timePeriodEnd?.toISOString()
                }}
                alternateInactivateIcons
                addButton={(<Tooltip title='Add New'>
                    <IconButton color="primary" size="large" onClick={handleCreateNew}>
                        <AddCircle fontSize="inherit" />
                    </IconButton>
                </Tooltip>)}
                additionalMenuItems={customMenuItems}
                editIcon={<Visibility />}
                editToolTip='View'
                handleFilterClicked={openFilterDialog}
                filterChips={filterChips}
                handleFilterChipDelete={clearFilterByKey}
                handleRowClick={navigateToDashboardPage}
                inactivateConfirmationDialog={
                    {
                        title: 'Inactivate Study Warning',
                        content: <>
                            <Typography> Inactivating this study will hide it by default on the Studies page. You can reactivate it later.</Typography>
                            <br />
                            <Typography>Are you sure you want to INACTIVATE this study?</Typography>
                        </>
                    }}
            />
            <AddEditStudyModal isOpen={isNewStudyModalOpen} close={() => setIsNewStudyModalOpen(false)} />
            <StudyFilterModal filterManager={filterManager} />
        </Box>
    );
};
