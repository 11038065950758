import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { useGetActivityTypesQuery } from '../../store/api/activity-stream-api';
import { snakeCaseToPrint } from '../../util';
import { eventNameMapper } from '../Forms/ActivityStream/utils';

export interface IActivityTypeSelectProps {
    selectedActivityType: string;
    handleSelectedActivityTypeChange: (selectedActivityType: string) => void;
    isRequired?: boolean;
    errorMessage?: string;
}

export const ActivityTypeSelect: FC<IActivityTypeSelectProps> = props => {
    const { selectedActivityType, handleSelectedActivityTypeChange, isRequired = false, errorMessage = ''} = props;
    const { data: activityTypes, isLoading: isLoadingActivityTypes, isError: isErrorLoadingActivityTypes } = useGetActivityTypesQuery();

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: any) => {
        handleSelectedActivityTypeChange(value);
    }

    const getSelectedActionType = () => {
        if (selectedActivityType === '') {
            return null;
        }
        return selectedActivityType;
    }

    return (
        <FormControl error={isErrorLoadingActivityTypes || !!errorMessage} fullWidth required={isRequired}>
            <FormLabel>Activity Type</FormLabel>
            <Autocomplete
                value={getSelectedActionType()}
                onChange={handleSelectChange}
                loading={isLoadingActivityTypes}
                options={activityTypes ?? []}
                getOptionLabel={x => snakeCaseToPrint(eventNameMapper(x as string))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
            />
            <FormHelperText>{isErrorLoadingActivityTypes ? 'Failed to load activity type options' : errorMessage}</FormHelperText>
        </FormControl>
    )
}