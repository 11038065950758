import { useCallback, useState } from "react";
import { CompanyDto } from "../../../dtos";
import { emptyGuid } from "../../../models";
import { useNavigate } from "react-router-dom";
import { useGetUsersLimitedQuery } from "../../../store/api/user-api";
import { SelectChangeEvent } from "@mui/material";
import { patterns } from "../../../Components/Forms/FormFields";
import { useFailedActionSnackbar } from "../../../Components/CoreLib/library";
import { useUpdateCompanyMutation } from "../../../store";

export const DEFAULT_COMPANY: CompanyDto = {
    id: emptyGuid,
    name: '',
    shortName: '',
    areaCode: '',
    phone: '',
    userId: '',
    yearEndMonth: 12,
    yearEndDay: 31,
    departments: [],
    isActive: true,
    createdOn: new Date(),
    respondentCount: 0
};

export function useCompanyForm(onSaveComplete: () => void, initValues: CompanyDto = DEFAULT_COMPANY, fixedCompanyId?: string) {
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.name : '');
    const [shortName, setShortName] = useState(initValues ? initValues.shortName : '');
    const [areaCode, setAreaCode] = useState(initValues ? initValues.areaCode : '');
    const [phone, setPhone] = useState(initValues ? initValues.phone : '');
    const [representativeId, setRepresentativeId] = useState(initValues ? initValues.userId : '');
    const [yearEndMonth, setYearEndMonth] = useState(initValues ? initValues.yearEndMonth.toString() : '');
    const [yearEndDay, setYearEndDay] = useState(initValues ? initValues.yearEndDay.toString() : '');
    const [yearEndFocus, setYearEndFocus] = useState(false);
    const navigate = useNavigate();
    const { data: users, isError: isErrorGettingUsers } = useGetUsersLimitedQuery();
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        SHORT_NAME: '',
        AREA_CODE: '',
        PHONE: '',
        REPRESENTATIVE: '',
        YEAR_END: '',
    });
    const [updateCompany] = useUpdateCompanyMutation();

    useFailedActionSnackbar('retrieve', 'representatives', isErrorGettingUsers);


    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleShortNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShortName(event.target.value);
    };

    const handleAreaCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setAreaCode(event.target.value);
        }
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const handleRepresentativeChange = (event: SelectChangeEvent) => {
        setRepresentativeId(event.target.value);
    };

    const handleYearEndMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setYearEndMonth(event.target.value);
        }
    };

    const handleYearEndDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setYearEndDay(event.target.value);
        }
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const onYearEndFocus = () => {
        setYearEndFocus(true);
    };

    const onYearEndBlur = () => {
        setYearEndFocus(false);
        validate('YEAR_END');
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NAME') {
            if (name) {
                fieldErrors.NAME = '';
                isValid = true;
            } else {
                fieldErrors.NAME = 'Name is required';
                isValid = false;
            }
        } else if (fieldName === 'SHORT_NAME') {
            if (shortName) {
                fieldErrors.SHORT_NAME = '';
                isValid = true;
            } else {
                fieldErrors.SHORT_NAME = 'Short Name is required';
                isValid = false;
            }
        } else if (fieldName === 'AREA_CODE') {
            const regex = patterns.AreaCode.validate;
            if (!areaCode || regex.test(areaCode)) {
                fieldErrors.AREA_CODE = '';
                isValid = true;
            } else {
                fieldErrors.AREA_CODE = 'Area Code must be ###';
                isValid = false;
            }
        } else if (fieldName === 'REPRESENTATIVE') {
            if (representativeId) {
                fieldErrors.REPRESENTATIVE = '';
                isValid = true;
            } else {
                fieldErrors.REPRESENTATIVE = 'Representative is required';
                isValid = false;
            }
        } else if (fieldName === 'YEAR_END') {
            const monthRegex = patterns.Month.validate;
            const monthDateRegex = patterns.MonthDate.validate;
            if (monthRegex.test(yearEndMonth) && monthDateRegex.test(yearEndDay)) {
                fieldErrors.YEAR_END = '';
                isValid = true;
            } else {
                fieldErrors.YEAR_END = 'Year End must be in format MM/DD';
                isValid = false;
            }
        }
        setFieldErrors({
            NAME: fieldErrors.NAME,
            SHORT_NAME: fieldErrors.SHORT_NAME,
            AREA_CODE: fieldErrors.AREA_CODE,
            PHONE: fieldErrors.PHONE,
            REPRESENTATIVE: fieldErrors.REPRESENTATIVE,
            YEAR_END: fieldErrors.YEAR_END,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('NAME');
        isValid = validate('SHORT_NAME') && isValid;
        isValid = validate('REPRESENTATIVE') && isValid;
        isValid = validate('YEAR_END') && isValid;
        return isValid;
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            const updatedCompany: CompanyDto = {
                ...initValues,
                id: initValues?.id ?? emptyGuid,
                isActive: isActive,
                name: name,
                shortName: shortName,
                areaCode: areaCode,
                phone: phone,
                userId: representativeId,
                yearEndMonth: parseInt(yearEndMonth),
                yearEndDay: parseInt(yearEndDay),
                createdOn: initValues?.createdOn ?? new Date(),
                departments: initValues?.departments ?? []
            };
            if (updatedCompany.id !== emptyGuid) {
                updateCompany(updatedCompany);
            }
            onSaveComplete();
        }
    };

    const loadFromInitValue = useCallback((companyDetails: CompanyDto) => {
        setName(companyDetails.name ?? '');
        setShortName(companyDetails.shortName ?? '');
        setAreaCode(companyDetails.areaCode ?? '');
        setPhone(companyDetails.phone ?? '');
        setRepresentativeId(companyDetails.userId ?? '');
        setYearEndMonth(companyDetails.yearEndMonth.toString() ?? 12);
        setYearEndDay(companyDetails.yearEndDay.toString() ?? '');
    }, []);

    const handleReset = useCallback(() => {
        loadFromInitValue(initValues);
    }, [loadFromInitValue, initValues]);

    const handleCancel = () => {
    
        navigate(-1);
        
    };


    return {
        name,
        handleNameChange,
        shortName,
        handleShortNameChange,
        areaCode,
        handleAreaCodeChange,
        phone,
        handlePhoneChange,
        representativeId,
        handleRepresentativeChange,
        yearEndMonth,
        handleYearEndMonthChange,
        yearEndDay,
        handleYearEndDayChange,
        isActive,
        handleActiveToggleChange,
        formIsValid,
        fieldErrors,
        validate,
        handleSave,
        handleCancel,
        onFieldBlur,
        onYearEndBlur,
        onYearEndFocus,
        yearEndFocus,
        users,
        handleReset
    };
}

export type CompanyFormManger = ReturnType<typeof useCompanyForm>;
