import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { CompanyDashboardContext } from '../../../../Utils';
import { AddEditDepartmentModal } from '../../../../Departments/AddEditDepartmentModal';

export const CompanyDashboardDepartmentsTabSidebarContent: FC = () => {
    const { company, selectedDepartmentId} = useContext(CompanyDashboardContext);
    const [isEditDepartmentModalOpen, setIsEditDepartmentModalOpen] = useState(false);

    const handleEdit = useCallback(() => {
        setIsEditDepartmentModalOpen(true);
    }, []);

    const department = useMemo(() => {
        return company.departments.find((dep) => {
            return dep.id === selectedDepartmentId;
        });
    }, [company, selectedDepartmentId]);

    const renderCompanyDetails = useMemo(() => {
        return (
            <Typography>Name: {department?.name}</Typography>
        );
    },[department]);

    if (!department) {
        return null;
    }

    return (
        <Box display='flex' flexDirection='column' rowGap={0.5}>
            <Box display='flex' alignItems='center' pb={1}>
                <Typography fontSize={18} fontWeight='bold'>
                    Department Details
                </Typography>
                <IconButton size='small' sx={{ ml: 1 }} onClick={handleEdit}>
                    <Edit />
                </IconButton>
            </Box>
            {!!company && renderCompanyDetails}
            <AddEditDepartmentModal company={company} department={department} isVisible={isEditDepartmentModalOpen} setIsVisible={setIsEditDepartmentModalOpen} />
        </Box>
    );
};