import { useCallback, useContext, useState } from 'react';
import { BulkUpdateResult } from '../../../../store';
import { useScheduleTableActions } from './useScheduleTableActions';
import { useSelectedScheduleFilters } from './useSelectedScheduleFilters';
import { CompanyDto, ScheduleDto } from '../../../../dtos';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSendScheduleEmailReminders } from '../../../../Views/Schedule/useSendScheduleEmailReminders';
import { ScheduleTableAction } from './useScheduleTableActionConditions';
import { ScheduleTableContext } from '../ScheduleTableContext';

export type ScheduleTableHandlers = 'SetApproved' | 'SetUnapproved' | 'SendNow' | 'CreateNew' | 'View' | 'MenuEdit' | 'MenuDuplicate' | 'SetActive' | 'PauseSurveys' | 'RestartSurveys' | 'ReopenSchedules' | 'SetInactive' | 'CreatePdf' | 'GetCSV' | 'Filter' | 'SendEmailReminder';

export function useScheduleTableButtonHandlers(selectedSchedules: readonly ScheduleDto[], can: (action: ScheduleTableAction) => boolean, isOnlyOneActiveRecurringSelected: boolean, editRoute: string, detailsRoute: string, company?: CompanyDto) {
    const [isSelectCompanyVisible, setIsSelectCompanyVisible] = useState(false);
    const [isSetInactiveModalVisible, setIsSetInactiveModalVisible] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [sendReminderEmails, setSendReminderEmails] = useState(false);
    const { surveyAliasShort } = useContext(ScheduleTableContext);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const {
        archiveSchedule,
        unarchiveSchedule,
        pauseSchedule,
        unpauseSchedule,
        createAndUploadPdf,
        getCSV,
        reopenSchedule,
        approveSchedules,
        unapproveSchedules,
        updateSchedule,
    } = useScheduleTableActions();
    const {
        reminderSchedules,
        selectedItemsThatCanBeDelayed,
        selectedItemsThatCanBeScheduled,
        selectedItemsThatCanBeActivated,
        selectedItemsThatCanBeDisabled,
    } = useSelectedScheduleFilters(selectedSchedules);

    useSendScheduleEmailReminders(reminderSchedules, sendReminderEmails, () => {
        setSendReminderEmails(false);
    });

    const handleBulkUpdateCallback = useCallback(
        (data: BulkUpdateResult, pastTenseAction: string) => {
            let itemName = '';
            if (data.successfulCount) {
                itemName = data.successfulCount > 1 ? `${surveyAliasShort}s` : surveyAliasShort;
                enqueueSnackbar(`${data.successfulCount} ${itemName} ${pastTenseAction}`, { variant: 'success' });
            }
            if (data.skippedCount) {
                itemName = data.skippedCount > 1 ? `${surveyAliasShort}s` : surveyAliasShort;
                enqueueSnackbar(`${data.skippedCount} ${itemName} did not meet requirements to be ${pastTenseAction}`, { variant: 'info' });
            }
            if (data.failedCount) {
                itemName = data.failedCount > 1 ? `${surveyAliasShort}s` : surveyAliasShort;
                enqueueSnackbar(`${data.failedCount} ${itemName} failed to be ${pastTenseAction}`, { variant: 'error' });
            }
        },
        [enqueueSnackbar, surveyAliasShort]
    );

    const handleSetApproved = useCallback(() => {
        const scheduleIds = selectedSchedules.map((selectedSchedule) => selectedSchedule.id);
        approveSchedules(scheduleIds).then((results) => {
            if ('data' in results) {
                handleBulkUpdateCallback(results.data, 'approved');
            }
        });
    }, [selectedSchedules, approveSchedules, handleBulkUpdateCallback]);

    const handleSetUnapproved = useCallback(() => {
        const scheduleIds = selectedSchedules.map((selectedSchedule) => selectedSchedule.id);
        unapproveSchedules(scheduleIds).then((results) => {
            if ('data' in results) {
                handleBulkUpdateCallback(results.data, 'unapproved');
            }
        });
    }, [selectedSchedules, unapproveSchedules, handleBulkUpdateCallback]);

    const handleDelayChange = useCallback(
        async (delay: number) => {
            selectedItemsThatCanBeDelayed.forEach((item) => {
                if (item === undefined) {
                    return;
                }

                // Despite the type here the item.scheduled comes to us as a string from the API so we need to convert it to a Date.
                const oldScheduledDate = new Date(Date.parse(item.scheduled as unknown as string));
                let newScheduledDate = new Date(oldScheduledDate);
                newScheduledDate.setDate(oldScheduledDate.getUTCDate() + delay);
                // This code here removed the timezone offset effectively converting out Date to a UTC Date.
                newScheduledDate.setTime(newScheduledDate.getTime() + newScheduledDate.getTimezoneOffset() * -60000);
                updateSchedule({
                    ...item,
                    scheduled: newScheduledDate,
                    isActive: item.isActive === undefined || item.isActive === null ? true : item.isActive,
                });
            });
        },
        [selectedItemsThatCanBeDelayed, updateSchedule]
    );

    const handleSendNow = useCallback(async () => {
        const promises = selectedItemsThatCanBeScheduled.map(async (schedule) => {
            if (!schedule) {
                return Promise.resolve();
            }

            await updateSchedule({
                ...schedule,
                scheduled: new Date(),
                status: 'SendNow',
            });
        });
        await Promise.all(promises);
        enqueueSnackbar(`${surveyAliasShort}(s) will be issued within the next 15 minutes`);
    }, [enqueueSnackbar, selectedItemsThatCanBeScheduled, updateSchedule, surveyAliasShort]);

    const handleCreateNew = useCallback(() => {
        if (company) {
            navigate(`/companies/${company.id}/${editRoute}`);
        } else {
            setIsSelectCompanyVisible(true);
        }
    }, [company, navigate, editRoute]);

    const handleMenuEdit = useCallback(() => {
        if (!can('Edit')) {
            return;
        }
        const currentSchedule = selectedSchedules[0];
        navigate(`/companies/${currentSchedule.companyId}/${editRoute}/${currentSchedule.surveyId}`);
    }, [navigate, selectedSchedules, can, editRoute]);

    const handleMenuDuplicate = useCallback(() => {
        if (!can('Duplicate')) {
            return;
        }
        const currentSchedule = selectedSchedules[0];
        navigate(`/companies/${currentSchedule.companyId}/${editRoute}/${currentSchedule.surveyId}/duplicate`);
    }, [can, navigate, selectedSchedules, editRoute]);

    const handleSetActive = useCallback(async () => {
        let promises: any[] = [];
        selectedItemsThatCanBeActivated.forEach(function (schedule) {
            if (schedule) {
                promises.push(unarchiveSchedule(schedule.id));
            }
        });

        await Promise.all(promises);
    }, [selectedItemsThatCanBeActivated, unarchiveSchedule]);

    const handlePauseSurveys = useCallback(async () => {
        let promises: any[] = [];
        selectedSchedules.forEach((selectedSchedule) => {
            if (selectedSchedule) {
                promises.push(
                    pauseSchedule({
                        parentId: selectedSchedule.id,
                    })
                );
            }
        });
        await Promise.all(promises);
    }, [pauseSchedule, selectedSchedules]);

    const handleRestartSurveys = useCallback(async () => {
        let promises: any[] = [];
        selectedSchedules.forEach((selectedSchedule) => {
            if (selectedSchedule) {
                promises.push(
                    unpauseSchedule({
                        parentId: selectedSchedule.id,
                    })
                );
            }
        });
        await Promise.all(promises);
    }, [selectedSchedules, unpauseSchedule]);

    const handleReopenSchedules = useCallback(async () => {
        let promises: any[] = [];
        selectedSchedules.forEach((selectedSchedule) => {
            if (selectedSchedule && selectedSchedule.isExpired) {
                promises.push(
                    reopenSchedule({
                        parentId: selectedSchedule.id,
                    })
                );
            }
        });
        await Promise.all(promises);
    }, [reopenSchedule, selectedSchedules]);

    const handleSetInactive = useCallback(async () => {
        if (isOnlyOneActiveRecurringSelected) {
            setIsSetInactiveModalVisible(true);
        } else {
            let promises: any[] = [];
            selectedItemsThatCanBeDisabled.forEach(function (schedule) {
                if (schedule) {
                    if (schedule.frequency === 'one-time') {
                        promises.push(
                            archiveSchedule({
                                scheduleId: schedule.id,
                                isSkipping: false,
                            })
                        );
                    }
                }
            });
            await Promise.all(promises);
        }
    }, [archiveSchedule, isOnlyOneActiveRecurringSelected, selectedItemsThatCanBeDisabled]);

    const handleCreatePdf = useCallback(() => {
        const ids = selectedSchedules.map((selectedSchedule) => selectedSchedule.id);
        createAndUploadPdf({
            scheduleIds: ids,
            scheduleDetailIds: [],
        });
        enqueueSnackbar('PDF requested and will be delivered via email when complete');
    }, [createAndUploadPdf, enqueueSnackbar, selectedSchedules]);

    const handleGetCSV = useCallback(() => {
        const ids = selectedSchedules.map((selectedSchedule) => selectedSchedule.id);
        getCSV({
            scheduleIds: ids,
            scheduleDetailIds: [],
        });
    }, [getCSV, selectedSchedules]);

    const handleTableEdit = useCallback(
        (id: string) => {
            if (id) {
                if (company) {
                    navigate(`/companies/${company.id}/${detailsRoute}s/${id}`);
                } else {
                    navigate(`/${detailsRoute}s/${id}`);
                }
            }
        },
        [company, navigate, detailsRoute]
    );

    const handleFilter = () => {
        setFilterOpen(true);
    };

    const handleSendEmailReminder = () => {
        setSendReminderEmails(true);
    };

    const handle = (action: ScheduleTableHandlers) => {
        // TODO: the methods below that are commented out are ones that require arguments. I don't have a good way to providing those so for now they will need to be returned separately.
        switch (action) {
            // case 'BulkUpdateCallback':
            //     return handleBulkUpdateCallback()
            case 'SetApproved':
                return handleSetApproved()
            case 'SetUnapproved':
                return handleSetUnapproved()
            // case 'DelayChange':
            //     return handleDelayChange()
            case 'SendNow':
                return handleSendNow()
            case 'CreateNew':
                return handleCreateNew()
            case 'MenuEdit':
                return handleMenuEdit()
            case 'MenuDuplicate':
                return handleMenuDuplicate()
            case 'SetActive':
                return handleSetActive()
            case 'PauseSurveys':
                return handlePauseSurveys()
            case 'RestartSurveys':
                return handleRestartSurveys()
            case 'ReopenSchedules':
                return handleReopenSchedules()
            case 'SetInactive':
                return handleSetInactive()
            case 'CreatePdf':
                return handleCreatePdf()
            case 'GetCSV':
                return handleGetCSV()
            case 'View':
                return handleTableEdit(selectedSchedules[0].id)
            case 'Filter':
                return handleFilter()
            case 'SendEmailReminder':
                return handleSendEmailReminder()
        
            default:
                break;
        }
    }

    return {
        isSelectCompanyVisible,
        isSetInactiveModalVisible,
        filterOpen,
        sendReminderEmails,
        handle,
        handleBulkUpdateCallback,
        handleDelayChange,
        handleTableEdit,
        setIsSetInactiveModalVisible,
        setFilterOpen,
        setIsSelectCompanyVisible,
    };
}
