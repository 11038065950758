import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { AppBar, Divider, Grid, Menu, MenuItem, Toolbar, Typography, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import img from './hk-logo.png';
import { NavigationDrawerContainer } from './NavigationDrawer/NavigationDrawerContainer';
import { IShellProps } from './types';
import { LoadingIndicator, GeneratedAvatar, AuthenticatedComponent, PermissionToggler } from '../CoreLib/library';
import { useLazyGetAllAvailablePermissionsQuery } from '../../store/api/user-api';
import { Box } from '@mui/system';

const Shell: React.FunctionComponent<IShellProps> = (props) => {
    const { isProtected } = props;
    const location = useLocation();
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const theme = useTheme();

    if (isProtected && !isAuthenticated) {
        loginWithRedirect();
        return <LoadingIndicator />;
    }

    const handleLogout = () => {
        setAnchorEl(null);
        logout({ returnTo: window.location.origin });
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const getUserName = () => {
        let name = `${user?.given_name ?? ''} ${user?.family_name ?? ''}`.trim();
        if (name === '') {
            name = user?.name ?? '';
        }
        return name;
    };

    if (isAuthenticated && location.pathname.includes('exportSurvey')) {
        return (
            <NavigationDrawerContainer>
                <div style={{ padding: '24px', minHeight: 'calc(100vh - 72px)' }}>
                    <Outlet />
                </div>
            </NavigationDrawerContainer>
        );
    }
    return (
        <>
            <NavigationDrawerContainer>
                <AppBar position='sticky' elevation={0} sx={{ height: '72px', [theme.breakpoints.down('sm')]: { height: '32px' } }}>
                    <Toolbar variant='dense'>
                        <img src={img} alt='Hull Knar' style={{ padding: theme.spacing(1), height: '100%' }} />
                        {isAuthenticated && (
                            <Grid container direction='row' justifyContent='end'>
                                <div style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                    <GeneratedAvatar name={user?.name} />
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={profileMenuOpen}
                                    onClose={handleProfileMenuClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    PaperProps={{
                                        sx: {
                                            mt: 1.5,
                                            border: 'solid 1px #AAAAAA',
                                            borderRadius: '10px',
                                            padding: '8px',
                                        },
                                    }}>
                                    <Grid container direction='column' alignItems='center' textAlign='center' sx={{ minWidth: '240px' }}>
                                        <GeneratedAvatar name={user?.name} />
                                        <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>{getUserName()}</Typography>
                                        <Typography sx={{ fontSize: '14px', marginBottom: '12px' }}>{user?.email}</Typography>
                                    </Grid>
                                    <Divider sx={{ marginBottom: '12px' }} />
                                    <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                        Sign Out
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        background: theme.palette.primary.main,
                        width: '100%',
                        [theme.breakpoints.down('sm')]: {
                            mt: 2,
                        },
                        [theme.breakpoints.up('md')]: {
                            display: 'none'
                        },
                    }}
                    px={2}>
                    <Typography fontWeight='bold' color='white' textAlign='center'>
                        This site is optimized for desktop browsers
                    </Typography>
                </Box>
                <Box
                    sx={{
                        minHeight: 'calc(100vh - 72px)',
                        [theme.breakpoints.up('sm')]: {
                            padding: '24px',
                        },
                        [theme.breakpoints.down('sm')]: {
                            padding: '8px',
                        },
                    }}>
                    <Outlet />
                </Box>
            </NavigationDrawerContainer>
            <AuthenticatedComponent
                requiredPermissions={['read:permissionToggle']}
                component={<PermissionToggler getPermissionsQuery={useLazyGetAllAvailablePermissionsQuery} />}
            />
        </>
    );
};

export default Shell;
