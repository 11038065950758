import { FC, useContext } from 'react';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';
import { RespondentDetails } from '../../../RespondentDetails';

export const StudyDashboardEmployeesTabSidebarContent: FC = () => {
    const { selectedEmployeeId, study } = useContext(StudyDashboardContext);

    if (!selectedEmployeeId) {
        return null;
    }

    return <RespondentDetails employeeId={selectedEmployeeId} companyId={study.companyId} />;
};