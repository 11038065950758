import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { IImportRespondentStepThreeProps } from '.';
import { DataTableColumn, LocalDataTable } from '../../CoreLib/library';
import { ImportRespondentRowDto } from '../../../dtos';

export const ImportRespondentsStepThree: FunctionComponent<IImportRespondentStepThreeProps> = (props) => {
	const { importResults, isLoading } = props;
	const mapStatus = (row: any) => {
		return row.status !== 'Good' ? <Typography sx={{ color: '#DF623B' }}>{row.status}</Typography> : null;
	};
	const tableColumns: DataTableColumn<ImportRespondentRowDto>[] = [
		{ key: 'firstName', label: 'First Name', sortKey: 'FIRSTNAME' },
		{ key: 'lastName', label: 'Last Name', sortKey: 'LASTNAME' },
		{ key: 'areaCode', label: 'Area Code', sortKey: 'AREACODE' },
		{ key: 'phone', label: 'Phone', sortKey: 'PHONE' },
		{ key: 'email', label: 'Email', sortKey: 'EMAIL' },
		{ key: 'department', label: 'Department', sortKey: 'DEPARTMENT' },
		{
			key: 'isPointOfContact',
			label: 'POC',
			sortKey: 'POINT_OF_CONTACT',
			fieldMapper(row) {
				return row.isPointOfContact ? 'Yes' : 'No';
			},
		},
		{ key: 'formType', label: 'Form Type', sortKey: 'FORMTYPE' },
		{ key: 'Status', label: 'Status', sortKey: 'STATUS', fieldMapper: mapStatus },
	];

	return (
		<Grid container direction='column' spacing={1}>
			{!isLoading && importResults !== undefined && (
				<>
					<Grid item>
						<Typography sx={{ fontWeight: 'bold' }}>Summary:</Typography>
					</Grid>
					<Grid item>
						<Typography>
							{importResults.recordsAdded!} {importResults.recordsAdded! === 1 ? 'record' : 'records'} added
						</Typography>
					</Grid>
					<Grid item>
						<Typography>
							{importResults.recordsSkipped!} {importResults.recordsSkipped! === 1 ? 'record' : 'records'} skipped
						</Typography>
					</Grid>
					<Grid item>
						<Typography>
							{importResults.duplicateRecords!} {importResults.duplicateRecords! === 1 ? 'record' : 'records'} marked as duplicate
						</Typography>
					</Grid>
					<Grid item marginTop={2}>
						{importResults.parsedFile?.some((x) => x.status !== 'Good') && (
							<LocalDataTable
								height={375}
								columns={tableColumns}
								loading={isLoading}
								rowData={importResults.parsedFile?.filter((x) => x.status !== 'Good')}
							/>
						)}
					</Grid>
				</>
			)}
		</Grid>
	);
};
