import { Grid, IconButton, Tooltip } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface IIconButtonWithTooltipProps extends PropsWithChildren {
    text: string;
    color?: 'primary' | 'inherit' | 'default' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    size?: 'small' | 'large' | 'medium';
    onClick: () => void;
    disabled?: boolean;
}

export const IconButtonWithTooltip: FC<IIconButtonWithTooltipProps> = ({ text, color = 'default', size = 'large', onClick, children, disabled }) => {
    return (
        <Grid item xs={'auto'}>
            <Tooltip title={text}>
                <span>
                    <IconButton color={color} size={size} onClick={onClick} disabled={disabled}>
                        {children}
                    </IconButton>
                </span>
            </Tooltip>
        </Grid>
    );
};
