import { Grid, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { BusinessComponentDetailsMetadataDto, ScheduleRespondentAnswerDto } from '../../../../../dtos';
import { FormInput } from '../../../../CoreLib/library';
import { BusinessComponentLabel } from '../BusinessComponentLabel';
import { textAlignCenterOnMobile } from '../../util';
import { patterns } from '../../../FormFields';
import { SurveyFormErrorMessage } from '../../../types';
import _ from 'lodash';

export interface ITAQByBCSurveyContentRendererProps {
    isPreview?: boolean;
    respondentAnswers: ScheduleRespondentAnswerDto[];
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
    isReadOnly: boolean;
    isSavingAnswers: boolean;
    errorMessages: SurveyFormErrorMessage[];
    setErrorMessages: (updatedErrors: SurveyFormErrorMessage[]) => void;
}

export const TAQByBCSurveyContentRenderer: FC<ITAQByBCSurveyContentRendererProps> = ({ isPreview, respondentAnswers, setQuestionAnswer, isReadOnly, isSavingAnswers, errorMessages, setErrorMessages }) => {
    const theme = useTheme();
    const TAQByBCEntries = useMemo(() => {
        return respondentAnswers.map((answerEntry) => ({
            ...answerEntry,
            businessComponentInfo: answerEntry.metadata && (JSON.parse(answerEntry.metadata.metadata) as BusinessComponentDetailsMetadataDto),
        })).sort((a, b) => (a.businessComponentInfo?.name.toLowerCase() ?? '').localeCompare(b.businessComponentInfo?.name.toLowerCase() ?? ''));
    }, [respondentAnswers]);

    const validatePercentageInput = useCallback((value: string | number) => {
        if (value === '') {
            return '';
        }
        let numericValue = Number(value);
        if (isNaN(numericValue)) {
            return 'Value must be a valid number.';
        } else if (numericValue < 0) {
            return 'Value cannot be less than 0.';
        } else if (numericValue > 100) {
            return 'Value cannot be greater than 100.';
        } else {
            return '';
        }
    }, []);

    const totalPercentage = useMemo(
        () =>
            respondentAnswers
                .map((answerEntry) => Number(answerEntry.answerValue))
                .filter((answerValue) => !isNaN(answerValue))
                .reduce((total, value) => total += value, 0),
        [respondentAnswers]
    );

    useEffect(() => {
        const currentErrorMessages = respondentAnswers
                                        .map<SurveyFormErrorMessage>(answerEntry => ({identifier: answerEntry.fieldKey, message: validatePercentageInput(answerEntry.answerValue)}))
                                        .filter(formError => !!formError.message);
        if (totalPercentage > 100) {
            currentErrorMessages.push({
                identifier: 'total',
                message: 'total may not exceed 100%.'
            });
        }

        const newErrorMessages = _.differenceBy(currentErrorMessages, errorMessages, (x) => x.identifier);
        const outdatedErrorMessages = _.differenceBy(errorMessages, currentErrorMessages, (x) => x.identifier);
        if (newErrorMessages.length > 0 || outdatedErrorMessages.length > 0) {
            setErrorMessages(currentErrorMessages);
        }
    }, [respondentAnswers, errorMessages, setErrorMessages, validatePercentageInput, totalPercentage]);

    return (
        <Grid item container direction='column' spacing={1} mt={3}>
            <Grid item container>
                <Grid item xs={12} sm='auto' sx={{ [theme.breakpoints.down('sm')]: { marginY: 2 } }}>
                    <Typography
                        variant='h2'
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: '#ffffff',
                            backgroundColor: '#606162',
                            padding: '4px 8px',
                            ...textAlignCenterOnMobile,
                        }}>
                        I. Business Components
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='end' spacing={1} sx={{ fontWeight: 'bold' }}>
                <Grid item width={200} textAlign='end'>
                    Percentage of Time
                </Grid>
            </Grid>
            {TAQByBCEntries.length === 0 && (
                <Typography textAlign='center' pt={2}>
                    {' '}
                    There are no Business Components at this time.{' '}
                </Typography>
            )}
            {/* If performance becomes an issue here we may be able to replace this with a FixedSizeList to control how many elements need to be re-rendered */}
            {TAQByBCEntries.map((taqByBcEntry) => (
                <Grid item container direction='row' key={taqByBcEntry.fieldKey} spacing={1} alignItems='start'>
                    <Grid item flexGrow={1} ml={3}>
                        <BusinessComponentLabel bcInfo={taqByBcEntry.businessComponentInfo} />
                    </Grid>
                    <Grid item width={100}>
                        <FormInput
                            label={''}
                            value={taqByBcEntry.answerValue.toString()}
                            onChange={(event) => setQuestionAnswer(taqByBcEntry.fieldKey, event.target.value)}
                            inputProps={{ inputMode: 'numeric', pattern: patterns.Numeric.validate.source, className: 'standard-outlined-input' }}
                            fullWidth
                            disabled={isPreview || isReadOnly || isSavingAnswers}
                            error={!!validatePercentageInput(taqByBcEntry.answerValue)}
                            errorText={validatePercentageInput(taqByBcEntry.answerValue)}
                        />
                    </Grid>
                    <Grid item alignSelf='center'>%</Grid>
                </Grid>
            ))}
            <Grid item textAlign='right'>
                <Typography fontWeight='bold'>
                    Subtotal Business Components <span style={{ marginLeft: '40px' }}>{totalPercentage}%</span>
                </Typography>
            </Grid>
            <Grid item textAlign='right'>
                <Typography fontWeight='bold'>
                    TotalActivities <span style={{ marginLeft: '40px' }}>{totalPercentage}%</span>
                </Typography>
            </Grid>
            <Grid item textAlign='right'>
                <Typography fontWeight='bold' color='error'>
                    {totalPercentage > 100 && 'The total percentage of time may not exceed 100%.'}
                </Typography>
            </Grid>
        </Grid>
    );
};
