import { FC, PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AccountBox, Ballot, Business, Email, GridOn, Home, InsertChart, ListAlt, MenuBook } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../CoreLib/library';
import NavigationDrawer from './NavigationDrawer';
import { NavigationDrawerRouteDTO } from './types';

const pageRoutes: NavigationDrawerRouteDTO[] = [
    {
        icon: <Home />,
        name: 'Home',
        route: '/',
        isProtected: true,
    },
    {
        icon: <Business />,
        name: 'Companies',
        route: '/companies',
        isProtected: true,
    },
    {
        icon: <MenuBook />,
        name: 'Studies',
        route: '/studies',
        isProtected: true,
    },
    {
        icon: <ListAlt />,
        name: 'Surveys',
        route: '/schedules',
        isProtected: true,
    },
    {
        icon: <GridOn />,
        name: 'BCMs',
        route: '/business-components',
        isProtected: true,
    },
    {
        icon: <InsertChart />,
        name: 'Advanced Reporting',
        route: process.env.REACT_APP_REPORTING_URL,
        isProtected: true,
        isExternalLink: true,
    },
];

const adminRoutes: NavigationDrawerRouteDTO[] = [
    {
        icon: <Ballot />,
        name: 'Form Types',
        route: '/form-types',
        isProtected: true,
    },
    {
        icon: <Email />,
        name: 'Email Templates',
        route: '/email-templates',
        isProtected: true,
    },
    {
        icon: <AccountBox />,
        name: 'Users',
        route: '/users',
        isProtected: true,
        requiredPermissions: ['read:users']
    },
];

export const NavigationDrawerContainer: FC<PropsWithChildren> = (props) => {
    const { isAuthenticated, isLoading, error } = useAuth0();
    const location = useLocation();

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }

    if (isAuthenticated && location.pathname.includes('exportSurvey')) {
        return <div>{props.children}</div>;
    }

    if (isAuthenticated) {
        return (
            <NavigationDrawer routes={pageRoutes} adminRoutes={adminRoutes}>
                {props.children}
            </NavigationDrawer>
        );
    } else {
        return <>{props.children}</>;
    }
};
