import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from './store';
import { SearchQueryParams } from './types';
import { buildSearchQueryRoute } from '../util';
import { CompanyDto, QueryResultsDto } from '../dtos';
import { GroupByDto } from '../dtos/generated/GroupByDto';

export interface GetCompanyByIdQueryParameters {
    id: string;
}

export interface GetCompanySurveyStatisticsByIdQueryParameters {
    companyId: string;
    searchParams: {
        status?: string;
        formType?: string;
        reminder?: string;
        approvedBy?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
        assignedToStudyId?: string;
    }
}

export interface GetCompanyBCMStatisticsByIdQueryParameters {
    companyId: string;
    searchParams: {
        status?: string;
        reminder?: string;
        approvedBy?: string;
        fromDate?: Date;
        toDate?: Date;
        periodStartDate?: Date;
        periodEndDate?: Date;
        assignedToStudyId?: string;
    }
}

export const serverApi = createApi({
    reducerPath: 'serverApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    tagTypes: [
        'Company',
        'Department',
        'Representative',
        'Respondent',
        'Schedule',
        'ScheduleDetails',
        'Survey',
        'SurveyTemplate',
        'Import',
        'ScheduleRespondentAnswers',
        'EmailTemplate',
        'User',
        'Study',
        'WorkItem',
    ],
    endpoints: (builder) => ({
        getCompanies: builder.query<QueryResultsDto<CompanyDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('company', params),
            providesTags: [{ type: 'Company' }],
        }),
        getRecentCompanies: builder.query<QueryResultsDto<CompanyDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('company/recent', params),
            providesTags: [{ type: 'Company' }],
        }),
        getCompany: builder.query<CompanyDto, string>({
            query: (companyId) => ({ url: `company/${companyId}` }),
            providesTags: [{ type: 'Company' }],
        }),
        createCompany: builder.mutation<CompanyDto, CompanyDto>({
            query: (companyDto) => ({
                url: 'company',
                method: 'POST',
                body: companyDto,
            }),
            invalidatesTags: [{ type: 'Company' }],
        }),
        updateCompany: builder.mutation<void, CompanyDto>({
            query: (companyDto) => ({
                url: 'company',
                method: 'PUT',
                body: companyDto,
            }),
            invalidatesTags: [{ type: 'Company' }],
        }),
        archiveCompany: builder.mutation<void, string>({
            query: (id) => ({
                url: `company/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Company' }],
        }),
        unarchiveCompany: builder.mutation<void, string>({
            query: (id) => ({
                url: `company/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Company' }],
        }),
        getCompanyWorkItemsByType: builder.query<GroupByDto[], GetCompanyByIdQueryParameters>({
            query: (params) => buildSearchQueryRoute(`company/${params.id}/statistics/work-item-by-type`, params),
            providesTags: ['WorkItem'],
        }),
        getCompanySurveysByStatus: builder.query<GroupByDto[], GetCompanySurveyStatisticsByIdQueryParameters>({
            query: (params) => buildSearchQueryRoute(`company/${params.companyId}/statistics/survey-by-status`, params.searchParams),
            providesTags: ['Schedule'],
        }),
        getCompanyBcmsByStatus: builder.query<GroupByDto[], GetCompanyBCMStatisticsByIdQueryParameters>({
            query: (params) => buildSearchQueryRoute(`company/${params.companyId}/statistics/bcm-by-status`, params.searchParams),
            providesTags: ['Schedule'],
        }),
    }),
});

export const {
    useGetCompaniesQuery,
    useGetRecentCompaniesQuery,
    useGetCompanyQuery,
    useLazyGetCompanyQuery,
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
    useArchiveCompanyMutation,
    useUnarchiveCompanyMutation,
    useGetCompanyBcmsByStatusQuery,
    useLazyGetCompanyBcmsByStatusQuery,
    useGetCompanySurveysByStatusQuery,
    useLazyGetCompanySurveysByStatusQuery,
    useGetCompanyWorkItemsByTypeQuery,
    useLazyGetCompanyWorkItemsByTypeQuery
} = serverApi;
