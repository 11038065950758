import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { FormControl, FormLabel, Grid, IconButton, TextField } from '@mui/material';
import { ChangeEvent, FC, useContext, useMemo } from 'react';
import { SurveyPayloadTimePeriodDto, SurveyPayloadDistributionConfigDto } from '../../../../dtos';
import { TimePeriodInput } from '../../../CommonInputs';
import { SurveyBuilderContext } from '../../utils';


interface ITimePeriodTextBoxRowProps {
    timePeriod: SurveyPayloadTimePeriodDto;
    timePeriodIndex: number;
    changeTimePeriodArray: (timePeriod: SurveyPayloadTimePeriodDto, index: number) => void;
    handleRemoveTextBox: (index: number) => void;
    handleAddTextBox: () => void;
    timePeriodArrayLength: number;
    distributionConfig: SurveyPayloadDistributionConfigDto;
    allowMultipleTimePeriods?: boolean;
    isBCM?: boolean;
}

export const TimePeriodTextBoxRow: FC<ITimePeriodTextBoxRowProps> = (props) => {
    const { timePeriod, timePeriodIndex, changeTimePeriodArray, handleRemoveTextBox, handleAddTextBox, timePeriodArrayLength, distributionConfig, allowMultipleTimePeriods, isBCM = false } = props;
    const { surveyAliasShort } = useContext(SurveyBuilderContext);

    const showLabels = useMemo(() => {
        return timePeriodIndex === 0;
    }, [timePeriodIndex]);

    const handleDatesChanged = (startDate?: Date | null | string, endDate?: Date | null | string) => {
        changeTimePeriodArray({ ...timePeriod, startDate: startDate, endDate: endDate }, timePeriodIndex);
    };

    const handleTextBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
        let newTextValue = event.target.value
        changeTimePeriodArray({ ...timePeriod, surveyLabel: newTextValue }, timePeriodIndex);
    };

    const handleAddRemoveButtons = () => {
        if (timePeriodArrayLength === 1) {
            return (
                <Grid item container direction='row' xs={1}>
                    <Grid item container direction="column" justifyContent="center" xs={6}>
                        <IconButton color="primary" size="large" onClick={handleAddTextBox} disabled={distributionConfig.surveyDistribution === "individualRecipient" && distributionConfig.fillOutSurveyValue === "Individual" && !isBCM}>
                            <AddCircle fontSize="inherit" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} />
                </Grid>
            );
        }
        else {
            if (timePeriodArrayLength === timePeriodIndex + 1) {
                return (
                    <Grid item container direction='row' xs={1}>
                        <Grid item container direction="column" justifyContent="start" xs={6}>
                            <IconButton size="large" sx={{ color: "#333333" }} onClick={() => handleRemoveTextBox(timePeriodIndex)}>
                                <RemoveCircle fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item container direction="column" justifyContent="center" xs={6}>
                            <IconButton color="primary" size="large" onClick={handleAddTextBox}>
                                <AddCircle fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            }
            else {
                return (
                    <Grid item container direction='row' xs={1}>
                        <Grid item container direction="column" justifyContent="start" xs={6}>
                            <IconButton size="large" sx={{ color: "#333333" }} onClick={() => handleRemoveTextBox(timePeriodIndex)}>
                                <RemoveCircle fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} />
                    </Grid>
                )

            }
        }
    }
    return (
        <Grid item container direction="row" justifyContent="flex-start" alignItems="end" xs={11} wrap='nowrap'>
            <Grid item container direction="row" justifyContent="start" alignItems='end' xs={12} spacing={1} wrap='nowrap'>
                <TimePeriodInput
                    index={timePeriodIndex}
                    showLabels={showLabels}
                    defaultStartDate={timePeriod.startDate}
                    defaultEndDate={timePeriod.endDate}
                    handleDatesChanged={handleDatesChanged}
                    quarterOptionsEnabled={!isBCM}
                />
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        {showLabels ? <FormLabel>{surveyAliasShort} Label</FormLabel> : <></>}
                        <TextField
                            value={timePeriod.surveyLabel}
                            onChange={handleTextBoxChange}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            {allowMultipleTimePeriods && handleAddRemoveButtons()}
        </Grid>
    );
}
