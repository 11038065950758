import { Box, Grid, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RespondentDto } from '../../../../dtos';
import { CompanyRespondentSelect } from '../../../CommonInputs';
import { SurveyBuilderContext } from '../../utils';
import { RespondentQuickAdd } from '../../../QuickAdds';

export const SurveyDistributionRespondent: FC = () => {
    const { surveyPayload, setSurveyPayload, surveyAliasShort } = useContext(SurveyBuilderContext);
    const { id } = useParams();

    const updateSelectedRecipient = useCallback((respondent: RespondentDto | undefined) => {
        const updatedList = respondent ? [ respondent ] : [];
        setSurveyPayload({
            ...surveyPayload,
            distributionConfig: {
                ...surveyPayload.distributionConfig,
                individualRecipient: updatedList,
            },
        });
    }, [surveyPayload, setSurveyPayload]);

    const handleNoRespondents = () => {
        if (surveyPayload.distributionConfig.individualRecipient.length === 0 && surveyPayload.distributionConfig.surveyDistribution === "individualRecipient") {
            return "One individual required with option selected."
        }
    }

    const selectedRecipientId = useMemo(() => {
        if (surveyPayload.distributionConfig.individualRecipient.length > 0) {
            return surveyPayload.distributionConfig.individualRecipient[0].id
        }
        return '';
    }, [surveyPayload.distributionConfig.individualRecipient]);

    return (
        <Box sx={{
            backgroundColor: 'primary.contrastText',
            py: 4,
            px: 4
        }}>
            <Grid item container direction="row" alignItems="start">
                <Grid item container direction="column" justifyContent="start" xs={6}>
                    <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                        Who should receive the {surveyAliasShort.toLowerCase()}?
                    </Typography>
                </Grid>
                <Grid item container direction="row" alignItems="center" justifyContent="flex-end" xs={6}>
                    <Grid item>
                        <RespondentQuickAdd companyId={id!} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center" spacing={2}>
                <Grid item pb={.5} fontWeight='bold'>
                    <span>Recipient</span>
                </Grid>
                <Grid item width={336}>
                    <CompanyRespondentSelect
                        companyId={id!}
                        selectedRespondentId={selectedRecipientId}
                        handleSelectedRespondentChange={updateSelectedRecipient}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems="center" sx={{ marginTop: 2 }}>
                <Grid item container direction="column" justifyContent="center" xs={'auto'}>
                    <Typography variant="body1" sx={{ px: 2, color: '#DF623B' }}>
                        {handleNoRespondents()}
                    </Typography>
                </Grid>
            </Grid >
        </Box >
    );
}