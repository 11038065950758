import { Button, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { ImportRowStatus, ImportWorkItemRowDto } from '../../../dtos';
import { InMemoryTable } from '../../InMemoryTable';
import { WORK_ITEM_IMPORT_COLUMN_DEFINITION } from './Constants';
import { LoadingIndicator } from '../../CoreLib/library';

export interface IImportWorkItemsImportResulteProps {
	onCloseClicked: () => void;
    importResults: ImportWorkItemRowDto[];
    isLoading: boolean;
    isError: boolean;
}

export const ImportWorkItemsImportResult: FC<IImportWorkItemsImportResulteProps> = ({ onCloseClicked, importResults, isLoading, isError }) => {
	const recordsAdded = useMemo(() => importResults.filter(record => record.status === ImportRowStatus.Added).length, [importResults]);
	const recordsSkipped = useMemo(() => importResults.filter(record => record.status === ImportRowStatus.Skipped).length, [importResults]);
	const duplicateRecords = useMemo(() => importResults.filter(record => record.status === ImportRowStatus.Duplicate).length, [importResults]);

	const resultContent = useMemo(() => {
		if (isLoading) {
			return <Grid item><LoadingIndicator /></Grid>;
		}
		if (isError) {
			return <Grid item><Typography color='error'>An error occurred while attempting to import records. Please try again and contact technical support if issue persists.</Typography></Grid>;
		}
		return (
			<>
				<Grid item>
					<Typography sx={{ fontWeight: 'bold' }}>Summary:</Typography>
				</Grid>
				<Grid item>
					<Typography>
						{recordsAdded!} {recordsAdded! === 1 ? 'record' : 'records'} added
					</Typography>
				</Grid>
				<Grid item>
					<Typography>
						{recordsSkipped!} {recordsSkipped! === 1 ? 'record' : 'records'} skipped
					</Typography>
				</Grid>
				<Grid item>
					<Typography>
						{duplicateRecords!} {duplicateRecords! === 1 ? 'record' : 'records'} marked as duplicate
					</Typography>
				</Grid>
				<Grid item mt={2}>
					<InMemoryTable
						rows={importResults.filter(record => record.status !== ImportRowStatus.Added)}
						columnDefinitions={WORK_ITEM_IMPORT_COLUMN_DEFINITION}
						defaultSortKey='NAME'
						defaultSortDirection='asc'
					/>
				</Grid>
			</>
		)
	}, [isLoading, isError, recordsAdded, importResults, recordsSkipped, duplicateRecords]);
	
	return (
		<Grid container direction='column' justifyContent={'space-between'}>
			{resultContent}
			<Grid item display='flex' justifyContent='end' mt={2}>
				<Button variant='contained' onClick={onCloseClicked}>
					Close
				</Button>
			</Grid>
		</Grid>
	);
};
