import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, SyntheticEvent, useMemo } from 'react';
import { WorkItemDto } from '../../dtos';
import { useGetStudyWorkItemsQuery } from '../../store';

export interface IWorkItemSelectProps {
    studyId: string;
    selectedWorkItemId: string;
    handleSelectedWorkItemChange: (selectedWorkItem?: WorkItemDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    label?: string;
    itemFilter?: (item: WorkItemDto) => boolean;
}

export const WorkItemSelect: FC<IWorkItemSelectProps> = ({ studyId, selectedWorkItemId , handleSelectedWorkItemChange, required, errorMessage, disabled, label = 'Component', itemFilter = () => true }) => {
    const { data: workItems, isLoading: isLoadingWorkItems } = useGetStudyWorkItemsQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
        studyId: studyId,
        itemType: 'BUSINESS COMPONENT'
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: WorkItemDto | null) => {
        handleSelectedWorkItemChange(value ?? undefined);
    }

    const selectedWorkItem = useMemo(() => {
        if (selectedWorkItemId === '') {
            return null;
        }
        return workItems?.pageResults.find(x => x.id === selectedWorkItemId);
    }, [selectedWorkItemId, workItems])

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                key={selectedWorkItemId}
                value={selectedWorkItem ?? null}
                onChange={handleSelectChange}
                loading={isLoadingWorkItems}
                options={workItems?.pageResults.filter(itemFilter) ?? []}
                getOptionLabel={x => x?.name ?? ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={disabled}
                renderInput={(params) => (<TextField {...params} />)}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}