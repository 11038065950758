import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { CompanyDashboardContext } from '../../../../Utils';
import { AddEditWorkItemModal } from '../../../../../Studies/Components/AddEditWorkItemModal';
import { WorkItemType } from '../../../../../../dtos';
import { formateDateStandard, toWords } from '../../../../../../util';
import { useGetWorkItemByIdQuery } from '../../../../../../store';

export const CompanyDashboardComponentsTabSidebarContent: FC = () => {
    const { company, selectedComponentId } = useContext(CompanyDashboardContext);
    const [isEditComponentsModalOpen, setIsEditComponentsModalOpen] = useState(false);
    const {
        data: workItem,
    } = useGetWorkItemByIdQuery({ id: selectedComponentId });

    const handleCloseModal = useCallback(() => {
        setIsEditComponentsModalOpen(false);
    }, []);

    const handleEdit = useCallback(() => {
        setIsEditComponentsModalOpen(true);
    }, []);

    const renderComponentDetails = useMemo(() => {
        return (
            <Box display='flex' flexDirection={'column'}>
                <Typography>Name: {workItem?.name}</Typography>
                <Typography>Type: {workItem && toWords(WorkItemType[workItem.type]?.toString())}</Typography>
                <Typography>Start Date: {formateDateStandard(workItem?.startDate)}</Typography>
                <Typography>End Date: {formateDateStandard(workItem?.endDate, 'Ongoing')}</Typography>
                <Typography>Description: {workItem?.description !== '' ? workItem?.description : 'None'}</Typography>
            </Box>
        );
    },[workItem]);

    if (!workItem) {
        return null;
    }

    
    return (
        <Box display='flex' flexDirection='column' rowGap={0.5}>
            <Box display='flex' alignItems='center' pb={1}>
                <Typography fontSize={18} fontWeight='bold'>
                    Component Details
                </Typography>
                <IconButton size='small' sx={{ ml: 1 }} onClick={handleEdit}>
                    <Edit />
                </IconButton>
            </Box>
        {!!selectedComponentId && renderComponentDetails}
        
        <AddEditWorkItemModal isOpen={isEditComponentsModalOpen} close={handleCloseModal} companyId={company.id} workItemToUpdate={workItem}/>
    </Box>
    );
};