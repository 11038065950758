import { BulkDepartmentArchiveParams, BulkUpdateWithParentId, ChildQueryParams, DepartmentArchiveParams, SearchQueryParamsWithParent, serverApi } from '..';
import { BulkUpdateResultDto, DepartmentDto, QueryResultsDto } from '../../dtos';
import { buildSearchQueryRoute } from '../../util';

const departmentApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getDepartments: builder.query<QueryResultsDto<DepartmentDto>, SearchQueryParamsWithParent>({
            query: (params) => buildSearchQueryRoute(`company/${params.parentId}/department`, params),
            providesTags: [{ type: 'Company' }, { type: 'Department' }],
        }),
        getCompanyDepartmentsWithNoRespondents: builder.query<DepartmentDto[], string>({
            query: (companyId) => ({ url: `company/${companyId}/department/noRespondents` }),
            providesTags: [{ type: 'Department' }],
        }),
        getCompanyDepartmentById: builder.query<DepartmentDto, {companyId: string, departmentId: string}>({
            query: ({ companyId, departmentId }) => ({ url: `company/${companyId}/department/${departmentId}` }),
            providesTags: [{ type: 'Company' }, { type: 'Department' }],
        }),
        getCompanyDepartments: builder.query<DepartmentDto[], string>({
            query: (companyId) => ({ url: `company/${companyId}/department/all` }),
            providesTags: [{ type: 'Company' }, { type: 'Department' }],
        }),
        createDepartment: builder.mutation<DepartmentDto, DepartmentDto>({
            query: (departmentDto) => ({
                url: `company/${departmentDto.companyId}/department`,
                method: 'POST',
                body: departmentDto,
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }],
        }),
        updateDepartment: builder.mutation<void, DepartmentDto>({
            query: (departmentDto) => ({
                url: `company/${departmentDto.companyId}/department`,
                method: 'PUT',
                body: departmentDto,
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }],
        }),
        archiveDepartment: builder.mutation<void, DepartmentArchiveParams>({
            query: (params) => ({
                url: `company/${params.parentId}/department/archive`,
                method: 'PUT',
                body: params.body,
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        unarchiveDepartment: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `company/${params.parentId}/department/${params.childId}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }],
        }),
        bulkArchiveDepartments: builder.mutation<BulkUpdateResultDto, BulkDepartmentArchiveParams>({
            query: (params) => ({
                url: `company/${params.parentId}/department/bulkArchive`,
                method: 'PUT',
                body: params.body
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
        bulkUnarchiveDepartments: builder.mutation<BulkUpdateResultDto, BulkUpdateWithParentId>({
            query: (params) => ({
                url: `company/${params.parentId}/department/bulkUnarchive`,
                method: 'PUT',
                body: params.recordIds
            }),
            invalidatesTags: [{ type: 'Company' }, { type: 'Department' }, { type: 'Respondent' }],
        }),
    }),
});

export const {
    useGetDepartmentsQuery,
    useGetCompanyDepartmentsQuery,
    useCreateDepartmentMutation,
    useUpdateDepartmentMutation,
    useArchiveDepartmentMutation,
    useUnarchiveDepartmentMutation,
    useBulkArchiveDepartmentsMutation,
    useBulkUnarchiveDepartmentsMutation,
    useGetCompanyDepartmentsWithNoRespondentsQuery,
    useLazyGetCompanyDepartmentByIdQuery
} = departmentApi;
