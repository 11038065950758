import { DesktopDatePicker } from '@mui/x-date-pickers';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC } from 'react';

export interface IStandardDatePickerProps {
    label?: string;
    value: Date | null;
    handleDateChange: (newDate: Date | null) => void;
    errorMessage?: string;
    required?: boolean;
    disabled?: boolean;
}

export const StandardDatePicker: FC<IStandardDatePickerProps> = ({ label, value, handleDateChange, errorMessage, required, disabled }) => {
    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <DesktopDatePicker
                value={value}
                onChange={(e: any) => {
                    handleDateChange(e?.$d ?? null);
                }}
                componentsProps={{
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                renderInput={(params) => <TextField {...params} />}
                disabled={disabled}
                closeOnSelect={true}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    );
};
