import Papa from 'papaparse';
import { ImportWorkItemRowDto } from '../../../../dtos';
import { useCallback, useState } from 'react';

const REQUIRED_HEADERS = ['Business Component (R&D Projects)', 'Employee'];
const IMPORT_ROW_FIELD_NAME_MAP = {
    businessComponentName: 'Business Component (R&D Projects)',
    businessComponentDescription: 'Research Activities',
    businessComponentStartDate: 'Start Date',
    businessComponentEndDate: 'End Date',
    employeeName: 'Employee'
}

export function useWorkItemImportFileProcessor(companyId: string) {
    const [isParsingFile, setIsParsingFile] = useState(false);
    const [fileProcessingError, setFileProcessingError] = useState('');
    const [parseWorkItems, setParsedWorkItems] = useState<ImportWorkItemRowDto[]>([]);

    const reset = useCallback(() => {
        setIsParsingFile(false);
        setFileProcessingError('');
        setParsedWorkItems([]);
    }, []);
    
    const extractImportDataFromFile = useCallback((file: File) => {
        reset();
        setIsParsingFile(true);
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                setIsParsingFile(false);
                // verify required headers are provided
                const isRequiredHeadersPresent = REQUIRED_HEADERS.every(reqHeader => result.meta.fields?.includes(reqHeader));
                if (!isRequiredHeadersPresent) {
                    setFileProcessingError(`File cannot be processed. Missing one or more of the following required headers: ${REQUIRED_HEADERS.join(', ')}`);
                    return;
                }
                if (!result.data) {
                    setFileProcessingError(`File cannot be processed. No data parsed from file`);
                    return;
                }
                const parsedRecords = result.data.map<ImportWorkItemRowDto>((importCSVRow: any) => ({
                    companyId: companyId,
                    businessComponentName: importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.businessComponentName] ?? '',
                    businessComponentDescription: importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.businessComponentDescription] ?? '',
                    businessComponentStartDate: importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.businessComponentStartDate] ? new Date(importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.businessComponentStartDate]).toISOString() as unknown as Date : undefined,
                    businessComponentEndDate: importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.businessComponentEndDate] ? new Date(importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.businessComponentEndDate]).toISOString() as unknown as Date : undefined,
                    employeeName: importCSVRow[IMPORT_ROW_FIELD_NAME_MAP.employeeName] ?? ''
                }));
                setParsedWorkItems(parsedRecords);
            },
            error: (error) => {
                console.error('CSV parsing error:', error.message);
                setFileProcessingError('An error occurred while attempting to parse selected file. If error persists please contact technical support.');
            },
        });
    }, [companyId, reset]);
    
    return {
        isParsingFile,
        fileProcessingError,
        extractImportDataFromFile,
        parseWorkItems
    }
}