import { AccountBox } from '@mui/icons-material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginatedProps, DataTableColumn, StandardListView } from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { useGetUsersQuery, useBulkArchiveUserMutation, useBulkUnarchiveUserMutation } from '../../store/api/user-api';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'FIRST_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const UsersListView: FC = () => {
    const navigate = useNavigate();
    const tableColumns: DataTableColumn<UserDto>[] = [
        { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
        { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
        { key: 'role', label: 'User Role', sortKey: 'ROLE' },
        { key: 'email', label: 'Email', sortKey: 'EMAIL' },
        { key: 'phoneNumber', label: 'Phone', sortKey: 'PHONE_NUMBER' },
    ];

    const handleAdd = () => {
        navigate('/users/create');
    }

    const handleEdit = (id: string) => {
        navigate(`/users/${id}`);
    }

    return (
        <StandardListView
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetUsersQuery}
            archiveMutation={useBulkArchiveUserMutation}
            recoverMutation={useBulkUnarchiveUserMutation}
            tableColumns={tableColumns}
            entityNameSingular='User'
            permissionName='users'
            headerTitle='Users'
            headerIcon={<AccountBox />}
            breadCrumbs={[
                { label: 'Admin', navLink: '', isText: true },
            ]}
            handleAdd={handleAdd}
            handleEdit={handleEdit}
        />
    );
};
