import { serverApi } from '..';
import { BulkUpdateResultDto, QueryResultsDto, WorkItemDto } from '../../dtos';
import { buildSearchQueryRoute } from '../../util';

export interface GetWorkItemQueryParameters {
    searchText?: string;
    sortKey?: string;
    sortAsc: boolean;
    page: number;
    pageSize: number;
    includeInactive: boolean;
    studyId?: string;
    itemType?: string;
}

export interface GetWorkItemByIdQueryParameters {
    id: string;
}

export interface DeleteWorkItemByIdQueryParameters {
    id: string;
}

export interface UpdateWorkItemByIdQueryParameters {
    id: string;
}

const workItemApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkItem: builder.query<QueryResultsDto<WorkItemDto>, GetWorkItemQueryParameters>({
            query: (args) => buildSearchQueryRoute('WorkItem', args),
            providesTags: ['WorkItem'],
        }),
        createWorkItem: builder.mutation<WorkItemDto, WorkItemDto>({
            query: (args) => ({
                url: `WorkItem`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: ['WorkItem'],
        }),
        updateWorkItem: builder.mutation<void, WorkItemDto>({
            query: (args) => ({
                url: `WorkItem`,
                method: 'PUT',
                body: args,
            }),
            invalidatesTags: ['WorkItem'],
        }),
        getWorkItemById: builder.query<WorkItemDto, GetWorkItemByIdQueryParameters>({
            query: (args) => ({
                url: `WorkItem/${args.id}`,
                method: 'GET',
            }),
            providesTags: ['WorkItem'],
        }),
        deleteWorkItemById: builder.mutation<void, DeleteWorkItemByIdQueryParameters>({
            query: (args) => ({
                url: `WorkItem/${args.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['WorkItem'],
        }),
        updateWorkItemById: builder.mutation<void, UpdateWorkItemByIdQueryParameters>({
            query: (args) => ({
                url: `WorkItem/${args.id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['WorkItem'],
        }),
        workItemBulkArchive: builder.mutation<BulkUpdateResultDto, string[]>({
            query: (args) => ({
                url: `WorkItem/bulkArchive`,
                method: 'PATCH',
                body: args,
            }),
            invalidatesTags: ['WorkItem'],
        }),
        workItemBulkUnarchive: builder.mutation<BulkUpdateResultDto, string[]>({
            query: (args) => ({
                url: `WorkItem/bulkUnarchive`,
                method: 'PATCH',
                body: args,
            }),
            invalidatesTags: ['WorkItem'],
        }),
    }),
});

export const {
    useGetWorkItemQuery,
    useLazyGetWorkItemQuery,
    useCreateWorkItemMutation,
    useUpdateWorkItemMutation,
    useGetWorkItemByIdQuery,
    useLazyGetWorkItemByIdQuery,
    useDeleteWorkItemByIdMutation,
    useUpdateWorkItemByIdMutation,
    useWorkItemBulkArchiveMutation,
    useWorkItemBulkUnarchiveMutation,
} = workItemApi;
