import { Contacts } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormHelperText,
    FormLabel,
    OutlinedInput
} from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { StandardDialogHeader } from '../../../../Components/CommonComponents';
import { useFailedCreateSnackbar, useFailedUpdateSnackbar, useSuccessfulCreateSnackbar, useSuccessfulUpdateSnackbar } from '../../../../Components/CoreLib/library';
import { CompanyDto, DepartmentDto } from '../../../../dtos';
import { emptyGuid } from '../../../../models';
import { useCreateDepartmentMutation, useUpdateDepartmentMutation } from '../../../../store';

export interface IAddEditDepartmentModalProps {
    company: CompanyDto;
    department?: DepartmentDto;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    afterCreate?: (newDepartment: DepartmentDto) => void;
}

export const AddEditDepartmentModal: FunctionComponent<IAddEditDepartmentModalProps> = ({ company, department, isVisible, setIsVisible, afterCreate }) => {
    const [name, setName] = useState(department ? department.name : '');
    const [invalidName, setInvalidName] = useState('');
    const [nameError, setNameError] = useState('');
    const [hasSaved, setHasSaved] = useState(false);
    const [createDepartment, { isSuccess, isLoading, isError, reset }] = useCreateDepartmentMutation();
    const [updateDepartment, { isSuccess: isUpdateSuccess, isLoading: updateLoading, isError: isUpdateError, reset: resetUpdate }] = useUpdateDepartmentMutation();

    useSuccessfulCreateSnackbar('department', isSuccess, reset);
    useFailedCreateSnackbar('department', isError, reset);
    useSuccessfulUpdateSnackbar('department', isUpdateSuccess, resetUpdate);
    useFailedUpdateSnackbar('department', isUpdateError, resetUpdate);

    useEffect(() => {
        if (department) {
            setName(department.name);
        }
    }, [department]);

    useEffect(() => {
        if (hasSaved && !isLoading && !updateLoading && name) {
            if (isError || isUpdateError) {
                setInvalidName(name);
                setNameError('Department name already exists.');
            } else if (isSuccess || isUpdateSuccess) {
                setInvalidName('');
                setNameError('');
                setName('');
                setIsVisible(false);
            }
            setHasSaved(false);
        }
    }, [isLoading, name, isError, isSuccess, setInvalidName, setNameError, setName, setIsVisible, hasSaved, setHasSaved, updateLoading, isUpdateError, isUpdateSuccess]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const validateName = () => {
        if (name) {
            if (name === invalidName) {
                setNameError('Department name already exists.');
            } else {
                setNameError('');
            }
        } else {
            setNameError('Department name is required');
        }
    };

    const handleSave = () => {
        validateName();
        if (!nameError) {
            setHasSaved(true);
            if (department) {
                updateDepartment({
                    ...department,
                    name: name
                });
            } else {
                createDepartment({
                    id: emptyGuid,
                    name: name,
                    companyId: company.id,
                    isActive: true,
                    respondentCount: 0,
                    createdOn: new Date()
                }).unwrap().then(afterCreate);
            }
        }
    };

    const handleClose = () => {
        setInvalidName('');
        setNameError('');
        setName('');
        setIsVisible(false);
        setHasSaved(false);
        reset();
        resetUpdate();
    };

    return (
        <Dialog
            open={isVisible}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: '100%',
                },
            }}>
            <StandardDialogHeader icon={<Contacts />} text={`${department ? 'Edit' : 'New'} Department`} />
            <DialogContent sx={{ mt: 1 }}>
                <DialogContentText>{department ? `Department is associated with ${company.name}` : `Department will be added to ${company.name}`}</DialogContentText>
                <FormControl error={nameError !== ''} fullWidth sx={{ marginTop: '16px' }} required>
                    <FormLabel>Department</FormLabel>
                    <OutlinedInput value={name} onChange={handleNameChange} onBlur={validateName} required />
                    <FormHelperText>{nameError}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>
                    Cancel
                </Button>
                <Button onClick={handleSave} variant='contained' color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
