import { LimitedUserDto, QueryResultsDto, UserDto } from '../../dtos';
import { serverApi } from '../api';
import { BaseApi } from './base-api';

const userApi = serverApi.injectEndpoints({
    endpoints: (builder) => {
        const baseApi = new BaseApi<UserDto>('user', 'User', builder);
        return {
            getUsers: baseApi.getAll(),
            getUser: baseApi.getById(),
            createUser: baseApi.create(),
            updateUser: baseApi.update(),
            archiveUser: baseApi.archive(),
            unarchiveUser: baseApi.unarchive(),
            bulkArchiveUser: baseApi.bulkArchive(),
            bulkUnarchiveUser: baseApi.bulkUnarchive(),
            getUserByAuth0Id: builder.query<UserDto, string>({
                query: (id) => ({ url: `user/auth0UserId/${id}` }),
                providesTags: [{ type: 'User' }],
            }),
            getAllAvailablePermissions: builder.query<string[], void>({
                query: () => ({ url: `user/permissions` }),
                providesTags: []
            }),
            getUsersLimited: builder.query<QueryResultsDto<LimitedUserDto>, void>({
                query: () => ({ url: `user/limited` }),
                providesTags: [{ type: 'User' }]
            })
        }
    },
});

export const {
    useLazyGetUserByAuth0IdQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useArchiveUserMutation,
    useUnarchiveUserMutation,
    useUpdateUserMutation,
    useGetUserQuery,
    useLazyGetUserQuery,
    useBulkArchiveUserMutation,
    useBulkUnarchiveUserMutation,
    useLazyGetAllAvailablePermissionsQuery,
    useGetUsersLimitedQuery
} = userApi;
