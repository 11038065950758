import { Warning } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { StandardDialogHeader } from './StandardDialogHeader';

export interface IConfirmationModalWrapperProps extends PropsWithChildren {
    isOpen: boolean;
    title: string;
    confirmText?: string;
    cancel: () => void;
    confirm: () => void;
}

export const ConfirmationModalWrapper: FC<IConfirmationModalWrapperProps> = ({ isOpen, title, confirmText = 'Confirm', cancel, confirm, children }) => {
    return (
        <Dialog open={isOpen} onClose={cancel} fullWidth maxWidth='md'>
            <StandardDialogHeader icon={<Warning />} text={title} />
            <DialogContent sx={{ marginTop: 3 }}>{children}</DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyItems: 'end', gap: 1 }}>
                    <Button variant='outlined' onClick={cancel}>
                        Cancel
                    </Button>
                    <Button color='primary' variant='contained' size='medium' onClick={confirm}>
                        {confirmText}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
