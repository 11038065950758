import { Button, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { ImportRowStatus, ImportWorkItemRowDto } from '../../../dtos';
import { LoadingIndicator } from '../../CoreLib/library';
import { InMemoryTable } from '../../InMemoryTable';
import { WORK_ITEM_IMPORT_COLUMN_DEFINITION } from './Constants';

export interface ImportWorkItemsVerifyImportProps {
    onCancelClicked: () => void;
    onCommitToImport: () => void;
    recordsToImport: ImportWorkItemRowDto[];
    isLoading: boolean;
    isError: boolean;
}

export const ImportWorkItemsVerifyImport: FC<ImportWorkItemsVerifyImportProps> = ({ recordsToImport, onCancelClicked, onCommitToImport, isLoading, isError }) => {
    const totalRecords = useMemo(() => recordsToImport.length, [recordsToImport]);
    const goodRecords = useMemo(
        () => recordsToImport.filter((record) => record.status === ImportRowStatus.Good || record.status === ImportRowStatus.Warning).length,
        [recordsToImport]
    );
    const badRecords = useMemo(() => recordsToImport.filter((record) => record.status === ImportRowStatus.Error).length, [recordsToImport]);

	const verificationContent = useMemo(() => {
		if (isLoading) {
			return <Grid item><LoadingIndicator /></Grid>;
		}
		if (isError) {
			return <Grid item><Typography color='error'>An error occurred while attempting to verify import records. Please try again and contact technical support if issue persists.</Typography></Grid>;
		}
		return (
			<>
				<Grid container item xs={12} alignItems='center'>
					<Grid container xs={6} item justifyContent={'flex-start'}>
						<Typography>Total records found: {totalRecords}</Typography>
						<Typography ml={5}>Good: {goodRecords}</Typography>
						<Typography ml={5} color='error'>
							{' '}
							Error: {badRecords}
						</Typography>
					</Grid>
				</Grid>
				<Grid item>
					<InMemoryTable rows={recordsToImport} columnDefinitions={WORK_ITEM_IMPORT_COLUMN_DEFINITION} defaultSortKey='NAME' defaultSortDirection='asc' />
				</Grid>
			</>
		)
	}, [badRecords, goodRecords, isError, isLoading, recordsToImport, totalRecords]);

    return (
        <Grid container direction='column' justifyContent={'space-between'}>
            {verificationContent}
            <Grid item display='flex' justifyContent='end' gap={2} mt={2}>
                <Button variant='outlined' onClick={onCancelClicked}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={onCommitToImport}>
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};
