import { Box, Grid, Stack, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { EmailTemplateDto } from '../../../dtos';
import { useStepSummaryGenerator } from '../utils';
import { SurveyBuilderContext } from '../utils/SurveyBuilderContext';
import { StepperSummaryListItem } from './SubComponents';

interface ISummaryStepContentsProps {
    emailTemplates: EmailTemplateDto[];
}

export const SurveySummaryStepContent: FC<ISummaryStepContentsProps> = ({ emailTemplates }) => {
    const { surveyPayload } = useContext(SurveyBuilderContext);
    const {
        buildRespondentString,
        frequencyString,
        buildDistributionString,
        emailReminderString,
        buildTimePeriodString,
        surveySettingsString
    } = useStepSummaryGenerator(surveyPayload, emailTemplates)

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Box
                sx={{
                    backgroundColor: 'primary.contrastText',
                    py: 4,
                    px: 4,
                }}>
                <Grid item container direction='row' alignItems='start'>
                    <Grid item container direction='column' justifyContent='start' xs={6}>
                        <Typography variant='h5' sx={{ marginBottom: '8px' }}>
                            Here is a summary of your survey:
                        </Typography>
                    </Grid>
                </Grid>
                <StepperSummaryListItem label='Summary' value={surveySettingsString} />
                <StepperSummaryListItem label='Form Type' value={surveyPayload.selectedFormType?.name} />
                <StepperSummaryListItem label='Respondents' value={buildRespondentString(surveyPayload.respondentQueue)} />
                <StepperSummaryListItem label='Distribution' value={buildDistributionString} />
                <StepperSummaryListItem label='Frequency' value={frequencyString} />
                <StepperSummaryListItem label='Time Period' value={buildTimePeriodString} />
                <StepperSummaryListItem label='Email' value={emailReminderString} />
                <Grid item container direction='row' alignItems='start'>
                    <Grid item container direction='column' justifyContent='start' xs={12}>
                        <Typography variant='body1' sx={{ marginTop: 4, color: '#DF623B' }}>
                            Click the SUBMIT button to complete and schedule your survey.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
};
