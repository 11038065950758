import moment from 'moment';
import { ScheduleRespondentAnswerDto, SurveyDto, SurveyPayloadDto, SurveyPayloadSurveyPeriodConfig } from '../dtos';
import { ChildQueryParams } from '../store';
import { emptyGuid } from '../models';

export interface ISurveyQuestionAnswerPayload extends ChildQueryParams {
    answers: ScheduleRespondentAnswerDto[];
    isSubmit: boolean;
}

export const DEFAULT_FORM_TYPE: SurveyDto = { name: '', id: emptyGuid, isActive: true, createdOn: new Date() };

export const BCM_ID = 'a18d15d2-294d-485c-9f55-8d61e99a6449';
export const TAQ_BY_BC_ID = '69ec048d-9bf8-439f-937b-6cbbfc19e4da';

export const DEFAULT_SURVEY_PERIOD_CONFIG: SurveyPayloadSurveyPeriodConfig = {
    month: 'January',
    day: '01',
    surveyLabelPrefix: '',
    yearLabel: 'current-year',
    surveyLabelSuffix: '',
}

// TODO: this should be an environmental variable
export const MASTER_EMAIL_TEMPLATE_ID = 'a9053b1a-d7ba-47a1-9641-4a84ac05b09d';
export const DEFAULT_SURVEY_PAYLOAD: SurveyPayloadDto = {
    respondentQueue: [],
    selectedFormType: { ...DEFAULT_FORM_TYPE },
    distributionConfig: {
        individualRecipient: [],
        CCRecipients: [],
        BCCRecipients: [],
        surveyDistribution: 'each-respondent',
        fillOutSurveyValue: 'Group',
        groupName: '',
    },
    timePeriodArray: [
        {
            startDate: null,
            endDate: null,
            surveyLabel: ''
        }
    ],
    frequencyConfig: {
        startDate: moment().add(1, 'd').minutes(0).toDate(),
        radioStartDate: 'immediately',
        radioOneTimeOrRecurring: 'one-time',
        frequencyValue: 'every-year',
        surveyPeriods: [
            { ...DEFAULT_SURVEY_PERIOD_CONFIG },
            { ...DEFAULT_SURVEY_PERIOD_CONFIG },
            { ...DEFAULT_SURVEY_PERIOD_CONFIG },
            { ...DEFAULT_SURVEY_PERIOD_CONFIG }
        ],
    },
    emailConfig: {
        emailTemplate: MASTER_EMAIL_TEMPLATE_ID,
        companyContactName: '',
        companyContactTitle: '',
        reminderValue: 'manually',
    },
    businessComponentMatrixConfig: {
        employees: [],
        businessComponents: [],
        businessComponentEmployees: []
    }
}