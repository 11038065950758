import { FormControl, FormLabel, Grid, OutlinedInput, FormHelperText, Typography, Link, } from "@mui/material";
import { FunctionComponent } from "react";
import { IVerifyRespondentsStepTwoProps } from './types';
import { useEffect } from "react";
import { AttachFile } from '@mui/icons-material';

export const VerifyRespondentsStepTwo: FunctionComponent<IVerifyRespondentsStepTwoProps> = props => {
    const { respondent, representative, setIsValid, subject, setSubject, body, setBody, csvLink, validate, fieldErrors } = props;

    useEffect(() => {
        const isValid = () => {
            let isValid = validate('SUBJECT');
            isValid = validate('BODY') && isValid;
            isValid = (respondent.email && representative.email) && isValid;
            setIsValid(isValid);
        };

        isValid();
    }, [body, subject, validate, setIsValid, respondent.email, representative.email]);

    const validateField = (fieldName: string) => () => {
        validate(fieldName);
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(event.target.value);
    };

    const handleBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBody(event.target.value);
    };

    return (
        <Grid container direction="column" spacing={1.5} >
            <Grid item container direction="row" xs={12} >
                <Grid item xs={2} >
                    <Typography sx={{ fontWeight: 'bold' }}>To:</Typography>
                </Grid>
                <Grid item xs={10}>
                    {respondent.email ? (<Typography>{respondent.firstName + " " + respondent.lastName + " <" + respondent.email + ">"}</Typography>) : <Typography color={"error"}>Missing email address for this respondent</Typography>}
                </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} >
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 'bold' }}>From:</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography>{"TAQ Portal <taqportal@hullandknarr.com>"}</Typography>
                </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: 'bold' }}>Reply To:</Typography>
                </Grid>
                <Grid item xs={10}>
                    {representative.email ? (<Typography>{representative.displayName + " <" + representative.email + ">"}</Typography>) : <Typography color={"error"}>Missing email address for this representative</Typography>}
                </Grid>
            </Grid>
            <Grid item container direction="row" xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Subject</FormLabel>
                    <OutlinedInput value={subject} onChange={handleSubjectChange} onBlur={validateField('SUBJECT')} />
                    <FormHelperText>{fieldErrors.SUBJECT}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item container direction="row" xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Body</FormLabel>
                    <OutlinedInput multiline style={{ display: 'flex', flexDirection: 'column', textAlign: "start" }} value={body} onChange={handleBodyChange} onBlur={validateField('BODY')} />
                    <FormHelperText>{fieldErrors.BODY}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item container direction="row" xs={12} padding={0}>
                <AttachFile></AttachFile>
                <Link href={csvLink} underline="none"><Typography color="primary">Respondents CSV</Typography></Link>
            </Grid>
        </Grid >

    );
}
