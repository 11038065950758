import { FC, useContext, useState } from 'react';
import { useGetWorkItemByIdQuery } from '../../../store';
import { ApiError, LoadingIndicator } from '../../../Components/CoreLib/library';
import { Edit } from '@mui/icons-material';
import { Box, Typography, IconButton } from '@mui/material';
import { AddEditWorkItemModal } from './AddEditWorkItemModal';
import { StudyDashboardContext } from '../Utils/StudyDashboardContext';
import { WorkItemType } from '../../../dtos';
import { formateDateStandard, toWords } from '../../../util';

export interface IWorkItemDetailsProps {
    componentId: string;
}

export const WorkItemDetails: FC<IWorkItemDetailsProps> = ({ componentId }) => {
    const { study } = useContext(StudyDashboardContext);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const {
        data: workItem,
        isLoading: isLoadingWorkItem,
        isError: isErrorGettingWorkItem,
        refetch: retryGettingWorkItem,
    } = useGetWorkItemByIdQuery({ id: componentId });

    if (isLoadingWorkItem) {
        return <LoadingIndicator />;
    }

    if (isErrorGettingWorkItem || !workItem) {
        return <ApiError onReloadClick={retryGettingWorkItem} />;
    }

    return (
        <>
            <Box display='flex' flexDirection='column' rowGap={0.5}>
                <Box display='flex' alignItems='center'>
                    <Typography fontSize={18} fontWeight='bold'>
                        Component Details
                    </Typography>
                    <IconButton size='small' sx={{ ml: 1 }} onClick={() => setIsEditModalOpen(true)}>
                        <Edit />
                    </IconButton>
                </Box>
                <Typography>Name: {workItem.name}</Typography>
                <Typography>Type: {toWords(WorkItemType[workItem.type]?.toString())}</Typography>
                <Typography>Start Date: {formateDateStandard(workItem.startDate)}</Typography>
                <Typography>End Date: {workItem.isOngoing ? 'Ongoing' : formateDateStandard(workItem.endDate)}</Typography>
                <Typography>Description: {workItem.description}</Typography>
            </Box>
            <AddEditWorkItemModal isOpen={isEditModalOpen} workItemToUpdate={workItem} close={() => setIsEditModalOpen(false)} companyId={study.companyId} study={study} />
        </>
    )
};
