import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"
import theme from "../../../Theme"

export const onlyShowOnMobile: SxProps<Theme> = {
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
}

export const onlyShowOnDesktop: SxProps<Theme> = {
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
}

export const textAlignCenterOnMobile: SxProps<Theme> = {
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
    }
}