import { CircularProgress, FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { FC } from 'react';
import { useGetRespondentQuery } from '../../store';

export interface IReadonlyRespondentNameProps {
    companyId: string;
    respondentId: string;
    required?: boolean;
}

export const ReadonlyRespondentName: FC<IReadonlyRespondentNameProps> = ({ companyId, respondentId, required }) => {
    const { data: respondent, isLoading: isLoadingRespondent, isError: isErrorLoadingRespondent } = useGetRespondentQuery({
        parentId: companyId,
        childId: respondentId,
    });

    return (
        <FormControl error={isErrorLoadingRespondent} fullWidth required={required} disabled>
            <FormLabel>Name</FormLabel>
            <OutlinedInput value={respondent ? `${respondent?.firstName} ${respondent?.lastName}` : ''} endAdornment={isLoadingRespondent && <div><CircularProgress size={24} /></div>} />
            <FormHelperText>{isErrorLoadingRespondent ? 'Failed to load respondent details' : ''}</FormHelperText>
        </FormControl>
    )
}