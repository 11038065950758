import { FilterList } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC, useEffect, useState, useContext, useMemo } from 'react';
import { useGetCompaniesQuery, useGetFormTypesQuery } from '../../../store';
import { useGetUsersLimitedQuery } from '../../../store/api/user-api';
import { StandardDialogHeader } from '../../CommonComponents';
import { LoadingIndicator } from '../../CoreLib/library';
import { StudySelect, TimePeriodInput } from '../../CommonInputs';
import { ScheduleTableContext } from '../../Forms/Schedule/ScheduleTableContext';
import _ from 'lodash';
import { DataFilterContext } from '../../../Contexts';

export interface IFilterScheduleDialogProps {
    title: string;
    open: boolean;
    onClose: any;
    companyId?: string;
    hidePeriodFilter?: boolean;
    hideRemindersFilter?: boolean;
    hideApprovedByFilter?: boolean;
    hideFormTypeFilter?: boolean;
    hideStatusFilter?: boolean;
    hideScheduledFilter?: boolean;
    hideStudyFilter?: boolean;
}

export const FilterScheduleDialog: FC<IFilterScheduleDialogProps> = ({
    open,
    onClose,
    title,
    companyId,
    hidePeriodFilter = false,
    hideRemindersFilter = false,
    hideApprovedByFilter = false,
    hideFormTypeFilter = false,
    hideStatusFilter = false,
    hideScheduledFilter = false,
    hideStudyFilter = false
}) => {
    const {
        companyFilter,
        setCompanyFilter
    } = useContext(ScheduleTableContext);

    const {
        scheduledFromDateFilter: fromDateFilter,
        setScheduledFromDateFilter: setFromDateFilter,
        scheduledToDateFilter: toDateFilter,
        setScheduledToDateFilter: setToDateFilter,
        periodStartDateFilter,
        setPeriodStartDateFilter,
        periodEndDateFilter,
        setPeriodEndDateFilter,
        formTypeFilter,
        setFormTypeFilter,
        statusFilter,
        setStatusFilter,
        reminderFilter,
        setReminderFilter,
        studyFilter,
        setStudyFilter,
        approvedByFilter,
        setApprovedByFilter
    } = useContext(DataFilterContext);

    // TODO: to make this more consistent with the other filter dialogs we should add a filter manager hook. However, this functions just fine the way it is so this is not a high priority.
    // Filter Values
    const [fromDate, setFromDate] = useState<Date | null>(fromDateFilter ?? null);
    const [toDate, setToDate] = useState<Date | null>(toDateFilter ?? null);
    const [company, setCompany] = useState<string | undefined>(companyFilter);
    const [study, setStudy] = useState<string | undefined>(studyFilter);
    const [formType, setFormType] = useState<string | undefined>(formTypeFilter);
    const [status, setStatus] = useState<string | undefined>(statusFilter);
    const [reminder, setReminder] = useState<string | undefined>(reminderFilter);
    const [approvedBy, setApprovedBy] = useState<string | undefined>(approvedByFilter);
    const [periodStartDate, setPeriodStartDate] = useState<Date | null>(periodStartDateFilter ?? null);
    const [periodEndDate, setPeriodEndDate] = useState<Date | null>(periodEndDateFilter ?? null);

    // Data Fetching
    const { data: companies, isLoading: companiesLoading } = useGetCompaniesQuery({ page: 0, pageSize: 5000 });
    const { data: formTypes, isLoading: formTypesLoading } = useGetFormTypesQuery();
    const { data: users, isLoading: isLoadingUsers } = useGetUsersLimitedQuery();

    useEffect(() => {
        setFromDate(fromDateFilter ?? null);
        setToDate(toDateFilter ?? null);
        setCompany(companyFilter);
        setFormType(formTypeFilter);
        setStatus(statusFilter);
        setApprovedBy(approvedByFilter);
        setReminder(reminderFilter);
        setStudy(studyFilter);
        setPeriodStartDate(periodStartDateFilter ?? null);
        setPeriodEndDate(periodEndDateFilter ?? null);
    }, [
        approvedByFilter,
        companyFilter,
        formTypeFilter,
        fromDateFilter,
        periodEndDateFilter,
        periodStartDateFilter,
        reminderFilter,
        studyFilter,
        statusFilter,
        toDateFilter,
    ]);

    const handleFromDateChange = (value: Date | null | undefined) => {
        setFromDate(value ?? null);
    };

    const handleToReportDateChange = (value: Date | null | undefined) => {
        setToDate(value ?? null);
    };

    const handleClose = () => {
        onClose();
    };

    const clearFilters = () => {
        setFromDate(null);
        setToDate(null);
        setCompany(undefined);
        setFormType(undefined);
        setStatus(undefined);
        setApprovedBy(undefined);
        setReminder(undefined);
        setStudy(undefined);
        setPeriodEndDate(null);
        setPeriodStartDate(null);

        setCompanyFilter && setCompanyFilter(undefined);
        setFormTypeFilter(undefined);
        setStatusFilter(undefined);
        setApprovedByFilter(undefined);
        setReminderFilter(undefined);
        setStudyFilter(undefined);
        setFromDateFilter(null);
        setToDateFilter(null);
        setPeriodEndDateFilter(null);
        setPeriodStartDateFilter(null);
        onClose();
    };

    const filter = () => {
        setCompanyFilter && setCompanyFilter(company);
        setFormTypeFilter(formType);
        setStatusFilter(status);
        setApprovedByFilter(approvedBy);
        setReminderFilter(reminder);
        setStudyFilter(study);
        setFromDateFilter(fromDate);
        setToDateFilter(toDate);
        setPeriodStartDateFilter(periodStartDate);
        setPeriodEndDateFilter(periodEndDate);
        onClose();
    };

    const isCompanyFilterVisible = useMemo(() => {
        return !!setCompanyFilter;
    }, [setCompanyFilter]);

    if (companiesLoading || formTypesLoading || isLoadingUsers) {
        return <LoadingIndicator />;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
            <StandardDialogHeader icon={<FilterList />} text={`Filter ${title}`} />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    {isCompanyFilterVisible && (
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Company</FormLabel>
                                <Select
                                    value={company ?? ''}
                                    onChange={(e: SelectChangeEvent) => {
                                        setCompany(e.target.value);
                                    }}>
                                    <MenuItem value='' key='company-none'>
                                        None
                                    </MenuItem>
                                    {companies?.pageResults.map((company) => (
                                        <MenuItem key={company.id} value={company.shortName}>
                                            {company.shortName.trim() || company.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {hidePeriodFilter || (
                        <Grid item xs={8}>
                            <TimePeriodInput
                                showLabels={true}
                                defaultStartDate={periodStartDate}
                                defaultEndDate={periodEndDate}
                                handleDatesChanged={(startDate, endDate) => {
                                    if (typeof startDate === 'string') {
                                        startDate = new Date(startDate);
                                    }
                                    if (typeof endDate === 'string') {
                                        endDate = new Date(endDate);
                                    }
                                    setPeriodStartDate(startDate ?? null);
                                    setPeriodEndDate(endDate ?? null);
                                }}
                            />
                        </Grid>
                    )}
                    {hideFormTypeFilter || (
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Form Type</FormLabel>
                                <Select
                                    value={formType ?? ''}
                                    onChange={(e: SelectChangeEvent) => {
                                        setFormType(e.target.value);
                                    }}>
                                    <MenuItem value='' key='formType-none'>
                                        None
                                    </MenuItem>
                                    {formTypes?.map((formType) => (
                                        <MenuItem key={formType.id} value={formType.name}>
                                            {formType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {hideStatusFilter || (
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Status</FormLabel>
                                <Select
                                    value={status ?? ''}
                                    onChange={(e: SelectChangeEvent) => {
                                        setStatus(e.target.value);
                                    }}>
                                    <MenuItem value='' key='status-none'>
                                        None
                                    </MenuItem>
                                    {['Issued', 'Pending', 'Scheduled'].map(s => (
                                        <MenuItem value={s} key={s.toLowerCase()}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {hideRemindersFilter || (
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Reminders</FormLabel>
                                <Select
                                    value={reminder ?? ''}
                                    onChange={(e: SelectChangeEvent) => {
                                        setReminder(e.target.value);
                                    }}>
                                    <MenuItem value='' key='reminder-none'>
                                        None
                                    </MenuItem>
                                    {['manually', 'every-day', 'every-2-days', 'every-3-days', 'every-week'].map(r => (
                                        <MenuItem value={r} key={r}>
                                            {_.capitalize(r.replaceAll('-', ' '))}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {hideApprovedByFilter || (
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Approved By</FormLabel>
                                <Select
                                    value={approvedBy ?? ''}
                                    onChange={(e: SelectChangeEvent) => {
                                        setApprovedBy(e.target.value);
                                    }}>
                                    <MenuItem value='' key='approvedBy-none'>
                                        None
                                    </MenuItem>
                                    <MenuItem value='Pending' key='approvedBy-pending'>
                                        Pending
                                    </MenuItem>
                                    {users?.pageResults.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {hideScheduledFilter || (
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel>Scheduled</FormLabel>
                                <Grid item container direction='row' alignItems={'center'} spacing={2}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            label=''
                                            value={fromDate}
                                            onChange={(e: any) => {
                                                handleFromDateChange(e?.$d);
                                            }}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ['clear'],
                                                },
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sx={{ justifyContent: 'center' }}>
                                        <Typography>to</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <DatePicker
                                            label=''
                                            value={toDate}
                                            onChange={(e: any) => {
                                                handleToReportDateChange(e?.$d);
                                            }}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ['clear'],
                                                },
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    )}
                    {companyId && !hideStudyFilter && (
                        <Grid item xs={4}>
                            <StudySelect
                                companyId={companyId}
                                selectedStudyId={studyFilter ?? ''}
                                handleSelectedStudyChange={(s) => setStudy(s?.id)}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={2}>
                        <Button variant='text' color='primary' onClick={() => clearFilters()}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={filter}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
