import _ from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { DataFilterContext } from "../../../Contexts";

export interface DepartmentFilterValues {
    isNoRespondents?: boolean;
    createdFromDate?: Date;
    createdToDate?: Date;
}

export function useDepartmentFilterDialogManager() {
    const [dialogFilters, setDialogFilters] = useState<DepartmentFilterValues>({});
    const {
        isNoRespondentsFilter,
        setIsNoRespondentsFilter,
        createdFromDateFilter,
        setCreatedFromDateFilter,
        createdToDateFilter,
        setCreatedToDateFilter,
    } = useContext(DataFilterContext);

    const appliedFilters = useMemo<DepartmentFilterValues>(
        () => ({
            isNoRespondents: isNoRespondentsFilter,
            createdFromDate: createdFromDateFilter ?? undefined,
            createdToDate: createdToDateFilter ?? undefined
        }),
        [isNoRespondentsFilter, createdFromDateFilter, createdToDateFilter]
    );

    const handleDialogFilterValueChange = useCallback(
        (fieldName: keyof DepartmentFilterValues, value: any) => {
            let updatedFilterValues = _.cloneDeep(dialogFilters);
            updatedFilterValues[fieldName] = value;
            setDialogFilters(updatedFilterValues);
        },
        [dialogFilters]
    );

    const applyDialogFilters = useCallback(() => {
        setIsNoRespondentsFilter(dialogFilters.isNoRespondents);
        setCreatedFromDateFilter(dialogFilters.createdFromDate);
        setCreatedToDateFilter(dialogFilters.createdToDate);
    }, [dialogFilters, setIsNoRespondentsFilter, setCreatedFromDateFilter, setCreatedToDateFilter]);

    const loadAppliedFiltersIntoDialog = useCallback(() => {
        setDialogFilters(appliedFilters);
    }, [appliedFilters]);

    const isFormDirty = useMemo(() => JSON.stringify(appliedFilters) !== JSON.stringify(dialogFilters), [appliedFilters, dialogFilters]);

    return {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty
    };
}