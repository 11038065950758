import { ActivityStreamDto } from "../../../dtos";
import { BaseMessageType } from "./BaseMessageType";

export class CompanyCrudMessage extends BaseMessageType {
    _action: string;

    constructor(streamEntry: ActivityStreamDto, action: string) {
        super(streamEntry);
        this._action = action;
    }

    getMessage(): JSX.Element[] {
        const { company, companyId, createdByUser } = this._streamEntry;
        return this.generateMessageFromPayload({
            noun: 'Company',
            properNoun: company?.shortName,
            properNounRoute: `/companies/${companyId}`,
            pastTenseAction: this._action,
            performedBy: `${createdByUser.firstName} ${createdByUser.lastName}`,
            performedByRoute: `/users/${createdByUser.id}`
        });
    }
}