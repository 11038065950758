import { Box, FormControl, FormLabel, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useContext} from 'react';
import _ from 'lodash';
import { SurveyPayloadEmailConfigDto } from '../../../../dtos';
import { SurveyBuilderContext } from '../../utils';

export const EmailReminderCard: FC = () => {
    const { surveyPayload, setSurveyPayload } = useContext(SurveyBuilderContext);

    const updateEmailConfig = (emailConfig: SurveyPayloadEmailConfigDto) => {
        const newEmailConfig = emailConfig;
        setSurveyPayload({
            ...surveyPayload,
            emailConfig: newEmailConfig,
        });
    };

    const addReminderValue = (newReminderValue: string) => {
        let updatedEmailConfig = _.cloneDeep(surveyPayload.emailConfig);
        updatedEmailConfig.reminderValue = newReminderValue;
        updateEmailConfig(updatedEmailConfig);
    }

    const handleReminderChange = (event: SelectChangeEvent) => {
        addReminderValue(event.target.value);
    }

    return(
        <Box sx={{
            backgroundColor: 'primary.contrastText',
            py:4,
            px: 4
        }}>
            <Grid item container direction="row" alignItems="start">
                <Grid item container direction="column" justifyContent="start" xs={6}>
                    <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                        How often do you want to send reminders?
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item container direction="row" spacing={6}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Frequency</FormLabel>
                            <Select
                                value={surveyPayload.emailConfig.reminderValue}
                                onChange={handleReminderChange}
                            >
                                <MenuItem value="manually">I will send reminders manually</MenuItem>
                                <MenuItem value="every-day">Every day</MenuItem>
                                <MenuItem value="every-2-days">Every 2 days</MenuItem>
                                <MenuItem value="every-3-days">Every 3 days</MenuItem>
                                <MenuItem value="every-week">Every week</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}