import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCompaniesQuery } from "../../../store";
import { useFailedActionSnackbar } from "../../CoreLib/library";
import { ScheduleTableContext } from "../Schedule/ScheduleTableContext";

export interface ISelectCompanyModal {
    isSelectCompanyVisible: boolean;
    setIsSelectCompanyVisible: any;
    editRoute: string;
}

export const SelectCompanyModal: FC<ISelectCompanyModal> = ({ isSelectCompanyVisible, setIsSelectCompanyVisible, editRoute }) => {
    const navigate = useNavigate();
    const [companyId, setCompanyId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const { data: allCompanies, isLoading: isAllCompaniesLoading, isError: isGetCompaniesError, refetch } = useGetCompaniesQuery({
        searchText: '',
        includeInactive: false
    });
    const { surveyAlias } = useContext(ScheduleTableContext);

    useFailedActionSnackbar('retrieve', 'companies', isGetCompaniesError)
   
    const handleClose = () => {
        setIsSelectCompanyVisible(false)
    }
    const handleOK = () => {
        navigate(`/companies/${companyId}/${editRoute}`);
    }


    return (
        <Dialog
            open={isSelectCompanyVisible}
            onClose={handleClose}
            aria-labelledby="modal-survey-preview"
            aria-describedby="modal-survey-preview"
            maxWidth="xl"
            keepMounted={false}

        >
            <DialogTitle >
                <Typography id="modal-modal-title" fontSize={"24px"} fontWeight={"normal"}>
                    Select Company
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ backgroundColor: "background.paper" }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="subtitle1">
                                Which company will you be sending this {surveyAlias.toLowerCase()} to?
                            </Typography>
                        </Grid>
                        <Grid item mt={2}>
                            <Autocomplete
                                loading={isAllCompaniesLoading}
                                id="companies-dropdown"
                                options={allCompanies ? allCompanies.pageResults.map((row) => {
                                    return { label: `${row.name}`, value: row }
                                }) : []}
                                onChange={(event: any, newValue: any | null) => {
                                    setCompanyId(newValue.value.id);
                                }}
                                isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
                                sx={{ mr: 2 }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                    refetch()
                                }}
                                onKeyDown={(event: any) => {
                                    if (event.key === 'Enter') {
                                        event.defaultMuiPrevented = true;
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company Name"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>


                </Box>
            </DialogContent>
            <DialogActions sx={{ py: 2 }} >
                <Button variant="contained" size="medium" onClick={handleClose} sx={{ color: "white", backgroundColor: "gray" }} > Cancel</Button>
                <Button variant="contained" size="medium" onClick={handleOK} disabled={companyId === '' || companyId === undefined} sx={{ color: "secondary", backgroundColor: "primary" }}> OK</Button>
            </DialogActions>
        </Dialog >
    )
}

