import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DataFilterContext } from '../../../Contexts';
import { ActivityStreamDto } from '../../../dtos/generated/ActivityStreamDto';
import { useGetActivityStreamQuery } from '../../../store/api/activity-stream-api';
import { formatDateTimeForActivityStream, formateDateStandard, generateEventActivityMessage, snakeCaseToPrint } from '../../../util';
import { DataTableColumn, IFilterChip, StandardReadOnlyListView } from '../../CoreLib/library';
import { ActivityStreamFilterDialog } from '../../FilterDialogs/ActivityStreams/ActivityStreamFilterDialog';
import { eventNameMapper, getActivityStreamRowIcon } from './utils';
import { useGetAndCacheCompany, useGetAndCacheFormType } from '../../../Hooks';

export interface IActivityStreamTableProps {
    headerIcon?: JSX.Element;
    headerText?: string;
    isBorderVisible?: boolean;
    respondentId?: string;
    companyId?: string;
    studyId?: string;
    companyName?: string;
    period?: string;
    surveyId?: string;
    headerAlignment?: 'start' | 'center' | 'end';
    headerVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    hideDateDropdownFilter?: boolean;
    hideSearch?: boolean;
    createdFrom?: Date | null;
    onFilterChange?: (filterChipKey: string) => void;
    hideHeader?: boolean;
    hideFilterButton?: boolean;
    initialDaysBack?: '1' | '10' | '30' | '90' | '360';
}

export const CHIP_KEYS = {
    company: 'Company',
    respondentFirstName: 'Respondent First Name',
    respondentLastName: 'Respondent Last Name',
    activityType: 'Activity Type',
    formType: 'Form Type',
    period: 'Period',
    activityDate: 'Activity Date',
}

export const ActivityStreamTable: FC<IActivityStreamTableProps> = ({
    headerIcon,
    headerText = 'Activity Stream',
    isBorderVisible = false,
    respondentId,
    companyId = '',
    studyId = '',
    period = '',
    surveyId = '',
    headerAlignment,
    headerVariant = 'h3',
    hideDateDropdownFilter = true,
    hideSearch = false,
    hideHeader = false,
    hideFilterButton = false,
    initialDaysBack
}) => {
    const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);
    const tableColumns: DataTableColumn<ActivityStreamDto>[] = [
        {
            key: 'createdOn',
            label: 'date',
            sortKey: 'CREATED_ON',
            width: '180px',
            fieldMapper: (row) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <div style={{ height: '0px', position: 'relative', top: '-11px' }}>{getActivityStreamRowIcon(row.logType, row.eventType)}</div>{' '}
                        {formatDateTimeForActivityStream(row.createdOn)}
                    </div>
                );
            },
        },
        { key: 'message', label: 'message', sortKey: '', unsorted: true, fieldMapper: (row) => generateEventActivityMessage(row) },
    ];
    const [createdFromDaysBack, setCreatedFromDaysBack] = useState<Date | null>(null);
    const [selectedDaysBack, setSelectedDaysBack] = useState('');
    const {
        companyIdFilter,
        setCompanyIdFilter,
        firstNameFilter,
        setFirstNameFilter,
        lastNameFilter,
        setLastNameFilter,
        activityTypeFilter,
        setActivityTypeFilter,
        formTypeFilter,
        setFormTypeFilter,
        periodNameFilter,
        setPeriodNameFilter,
        createdFromDateFilter,
        setCreatedFromDateFilter,
        createdToDateFilter,
        setCreatedToDateFilter,
    } = useContext(DataFilterContext);

    const onDaysBackChange = useCallback((value: string) => {
        var newDayBound = computeDaysAgo(value);
        setCreatedFromDaysBack(newDayBound);
        setSelectedDaysBack(value);
        setCreatedFromDateFilter(newDayBound ?? undefined);
    }, [setCreatedFromDateFilter]);

    useEffect(() => {
        if (createdFromDateFilter !== createdFromDaysBack) {
            setSelectedDaysBack('');
        }
    }, [createdFromDateFilter, createdFromDaysBack]);

    useEffect(() => {
        if (initialDaysBack) {
            onDaysBackChange(initialDaysBack);
        }
    }, [onDaysBackChange, initialDaysBack]);

    const computeDaysAgo = (days: string) => {
        if (days === '') {
            return null;
        }
        let subtractDays = Number(days);
        var resultingDay = new Date();
        resultingDay.setDate(resultingDay.getDate() - subtractDays);
        return resultingDay;
    };

    const handleFilterClicked = () => {
        setIsFilterDialogVisible(true);
    };

    const previousDaysDropdownFilter = useMemo(() => (
        <Grid container direction='row' spacing={1}>
            <Grid item>
                <Typography>Activities in the past </Typography>
            </Grid>
            <Grid item>
                <Select value={selectedDaysBack} onChange={e => onDaysBackChange(e.target.value)} style={{ maxHeight: 24 }}>
                    {['', '1', '10', '30', '90', '360'].map((op, idx) => (
                        <MenuItem key={`${idx}-${op}`} value={op}>
                            <div style={{ minHeight: '24px' }}>{op}</div>
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <Typography> days:</Typography>
            </Grid>
        </Grid>
    ), [onDaysBackChange, selectedDaysBack]);

    const { companyName } = useGetAndCacheCompany(companyIdFilter);
    const { formTypeName } = useGetAndCacheFormType(formTypeFilter);

    const getFilterChips = (): IFilterChip[] => {
        const filterChips: IFilterChip[] = [];
        companyIdFilter && filterChips.push({ key: CHIP_KEYS.company, text: companyName });
        firstNameFilter && filterChips.push({ key: CHIP_KEYS.respondentFirstName, text: firstNameFilter });
        lastNameFilter && filterChips.push({ key: CHIP_KEYS.respondentLastName, text: lastNameFilter });
        activityTypeFilter && filterChips.push({ key: CHIP_KEYS.activityType, text: snakeCaseToPrint(eventNameMapper(activityTypeFilter)) });
        formTypeFilter && filterChips.push({ key: CHIP_KEYS.formType, text: formTypeName });
        periodNameFilter && filterChips.push({ key: CHIP_KEYS.period, text: periodNameFilter });
        if (createdFromDateFilter && createdToDateFilter) {
            filterChips.push({
                key: CHIP_KEYS.activityDate,
                text: `${formateDateStandard(createdFromDateFilter)} - ${formateDateStandard(createdToDateFilter)}`,
            });
        } else {
            createdFromDateFilter && filterChips.push({ key: CHIP_KEYS.activityDate, text: `${formateDateStandard(createdFromDateFilter)} to present` });
            createdToDateFilter && filterChips.push({ key: CHIP_KEYS.activityDate, text: `On or before ${formateDateStandard(createdToDateFilter)}` });
        }

        return filterChips;
    };

    const handleRemoveFilterChip = (filterChipKey: string) => {
        switch (filterChipKey) {
            case CHIP_KEYS.company:
                setCompanyIdFilter(undefined);
                break;
            case CHIP_KEYS.respondentFirstName:
                setFirstNameFilter(undefined);
                break;
            case CHIP_KEYS.respondentLastName:
                setLastNameFilter(undefined);
                break;
            case CHIP_KEYS.activityType:
                setActivityTypeFilter(undefined);
                break;
            case CHIP_KEYS.formType:
                setFormTypeFilter(undefined);
                break;
            case CHIP_KEYS.period:
                setPeriodNameFilter(undefined);
                break;
            case CHIP_KEYS.activityDate:
                setCreatedFromDateFilter(undefined);
                setCreatedToDateFilter(undefined);
                setSelectedDaysBack('');
                break;
            default:
                console.warn('Invalid filter chip key provided: ', filterChipKey);
                break;
        }
    };

    return (
        <>
            <StandardReadOnlyListView
                defaultPaginationProps={{
                    sortKey: 'CREATED_ON',
                    sortAsc: false,
                    page: 0,
                    pageSize: 25,
                }}
                getDataQuery={useGetActivityStreamQuery}
                additionalQueryParameters={{
                    studyId,
                    respondentId,
                    surveyId,
                    companyId: companyId === '' ?  companyIdFilter : companyId,
                    firstName: firstNameFilter,
                    lastName: lastNameFilter,
                    activityType: activityTypeFilter,
                    formType: formTypeFilter,
                    period: periodNameFilter,
                    fromDate: createdFromDateFilter?.toISOString(),
                    toDate: createdToDateFilter?.toISOString()
                }}
                tableColumns={tableColumns}
                entityNamePlural='Logs'
                headerIcon={headerIcon}
                headerTitle={hideHeader === false ? headerText : ''}
                headerVariant={headerVariant}
                isTableHeaderVisible={false}
                isBorderVisible={isBorderVisible}
                handleFilterClicked={handleFilterClicked}
                filterChips={hideFilterButton ? undefined : getFilterChips()}
                handleFilterChipDelete={hideFilterButton ? undefined : handleRemoveFilterChip}
                headerAlignment={headerAlignment}
                hideSearch={hideSearch}
                hideFilterButton={hideFilterButton}
                subtitleComponentSlot={!hideDateDropdownFilter ? previousDaysDropdownFilter : undefined}
            />
            <ActivityStreamFilterDialog
                open={isFilterDialogVisible}
                onClose={() => setIsFilterDialogVisible(false)}
                hideRespondentNameFilters={!!respondentId}
                hideCompanyFilter={!!companyId}
                hidePeriodFilter={!!period}
            />
        </>
    );
};
