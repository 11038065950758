import { FC, useCallback, useMemo } from 'react';
import { StandardDialogHeader } from '../../../Components/CommonComponents';
import { Dialog, DialogContent, Grid, DialogActions, Button } from '@mui/material';
import { MenuBook } from '@mui/icons-material';
import { CompanyDto } from '../../../dtos';
import { useCompanyForm } from './useCompanyForm';
import { AddEditCompanyForm } from './AddEditCompanyForm';

export interface IAddEditCompanyModalProps {
    isOpen: boolean;
    close: () => void;
    companyToUpdate?: CompanyDto;
    companyId?: string;
};

export const AddEditCompanyModal: FC<IAddEditCompanyModalProps> = ({ isOpen, close, companyToUpdate, companyId }) => {
    const formManager = useCompanyForm(close, companyToUpdate, companyId);
    const { formIsValid, handleSave, handleReset } = formManager;
    const isNew = useMemo(() => !companyToUpdate, [companyToUpdate]);
    

    const handleCloseClicked = useCallback(() => {
        close();
        handleReset();
    }, [close, handleReset]);

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth='md'>
            <StandardDialogHeader icon={<MenuBook />} text={isNew ? 'New Company' : 'Edit Company'} />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <AddEditCompanyForm formManager={formManager} />
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                    <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleCloseClicked}>
                            {!formIsValid ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};