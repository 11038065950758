import { useCallback, useMemo, useState } from 'react';
import {
    RespondentDto,
    ScheduleDetailDto,
    SchedulePeriodDto,
    ScheduleRespondentAnswerDto,
    ScheduleStatus,
    SurveyPayloadDto,
    SurveyPayloadTimePeriodDto,
} from '../../../dtos';
import { useLazyGetSurveyPreviewQuery, useLazyPreviewTaqByBcAnswersQuery } from '../../../store';
import { LoadingIndicator, useFailedActionSnackbar } from '../../CoreLib/library';
import { emptyGuid } from '../../../models';
import { SurveyPreviewModal } from '../steps/SubComponents';
import { BCM_ID, TAQ_BY_BC_ID } from '../../../Views';
import * as uuid from 'uuid';
import { DEFAULT_STUDY } from '../../../Views/Studies/Hooks';

export const useSurveyPreviewer = (surveyPayload: SurveyPayloadDto, targetRespondent: RespondentDto | null, surveyAliasShort: string = 'Survey', onClose?: () => void) => {
    const [isSurveyPreviewVisible, setIsSurveyPreviewVisible] = useState(false);
    const [getPreview, { currentData: surveyPreview, isError: isErrorGeneratingSurveyPreview, isFetching: isSurveyPreviewLoading }] = useLazyGetSurveyPreviewQuery();
    useFailedActionSnackbar('retrieve', `${surveyAliasShort.toLowerCase()} preview`, isErrorGeneratingSurveyPreview);
    const [previewAnswers, setPreviewAnswers] = useState<ScheduleRespondentAnswerDto[]>();
    const [getTaqByBcPreviewAnswers] = useLazyPreviewTaqByBcAnswersQuery();

    const handleSetIsVisible = useCallback((value: boolean) => {
        setIsSurveyPreviewVisible(value);
        if (value === false && onClose) {
            onClose();
        }
    }, [onClose]);

    const generatePreviewAnswer = useCallback(
        (fieldKey: string): ScheduleRespondentAnswerDto => ({
            id: uuid.v4(),
            scheduleDetailId: emptyGuid,
            fieldKey: fieldKey,
            answerValue: '',
            isActive: true,
            createdOn: new Date(),
        }),
        []
    );

    const convertPayloadTimePeriodToSurveyPayload = useCallback(
        (payloadTimePeriod: SurveyPayloadTimePeriodDto): SchedulePeriodDto => ({
            id: emptyGuid,
            scheduleId: emptyGuid,
            startDate: (payloadTimePeriod.startDate as Date) ?? new Date(),
            endDate: (payloadTimePeriod.endDate as Date) ?? new Date(),
            surveyLabel: payloadTimePeriod.surveyLabel ?? '',
        }),
        []
    );

    const surveyPayloadTimePeriods = useMemo(
        () => surveyPayload.timePeriodArray.map(convertPayloadTimePeriodToSurveyPayload),
        [convertPayloadTimePeriodToSurveyPayload, surveyPayload]
    );

    const showSurveyPreview = () => {
        if (targetRespondent === null) {
            console.error('Cannot preview survey without selecting a respondent to preview with');
            return;
        }

        getPreview({
            id: emptyGuid,
            studyId: surveyPayload.study?.id!,
            surveyTemplateId: surveyPayload.selectedFormType.id,
            surveyPayload: JSON.stringify(surveyPayload),
            isActive: true,
            createdBy: emptyGuid,
            createdOn: new Date().toISOString() as unknown as Date,
        });

        var generatedPreviewAnswers: ScheduleRespondentAnswerDto[] = [];
        switch (surveyPayload.selectedFormType.id) {
            case TAQ_BY_BC_ID:
                getTaqByBcPreviewAnswers({
                    respondentId: targetRespondent.id,
                    companyId: surveyPayload.study?.companyId ?? emptyGuid,
                    periodTimeRanges: surveyPayloadTimePeriods.map((x) => ({ startDate: x.startDate, endDate: x.endDate })),
                }).then((x) => {
                    if (x.data) {
                        setPreviewAnswers(x.data);
                    }
                });
                break;
            case BCM_ID:
                surveyPayload.businessComponentMatrixConfig.businessComponentEmployees.forEach((bce) => {
                    generatedPreviewAnswers.push(generatePreviewAnswer(`${bce.businessComponentId}-${bce.employeeId}-research-activity`));
                    generatedPreviewAnswers.push(generatePreviewAnswer(`${bce.businessComponentId}-${bce.employeeId}-information-sought`));
                });
                break;
        }

        setPreviewAnswers(generatedPreviewAnswers);
        setIsSurveyPreviewVisible(true);
    };

    const previewScheduleDetails = useMemo<ScheduleDetailDto | undefined>(
        () => {
            if (targetRespondent === null) {
                console.error('Cannot preview a survey without selecting a respondent');
                return;
            }

            return {
                status: ScheduleStatus.Issued,
                isPaused: false,
                scheduleId: emptyGuid,
                schedule: {
                    period: '',
                    periods: surveyPayloadTimePeriods,
                    surveyId: emptyGuid,
                    survey: {
                        studyId: surveyPayload.study?.id ?? emptyGuid,
                        surveyTemplateId: emptyGuid,
                        surveyPayload: JSON.stringify(surveyPayload),
                        id: emptyGuid,
                        isActive: true,
                        createdOn: new Date(),
                    },
                    formType: surveyPayload.selectedFormType.name,
                    frequency: surveyPayload.frequencyConfig.frequencyValue,
                    status: 'Issued',
                    reminders: '',
                    scheduled: new Date(),
                    created: 1,
                    issued: 1,
                    started: 0,
                    paused: 0,
                    completed: 0,
                    isExpired: false,
                    studyId: surveyPayload.study?.id ?? emptyGuid,
                    study: DEFAULT_STUDY,
                    companyId: surveyPayload.study?.companyId ?? emptyGuid,
                    companyName: surveyPayload.study?.companyName ?? '',
                    companyShortName: surveyPayload.study?.company?.shortName ?? '',
                    id: emptyGuid,
                    isActive: true,
                    createdOn: new Date(),
                },
                respondentId: targetRespondent.id,
                respondent: targetRespondent,
                respondentSurveyTemplate: '',
                addedBusinessComponents: [],
                addedRespondents: [],
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
            }
        },
        [surveyPayload, targetRespondent, surveyPayloadTimePeriods]
    );

    const renderSurveyPreviewModal = useCallback(() => {
        if (!surveyPreview || !previewScheduleDetails) {
            return null;
        }

        if (isSurveyPreviewLoading) {
            return <LoadingIndicator />
        }

        return (
            <SurveyPreviewModal
                setIsPreviewVisible={handleSetIsVisible}
                isPreviewVisible={isSurveyPreviewVisible}
                surveyPreview={surveyPreview!}
                previewScheduleDetail={previewScheduleDetails}
                previewAnswers={previewAnswers}
            />
        );
    }, [isSurveyPreviewVisible, surveyPreview, previewAnswers, previewScheduleDetails, handleSetIsVisible, isSurveyPreviewLoading]);

    return { showSurveyPreview, renderSurveyPreviewModal, isSurveyPreviewVisible };
};
