import { AccountBox } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, Switch, Typography } from '@mui/material';
import { FC } from 'react';
import { IFormProps, NavBreadcrumbs } from '../../../Components/CoreLib/library';
import { FormSection } from '../../../Components/Forms';
import { UserDto } from '../../../dtos';
import { useUserForm } from './useUserForm';

export const UserForm: FC<IFormProps<UserDto>> = (props) => {
    const { isLoading } = props;
    const {
        handleSave,
        handleCancel,
        fieldErrors,
        validateField,
        isFormDirty,
        formFirstName,
        handleFirstNameChange,
        formLastName,
        handleLastNameChange,
        formIsActive,
        handleIsActiveChange,
        formEmail,
        handleEmailChange,
        formPhoneNumber,
        handlePhoneNumberChange,
        formRole,
        handleRoleChange,
        formTitle,
        handleTitleChange,
    } = useUserForm(props)

    const userDisplayName = () => {
        return (formFirstName + ' ' + formLastName).trim();
    }

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <AccountBox /> {userDisplayName() ? userDisplayName() : 'New User'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Admin', navLink: '', isText: true },
                            { label: 'Users', navLink: '/users' },
                        ]}
                        currentPageLabel={userDisplayName() ? userDisplayName() : 'New User'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={formIsActive} onChange={handleIsActiveChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' onClick={handleCancel}>
                            {isFormDirty() ? 'Cancel' : 'Close'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='primary' type='submit' onClick={handleSave} disabled={isLoading}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('firstName')} fullWidth required disabled={isLoading}>
                                <FormLabel>First Name</FormLabel>
                                <OutlinedInput value={formFirstName} onChange={handleFirstNameChange} onBlur={() => validateField('firstName')} />
                                <FormHelperText>{fieldErrors.get('firstName')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('lastName')} fullWidth required disabled={isLoading}>
                                <FormLabel>Last Name</FormLabel>
                                <OutlinedInput value={formLastName} onChange={handleLastNameChange} onBlur={() => validateField('lastName')} />
                                <FormHelperText>{fieldErrors.get('lastName')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth disabled={isLoading}>
                                <FormLabel>Title</FormLabel>
                                <OutlinedInput value={formTitle} onChange={handleTitleChange} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('email')} fullWidth required disabled={isLoading}>
                                <FormLabel>Email</FormLabel>
                                <OutlinedInput value={formEmail} onChange={handleEmailChange} onBlur={() => validateField('email')} />
                                <FormHelperText>{fieldErrors.get('email')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('phoneNumber')} fullWidth disabled={isLoading}>
                                <FormLabel>Phone</FormLabel>
                                <OutlinedInput value={formPhoneNumber} onChange={handlePhoneNumberChange} onBlur={() => validateField('phoneNumber')} />
                                <FormHelperText>{fieldErrors.get('phoneNumber')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('role')} fullWidth required disabled={isLoading}>
                                <FormLabel>User Role</FormLabel>
                                <Select value={formRole} onChange={handleRoleChange} onBlur={() => validateField('role')}>
                                    <MenuItem value='' sx={{ height: '36px' }}></MenuItem>
                                    <MenuItem value='Admin'> Admin </MenuItem>
                                    <MenuItem value='Editor'> Editor </MenuItem>
                                </Select>
                                <FormHelperText>{fieldErrors.get('role')}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
