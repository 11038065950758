import { FilterList } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FunctionComponent, useEffect, useState } from 'react';
import { LoadingIndicator } from '../../Components/CoreLib/library';
import { StandardDialogHeader } from '../../Components/CommonComponents';
import { useGetFormTypesQuery } from '../../store';

export interface IFilterScheduleDetailsDialogProps {
    emailFilter?: string;
    firstNameFilter?: string;
    formTypeFilter?: string;
    fromDateFilter?: Date | null;
    lastNameFilter?: string;
    onClose: any;
    open: boolean;
    setEmailFilter: (email?: string) => void;
    setFirstNameFilter: (firstName?: string) => void;
    setFormTypeFilter: (formType?: string) => void;
    setFromDateFilter: (fromDate: Date | null) => void;
    setLastNameFilter: (lastName?: string) => void;
    setStatusFilter: (status?: string) => void;
    setToDateFilter: (toDate: Date | null) => void;
    statusFilter?: string;
    toDateFilter?: Date | null;
    hideFormType?: boolean;
}

export const FilterScheduleDetailsDialog: FunctionComponent<IFilterScheduleDetailsDialogProps> = (props) => {
    const {
        emailFilter,
        firstNameFilter,
        formTypeFilter,
        fromDateFilter,
        lastNameFilter,
        onClose,
        open,
        setEmailFilter,
        setFirstNameFilter,
        setFormTypeFilter,
        setFromDateFilter,
        setLastNameFilter,
        setStatusFilter,
        setToDateFilter,
        statusFilter,
        toDateFilter,
        hideFormType
    } = props;

    // Filter Values
    const [fromUpdatedOnDate, setFromUpdatedOnDate] = useState<Date | null>(fromDateFilter ?? null);
    const [toUpdatedOnDate, setToUpdatedOnDate] = useState<Date | null>(toDateFilter ?? null);
    const [email, setEmail] = useState<string | undefined>(emailFilter);
    const [firstName, setFirstName] = useState<string | undefined>(firstNameFilter);
    const [lastName, setLastName] = useState<string | undefined>(lastNameFilter);
    const [formType, setFormType] = useState<string | undefined>(formTypeFilter);
    const [status, setStatus] = useState<string | undefined>(statusFilter);

    // Data Fetching
    const { data: formTypes, isLoading: formTypesLoading } = useGetFormTypesQuery();

    useEffect(() => {
        setFromUpdatedOnDate(fromDateFilter ?? null);
        setToUpdatedOnDate(toDateFilter ?? null);
        setEmail(emailFilter);
        setFirstName(firstNameFilter);
        setLastName(lastNameFilter);
        setFormType(formTypeFilter);
        setStatus(statusFilter);
    }, [emailFilter, firstNameFilter, formTypeFilter, fromDateFilter, lastNameFilter, statusFilter, toDateFilter]);

    const handleFromUpdatedOnDateChange = (value: Date | null | undefined) => {
        setFromUpdatedOnDate(value ?? null);
    };

    const handleToReportDateChange = (value: Date | null | undefined) => {
        setToUpdatedOnDate(value ?? null);
    };

    const handleClose = () => {
        onClose();
    }

    const clearFilters = () => {
        setFromUpdatedOnDate(null);
        setToUpdatedOnDate(null);
        setEmail(undefined);
        setFirstName(undefined);
        setFormType(undefined);
        setStatus(undefined);
        setLastName(undefined);

        setEmailFilter(undefined);
        setFirstNameFilter(undefined);
        setFormTypeFilter(undefined);
        setStatusFilter(undefined);
        setLastNameFilter(undefined);
        setFromDateFilter(null);
        setToDateFilter(null);
        onClose();
    };

    const filter = () => {
        if (email) {
            setEmailFilter(email);
        } else {
            setEmailFilter(undefined);
        }
        if (firstName) {
            setFirstNameFilter(firstName);
        } else {
            setFirstNameFilter(undefined);
        }
        if (lastName) {
            setLastNameFilter(lastName);
        } else {
            setLastNameFilter(undefined);
        }
        if (formType) {
            setFormTypeFilter(formType);
        } else {
            setFormTypeFilter(undefined);
        }
        if (status) {
            setStatusFilter(status);
        } else {
            setStatusFilter(undefined);
        }
        setFromDateFilter(fromUpdatedOnDate);
        setToDateFilter(toUpdatedOnDate);
        onClose();
    };

    if (formTypesLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
            <StandardDialogHeader icon={<FilterList />} text='Filter Surveys' />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container direction='row' spacing={2} alignItems='center'>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>First name</FormLabel>
                                <TextField
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Last Name</FormLabel>
                                <TextField
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2} alignItems='center'>
                        { !hideFormType && <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Form Type</FormLabel>
                                <Select value={formType ?? ''} onChange={(e: SelectChangeEvent) => {
                                    setFormType(e.target.value)
                                }}>
                                    <MenuItem value='' key='formType-none'>None</MenuItem>
                                    {formTypes?.map((formType) => (
                                        <MenuItem key={formType.id} value={formType.name}>
                                            {formType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> }
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Status</FormLabel>
                                <Select value={status ?? ''} onChange={(e: SelectChangeEvent) => {
                                    setStatus(e.target.value)
                                }}>
                                    <MenuItem value='' key='status-none'>None</MenuItem>
                                    <MenuItem value='0' key='created'>Created</MenuItem>
                                    <MenuItem value='1' key='issued'>Issued</MenuItem>
                                    <MenuItem value='2' key='started'>Started</MenuItem>
                                    <MenuItem value='3' key='completed'>Completed</MenuItem>
                                    <MenuItem value='4' key='paused'>Paused</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <FormLabel>Last Updated</FormLabel>
                                <Grid item container direction='row' alignItems={'center'} spacing={2}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            label=''
                                            value={fromUpdatedOnDate}
                                            onChange={(e: any) => {
                                                handleFromUpdatedOnDateChange(e?.$d);
                                            }}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ['clear']
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={1} sx={{ justifyContent: 'center' }}>
                                        <Typography>to</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <DatePicker
                                            label=''
                                            value={toUpdatedOnDate}
                                            onChange={(e: any) => {
                                                handleToReportDateChange(e?.$d);
                                            }}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ['clear']
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={2}>
                        <Button variant='text' color='primary' onClick={() => clearFilters()}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs='auto'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={filter}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
