import { Chip, Grid } from '@mui/material';
import { FC, useContext } from 'react';
import { DataFilterContextChips } from '../../../Contexts';
import { ScheduleTableContext } from './ScheduleTableContext';

export const ScheduleTableFilterChips: FC = () => {
    const {
        companyFilter,
        setCompanyFilter,
    } = useContext(ScheduleTableContext);

    return (
        <Grid item container direction='row' alignItems='center' xs={6} spacing={1} sx={{ height: '100%', paddingLeft: '12px', marginTop: '0px' }}>
            {companyFilter && (
                <Grid item>
                    <Chip
                        label={`Company: ${companyFilter} `}
                        color='primary'
                        onDelete={() => {
                            setCompanyFilter && setCompanyFilter(undefined);
                        }}
                    />
                </Grid>
            )}
            <DataFilterContextChips />
        </Grid>
    );
};
