import { SearchQueryParams, serverApi } from '..';
import { QueryResultsDto, EmailTemplateDto } from '../../dtos';
import { buildSearchQueryRoute } from '../../util';

const emailTemplateApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getEmailTemplates: builder.query<QueryResultsDto<EmailTemplateDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('emailTemplate', params),
            providesTags: [{ type: 'EmailTemplate'}]
        })
    })
});

export const {
    useGetEmailTemplatesQuery,
} = emailTemplateApi;