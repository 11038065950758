import { AddCircle } from '@mui/icons-material';
import { Box, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react';
import { AddEditStudyModal } from '../../../Views/Studies/Components';
import { CompanyDto, StudyDto } from '../../../dtos';
import { ConfirmationModalWrapper } from '../../CommonComponents/ConfirmationModalWrapper';
import { StudySelect } from '../../CommonInputs';
import { BCMInitializationType, SurveyBuilderContext } from '../utils';

export interface IBCMSettingsStepContentsProps {
    company: CompanyDto;
}

export const BCMSettingsStepContents: FC<IBCMSettingsStepContentsProps> = ({ company }) => {
    const { surveyPayload, setSurveyPayload, bcmInitializationType, setBCMInitializationType } = useContext(SurveyBuilderContext);
    const [isNewStudyModalOpen, setIsNewStudyModalOpen] = useState(false);
    const [switchingToStudy, setSwitchingToStudy] = useState<StudyDto>();
    const [switchingToInitializationType, setSwitchingToInitializationType] = useState<BCMInitializationType>();
    const isDataDefinedInDataStep = useMemo(
        () => surveyPayload.businessComponentMatrixConfig.businessComponents.length > 0 || surveyPayload.businessComponentMatrixConfig.employees.length > 0,
        [surveyPayload]
    );

    const stepErrorMessage = useMemo(() => {
        if (!surveyPayload.study) {
            return 'Study is required.';
        }
    }, [surveyPayload.study]);

    const setStudy = useCallback(
        (study?: StudyDto) => {
            if (isDataDefinedInDataStep) {
                setSwitchingToStudy(study);
            } else {
                setSurveyPayload({
                    ...surveyPayload,
                    study: study,
                });
            }
        },
        [surveyPayload, setSurveyPayload, isDataDefinedInDataStep]
    );

    const switchStudy = useCallback(() => {
        setSurveyPayload({
            ...surveyPayload,
            study: switchingToStudy,
            businessComponentMatrixConfig: {
                businessComponents: [],
                businessComponentEmployees: [],
                employees: [],
            },
        });
        setSwitchingToStudy(undefined);
    }, [switchingToStudy, surveyPayload, setSurveyPayload]);

    const handleCreateNew = useCallback(() => {
        setIsNewStudyModalOpen(true);
    }, []);

    const handleInitializationOptionChange = useCallback(
        (_: ChangeEvent<HTMLInputElement>, value: string) => {
            if (isDataDefinedInDataStep) {
                setSwitchingToInitializationType(value as BCMInitializationType);
            } else {
                setBCMInitializationType(value as BCMInitializationType);
            }
        },
        [setBCMInitializationType, isDataDefinedInDataStep]
    );

    const switchInitializationOption = useCallback(() => {
        setSurveyPayload({
            ...surveyPayload,
            businessComponentMatrixConfig: {
                businessComponents: [],
                businessComponentEmployees: [],
                employees: [],
            },
        });
        setBCMInitializationType(switchingToInitializationType!);
        setSwitchingToInitializationType(undefined);
    }, [switchingToInitializationType, setSurveyPayload, surveyPayload, setBCMInitializationType]);

    return (
        <Paper sx={{ p: 4 }} elevation={0}>
            <Grid container direction='column' spacing={2}>
                <Grid item container direction='row'>
                    <Grid item xs={12} sm={8} md={4} sx={{ display: 'flex', alignItems: 'start' }}>
                        <StudySelect
                            companyId={company.id}
                            selectedStudyId={surveyPayload.study?.id ?? ''}
                            handleSelectedStudyChange={setStudy}
                            required
                            errorMessage={stepErrorMessage}
                        />
                        <Box mt={3}>
                            <IconButton color='primary' onClick={handleCreateNew}>
                                <AddCircle fontSize='large' />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>How would you like to create this business component matrix?</Typography>
                    <RadioGroup value={bcmInitializationType} onChange={handleInitializationOptionChange}>
                        <FormControlLabel value='import' control={<Radio />} label='Automatically generate for selected study' />
                        <FormControlLabel value='new' control={<Radio />} label='Create from scratch' />
                    </RadioGroup>
                </Grid>
            </Grid>
            <AddEditStudyModal isOpen={isNewStudyModalOpen} close={() => setIsNewStudyModalOpen(false)} companyId={company.id} afterCreate={setStudy} />
            <ConfirmationModalWrapper isOpen={!!switchingToStudy} title='Change Study' cancel={() => setSwitchingToStudy(undefined)} confirm={switchStudy}>
                <Typography>
                    It looks like you already have some business components and/or employees defined in step 3. Switching study now will remove the existing
                    business components and employees. If you have 'Automatically generate for selected study' selected then new business components and
                    employees will be created to replace the ones that were removed.
                </Typography>
                <br />
                <Typography>Are you sure you want to switch study?</Typography>
            </ConfirmationModalWrapper>
            <ConfirmationModalWrapper
                isOpen={!!switchingToInitializationType}
                title='Change Initial Business Components'
                cancel={() => setSwitchingToInitializationType(undefined)}
                confirm={switchInitializationOption}>
                <Typography>
                    It looks like you already have some business components defined in later steps. Switching between automatically creating business components
                    and creating them from scratch now will remove the existing business components.
                </Typography>
                <br />
                <Typography>Are you sure you want to switch?</Typography>
            </ConfirmationModalWrapper>
        </Paper>
    );
};
