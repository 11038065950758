import { MenuBook } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { StandardDialogHeader } from '../../../Components/CommonComponents';
import { StudyDto, WorkItemDto, WorkItemType } from '../../../dtos';
import { emptyGuid } from '../../../models';
import { useWorkItemForm } from '../Hooks';
import { AddEditWorkItemForm } from './AddEditWorkItemForm';


export interface IAddEditWorkItemModalProps {
    isOpen: boolean;
    close: () => void;
    workItemToUpdate?: WorkItemDto;
    companyId: string;
    study?: StudyDto;
    hideAssociatedRespondents?: boolean;
    fixedType?: WorkItemType;
    itemTypeName?: string;
    afterSave?: (savedRecord: WorkItemDto) => void;
};

export const AddEditWorkItemModal: FC<IAddEditWorkItemModalProps> = ({ isOpen, close, workItemToUpdate, companyId, study, hideAssociatedRespondents, fixedType, itemTypeName = 'Component', afterSave }) => {
    const formManager = useWorkItemForm(close, workItemToUpdate, companyId, study?.endDate ? new Date(study?.endDate + 'Z') : null, study?.startDate ? new Date(study?.startDate + 'Z') : null, fixedType, afterSave);
    const { isFormDirty, reset, save } = formManager;
    const isNew = useMemo(() => !workItemToUpdate || workItemToUpdate.id === emptyGuid, [workItemToUpdate]);

    const handleCloseClicked = useCallback(() => {
        close();
        reset();
    }, [close, reset]);

    const saveClicked = useCallback(() => {
        save();
    }, [save])

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth='md'>
            <StandardDialogHeader icon={<MenuBook />} text={isNew ? `New ${itemTypeName}` : `Edit ${itemTypeName}`} />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <AddEditWorkItemForm formManager={formManager} companyId={companyId} hideAssociatedRespondents={hideAssociatedRespondents}/>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleCloseClicked}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={saveClicked} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};