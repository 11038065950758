import { Grid, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { BusinessComponentDto, EmployeeDto, ScheduleRespondentAnswerDto } from '../../../../dtos';
import { FormInput } from '../../../CoreLib/library';
import { BusinessComponentLabel } from './BusinessComponentLabel';

export interface IBCMSurveyRowProps {
    key: string;
    businessComponent: BusinessComponentDto;
    respondent: EmployeeDto;
    researchActivityEntry: ScheduleRespondentAnswerDto;
    informationSoughtEntry: ScheduleRespondentAnswerDto;
    disabled?: boolean;
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
};

export const BCMSurveyRow: FC<IBCMSurveyRowProps> = memo(({
    key,
    businessComponent,
    respondent,
    researchActivityEntry,
    informationSoughtEntry,
    disabled,
    setQuestionAnswer,
}) => {
    return (
        <Grid item container direction='row' key={key} spacing={1} alignItems='center'>
            <Grid item xs={2.75}>
                <BusinessComponentLabel bcInfo={{ name: businessComponent.name, description: businessComponent.description, displayMode: businessComponent.descriptionDisplayMode }} isBCMTaker={true} />
            </Grid>
            <Grid item xs={2.75}>
                <Typography>{respondent.name}</Typography>
            </Grid>
            <Grid item xs={2.75}>
                <FormInput
                    label={''}
                    value={researchActivityEntry.answerValue.toString()}
                    onChange={(event) => setQuestionAnswer(researchActivityEntry.fieldKey, event.target.value)}
                    inputProps={{ className: 'standard-outlined-input' }}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
            <Grid item flexGrow={1}>
                <FormInput
                    label={''}
                    value={informationSoughtEntry.answerValue.toString()}
                    onChange={(event) => setQuestionAnswer(informationSoughtEntry.fieldKey, event.target.value)}
                    inputProps={{ className: 'standard-outlined-input' }}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
});