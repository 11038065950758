import { AddCircle, Delete } from '@mui/icons-material';
import { FormControlLabel, Grid, IconButton, Switch, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
    DataTableColumn,
    IStandardListViewExtraItems,
    StandardListViewV2,
    useFailedActionSnackbar,
    useSuccessfulActionSnackbar,
} from '../../../../../../Components/CoreLib/library';
import { DataFilterContext } from '../../../../../../Contexts';
import { WorkItemDto, WorkItemType } from '../../../../../../dtos';
import { useGetStudyWorkItemsQuery } from '../../../../../../store';
import { useWorkItemBulkArchiveMutation, useWorkItemBulkUnarchiveMutation } from '../../../../../../store/api/work-item-api';
import { toWords } from '../../../../../../util';
import { DEFAULT_WORK_ITEM } from '../../../../Hooks/useWorkItemForm';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';
import { AddEditWorkItemModal } from '../../../AddEditWorkItemModal';

const DEFAULT_PAGINATION = { searchText: '', sortKey: 'NAME', sortAsc: true, page: 0, pageSize: 25, includeInactive: false };
const TABLE_COLUMNS: DataTableColumn<WorkItemDto>[] = [
    { key: 'name', label: 'Name', sortKey: 'NAME' },
    { key: 'type', label: 'Type', sortKey: 'TYPE', fieldMapper: (row) => toWords(WorkItemType[row.type]?.toString()) },
    { key: 'employeeCount', label: 'Respondents', sortKey: '', unsorted: true },
    { key: 'startDate', label: 'Created', sortKey: 'START_DATE', fieldMapper: (row) => new Date(row.startDate).toLocaleString('en-us') },
];

type StandardListViewV2EditHandler<T> = (id: string, record: T, event?: any) => void;

export const StudyDashboardComponentsTab: FC = () => {
    const { study, setSelectedComponentId, searchText } = useContext(StudyDashboardContext);
    const { activeStatusFilter, itemTypeFilter, periodStartDateFilter, periodEndDateFilter } = useContext(DataFilterContext);
    const [isCompletedVisible, setIsCompletedVisible] = useState(true);
    const [selectedWorkItem, setSelectedWorkItem] = useState<WorkItemDto>();

    const [inactivateWorkItems, { isSuccess: isInactivationSuccess, isError: isInactivationError, reset: resetInactivation }] =
        useWorkItemBulkArchiveMutation();
    const [activateWorkItems, { isSuccess: isActivationSuccess, isError: isActivationError, reset: resetActivation }] = useWorkItemBulkUnarchiveMutation();
    useSuccessfulActionSnackbar('inactivated', 'work items', isInactivationSuccess, resetInactivation);
    useFailedActionSnackbar('inactivate', 'work items', isInactivationError, resetInactivation);
    useSuccessfulActionSnackbar('activated', 'work items', isActivationSuccess, resetActivation);
    useFailedActionSnackbar('activate', 'work items', isActivationError, resetActivation);

    const customHeaderContent = useMemo(() => {
        return (
            <FormControlLabel
                style={{ marginRight: 1 }}
                control={<Switch size='small' checked={isCompletedVisible} onChange={(_, val) => setIsCompletedVisible(val)} />}
                label='Show Completed'
                labelPlacement='start'
            />
        );
    }, [isCompletedVisible]);

    const handleAdd = () => {
        setSelectedWorkItem(DEFAULT_WORK_ITEM);
    };

    const handleInactivate = useCallback((workItemIds: string[]) => {
        inactivateWorkItems(workItemIds)
    }, [inactivateWorkItems]);

    const handleActivate = useCallback(
        (workItemIds: string[]) => {
            if (!workItemIds || workItemIds.length === 0) {
                return;
            }
            activateWorkItems(workItemIds);
        },
        [activateWorkItems]
    );

    const customTitleActions: IStandardListViewExtraItems[] = useMemo(
        () => [
            {
                enabledCheck: (selectedItems) => selectedItems && selectedItems.length > 0 && selectedItems.every((i) => i && i.isActive),
                onClick: (selectedItems) => handleInactivate(selectedItems.map((i) => i.id)),
                text: 'Make Inactive',
                buttonIsIconButton: true,
                icon: <Delete fontSize='inherit' />,
            },
        ],
        [handleInactivate]
    );

    const customMenuActions: IStandardListViewExtraItems[] = useMemo(
        () => [
            {
                enabledCheck: (selectedItems) => selectedItems && selectedItems.length > 0 && selectedItems.every((i) => i && !i.isActive),
                onClick: (selectedItems) => handleActivate(selectedItems.map((i) => i.id)),
                text: 'Make Active',
            },
            {
                enabledCheck: (selectedItems) => selectedItems && selectedItems.length > 0 && selectedItems.every((i) => i && i.isActive),
                onClick: (selectedItems) => handleInactivate(selectedItems.map((i) => i.id)),
                text: 'Make Inactive',
            },
        ],
        [handleActivate, handleInactivate]
    );

    const handleEdit: StandardListViewV2EditHandler<WorkItemDto> = (_, workItem) => {
        setSelectedWorkItem(workItem);
        setSelectedComponentId(workItem.id);
    };

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle=''
                    defaultPaginationProps={DEFAULT_PAGINATION}
                    getDataQuery={useGetStudyWorkItemsQuery}
                    isShowActiveToggleVisible={true}
                    addButton={
                        <Tooltip title='Add New'>
                            <IconButton color='primary' size='large' onClick={handleAdd}>
                                <AddCircle fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                    }
                    additionalActionItems={customTitleActions}
                    additionalMenuItems={customMenuActions}
                    tableColumns={TABLE_COLUMNS}
                    entityNameSingular='Component'
                    permissionName='workItems'
                    spacing={3}
                    additionalQueryParameters={{
                        searchText: searchText,
                        studyId: study.id,
                        showCompleted: isCompletedVisible,
                        activeStatus: activeStatusFilter,
                        itemType: itemTypeFilter,
                        periodStartDate: periodStartDateFilter?.toISOString(),
                        periodEndDate: periodEndDateFilter?.toISOString(),
                    }}
                    preActionItemSlot={customHeaderContent}
                    handleRowClick={setSelectedComponentId}
                    hideSearch
                    disableGutters
                    hideBorder
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    hideMenuDivider
                />
            </Grid>
            <AddEditWorkItemModal
                isOpen={!!selectedWorkItem}
                workItemToUpdate={selectedWorkItem}
                close={() => setSelectedWorkItem(undefined)}
                companyId={study.companyId}
                study={study}
            />
        </Grid>
    );
};
