import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Box, Chip, FormControl, Grid, InputLabel, MenuItem, Radio, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RespondentDto } from '../../../../dtos';
import { useGetCompanyRespondentsQuery } from '../../../../store';
import { RespondentQuickAdd } from '../../../QuickAdds';
import { SurveyBuilderContext } from '../../utils';
import { TAQ_BY_BC_ID } from '../../../../Views';

interface ISurveyDistributionRespondentsProps {
    addToDistributionConfig: (arrayName: string, respondent: RespondentDto) => void;
    deleteDistributionConfig: (arrayName: string, respondent?: RespondentDto) => void;
    setSurveyDistribution: (surveyDistribution: string) => void;
    setFillOutSurveyValue: (SurveyValue: string) => void;
    setGroupName: (groupName: string) => void;
}

export const SurveyDistributionRespondents: FC<ISurveyDistributionRespondentsProps> = (props) => {
    const { addToDistributionConfig, deleteDistributionConfig, setSurveyDistribution, setFillOutSurveyValue, setGroupName } = props;
    const { surveyPayload, surveyAliasShort, shouldForceFillOutByGroup } = useContext(SurveyBuilderContext);
    const [inputValue, setInputValue] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const { id } = useParams();
    const {
        data: autoCompleteData,
        isLoading: autoCompleteLoading,
        refetch,
    } = useGetCompanyRespondentsQuery({
        parentId: id!,
        searchText: inputValue,
        page: 0,
        pageSize: 100,
    });

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedValue = event.target.value;

        if (newSelectedValue === 'each-respondent') {
            setDropdownValue('');
            setFillOutSurveyValue('');
            setGroupName('');
            setInputValue('');
            deleteDistributionConfig('individualRecipient');
            setSurveyDistribution(newSelectedValue);
        } else {
            setSurveyDistribution(newSelectedValue);
        }
    };

    const handleAddChip = (arrayName: string, respondent: RespondentDto) => {
        if (surveyPayload.distributionConfig.individualRecipient.length === 0) {
            addToDistributionConfig(arrayName, respondent);
        }
        setInputValue('');
    };

    const handleDeleteChip = (arrayName: string, respondent: RespondentDto) => {
        deleteDistributionConfig(arrayName, respondent);
    };

    const handleSurveyValueChange = (event: SelectChangeEvent) => {
        setFillOutSurveyValue(event.target.value);
    };

    const handleGroupName = (event: ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
    };

    const isIndividualRecipientRequiredButNotProvided = useMemo(
        () =>
            surveyPayload.distributionConfig.individualRecipient.length === 0 && surveyPayload.distributionConfig.surveyDistribution === 'individualRecipient',
        [surveyPayload.distributionConfig]
    );

    const isGroupNameRequiredButNotProvided = useMemo(
        () =>
            surveyPayload.distributionConfig.fillOutSurveyValue === 'Group' &&
            surveyPayload.distributionConfig.groupName === '' &&
            surveyPayload.distributionConfig.surveyDistribution === 'individualRecipient',
        [surveyPayload.distributionConfig]
    );

    return (
        <Box
            sx={{
                backgroundColor: 'primary.contrastText',
                py: 4,
                px: 4,
            }}>
            <Grid item container direction='row' alignItems='start'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h5' sx={{ marginBottom: '8px' }}>
                        How should the {surveyAliasShort.toLowerCase()} be distributed?
                    </Typography>
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='flex-end' xs={6}>
                    <Grid item>
                        <RespondentQuickAdd companyId={id!} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='center' xs={'auto'}>
                    <Radio
                        checked={surveyPayload.distributionConfig.surveyDistribution === 'each-respondent'}
                        onChange={handleRadioChange}
                        value='each-respondent'
                        name='distribution-button-group'
                    />
                </Grid>
                <Grid item container direction='row' alignItems='center' justifyContent='center' xs={'auto'}>
                    <Typography variant='body1' sx={{ marginLeft: '16px' }}>
                        Each respondent should receive their own {surveyAliasShort.toLowerCase()}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' spacing={2}>
                <Grid item container direction='column' justifyContent='center' xs={'auto'}>
                    <Radio
                        checked={surveyPayload.distributionConfig.surveyDistribution === 'individualRecipient'}
                        onChange={handleRadioChange}
                        value='individualRecipient'
                        name='distribution-button-group'
                        disabled={surveyPayload.selectedFormType.id === TAQ_BY_BC_ID}
                    />
                </Grid>
                <Grid item container direction='row' alignItems='flex-start' justifyContent='flex-start' xs={'auto'}>
                    <Typography variant='body1'>One individual...</Typography>
                </Grid>
                <Grid item container direction='row' justifyContent='start' xs={'auto'}>
                    <Autocomplete
                        freeSolo
                        loading={autoCompleteLoading}
                        disabled={surveyPayload.distributionConfig.surveyDistribution === 'each-respondent'}
                        id='respondents-dropdown'
                        options={
                            autoCompleteData
                                ? autoCompleteData.pageResults.map((row, index) => {
                                      return { label: `${row.firstName} ${row.lastName}`, value: row };
                                  })
                                : []
                        }
                        value={dropdownValue}
                        sx={{ width: 300 }}
                        inputValue={inputValue}
                        onChange={(_: any, newValue: any | null) => {
                            if (newValue) {
                                handleAddChip('individualRecipient', newValue.value);
                            }
                        }}
                        onInputChange={(_, newInputValue) => {
                            setInputValue(newInputValue);
                            refetch();
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key === 'Enter') {
                                event.defaultMuiPrevented = true;
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label='Select individual' />}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='start' xs={'auto'}>
                    {surveyPayload.distributionConfig.individualRecipient.map((respondent, index) => {
                        return (
                            <Grid item xs='auto' sx={{ mr: 2 }} key={index}>
                                <Chip
                                    label={`${respondent.firstName} ${respondent.lastName}`}
                                    size='small'
                                    variant='outlined'
                                    style={{ backgroundColor: '#E9E9E9', borderColor: '#707070', maxWidth: 150 }}
                                    onDelete={() => handleDeleteChip('individualRecipient', respondent)}
                                    deleteIcon={<CancelIcon style={{ color: '#333333' }} />}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container direction='row' alignItems='start' justifyContent='start' xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Typography variant='body1'>...who will fill out {surveyAliasShort.toLowerCase()} for</Typography>
                </Grid>
                <Grid item container direction='row' alignItems='start' justifyContent='start' xs={'auto'}>
                    <FormControl sx={{ width: 200 }}>
                        <InputLabel>Individual or Group</InputLabel>
                        <Select
                            value={surveyPayload.distributionConfig.fillOutSurveyValue}
                            onChange={handleSurveyValueChange}
                            disabled={surveyPayload.distributionConfig.surveyDistribution === 'each-respondent' || shouldForceFillOutByGroup}
                            label='Individual or Group'>
                            <MenuItem value={'Group'}>The entire group</MenuItem>
                            <MenuItem value={'Individual'}>Each individual</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container direction='row' alignItems='start' justifyContent='start' xs={'auto'}>
                    <TextField
                        disabled={
                            surveyPayload.distributionConfig.fillOutSurveyValue !== 'Group' ||
                            surveyPayload.distributionConfig.surveyDistribution === 'each-respondent'
                        }
                        label='Group Name'
                        value={surveyPayload.distributionConfig.groupName}
                        onChange={handleGroupName}
                    />
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' sx={{ marginTop: 2 }}>
                <Grid item xs={6}>
                    {isIndividualRecipientRequiredButNotProvided && (
                        <Typography variant='body1' color='primary' ml={2}>
                            One individual required with option selected.
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} textAlign='end'>
                    {shouldForceFillOutByGroup && (
                        <Typography variant='body1' color='primary'>
                            Note: multiple time periods selected. Survey must be filled out for entire group.
                        </Typography>
                    )}
                    {isGroupNameRequiredButNotProvided && (
                        <Typography variant='body1' color='primary'>
                            Group name is required when filling out the {surveyAliasShort.toLowerCase()} for the entire group.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
