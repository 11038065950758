import { Business, FilterList } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ApiError, LoadingIndicator, SlimPageHeader, StandardSearchInput } from '../../Components/CoreLib/library';
import { useGetCompanyQuery } from '../../store';
import { CompanyDetails } from './Components';
import { CompanyDashboardSidebarTabContentViewer, CompanyDashboardTabsContainer } from './Components/Dashboard';
import { CompanyDashboardContext } from './Utils';
import { FilterScheduleDialog } from '../../Components/Forms/Schedule';
import { DataFilterContext, DataFilterContextChips, useInitializeDataFilterContext } from '../../Contexts';
import { WorkItemFilterModal } from '../Studies/Components';
import { RespondentFilterDialog } from '../../Components/FilterDialogs/Respondents/RespondentFilterDialog';
import { DepartmentFilterDialog } from '../../Components/FilterDialogs';
import { ActivityStreamFilterDialog } from '../../Components/FilterDialogs/ActivityStreams';
import { useSearchParams } from 'react-router-dom';

// Cannot search the Overview tab
const UNSEARCHABLE_TABS = [0];

export const CompanyDashboard: FC = () => {
    const { id: companyId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlTab = searchParams.get('tab');
    const { data: company, isLoading, isError: isGetCompanyError, refetch: refetchCompany } = useGetCompanyQuery(companyId!);
    const [searchText, setSearchText] = useState('');
    const [selectedTab, setSelectedTab] = useState<number>(urlTab ? Number(urlTab) : 0);
    const [selectedComponentId, setSelectedComponentId] = useState('');
    const [selectedRespondentId, setSelectedRespondentId] = useState('');
    const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
    const isSearchDisabled = useMemo(() => UNSEARCHABLE_TABS.includes(selectedTab), [selectedTab]);
    const [overviewDaysBack, setOverviewDaysBack] = useState(90);
    const surveyFilterContext = useInitializeDataFilterContext(true);
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
    const scheduleFilterDialogTitle = useMemo(() => (selectedTab === 2 ? 'BCMs' : 'Surveys'), [selectedTab]);

    const handleTabChange = useCallback(
        (newTab: number) => {
            setSelectedTab(newTab);
            surveyFilterContext.clearFilters();
            setSearchParams({ tab: newTab.toString() });
        },
        [surveyFilterContext, setSearchParams]
    );

    const currentTabNameOverrides = useMemo(() => {
        const overrideMap = new Map<string, string>();
        switch (selectedTab) {
            case 3:
                overrideMap.set('Period', 'Started');
                break;
            case 6:
                overrideMap.set('Created', 'Activity');
                break;
        }
        return overrideMap;
    }, [selectedTab]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (isGetCompanyError || !company) {
        return <ApiError onReloadClick={refetchCompany} />;
    }

    return (
        <CompanyDashboardContext.Provider
            value={{
                company,
                searchText,
                setSearchText,
                selectedTab,
                setSelectedTab: handleTabChange,
                selectedComponentId,
                setSelectedComponentId,
                selectedDepartmentId,
                setSelectedDepartmentId,
                setSelectedRespondentId,
                selectedRespondentId,
                overviewFilters: {
                    daysBack: overviewDaysBack,
                    setDaysBack: setOverviewDaysBack,
                },
            }}>
            <DataFilterContext.Provider value={surveyFilterContext}>
                <Grid container direction='row' spacing={3}>
                    <Grid item xs={12}>
                        <SlimPageHeader
                            icon={<Business />}
                            title={company.name}
                            breadCrumbProps={{
                                currentPageLabel: company.name,
                                links: [
                                    { label: 'Home', navLink: '/' },
                                    { label: 'Companies', navLink: '/companies' },
                                ],
                            }}
                            color='transparent'
                            disableGutters
                            endSlot={
                                <Box display='flex' gap={2}>
                                    <Tooltip title={'Filter'}>
                                        <IconButton color='primary' size='large' onClick={() => setIsFilterDialogOpen(true)}>
                                            <FilterList fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                    <StandardSearchInput
                                        searchText={searchText}
                                        handleSearchChange={setSearchText}
                                        textFieldProps={{ disabled: isSearchDisabled, sx: { width: 400 } }}
                                    />
                                </Box>
                            }
                        />
                    </Grid>
                    <Grid item container direction='row' xs={12} gap={1}>
                        <DataFilterContextChips nameOverrides={currentTabNameOverrides} />
                    </Grid>
                    <Grid item xs={4}>
                        <Paper
                            elevation={0}
                            sx={{ border: 'solid 1px #AAAAAA', height: '100%', padding: 2, display: 'flex', flexDirection: 'column', rowGap: 3 }}>
                            <CompanyDetails />
                            <CompanyDashboardSidebarTabContentViewer />
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper elevation={0} sx={{ border: 'solid 1px #AAAAAA', height: '100%' }}>
                            <CompanyDashboardTabsContainer />
                        </Paper>
                    </Grid>
                </Grid>
                <FilterScheduleDialog
                    open={isFilterDialogOpen && [0, 1, 2].includes(selectedTab)}
                    onClose={() => setIsFilterDialogOpen(false)}
                    title={scheduleFilterDialogTitle}
                    companyId={company.id}
                    hideRemindersFilter={selectedTab === 0 || selectedTab === 2}
                    hideApprovedByFilter={selectedTab === 0 || selectedTab === 2}
                    hideFormTypeFilter={selectedTab === 2}
                    hideStudyFilter={selectedTab === 0}
                />
                <WorkItemFilterModal
                    open={isFilterDialogOpen && selectedTab === 3}
                    onClose={() => setIsFilterDialogOpen(false)}
                />
                <RespondentFilterDialog
                    open={isFilterDialogOpen && selectedTab === 4}
                    onClose={() => setIsFilterDialogOpen(false)}
                    companyId={company.id}
                />
                <DepartmentFilterDialog
                    open={isFilterDialogOpen && selectedTab === 5}
                    onClose={() => setIsFilterDialogOpen(false)}
                    companyId={company.id}
                />
                <ActivityStreamFilterDialog
                    open={isFilterDialogOpen && selectedTab === 6}
                    onClose={() => setIsFilterDialogOpen(false)}
                    hideCompanyFilter
                />
            </DataFilterContext.Provider>
        </CompanyDashboardContext.Provider>
    );
};
