import { Button, Grid, TextField, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { ImportWorkItemRowDto } from '../../../dtos';
import { Download } from '@mui/icons-material';
import { useWorkItemImportFileProcessor } from './Hooks/useWorkItemImportFileProcessor';

export interface ImportWorkItemsFileSelectProps {
    onCancelClicked: () => void;
    loadSelectedFileData: (parseData: ImportWorkItemRowDto[]) => void;
    companyId: string;
}

export const ImportWorkItemsFileSelect: FC<ImportWorkItemsFileSelectProps> = ({ onCancelClicked, loadSelectedFileData, companyId }) => {
    const [selectedFile, setSelectedFile] = useState<File>();
    const {
        isParsingFile,
        fileProcessingError,
        extractImportDataFromFile,
        parseWorkItems
    } = useWorkItemImportFileProcessor(companyId);
    
    const handleChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length === 1) {
            setSelectedFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        if (selectedFile) {
            extractImportDataFromFile(selectedFile);
        }
    }, [selectedFile, extractImportDataFromFile]);

    const handleNextClicked = useCallback(() => {
        if (parseWorkItems.length === 0) {
            return;
        }
        loadSelectedFileData(parseWorkItems);
    }, [loadSelectedFileData, parseWorkItems]);

    const handleDownloadSample = () => {
        window.open(`${window.origin}/sample-work-item.csv`);
    }

    return (
        <Grid container direction='column' justifyContent='space-between'>
            <Grid item container direction='row'>
                <Grid item sx={{ pr: 2 }}>
                    <label htmlFor='choose-file'>
                        <input
                            name='choose-file'
                            id='choose-file'
                            type='file'
                            accept='.csv'
                            style={{ display: 'none' }}
                            onChange={handleChooseFile}
                            disabled={isParsingFile}
                        />

                        <Button variant='contained' component="span" color='primary' disabled={isParsingFile}>Choose File</Button>
                    </label>
                </Grid>
                <Grid item sx={{ flex: 1, maxWidth: 400 }}>
                    <TextField size='small' fullWidth label='File Name' disabled value={selectedFile?.name} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography color='error'>{fileProcessingError}</Typography>
                </Grid>
            </Grid>
            <Grid item display='flex' gap={2} mt={3}>
                <Button onClick={handleDownloadSample} size='large' sx={{ textAlign: 'center', marginLeft: '-16px', marginRight: 'auto', minWidth: 360 }}>
                    <Download fontSize='small'/> Download Components CSV template
                </Button>
                <Button variant='outlined' onClick={onCancelClicked} sx={{ width: 104 }}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleNextClicked} disabled={parseWorkItems.length === 0}  sx={{ width: 104 }}>
                    Next
                </Button>
            </Grid>
        </Grid>
    );
}