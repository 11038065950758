import { Grid } from '@mui/material';
import { Marked } from '@ts-stack/markdown';
import { FC } from 'react';
import { SectionHeading } from '../../../../models/survey';
import { onlyShowOnMobile } from '../util';

export interface IMobileSectionTitleHeadingsProps {
    sectionHeadings: SectionHeading[];
}

export const MobileSectionTitleHeadings: FC<IMobileSectionTitleHeadingsProps> = props => {
    const { sectionHeadings } = props;

    if (sectionHeadings.length === 0) {
        return null;
    }

    return (
        <Grid item container direction='column' my={2} sx={{ textAlign: 'center', ...onlyShowOnMobile }}>
            {sectionHeadings.map((heading, i) => (
                <Grid
                    key={`heading-${i}`}
                    item
                    container
                    direction='row'
                    justifyContent='center'
                >
                    <div dangerouslySetInnerHTML={{ __html: Marked.parse(heading.text) }} />
                </Grid>
            ))}
        </Grid>
    )
}