import { FormControl, FormLabel, Select, MenuItem, FormHelperText, CircularProgress, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { DepartmentDto } from '../../dtos';
import { useGetDepartmentsQuery } from '../../store';

export interface IDepartmentSelectProps {
    companyId: string;
    selectedDepartmentId: string;
    handleSelectedDepartmentChange: (selectedDepartment?: DepartmentDto) => void;
    departmentFilter?: (department: DepartmentDto) => boolean;
    isRequired?: boolean;
    errorMessage?: string;
}

export const DepartmentSelect: FC<IDepartmentSelectProps> = props => {
    const { companyId, selectedDepartmentId, handleSelectedDepartmentChange, departmentFilter = () => true, isRequired = false, errorMessage = ''} = props;
    const { data: departments, isLoading: isLoadingDepartments, isError: isErrorLoadingDepartments } = useGetDepartmentsQuery({
        parentId: companyId,
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 50000,
        includeInactive: false
    });

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const selectedRecord = departments?.pageResults.find(x => x.id === event.target.value);
        handleSelectedDepartmentChange(selectedRecord);
    }

    return (
        <FormControl error={isErrorLoadingDepartments || !!errorMessage} fullWidth required={isRequired}>
            <FormLabel>Department</FormLabel>
            <Select
                value={selectedDepartmentId}
                onChange={handleSelectChange}
                endAdornment={(isLoadingDepartments && <div><CircularProgress size={24} /></div>)}
            >
                <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                {departments?.pageResults.filter(departmentFilter).map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                        {department.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{isErrorLoadingDepartments ? 'Failed to load department options' : errorMessage}</FormHelperText>
        </FormControl>
    )
}