import { FC, useCallback, useContext } from 'react';
import { RespondentDto } from '../../../dtos';
import { useGetCompanyRespondentsQuery } from '../../../store';
import { DataTableColumn, IStandardListViewProps, StandardListView } from '../../CoreLib/library';
import { CompanyDashboardContext } from '../../../Views/Company/Utils';

const TABLE_COLUMNS: DataTableColumn<RespondentDto>[] = [
    { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
    { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
    { key: 'title', label: 'Title', sortKey: 'TITLE' },
    { key: 'department', label: 'Department', sortKey: 'DEPARTMENT', fieldMapper: (row: RespondentDto) => row.department?.name },
    {
        key: 'manager',
        label: 'Manager',
        sortKey: 'MANAGER',
        fieldMapper: (row: RespondentDto) => (row.manager ? `${row.manager?.firstName} ${row.manager?.lastName}` : ''),
    },
    {
        key: 'shouldForwardEmailsToManager',
        label: 'Emails Forwarded',
        sortKey: 'FORWARDED',
        fieldMapper: (row: RespondentDto) => row.shouldForwardEmailsToManager ? 'Yes' : 'No',
    },
    {
        key: 'formType',
        label: 'Form Type',
        sortKey: 'FORM_TYPE',
        fieldMapper: (row: RespondentDto) => row.surveyTemplate?.name ?? '',
    },
];

export interface ICompanyRespondentsListView {
    companyId: string;
    customTableProps?: Partial<IStandardListViewProps>;
}

export const CompanyRespondentsListView: FC<ICompanyRespondentsListView> = ({ companyId, customTableProps = {} }) => {
    const { setSelectedRespondentId } = useContext(CompanyDashboardContext);

    const handleRowClick = useCallback((id: string) => {
        if(id && setSelectedRespondentId) {
            setSelectedRespondentId(id);
        }
    }, [setSelectedRespondentId]);

    return (
        <StandardListView
            headerIcon={<></>}
            headerTitle=''
            entityNameSingular='Respondent'
            entityNamePlural='Respondents'
            tableColumns={TABLE_COLUMNS}
            defaultPaginationProps={{
                sortKey: 'FIRST_NAME',
                sortAsc: true,
                page: 0,
                pageSize: 25,
            }}
            {...customTableProps}
            handleRowClick={handleRowClick}
            getDataQuery={useGetCompanyRespondentsQuery}
            additionalQueryParameters={{ parentId: companyId, ...customTableProps.additionalQueryParameters }}
            permissionName='respondents'
            showSearch={false}
            showFilterButton={false}
            isBorderVisible={false}
        />
    );
};
