import { AddCircle } from '@mui/icons-material';
import { Tooltip, IconButton, Box } from '@mui/material';
import { FC, useState } from 'react';
import { AddRespondentModal } from '../Forms/CompanyForm/RespondentForm/AddRespondentModal';
import { RespondentDto } from '../../dtos';

export interface IRespondentQuickAddProps {
    companyId: string;
    tooltip?: string;
    afterCreate?: (createdRecord: RespondentDto) => void
}

export const RespondentQuickAdd: FC<IRespondentQuickAddProps> = ({ tooltip = 'Add New', companyId, afterCreate }) => {
    const [addRespondentIsVisible, setAddRespondentIsVisible] = useState(false);

    return (
        <>
            <Tooltip title={tooltip}>
                <Box>
                    <IconButton color='primary' size='large' onClick={() => setAddRespondentIsVisible(true)}>
                        <AddCircle fontSize='inherit' />
                    </IconButton>
                </Box>
            </Tooltip>
            <AddRespondentModal companyId={companyId} isVisible={addRespondentIsVisible} setIsVisible={setAddRespondentIsVisible} afterCreate={afterCreate} />
        </>
    );
};
