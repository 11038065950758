import { FC, useContext } from 'react';
import { GroupedDataPieChart } from '../../../../../../Components/Charts';
import { emptyGuid } from '../../../../../../models';
import { useGetStudyBcmsByStatusQuery } from '../../../../../../store';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';
import { DataFilterContext } from '../../../../../../Contexts';

const COLOR_PALLET = new Map([
    ['Issued', '#BFBFBF'],
    ['Scheduled', '#97CBD2'],
    ['Pending', '#57585A'],
]);

export const StudyDashboardBCMTabSidebarContent: FC = () => {
    const { study } = useContext(StudyDashboardContext);
    const {
        statusFilter,
        reminderFilter,
        approvedByFilter,
        scheduledFromDateFilter: fromDateFilter,
        scheduledToDateFilter: toDateFilter,
        periodStartDateFilter,
        periodEndDateFilter,
        setStatusFilter
    } = useContext(DataFilterContext);
    const { data: workItemStats, isLoading } = useGetStudyBcmsByStatusQuery({ studyId: study.id ?? emptyGuid, searchParams: {
        status: statusFilter,
        reminder: reminderFilter,
        approvedBy: approvedByFilter,
        fromDate: fromDateFilter ?? undefined,
        toDate: toDateFilter ?? undefined,
        periodStartDate: periodStartDateFilter ?? undefined,
        periodEndDate: periodEndDateFilter ?? undefined,
    }});

    return <GroupedDataPieChart name='BCM Status' groupedData={workItemStats ?? []} onSliceClicked={setStatusFilter} colorMap={COLOR_PALLET} isLoading={isLoading} />;
};