import { Grid } from '@mui/material';
import { FC } from 'react';
import { ActivityStreamTable } from '../ActivityStream';

export const ActivityStreamWidget: FC = () => {

    return (
        <Grid container direction='column'>
            <Grid item>
                <ActivityStreamTable />
            </Grid>
        </Grid>
    )
}