import { Grid } from '@mui/material';
import { Marked } from '@ts-stack/markdown';
import { FC } from 'react';
import { SectionHeading } from '../../../../models/survey';
import { onlyShowOnDesktop } from '../util';

export interface IDesktopSectionTitleHeadingsProps {
    sectionHeadings: SectionHeading[];
}

export const DesktopSectionTitleHeadings: FC<IDesktopSectionTitleHeadingsProps> = props => {
    const { sectionHeadings } = props;

    if (sectionHeadings.length === 0) {
        return null;
    }

    return (
        <Grid item container direction='column' sx={{ paddingRight: '24px', ...onlyShowOnDesktop }}>
            {sectionHeadings.map((heading, i) => (
                <Grid
                    key={`heading-${i}`}
                    item
                    container
                    direction='row'
                    justifyContent={heading.align === 'right' ? 'flex-end' : 'flex-start'}
                >
                    <div dangerouslySetInnerHTML={{ __html: Marked.parse(heading.text) }} />
                </Grid>
            ))}
        </Grid>
    )
}