import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useGetCompanyManagersQuery, useGetDepartmentsQuery, useGetFormTypesQuery, useUpdateRespondentMutation } from '../../../../../store';
import { LoadingIndicator, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../../../CoreLib/library';
import { RespondentDto } from '../../../../../dtos';

export interface IBulkReassignModalProps {
    selectedRespondents: RespondentDto[];
    setIsMoveDepartmentModalVisible: Function;
    isMoveDepartmentModalVisible: boolean;
    companyId: string;
}

export const BulkReassignModal: FC<IBulkReassignModalProps> = ({ selectedRespondents, isMoveDepartmentModalVisible, setIsMoveDepartmentModalVisible, companyId }) => {
    const [department, setDepartment] = useState('');
    const [manager, setManager] = useState('');
    const [formType, setFormType] = useState('');
    const [updateRespondent, { isSuccess: isUpdateRespondentSuccess, isError: isUpdateRespondentError, reset: resetUpdate }] = useUpdateRespondentMutation();
    const { data: departments, isLoading: isLoadingDepartments, isError: isErrorLoadingDepartments } = useGetDepartmentsQuery({
        parentId: companyId,
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 50000,
        includeInactive: false
    });
    const { data: managers, isLoading: managersLoading, isError: isGetManagersError } = useGetCompanyManagersQuery(companyId);
    const { data: formTypes, isLoading: formTypesLoading, isError: isGetFormTypeError } = useGetFormTypesQuery();

    useSuccessfulUpdateSnackbar('respondents', isUpdateRespondentSuccess, () => {
        resetUpdate();
        handleClose();
    });
    useFailedUpdateSnackbar('respondents', isUpdateRespondentError, resetUpdate);
    useFailedActionSnackbar('retrieve', 'departments', isErrorLoadingDepartments);
    useFailedActionSnackbar('retrieve', 'managers', isGetManagersError);
    useFailedActionSnackbar('retrieve', 'surveys', isGetFormTypeError);

    const formIsValid = useMemo(() => {
        let isValid = false;

        if (department || manager || formType) {
            isValid = true;
        }

        return isValid;
    }, [department, formType, manager]);

    const handleClose = useCallback(() => {
        setIsMoveDepartmentModalVisible(false);
    }, [setIsMoveDepartmentModalVisible]);

    const removableIdMapping = (selectedId: string, previousId: string | undefined): string | undefined => {
        if (selectedId === 'remove') {
            return undefined;
        }
        if (selectedId === '') {
            return previousId;
        }
        return selectedId;
    }

    const handleSave = useCallback(() => {
        if (formIsValid) {
            selectedRespondents.forEach((respondent) => {
                updateRespondent(
                    {
                        ...respondent,
                        departmentId: removableIdMapping(department, respondent.departmentId),
                        managerId: removableIdMapping(manager, respondent.managerId),
                        surveyTemplateId: formType ? formType : respondent.surveyTemplateId
                    })
            });
        }
    }, [department, formIsValid, formType, manager, selectedRespondents, updateRespondent]);

    const onDepartmentChange = useCallback((event: SelectChangeEvent) => {
        setDepartment(event.target.value);
    }, []);

    const onManagerChange = useCallback((event: SelectChangeEvent) => {
        setManager(event.target.value);
    }, []);

    const onFormTypeChange = useCallback((event: SelectChangeEvent) => {
        setFormType(event.target.value);
    }, []);

    return (
        <Dialog
            open={isMoveDepartmentModalVisible}
            onClose={handleClose}
            maxWidth='lg'
            keepMounted={false}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: '40%',
                },
            }}>
            <DialogTitle>Bulk Reassign</DialogTitle>
            <DialogContent>
                {managersLoading || formTypesLoading || isLoadingDepartments ? <LoadingIndicator /> :
                    <>
                        <DialogContentText>Choose the new values for the selected respondents.</DialogContentText>
                        <Grid container direction='column'>
                            <Grid item>
                                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                                    <FormLabel>Department</FormLabel>
                                    <Select value={department} onChange={onDepartmentChange}>
                                        <MenuItem key='none' value=''>
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem key='remove' value='remove'>
                                            (Remove Department)
                                        </MenuItem>
                                        {departments?.pageResults.map((model) => (
                                            <MenuItem key={model.id} value={model.id}>
                                                {model.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                                    <FormLabel>Manager</FormLabel>
                                    <Select value={manager} onChange={onManagerChange}>
                                        <MenuItem key='none' value=''>
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem key='remove' value='remove'>
                                            (Remove Manager)
                                        </MenuItem>
                                        {managers?.map((model) => (
                                            <MenuItem key={model.id} value={model.id}>
                                                {model.firstName} {model.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                                    <FormLabel>Form Type</FormLabel>
                                    <Select value={formType} onChange={onFormTypeChange}>
                                        <MenuItem key='none' value=''>
                                            <em>None</em>
                                        </MenuItem>
                                        {formTypes?.map((model) => (
                                            <MenuItem key={model.id} value={model.id}>
                                                {model.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>
                    CANCEL
                </Button>
                <Button onClick={handleSave} variant='contained' color='primary' disabled={!formIsValid}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
