import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuccessfulCreateSnackbar, useFailedCreateSnackbar } from '../../Components/CoreLib/library';
import { UserDto } from '../../dtos';
import { useCreateUserMutation } from '../../store/api/user-api';
import { UserForm } from './UserForm';

export const AddUserView: FC = () => {
    const navigate = useNavigate();
    const [createUser, { data, isSuccess, isError, error: createError, reset, isLoading }] = useCreateUserMutation();

    const handleSuccessfulCreation = () => {
        reset();
        navigate(`/users/${data?.id}`);
    };

    useSuccessfulCreateSnackbar('User', isSuccess, handleSuccessfulCreation);
    useFailedCreateSnackbar('User', isError, reset, createError);

    const handleSave = (values: UserDto) => {
        createUser(values);
    };

    const handleCancel = () => {
        navigate('/users');
    };

    return <UserForm save={handleSave} cancel={handleCancel} isLoading={isLoading} />;
};
