import { useCallback, useContext, useMemo, useState } from 'react';
import { DataFilterContext } from '../../../Contexts';
import _ from 'lodash';

// TODO: (in later story likely) There is currently no way to filter by study in the filter dialog. I assume the client will want this in the future.
export interface ActivityStreamFilters {
    companyId?: string;
    respondentFirstName?: string;
    respondentLastName?: string;
    activityType?: string;
    formTypeId?: string;
    period?: string;
    createdFrom?: Date;
    createdTo?: Date;
}

export function useActivityStreamFilterDialogManager() {
    const [dialogFilters, setDialogFilters] = useState<ActivityStreamFilters>({});
    const {
        companyIdFilter,
        setCompanyIdFilter,
        firstNameFilter,
        setFirstNameFilter,
        lastNameFilter,
        setLastNameFilter,
        activityTypeFilter,
        setActivityTypeFilter,
        formTypeFilter,
        setFormTypeFilter,
        periodNameFilter,
        setPeriodNameFilter,
        createdFromDateFilter,
        setCreatedFromDateFilter,
        createdToDateFilter,
        setCreatedToDateFilter,
    } = useContext(DataFilterContext);

    const appliedFilters = useMemo<ActivityStreamFilters>(
        () => ({
            companyId: companyIdFilter,
            respondentFirstName: firstNameFilter,
            respondentLastName: lastNameFilter,
            activityType: activityTypeFilter,
            formTypeId: formTypeFilter,
            period: periodNameFilter,
            createdFrom: createdFromDateFilter,
            createdTo: createdToDateFilter,
        }),
        [companyIdFilter, firstNameFilter, lastNameFilter, activityTypeFilter, formTypeFilter, periodNameFilter, createdFromDateFilter, createdToDateFilter]
    );

    const handleDialogFilterValueChange = useCallback(
        (fieldName: keyof ActivityStreamFilters, value: any) => {
            let updatedFilterValues = _.cloneDeep(dialogFilters);
            updatedFilterValues[fieldName] = value;
            setDialogFilters(updatedFilterValues);
        },
        [dialogFilters]
    );

    const applyDialogFilters = useCallback(() => {
        setCompanyIdFilter(dialogFilters.companyId);
        setFirstNameFilter(dialogFilters.respondentFirstName);
        setLastNameFilter(dialogFilters.respondentLastName);
        setActivityTypeFilter(dialogFilters.activityType);
        setFormTypeFilter(dialogFilters.formTypeId);
        setPeriodNameFilter(dialogFilters.period);
        setCreatedFromDateFilter(dialogFilters.createdFrom);
        setCreatedToDateFilter(dialogFilters.createdTo);
    }, [
        dialogFilters,
        setCompanyIdFilter,
        setFirstNameFilter,
        setLastNameFilter,
        setActivityTypeFilter,
        setFormTypeFilter,
        setPeriodNameFilter,
        setCreatedFromDateFilter,
        setCreatedToDateFilter,
    ]);

    const loadAppliedFiltersIntoDialog = useCallback(() => {
        setDialogFilters(appliedFilters);
    }, [appliedFilters]);

    const isFormDirty = useMemo(() => JSON.stringify(appliedFilters) !== JSON.stringify(dialogFilters), [appliedFilters, dialogFilters]);

    return {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty
    };
}
