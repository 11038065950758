import { useMemo } from 'react';
import { EmailTemplateDto, RespondentDto, SurveyPayloadDto } from '../../../dtos';
import { BCMInitializationType } from './SurveyBuilderContext';
import { formatDateTimeForReading } from '../../../util';

export function useStepSummaryGenerator(surveyPayload: SurveyPayloadDto, emailTemplates: EmailTemplateDto[], bcmInitializationType?: BCMInitializationType) {
    const dataString = useMemo(() => {
        const { businessComponents, employees } = surveyPayload.businessComponentMatrixConfig;
        const numBusinessComponents = businessComponents.length;
        const numEmployees = employees.length;
        const businessComponentCount = `${numBusinessComponents} business component${numBusinessComponents === 1 ? '' : 's'}`;
        const employeeCount = `${numEmployees} respondent${numEmployees === 1 ? '' : 's'}`;
        return `${businessComponentCount} and ${employeeCount}.`;
    }, [surveyPayload]);

    const frequencyString = useMemo(() => {
        let freqString = 'This is a one-time survey that should be sent ';
        if (surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring') {
            freqString = freqString + surveyPayload.frequencyConfig.frequencyValue.replaceAll('-', ' ') + '.';
        } else {
            if (surveyPayload.frequencyConfig.radioStartDate === 'immediately') {
                freqString = freqString + 'immediately.';
            } else {
                freqString = freqString + formatDateTimeForReading(surveyPayload.frequencyConfig.startDate) + '.';
            }
        }
        return freqString;
    }, [surveyPayload]);

    const buildRespondentString = (respondentArray: RespondentDto[]) => {
        let respondentString = '';
        switch (respondentArray.length) {
            case 1:
                respondentString = `${respondentArray[0].firstName} ${respondentArray[0].lastName}`;
                break;
            case 2:
                respondentString = `${respondentArray[0].firstName} ${respondentArray[0].lastName} and ${respondentArray[1].firstName} ${respondentArray[1].lastName}`;
                break;
            default:
                respondentArray.forEach((respondent, index) => {
                    if (respondentArray.length - 1 === index) {
                        respondentString += `and ${respondent.firstName} ${respondent.lastName}`;
                    } else {
                        respondentString += `${respondent.firstName} ${respondent.lastName}, `;
                    }
                });
        }
        return respondentString;
    };

    const buildDistributionString = useMemo(() => {
        if (surveyPayload.distributionConfig.surveyDistribution === 'each-respondent') {
            return 'Each respondent should receive their own survey.';
        }

        let distributionString = '';
        distributionString += `One individual: ${buildRespondentString(surveyPayload.distributionConfig.individualRecipient)}, who will fill out survey for `;

        if (surveyPayload.distributionConfig.fillOutSurveyValue === 'Individual') {
            distributionString += `each individual. `;
        } else {
            distributionString += `the entire group "${surveyPayload.distributionConfig.groupName}". `;
        }

        if (surveyPayload.distributionConfig.CCRecipients.length > 0) {
            distributionString += `A CC will be sent to ${buildRespondentString(surveyPayload.distributionConfig.CCRecipients)}. `;
        }

        if (surveyPayload.distributionConfig.BCCRecipients.length > 0) {
            distributionString += `A BCC will be sent to ${buildRespondentString(surveyPayload.distributionConfig.BCCRecipients)}.`;
        }

        return distributionString;
    }, [surveyPayload]);

    const emailReminderString = useMemo(() => {
        let emailString = '';
        let emailTemplate = emailTemplates.find((x) => x.id === surveyPayload.emailConfig.emailTemplate);
        emailString += `Use the "${emailTemplate?.name}" email template. `;
        if (emailTemplate?.name === 'Client Contact Email') {
            emailString += `Company contact is ${surveyPayload.emailConfig.companyContactName}, ${surveyPayload.emailConfig.companyContactTitle}. `;
        }
        switch (surveyPayload.emailConfig.reminderValue) {
            case 'manually':
                emailString += 'I will send reminders manually.';
                break;
            case 'every-day':
                emailString += 'Every day.';
                break;
            case 'every-2-days':
                emailString += 'Every 2 days.';
                break;
            case 'every-3-days':
                emailString += 'Every 3 days.';
                break;
            case 'every-week':
                emailString += 'Every week.';
                break;
        }

        return emailString;
    }, [surveyPayload, emailTemplates]);

    const buildTimePeriodString = useMemo(() => {
        let timePeriodString = ``;
        if (surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring') {
            switch (surveyPayload.frequencyConfig.frequencyValue) {
                case 'every-year':
                    timePeriodString =
                        timePeriodString +
                        `${surveyPayload.frequencyConfig.surveyPeriods[0].month} ${surveyPayload.frequencyConfig.surveyPeriods[0].day} ("${
                            surveyPayload.frequencyConfig.surveyPeriods[0].surveyLabelPrefix
                        }", ${surveyPayload.frequencyConfig.surveyPeriods[0].yearLabel.replace('-', ' ')}, "${
                            surveyPayload.frequencyConfig.surveyPeriods[0].surveyLabelSuffix
                        }")`;
                    break;
                case 'every-three-months':
                    const dateString = surveyPayload.frequencyConfig.surveyPeriods
                        .map((d, i) => {
                            return `${i === surveyPayload.frequencyConfig.surveyPeriods.length - 1 ? 'and ' : ''}${d.month} ${d.day} ("${
                                d.surveyLabelPrefix
                            }", ${surveyPayload.frequencyConfig.surveyPeriods[i].yearLabel.replace('-', ' ')}, "${d.surveyLabelSuffix}")`;
                        })
                        .join(', ');
                    timePeriodString = timePeriodString + dateString;
                    break;
            }
            timePeriodString = timePeriodString + '.';
            return timePeriodString;
        } else {
            surveyPayload.timePeriodArray.forEach((timePeriod, index) => {
                if (surveyPayload.timePeriodArray.length === 1) {
                    timePeriodString += ` ${timePeriod.surveyLabel}`;
                } else if (surveyPayload.timePeriodArray.length - 1 === index) {
                    timePeriodString += `${timePeriod.surveyLabel}`;
                } else {
                    timePeriodString += `${timePeriod.surveyLabel}, `;
                }
            });
        }
        timePeriodString = timePeriodString + '.';
        return timePeriodString;
    }, [surveyPayload]);

    const settingString = useMemo(() => {
        switch (bcmInitializationType) {
            case 'import':
                return `Automatically generate for study "${surveyPayload.study?.name}".`;
            case 'new':
                return 'Manually Entered.';
            default:
                return 'Manually Updated.';
        }
    }, [surveyPayload, bcmInitializationType]);

    const surveySettingsString = useMemo(() => {
        return `TAQ survey for project "${surveyPayload.study?.name}".`;
    }, [surveyPayload]);

    return {
        dataString,
        frequencyString,
        buildDistributionString,
        emailReminderString,
        buildTimePeriodString,
        buildRespondentString,
        settingString,
        surveySettingsString,
    };
}
