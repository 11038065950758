import { AddCircle, Person } from '@mui/icons-material';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { ChangeEvent, FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { AddEditDepartmentModal } from '../../../../Views/Company/Departments/AddEditDepartmentModal';
import { RespondentDto } from '../../../../dtos';
import { emptyGuid } from '../../../../models';
import { useGetCompanyDepartmentsQuery, useGetCompanyManagersQuery, useGetCompanyQuery, useGetFormTypesQuery } from '../../../../store';
import { LoadingIndicator, NavBreadcrumbs, useFailedActionSnackbar } from '../../../CoreLib/library';
import { patterns } from '../../FormFields';
import { FormSection } from '../../FormSection';

export interface IRespondentFormProps {
    save: (respondent: RespondentDto) => void;
    cancel?: () => void;
    companyId: string;
    initValues?: RespondentDto;
    isModal?: boolean;
    doSaveRef?: React.MutableRefObject<(() => void) | undefined>;
}

// TODO: this component is using an old patter where all the for logic is defined with the rendering code. This should be refactored to use a form hook in the future.
export const RespondentForm: FC<IRespondentFormProps> = (props) => {
    const { companyId, initValues, save, cancel, isModal, doSaveRef } = props;
    const { data: company, isError: isGetCompanyError } = useGetCompanyQuery(companyId);
    const { data: departments, isLoading: isDepartmentsLoading, isError: isGetDepartmentsError } = useGetCompanyDepartmentsQuery(companyId);
    const { data: managers, isLoading: isManagersLoading, isError: isGetManagersError } = useGetCompanyManagersQuery(companyId);
    const { data: formTypes, isLoading: isFormTypesLoading, isError: isGetFormTypeError } = useGetFormTypesQuery();
    const [addDepartmentIsVisible, setAddDepartmentIsVisible] = useState(false);
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive ?? true : true);
    const [firstName, setFirstName] = useState(initValues ? initValues.firstName ?? '' : '');
    const [lastName, setLastName] = useState(initValues ? initValues.lastName ?? '' : '');
    const [title, setTitle] = useState(initValues ? initValues.title ?? '' : '');
    const [nickname, setNickname] = useState(initValues ? initValues.nickname ?? '' : '');
    const [email, setEmail] = useState(initValues ? initValues.email ?? '' : '');
    const [areaCode, setAreaCode] = useState(initValues ? initValues.areaCode ?? '' : '');
    const [phone, setPhone] = useState(initValues ? initValues.phone ?? '' : '');
    const [department, setDepartment] = useState(initValues && initValues.departmentId ? initValues.departmentId : '');
    const [manager, setManager] = useState(initValues && initValues.managerId ? initValues.managerId : '');
    const [isManager, setIsManager] = useState(initValues ? initValues.isManager ?? false : false);
    const [shouldForwardEmailsToManager, setShouldForwardEmailsToManager] = useState(initValues?.shouldForwardEmailsToManager ?? false);
    const [isContactOnly, setIsContactOnly] = useState(initValues ? initValues.isContactOnly ?? false : false);
    const [surveyTemplate, setSurveyTemplate] = useState(initValues && initValues.surveyTemplateId ? initValues.surveyTemplateId : '');
    const [fieldTesting, setFieldTesting] = useState(initValues ? initValues.fieldTesting ?? '' : '');
    const [fieldErrors, setFieldErrors] = useState({
        FIRST_NAME: '',
        LAST_NAME: '',
        EMAIL: '',
        AREA_CODE: '',
        FORM_TYPE: '',
    });

    useFailedActionSnackbar('retrieve', 'company', isGetCompanyError);
    useFailedActionSnackbar('retrieve', 'departments', isGetDepartmentsError);
    useFailedActionSnackbar('retrieve', 'managers', isGetManagersError);
    useFailedActionSnackbar('retrieve', 'surveys', isGetFormTypeError);

    const handleActiveToggleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    }, []);

    const handleFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }, []);

    const handleLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }, []);

    const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }, []);

    const handleNicknameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }, []);

    const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handleAreaCodeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setAreaCode(event.target.value);
        }
    }, []);

    const handlePhoneChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }, []);

    const handleDepartmentChange = useCallback((event: SelectChangeEvent) => {
        setDepartment(event.target.value);
    }, []);

    const handleSurveyTemplateChange = useCallback((event: SelectChangeEvent) => {
        setSurveyTemplate(event.target.value);
    }, []);

    const handleManagerChange = useCallback((event: SelectChangeEvent) => {
        setManager(event.target.value);
        if (!event.target.value) {
            setShouldForwardEmailsToManager(false);
        }
    }, []);

    const handleIsManagerToggleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIsManager(event.target.checked);
    }, []);
    
    const handleShouldForwardEmailsToManagerToggleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShouldForwardEmailsToManager(event.target.checked);
    }, []);

    const handleFieldTestingChange = useCallback((event: SelectChangeEvent) => {
        setFieldTesting(event.target.value);
    }, []);

    const validate = useCallback(
        (fieldName: string, overrideIsContactOnly?: boolean) => {
            let isValid = false;
            if (fieldName === 'FIRST_NAME') {
                if (firstName) {
                    fieldErrors.FIRST_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.FIRST_NAME = 'First Name is required';
                    isValid = false;
                }
            } else if (fieldName === 'LAST_NAME') {
                if (lastName) {
                    fieldErrors.LAST_NAME = '';
                    isValid = true;
                } else {
                    fieldErrors.LAST_NAME = 'Last Name is required';
                    isValid = false;
                }
            } else if (fieldName === 'AREA_CODE') {
                const regex = patterns.AreaCode.validate;
                if (!areaCode || regex.test(areaCode)) {
                    fieldErrors.AREA_CODE = '';
                    isValid = true;
                } else {
                    fieldErrors.AREA_CODE = 'Area Code must be ###';
                    isValid = false;
                }
            } else if (fieldName === 'EMAIL') {
                const regex = patterns.Email.validate;
                if (regex.test(email)) {
                    fieldErrors.EMAIL = '';
                    isValid = true;
                } else {
                    fieldErrors.EMAIL = 'Email is required';
                    isValid = false;
                }
            } else if (fieldName === 'FORM_TYPE') {
                if (surveyTemplate || (overrideIsContactOnly ?? isContactOnly)) {
                    fieldErrors.FORM_TYPE = '';
                    isValid = true;
                } else {
                    fieldErrors.FORM_TYPE = 'Form Type is required';
                    isValid = false;
                }
            } else {
                isValid = true;
            }
            setFieldErrors({
                FIRST_NAME: fieldErrors.FIRST_NAME,
                LAST_NAME: fieldErrors.LAST_NAME,
                EMAIL: fieldErrors.EMAIL,
                AREA_CODE: fieldErrors.AREA_CODE,
                FORM_TYPE: fieldErrors.FORM_TYPE,
            });
            return isValid;
        },
        [fieldErrors, firstName, lastName, areaCode, email, surveyTemplate, isContactOnly]
    );

    const handleIsContactOnlyToggleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setIsContactOnly(event.target.checked);
            validate('FORM_TYPE', event.target.checked);
        },
        [validate]
    );

    const onFieldBlur = useCallback(
        (fieldName: string) => () => {
            validate(fieldName);
        },
        [validate]
    );

    const formIsValid = useCallback(() => {
        let isValid = validate('FIRST_NAME');
        isValid = validate('LAST_NAME') && isValid;
        isValid = validate('EMAIL') && isValid;
        isValid = validate('AREA_CODE') && isValid;
        isValid = validate('FORM_TYPE') && isValid;
        return isValid;
    }, [validate]);

    const handleSave = (event: SyntheticEvent) => {
        event.preventDefault();
        doSave();
    };

    const doSave = useCallback(() => {
        if (formIsValid()) {
            save({
                ...initValues,
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                firstName: firstName,
                lastName: lastName,
                title: title,
                nickname: nickname,
                email: email,
                areaCode: areaCode,
                phone: phone,
                departmentId: department !== '' ? department : undefined,
                managerId: manager !== '' ? manager : undefined,
                isManager: isManager,
                shouldForwardEmailsToManager: shouldForwardEmailsToManager,
                fieldTesting: fieldTesting,
                companyId: companyId,
                isContactOnly: isContactOnly,
                surveyTemplateId: surveyTemplate !== '' ? surveyTemplate : undefined,
                createdOn: initValues?.createdOn ?? new Date(),
                hasBeenEmailed: initValues?.hasBeenEmailed ?? false,
                workItemCount: 0,
            });
        }
    }, [
        initValues,
        isActive,
        firstName,
        lastName,
        title,
        nickname,
        email,
        areaCode,
        phone,
        department,
        manager,
        isManager,
        shouldForwardEmailsToManager,
        fieldTesting,
        companyId,
        isContactOnly,
        surveyTemplate,
        formIsValid,
        save,
    ]);

    const handleCancel = useCallback(() => {
        if (cancel) {
            cancel();
        }
    }, [cancel]);

    const handleAddDepartment = useCallback(() => {
        setAddDepartmentIsVisible(true);
    }, []);

    // TODO: we don't normally use refs to call functions and the fact that this is just defined in the render method outside of any useEffect or useMemo is concerning, this should be refactored in the future.
    if (isModal && doSaveRef) {
        doSaveRef.current = doSave;
    }

    const formContent = useMemo(
        () => (
            <>
                <Grid item container direction='row' spacing={4}>
                    <Grid item xs={4}>
                        <FormControl error={fieldErrors.FIRST_NAME !== ''} fullWidth required>
                            <FormLabel>First Name</FormLabel>
                            <OutlinedInput value={firstName} onChange={handleFirstNameChange} onBlur={onFieldBlur('FIRST_NAME')} />
                            <FormHelperText>{fieldErrors.FIRST_NAME}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl error={fieldErrors.LAST_NAME !== ''} fullWidth required>
                            <FormLabel>Last Name</FormLabel>
                            <OutlinedInput value={lastName} onChange={handleLastNameChange} onBlur={onFieldBlur('LAST_NAME')} />
                            <FormHelperText>{fieldErrors.LAST_NAME}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Title</FormLabel>
                            <OutlinedInput value={title} onChange={handleTitleChange} onBlur={onFieldBlur('TITLE')} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={4}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Nickname</FormLabel>
                            <OutlinedInput value={nickname} onChange={handleNicknameChange} onBlur={onFieldBlur('NICKNAME')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl error={fieldErrors.EMAIL !== ''} fullWidth required>
                            <FormLabel>Email</FormLabel>
                            <OutlinedInput inputProps={{ inputMode: 'email' }} value={email} onChange={handleEmailChange} onBlur={onFieldBlur('EMAIL')} />
                            <FormHelperText>{fieldErrors.EMAIL}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item container direction='row' spacing={2} xs={4} flexWrap='nowrap'>
                        <Grid item width={104}>
                            <FormControl error={fieldErrors.AREA_CODE !== ''} fullWidth>
                                <FormLabel>Area Code</FormLabel>
                                <OutlinedInput
                                    inputProps={{ inputMode: 'numeric', pattern: patterns.AreaCode.input.source }}
                                    value={areaCode}
                                    onChange={handleAreaCodeChange}
                                    onBlur={onFieldBlur('AREA_CODE')}
                                />
                                <FormHelperText>{fieldErrors.AREA_CODE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item flexGrow={1}>
                            <FormControl fullWidth>
                                <FormLabel>Phone</FormLabel>
                                <OutlinedInput value={phone} onChange={handlePhoneChange} onBlur={onFieldBlur('PHONE')} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={4} alignItems='center'>
                    <Grid item container direction='row' alignItems='center' xs={4}>
                        <Grid item flexGrow={1}>
                            <FormControl fullWidth>
                                <FormLabel>Department</FormLabel>
                                <Select onChange={handleDepartmentChange} onBlur={onFieldBlur('DEPARTMENT')} value={department}>
                                    <MenuItem key='' value=''>
                                        None
                                    </MenuItem>
                                    {departments?.map((department) => (
                                        <MenuItem key={department.id} value={department.id}>
                                            {department.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <IconButton color='primary' size='large' sx={{ marginTop: '20px' }} onClick={handleAddDepartment}>
                                <AddCircle fontSize='inherit' />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' alignItems='center' xs={4}>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <FormLabel>Manager</FormLabel>
                                <Select onChange={handleManagerChange} onBlur={onFieldBlur('FIELD_TESTING')} value={manager}>
                                    <MenuItem key='' value=''>
                                        None
                                    </MenuItem>
                                    {managers?.map((manager) => (
                                        <MenuItem key={manager.id} value={manager.id}>
                                            {manager.firstName} {manager.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Switch checked={isManager} onChange={handleIsManagerToggleChange} />}
                                label='Manager'
                                labelPlacement='start'
                                sx={{ marginTop: '20px' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                            <FormControlLabel
                                control={<Switch checked={shouldForwardEmailsToManager} disabled={!manager} onChange={handleShouldForwardEmailsToManagerToggleChange} />}
                                label='Forward Emails To Manager'
                                labelPlacement='start'
                                sx={{ marginTop: '20px' }}
                            />
                        </Grid>
                </Grid>
                <Grid item container direction='row' spacing={4}>
                    <Grid item container direction='row' alignItems='center' xs={4}>
                        <Grid item xs={10}>
                            <FormControlLabel
                                control={<Switch checked={isContactOnly} onChange={handleIsContactOnlyToggleChange} />}
                                label='Point of Contact Only'
                                labelPlacement='start'
                                sx={{ marginTop: '20px' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth error={fieldErrors.FORM_TYPE !== ''} required={!isContactOnly}>
                            <FormLabel>Form Type</FormLabel>
                            <Select value={surveyTemplate} onChange={handleSurveyTemplateChange} onBlur={onFieldBlur('FORM_TYPE')}>
                                <MenuItem value=''>None</MenuItem>
                                {formTypes?.map((formType) => (
                                    <MenuItem key={formType.id} value={formType.id}>
                                        {formType.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{fieldErrors.FORM_TYPE}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Field Testing</FormLabel>
                            <Select onChange={handleFieldTestingChange} onBlur={onFieldBlur('FIELD_TESTING')} value={fieldTesting}>
                                <MenuItem value=''>None</MenuItem>
                                <MenuItem value='Embedded'>Embedded</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </>
        ),
        [
            areaCode,
            department,
            departments,
            email,
            fieldErrors,
            fieldTesting,
            firstName,
            formTypes,
            handleIsContactOnlyToggleChange,
            isContactOnly,
            isManager,
            lastName,
            manager,
            managers,
            nickname,
            onFieldBlur,
            phone,
            surveyTemplate,
            title,
            handleAddDepartment,
            handleAreaCodeChange,
            handleDepartmentChange,
            handleEmailChange,
            handleFieldTestingChange,
            handleFirstNameChange,
            handleIsManagerToggleChange,
            handleLastNameChange,
            handleManagerChange,
            handleShouldForwardEmailsToManagerToggleChange,
            shouldForwardEmailsToManager,
            handleNicknameChange,
            handlePhoneChange,
            handleSurveyTemplateChange,
            handleTitleChange,
        ]
    );

    // TODO: the rendering for this component is abnormal due to the structure of the Create and Edit RespondentView files. It would be nice to refactor this in the future.
    return (
        <>
            {company && !isDepartmentsLoading && !isManagersLoading && !isFormTypesLoading ? (
                <Grid component='form' container direction='column' autoComplete='off' spacing={3} onSubmit={handleSave}>
                    {!isModal && (
                        <Grid position='sticky' item container direction='row' alignItems='center'>
                            <Grid item container direction='column' justifyContent='start' xs={8}>
                                <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                                    <Person /> {firstName || lastName ? `${firstName} ${lastName}`.trim() : 'New Respondent'}
                                </Typography>
                                <NavBreadcrumbs
                                    links={[{ label: company.name, navLink: `/companies/${companyId}` }]}
                                    currentPageLabel={firstName || lastName ? `${firstName} ${lastName}`.trim() : 'New Respondent'}
                                />
                            </Grid>
                            <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Switch checked={isActive} onChange={handleActiveToggleChange} />}
                                        label='Active'
                                        labelPlacement='start'
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' color='primary' type='submit' onClick={handleSave}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>{isModal ? formContent : <FormSection>{formContent}</FormSection>}</Grid>
                    <AddEditDepartmentModal company={company} isVisible={addDepartmentIsVisible} setIsVisible={setAddDepartmentIsVisible} afterCreate={(dep) => setDepartment(dep.id)} />
                </Grid>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};
