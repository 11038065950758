import { Business, People, Ballot, Email } from "@mui/icons-material";

export const getActivityStreamRowIcon = (logType: string, eventType: string) => {
    const iconProps: any = {
        fontSize: 'small',
        position: 'relative'
    };

    if (eventType.includes('EMAIL')) {
        return <Email {...iconProps} color='info' />
    }

    switch (logType) {
        case 'Company':
            return <Business {...iconProps} color='primary' />
        case 'Respondent':
            return <People {...iconProps} color='secondary' />
        case 'ScheduleDetail':
            return <Ballot {...iconProps} color='success' />
        default:
            return null
    }
}

export const eventNameMapper = (eventName: string) => {
    let text = eventName;
    if (text.includes('SCHEDULE_DETAIL')) {
        text = text.replace('SCHEDULE_DETAIL', 'SURVEY_INSTANCE');
    }
    return text;
}