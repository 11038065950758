import { Box, Typography } from '@mui/material';
import Highcharts, { PointClickCallbackFunction, PointOptionsObject, SeriesPieOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GroupByDto } from '../../dtos/generated/GroupByDto';
import { toWords } from '../../util';
import { LoadingIndicator } from '../CoreLib/library';

export interface IGroupedDataPieChartProps {
    name: string;
    groupedData: GroupByDto[];
    onSliceClicked?: (sliceName: string) => void;
    colorMap?: Map<string, string>;
    isLoading?: boolean;
}

export const GroupedDataPieChart: FC<IGroupedDataPieChartProps> = ({
    name: chartName,
    groupedData,
    onSliceClicked,
    colorMap = new Map<string, string>(),
    isLoading,
}) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [graphRenderKey, setGraphRenderKey] = useState(0);

    // This is required to force the chart to re-render when data changes. Otherwise the changes will be ignored by the chart and it will not update.
    useEffect(() => {
        if (groupedData) {
            setGraphRenderKey((x) => x + 1);
        }
    }, [groupedData]);

    const chartData: PointOptionsObject[] = useMemo(() => {
        if (!groupedData) {
            return [];
        }
        return groupedData
            .filter((metric) => metric.aggregate > 0)
            .map((metric) => ({
                name: toWords(metric.name),
                y: metric.aggregate,
                color: colorMap.get(metric.name),
            }));
    }, [groupedData, colorMap]);

    const surveyByStatusSeriesOptions = useMemo((): SeriesPieOptions => {
        return {
            type: 'pie',
            name: chartName,
            data: chartData,
            showInLegend: true,
            dataLabels: {
                format: `<b>{point.name}: {y}</b>`,
                style: { fontWeight: 'normal' },
            },
            id: 'components',
        };
    }, [chartData, chartName]);

    const handleSliceClicked: PointClickCallbackFunction = useCallback(
        (event) => {
            onSliceClicked && onSliceClicked(event.point.name);
        },
        [onSliceClicked]
    );

    const surveyByStatusOptions: Highcharts.Options = useMemo(() => {
        return {
            chart: { type: 'pie' },
            title: { text: '' },
            plotOptions: { pie: { shadow: false, center: ['50%', '50%'] }, series: { point: { events: { click: handleSliceClicked } } } },
            tooltip: {
                format: '{point.name}: <b>{point.percentage:.1f}%</b>',
            },
            series: [surveyByStatusSeriesOptions],
            legend: {
                layout: 'horizontal',
                verticalAlign: 'bottom',
            },
        };
    }, [surveyByStatusSeriesOptions, handleSliceClicked]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ position: 'relative', minHeight: '400px' }}>
            {groupedData.length === 0 && (
                <Typography sx={{ position: 'absolute', top: 'calc(50% - 14px)', left: 0, zIndex: 2, width: '100%', textAlign: 'center' }} fontWeight='bold'>
                    No Data To Show
                </Typography>
            )}
            <HighchartsReact key={graphRenderKey} highcharts={Highcharts} options={surveyByStatusOptions} ref={chartComponentRef} />
        </Box>
    );
};
