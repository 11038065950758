import { Grid, Paper, Typography } from '@mui/material';
import { format } from "date-fns";
import { FC } from "react";
import { ScheduleDetailServiceFormSubmissionDto } from '../../dtos/generated/ScheduleDetailServiceFormSubmissionDto';

export interface ICustomerServiceSubmissionViewProps {
    submissionDetails: ScheduleDetailServiceFormSubmissionDto;
}

export const CustomerServiceSubmissionView: FC<ICustomerServiceSubmissionViewProps> = ({submissionDetails}) => {
    
    return (
        <Paper sx={{maxWidth:'700px', marginLeft:'auto', marginRight:'auto'}}>
            <Grid item container direction='column' padding={4} rowSpacing={1}>
                <Grid item textAlign={'center'}>
                    <Typography variant='h1'>Thank You for Contacting Us!</Typography>
                    <br/>
                </Grid>
                <Grid item>
                    <Typography>Your feedback matters! We will review your request as soon as possible.</Typography>
                </Grid>
                <Grid item>
                    <Typography>Summary:</Typography>
                        {submissionDetails.isUnintendedRecipient && <Typography>- I am not the intended recipient or should not receive this.</Typography>}
                        {submissionDetails.isAlreadyReceived && <Typography>- I already received this.</Typography>}
                        {submissionDetails.isAlreadyFilledOut && <Typography>- I already filled this out.</Typography>}
                        {submissionDetails.isOther && <Typography>- Other: {submissionDetails.otherDescription}</Typography>}
                </Grid>
                <Grid item>
                    <Typography>Submitted by {submissionDetails.name} ({submissionDetails.email}).</Typography>
                    <Typography>Time: {format(submissionDetails.submittedOn!,"MMMM d, yyyy 'at' h:mm a")}.</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}