import { useEffect } from 'react';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { ScheduleDto } from '../../dtos';
import { useSendReminderEmailForScheduleMutation } from '../../store/api/schedule-api';

export function useSendScheduleEmailReminders(schedules: ScheduleDto[], shouldSend: boolean, resetShouldSend: () => void) {
    const [sendReminder, { isSuccess: isReminderSent, isError: isReminderError, reset }] = useSendReminderEmailForScheduleMutation();

    const subject = schedules.reduce((a, s) => {
        return a + s.issued + s.started
    }, 0) > 1 ? 'reminders' : 'reminder';
    useSuccessfulActionSnackbar('sent', `email ${subject}`, isReminderSent, reset);
    useFailedActionSnackbar('send', `email ${subject}`, isReminderError, reset);

    useEffect(() => {
        if (schedules.length > 0 && shouldSend) {
            for (let i = 0; i < schedules.length; i++) {
                const schedule = schedules[i];
                sendReminder({
                    id: schedule.id,
                    cancelNext: true,
                });
            }
            resetShouldSend();
        }
    }, [resetShouldSend, schedules, sendReminder, shouldSend]);
}
