import { FC, useContext } from 'react';
import { CompanyDashboardContext } from '../../../../Utils';
import { useGetScheduleDetailsByStatusQuery } from '../../../../../../store';
import { GroupedDataPieChart } from '../../../../../../Components/Charts';
import { DataFilterContext } from '../../../../../../Contexts';

const COLOR_PALLET = new Map([
    ['Created', '#97CBD2'],
    ['Issued', '#BFBFBF'],
    ['Started', '#57585A'],
    ['Paused', '#DF623B'],
    ['Completed', '#50B432'],
]);

export const CompanyDashboardOverviewTabSidebarContent: FC = () => {
    const { company, overviewFilters } = useContext(CompanyDashboardContext);
    const { formTypeFilter, setStatusFilter, statusFilter, scheduledFromDateFilter: fromDateFilter, scheduledToDateFilter: toDateFilter, periodStartDateFilter, periodEndDateFilter } = useContext(DataFilterContext);
    const { data: respondentStatusByFormType, isLoading } = useGetScheduleDetailsByStatusQuery({
        companyId: company.id,
        searchParams: {
            daysBack: overviewFilters.daysBack,
            formType: formTypeFilter ?? '',
            status: statusFilter,
            fromDate: fromDateFilter ?? undefined,
            toDate: toDateFilter ?? undefined,
            periodStartDate: periodStartDateFilter ?? undefined,
            periodEndDate: periodEndDateFilter ?? undefined
        }
    });
    
    return <GroupedDataPieChart name='Survey Status Breakdown' groupedData={respondentStatusByFormType ?? []} onSliceClicked={setStatusFilter} colorMap={COLOR_PALLET} isLoading={isLoading} />;
};