import { FilterList } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { StandardDialogHeader } from '../../CommonComponents';
import { StandardDatePicker } from '../../CommonInputs/StandardDatePicker';
import { StandardSelect } from '../../CommonInputs';
import { useWorkItemFilterDialogManager } from './useWorkItemFilterDialogManager';

export interface IWorkItemFilterModalProps {
    open: boolean;
    onClose: () => void;
};

export const WorkItemFilterModal: FC<IWorkItemFilterModalProps> = ({ open, onClose }) => {
    const {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty
    } = useWorkItemFilterDialogManager();

    const handleApplyFilters = useCallback(() => {
        applyDialogFilters();
        onClose();
    }, [applyDialogFilters, onClose]);

    const handleClose = useCallback(() => {
        loadAppliedFiltersIntoDialog();
        onClose();
    }, [loadAppliedFiltersIntoDialog, onClose])

    useEffect(() => {
        if (open) {
            loadAppliedFiltersIntoDialog();
        }
    }, [loadAppliedFiltersIntoDialog, open]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
            <StandardDialogHeader icon={<FilterList />} text='Filter Project Components' />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={4}>
                        <StandardSelect
                            label='Type'
                            selectedValue={dialogFilters.itemType?.toString() ?? ''}
                            handleSelectedValueChange={(as) => handleDialogFilterValueChange('itemType', as)}
                            options={['', 'Activity', 'Business Component']}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StandardDatePicker
                            label='Started After'
                            value={dialogFilters.periodStartDate ?? null}
                            handleDateChange={(nd) => handleDialogFilterValueChange('periodStartDate', nd)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StandardDatePicker
                            label='Ended Before'
                            value={dialogFilters.periodEndDate ?? null}
                            handleDateChange={(nd) => handleDialogFilterValueChange('periodEndDate', nd)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleApplyFilters} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};