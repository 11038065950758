import { createContext } from 'react';
import { StudyDto } from '../../../dtos';
import { emptyGuid } from '../../../models';

export interface IStudyDashboardContext {
    study: StudyDto;
    selectedEmployeeId: string;
    setSelectedEmployeeId: (empId: string) => void;
    selectedComponentId: string;
    setSelectedComponentId: (componentId: string) => void;
    searchText: string;
    setSearchText: (searchText: string) => void;
    selectedTab: number;
    setSelectedTab: (tabIndex: number) => void;
}

const PLACEHOLDER_STUDY: StudyDto = {
    id: emptyGuid,
    name: '',
    companyId: '',
    companyName: '',
    startDate: new Date(),
    isOngoing: true,
    workItemCount: 0,
    respondentCount: 0,
    isActive: true,
    createdOn: new Date(),
    status: '',
    businessComponentCount: 0,
    activityCount: 0
};

const PLACEHOLDER_METHOD = () => {};

export const StudyDashboardContext = createContext<IStudyDashboardContext>({
    study: PLACEHOLDER_STUDY,
    selectedEmployeeId: '',
    setSelectedEmployeeId: PLACEHOLDER_METHOD,
    selectedComponentId: '',
    setSelectedComponentId: PLACEHOLDER_METHOD,
    searchText: '',
    setSearchText: PLACEHOLDER_METHOD,
    selectedTab: 0,
    setSelectedTab: PLACEHOLDER_METHOD,
});
