import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingIndicator, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { SurveyForm } from '../../Components/Forms';
import { SurveyTemplate } from '../../models/survey';
import { useGetScheduleDetailQuery, useGetScheduleRespondentAnswersQuery } from '../../store/api/schedule-api';

export const ExportSurveyView: FC = () => {
    const { scheduleId, detailId, fieldGroupIndex, timePeriodIndex } = useParams();
    const {
        data: scheduleDetail,
        isLoading: scheduleDetailIsLoading,
        isError: isScheduleDetailError,
    } = useGetScheduleDetailQuery({
        parentId: scheduleId!,
        childId: detailId!,
    });
    const { data: scheduleDetailAnswers, isError: isGetAnswersError } = useGetScheduleRespondentAnswersQuery({
        parentId: scheduleId!,
        childId: detailId!,
    });
    const [surveyTemplateData, setSurveyTemplateData] = useState<SurveyTemplate | undefined>(undefined);

    useFailedActionSnackbar('retrieve', 'survey instance', isScheduleDetailError);
    useFailedActionSnackbar('retrieve', 'respondent answers', isGetAnswersError);

    useEffect(() => {
        if (!scheduleDetailIsLoading && !surveyTemplateData && scheduleDetail && scheduleDetail.respondentSurveyTemplate) {
            setSurveyTemplateData(JSON.parse(scheduleDetail.respondentSurveyTemplate));
        }
    }, [surveyTemplateData, setSurveyTemplateData, scheduleDetail, scheduleDetailIsLoading]);

    if (scheduleDetailIsLoading || !surveyTemplateData) {
        return <LoadingIndicator />;
    }

    if (scheduleDetail) {
        return (
            <Grid direction='row' xs={12} container>
                <Grid item xs={12} className='print-survey-form-container'>
                    <SurveyForm
                        scheduleDetail={scheduleDetail}
                        surveyTemplateData={surveyTemplateData}
                        answers={scheduleDetailAnswers}
                        isPdfView={true}
                        fieldGroupIndex={fieldGroupIndex ? parseInt(fieldGroupIndex) : undefined}
                        timePeriodIndex={timePeriodIndex ? parseInt(timePeriodIndex) : undefined}></SurveyForm>
                </Grid>
            </Grid>
        );
    } else {
        return <p>No schedule detail found</p>;
    }
};
