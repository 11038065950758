import { FilterList } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, OutlinedInput, Switch } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { StandardDialogHeader } from '../../CommonComponents';
import { DepartmentSelect, FormTypeSelect, ManagerSelect } from '../../CommonInputs';
import { useRespondentFiltersDialogManager } from './useRespondentFiltersDialogManager';

export interface IRespondentFilterDialogProps {
    companyId: string;
    open: boolean;
    onClose: () => void;
}

export const RespondentFilterDialog: FC<IRespondentFilterDialogProps> = ({ companyId, open, onClose  }) => {
    const {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty,
        setNoDepartmentFilter
    } = useRespondentFiltersDialogManager()

    const handleApplyFilters = useCallback(() => {
        applyDialogFilters();
        onClose();
    }, [applyDialogFilters, onClose]);

    const handleClose = useCallback(() => {
        loadAppliedFiltersIntoDialog();
        onClose();
    }, [loadAppliedFiltersIntoDialog, onClose])

    useEffect(() => {
        if (open) {
            loadAppliedFiltersIntoDialog();
        }
    }, [loadAppliedFiltersIntoDialog, open]);

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '100%' } }} maxWidth='lg'>
            <StandardDialogHeader icon={<FilterList />} text='Filter Respondents' />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>First Name</FormLabel>
                            <OutlinedInput
                                value={dialogFilters.firstName}
                                onChange={(e) => handleDialogFilterValueChange('firstName', e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Last Name</FormLabel>
                            <OutlinedInput
                                value={dialogFilters.lastName}
                                onChange={(e) => handleDialogFilterValueChange('lastName', e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Title</FormLabel>
                            <OutlinedInput
                                value={dialogFilters.title}
                                onChange={(e) => handleDialogFilterValueChange('title', e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <DepartmentSelect
                            companyId={companyId}
                            selectedDepartmentId={dialogFilters.departmentId}
                            handleSelectedDepartmentChange={(dep) => handleDialogFilterValueChange('departmentId', dep?.id ?? '')}
                        />
                    </Grid>
                    <Grid item xs={4} mt={2}>
                        <FormControlLabel
                            control={<Switch checked={dialogFilters.isNotAssignedToDepartment} onChange={(_, isChecked) => setNoDepartmentFilter(isChecked)} />}
                            label='Not assigned to a department'
                            labelPlacement='end'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ManagerSelect
                            companyId={companyId}
                            selectedManagerId={dialogFilters.managerId}
                            handleSelectedManagerChange={(mngr) => handleDialogFilterValueChange('managerId', mngr?.id ?? '')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormTypeSelect
                            selectedFormTypeId={dialogFilters.formTypeId}
                            handleSelectedFormTypeChange={(ft) => handleDialogFilterValueChange('formTypeId', ft?.id ?? '')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClose}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleApplyFilters} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
