import { useContext, useEffect } from "react";
import { useArchiveScheduleMutation, useUnarchiveScheduleMutation, usePauseAllScheduleDetailsMutation, useUnpauseAllScheduleDetailsMutation, useReopenScheduleMutation, useApproveSchedulesMutation, useUnapproveSchedulesMutation, useUpdateScheduleMutation } from "../../../../store/api/schedule-api";
import { useCreateAndUploadSurveyPdfMutation, useGetSurveyCSVMutation } from "../../../../store/api/survey-pdf-api";
import { useSuccessfulActionSnackbar, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from "../../../CoreLib/library";
import { downloadBase64File } from "../../../../util";
import { ScheduleTableContext } from "../ScheduleTableContext";

export function useScheduleTableActions() {
    const [archiveSchedule, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchiveSchedule }] = useArchiveScheduleMutation();
    const [unarchiveSchedule, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchiveSchedule }] = useUnarchiveScheduleMutation();
    const [pauseSchedule, { isError: isPauseError, isSuccess: pauseSuccess, reset: resetPauseSchedule }] = usePauseAllScheduleDetailsMutation();
    const [unpauseSchedule, { isError: isUnpauseError, isSuccess: unpauseSuccess, reset: resetUnpauseSchedule }] = useUnpauseAllScheduleDetailsMutation();
    const [createAndUploadPdf, { isError: isCreatePdfError, error: createPdfError, reset: resetCreate, isSuccess: isCreatePDFSuccess }] = useCreateAndUploadSurveyPdfMutation();
    const [getCSV, { isError: isGetCSVError, reset: resetGetCSV, data: csvData, isSuccess: isCreateCSVSuccess }] = useGetSurveyCSVMutation();
    const [reopenSchedule, { isError: isReopenError, isSuccess: reopenSuccess, reset: resetReopen }] = useReopenScheduleMutation();
    const [approveSchedules, { isError: isApproveScheduleError, reset: resetApproveSchedules }] = useApproveSchedulesMutation();
    const [unapproveSchedules, { isError: isUnapproveScheduleError, reset: resetUnapproveSchedules }] = useUnapproveSchedulesMutation();
    const [updateSchedule, { isError: isUpdateScheduleError, isSuccess: isUpdateScheduleSuccess, reset: resetUpdateSchedule }] = useUpdateScheduleMutation();
    const { surveyAliasShort } = useContext(ScheduleTableContext);

    useSuccessfulActionSnackbar('inactivate', `${surveyAliasShort}(s)`, isArchiveSuccess);
    useFailedActionSnackbar('inactivate', `${surveyAliasShort}(s)`, isArchiveError, resetArchiveSchedule);
    useSuccessfulActionSnackbar('activate', `${surveyAliasShort}(s)`, isUnarchiveSuccess);
    useFailedActionSnackbar('activate', `${surveyAliasShort}(s)`, isUnarchiveError, resetUnarchiveSchedule);
    useSuccessfulActionSnackbar('pause', `${surveyAliasShort}(s)`, pauseSuccess);
    useFailedActionSnackbar('pause', `${surveyAliasShort}(s)`, isPauseError, resetPauseSchedule);
    useSuccessfulActionSnackbar('restarted', `${surveyAliasShort}(s)`, unpauseSuccess);
    useFailedActionSnackbar('restart', `${surveyAliasShort}(s)`, isUnpauseError, resetUnpauseSchedule);
    useSuccessfulActionSnackbar('generated', 'PDF', isCreatePDFSuccess);
    useFailedActionSnackbar('generate', 'PDF', isCreatePdfError, resetCreate, createPdfError);
    useSuccessfulActionSnackbar('generated', 'CSV export', isCreateCSVSuccess);
    useFailedActionSnackbar('generate', 'CSV export', isGetCSVError, resetGetCSV);
    useSuccessfulActionSnackbar('re-open', `${surveyAliasShort}(s)`, reopenSuccess);
    useFailedActionSnackbar('re-open', `${surveyAliasShort}(s)`, isReopenError, resetReopen);
    useFailedActionSnackbar('approve', `${surveyAliasShort}(s)`, isApproveScheduleError, resetApproveSchedules);
    useFailedActionSnackbar('un-approve', `${surveyAliasShort}(s)`, isUnapproveScheduleError, resetUnapproveSchedules);
    useSuccessfulUpdateSnackbar('survey', isUpdateScheduleSuccess, resetUpdateSchedule);
    useFailedUpdateSnackbar('survey', isUpdateScheduleError, resetUpdateSchedule);

    useEffect(() => {
        if (csvData) {
            downloadBase64File(csvData.contents, `${surveyAliasShort}s_` + new Date().toLocaleDateString() + '.csv', 'text/csv');
        }
    }, [csvData, surveyAliasShort]);

    return {
        archiveSchedule,
        unarchiveSchedule,
        pauseSchedule,
        unpauseSchedule,
        createAndUploadPdf,
        getCSV,
        reopenSchedule,
        approveSchedules,
        unapproveSchedules,
        updateSchedule
    }
}