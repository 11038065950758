import { Grid, TextField } from '@mui/material';
import { Marked } from '@ts-stack/markdown';
import { FC, useCallback, useEffect, useState } from 'react';
import theme from '../../../../../Theme';
import { ScheduleDetailDto, ScheduleRespondentAnswerDto } from '../../../../../dtos';
import { Question, Section, SectionHeading, SurveyTemplate } from '../../../../../models/survey';
import { SectionFieldNameValue, SurveyFormErrorMessage, SurveyFormPercentageTotal, SurveyHeaderDetails } from '../../../types';
import { DesktopSectionTitleHeadings } from '../DesktopSectionTitleHeadings';
import { MobileSectionTitleHeadings } from '../MobileSectionTitleHeadings';
import { ResponsiveSectionTitle } from '../ResponsiveSectionTitle';
import { CenteredQuestionInputFields, NonCenteredQuestionInputFields, QuestionTotal, SectionTotalRow } from './Components';

export interface IStandardFormTypeRendererProps {
    isPreview: boolean;
    respondentAnswers: ScheduleRespondentAnswerDto[];
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
    // The only place this is not passed in is in the survey preview modal. We may need to make a placeholder value to support the BCM preview.
    scheduleDetail?: ScheduleDetailDto;
    completedBy: string;
    surveyTemplateData: SurveyTemplate;
    errorMessages: SurveyFormErrorMessage[];
    setErrorMessages: (updatedErrors: SurveyFormErrorMessage[]) => void;
    isReadOnly: boolean;
    isSavingAnswers: boolean;
    headerData: SurveyHeaderDetails;
    // these three could be pulled from the url rather than passed through the form
    isPdfView: boolean;
    fieldGroupIndex?: number;
    timePeriodIndex?: number;
}

// TODO: split this up into smaller components that are more readable, maintainable, and overall cleaner.
export const StandardFormTypeRenderer: FC<IStandardFormTypeRendererProps> = ({
    isPreview,
    respondentAnswers,
    setQuestionAnswer,
    scheduleDetail,
    completedBy,
    surveyTemplateData,
    errorMessages,
    setErrorMessages,
    isReadOnly,
    isSavingAnswers,
    headerData,
    isPdfView,
    fieldGroupIndex,
    timePeriodIndex,
}) => {
    const [sectionTotals, setSectionTotals] = useState<SurveyFormPercentageTotal[]>([]);
    const [uniqueEmployeeIds, setUniqueEmployeeIds] = useState<string[]>([]);
    const [sectionFieldNameValues, setSectionFieldNameValues] = useState<SectionFieldNameValue[]>([]);
    const [isSectionFieldNamesLoaded, setIsSectionFieldNamesLoaded] = useState(false);

    const getOtherDescriptionFieldName = (fieldName: string, period: string) => {
        let fieldNameParts = fieldName.split('|');
        fieldNameParts[0] += '_DESCRIPTION';
        fieldNameParts[1] = period;
        return fieldNameParts.join('|');
    };

    const getFieldValue = (fieldName: string): number => {
        let fieldAnswerValue = Number(respondentAnswers.find((answer) => answer.fieldKey === fieldName)?.answerValue);
        if (!isNaN(fieldAnswerValue)) {
            return fieldAnswerValue;
        }
        return 0;
    };

    const getFieldValueCallback = useCallback(getFieldValue, [respondentAnswers]);

    const validateField = (fieldName: string): string => {
        let errorMessage = '';
        let fieldAnswerValue = Number(respondentAnswers.find((answer) => answer.fieldKey === fieldName)?.answerValue);
        if (fieldAnswerValue > 100 && !isNaN(fieldAnswerValue)) {
            errorMessage = 'Value cannot be greater than 100.';
        } else if (fieldAnswerValue < 0 && !isNaN(fieldAnswerValue)) {
            errorMessage = 'Value cannot be less than 0.';
        } else if (fieldName.includes('_OTHER') && !isNaN(fieldAnswerValue)) {
            const descriptionFieldName = getOtherDescriptionFieldName(fieldName, scheduleDetail?.schedule.periods[0].surveyLabel!);
            const descriptionAnswerValue = respondentAnswers.find((answer) => answer.fieldKey === descriptionFieldName)?.answerValue;
            if (fieldAnswerValue > 0 && (descriptionAnswerValue === '' || descriptionAnswerValue === undefined)) {
                errorMessage = 'Description of activity required when the percentage of time associated with it is greater than 0%.';
            }
        }
        return errorMessage;
    };

    const validateFieldCallback = useCallback(validateField, [respondentAnswers, scheduleDetail?.schedule.periods]);

    const validateSectionTotal = (sectionName: string, localSectionTotal: number): string => {
        let errorMessage = '';
        if (sectionName === 'total' && localSectionTotal !== 100) {
            errorMessage = 'Total must be exactly 100%.';
        } else if (localSectionTotal > 100) {
            errorMessage = 'Subtotals can not be greater than 100%.';
        } else if (localSectionTotal < 0) {
            errorMessage = 'Subtotals can not be less than 100%.';
        }
        return errorMessage;
    };

    const validateForm = (): SurveyFormErrorMessage[] => {
        const validationErrors: SurveyFormErrorMessage[] = [];
        if (completedBy === '') {
            validationErrors.push({
                identifier: 'completedBy',
                message: 'Completed by is required.',
            });
        }

        sectionFieldNameValues.forEach((sectionFields) => {
            if (surveyTemplateData.groupByRespondents) {
                uniqueEmployeeIds.forEach((employeeId) => {
                    let employeeIdFieldNames = sectionFields.fieldNames.filter((sectionFieldName) => sectionFieldName.split('|')[2] === employeeId);
                    employeeIdFieldNames.forEach((field) => {
                        let errorMessage = validateFieldCallback(field);
                        if (errorMessage !== '') {
                            validationErrors.push({
                                identifier: field,
                                message: errorMessage,
                            });
                        }
                    });
                });
            } else {
                scheduleDetail?.schedule.periods.forEach((period) => {
                    let periodFieldNames = sectionFields.fieldNames.filter((sectionFieldName) => sectionFieldName.split('|')[1] === period.surveyLabel);
                    periodFieldNames.forEach((field) => {
                        let errorMessage = validateFieldCallback(field);
                        if (errorMessage !== '') {
                            validationErrors.push({
                                identifier: field,
                                message: errorMessage,
                            });
                        }
                    });
                });
            }
        });

        sectionTotals.forEach((sectionTotal) => {
            let errorMessage = validateSectionTotal(sectionTotal.sectionName, sectionTotal.sectionTotal);
            if (errorMessage !== '') {
                validationErrors.push({
                    identifier: `${sectionTotal.sectionName} ${sectionTotal.identifier}`,
                    message: errorMessage,
                });
            }
        });

        return validationErrors;
    };

    const validateFormCallback = useCallback(validateForm, [
        sectionFieldNameValues,
        scheduleDetail,
        sectionTotals,
        uniqueEmployeeIds,
        completedBy,
        surveyTemplateData.groupByRespondents,
        validateFieldCallback,
    ]);

    useEffect(() => {
        const validationErrors = validateFormCallback();
        setErrorMessages(validationErrors);
    }, [respondentAnswers, validateFormCallback, setErrorMessages]);

    useEffect(() => {
        let localSectionTotals: SurveyFormPercentageTotal[] = [];
        let employeeIds: string[] = [];

        if (sectionFieldNameValues && sectionFieldNameValues[0]) {
            employeeIds = sectionFieldNameValues[0].fieldNames.map((fieldName) => {
                return fieldName.split('|')[2];
            });
        }
        let localUniqueEmployeeIds = Array.from(new Set(employeeIds));
        setUniqueEmployeeIds(localUniqueEmployeeIds);
        sectionFieldNameValues
            .filter((section) => section.fieldNames.length > 0)
            .forEach((sectionFields) => {
                if (surveyTemplateData.groupByRespondents) {
                    localUniqueEmployeeIds.forEach((employeeId) => {
                        let employeeIdFieldNames = sectionFields.fieldNames.filter((sectionFieldName) => sectionFieldName.split('|')[2] === employeeId);
                        let localSectionTotal: number = 0;
                        employeeIdFieldNames.forEach((field) => {
                            localSectionTotal = localSectionTotal + getFieldValueCallback(field);
                        });
                        localSectionTotals.push({ sectionName: sectionFields.sectionName, sectionTotal: localSectionTotal, identifier: employeeId });
                    });
                } else {
                    scheduleDetail?.schedule.periods.forEach((period) => {
                        let periodFieldNames = sectionFields.fieldNames.filter((sectionFieldName) => sectionFieldName.split('|')[1] === period.surveyLabel);
                        let localSectionTotal: number = 0;
                        periodFieldNames.forEach((field) => {
                            localSectionTotal = localSectionTotal + getFieldValueCallback(field);
                        });
                        localSectionTotals.push({ sectionName: sectionFields.sectionName, sectionTotal: localSectionTotal, identifier: period.surveyLabel });
                    });
                }
            });
        setSectionTotals(localSectionTotals);
    }, [
        respondentAnswers,
        sectionFieldNameValues,
        scheduleDetail?.schedule.periods,
        surveyTemplateData.groupByRespondents,
        setUniqueEmployeeIds,
        completedBy,
        getFieldValueCallback,
    ]);

    useEffect(() => {
        const generateSectionFieldList = (section: Section): string[] => {
            let allSectionFieldNames: string[] = [];
            allSectionFieldNames = section.questions.reduce<string[]>((sectionFields, question) => {
                let allQuestionFieldNames: string[] = [];
                allQuestionFieldNames = question.fieldGroups.reduce<string[]>((questionFields, fieldGroup) => {
                    const allFieldGroupFieldNames = fieldGroup.fields.filter((field) => field.fieldType === 'percentage').map((field) => field.fieldName);

                    return questionFields.concat(allFieldGroupFieldNames);
                }, allQuestionFieldNames);
                return sectionFields.concat(allQuestionFieldNames);
            }, allSectionFieldNames);
            section.sections?.forEach((section) => {
                let subsectionFieldNames = generateSectionFieldList(section);
                allSectionFieldNames = allSectionFieldNames.concat(subsectionFieldNames);
            });
            return allSectionFieldNames;
        };

        const getRecursiveSectionFieldListName = (section: Section): SectionFieldNameValue[] => {
            let RecursiveSectionFieldList: SectionFieldNameValue[] = [];
            const sectionFieldList = generateSectionFieldList(section);
            RecursiveSectionFieldList.push({
                sectionName: section.title,
                fieldNames: Array.from(new Set(sectionFieldList)),
            });
            section.sections?.forEach((subSection) => {
                const RecursiveSectionFieldSubsectionLis = getRecursiveSectionFieldListName(subSection);
                RecursiveSectionFieldList = RecursiveSectionFieldList.concat(RecursiveSectionFieldSubsectionLis);
            });
            return RecursiveSectionFieldList;
        };

        if (!isSectionFieldNamesLoaded) {
            let localSectionFieldNameValues: SectionFieldNameValue[] = [];
            let totalFieldList: string[] = [];
            totalFieldList = surveyTemplateData.sections.reduce<string[]>((totalFieldNames, section) => {
                let totalSections: SectionFieldNameValue[] = [];
                totalSections = getRecursiveSectionFieldListName(section);
                let totalSectionsFieldNames: string[] = [];
                totalSectionsFieldNames = totalSections.reduce<string[]>((totalSectionsFieldNames, totalSection) => {
                    return totalSectionsFieldNames.concat(totalSection.fieldNames);
                }, totalSectionsFieldNames);
                return totalFieldNames.concat(totalSectionsFieldNames);
            }, totalFieldList);
            localSectionFieldNameValues.push({
                sectionName: 'total',
                fieldNames: Array.from(new Set(totalFieldList)),
            });
            surveyTemplateData.sections.forEach((section) => {
                const sectionFieldNameValues = getRecursiveSectionFieldListName(section);
                localSectionFieldNameValues = localSectionFieldNameValues.concat(sectionFieldNameValues);
            });
            if (sectionFieldNameValues !== localSectionFieldNameValues) {
                setSectionFieldNameValues(localSectionFieldNameValues);
                setIsSectionFieldNamesLoaded(true);
            } else {
                setIsSectionFieldNamesLoaded(false);
            }
        }
    }, [surveyTemplateData, isSectionFieldNamesLoaded, sectionFieldNameValues]);

    const getQuestionAnswer = (fieldName: string) => {
        if (isPreview) {
            return '';
        }
        const respondentAnswer = respondentAnswers.find((ra) => ra.fieldKey === fieldName);
        if (!respondentAnswer) {
            return '';
        }
        return respondentAnswer.answerValue;
    };

    

    const getSectionQuestionLabels = useCallback((section: Section): string[] => {
        const questionLabels = section.questions.at(0)?.fieldGroups.map((fg) => fg.fieldGroupLabel);
        if (!questionLabels && section.sections && section.sections.length > 0) {
            for (const subsection of section.sections) {
                return getSectionQuestionLabels(subsection);
            }
        }
        return questionLabels ?? [];
    }, []);

    const getTotalsForSection = useCallback((section: Section) : QuestionTotal[] => {
        var totals = sectionTotals.filter(st => st.sectionName === section.title);
        if (surveyTemplateData.groupByRespondents) {
            var questionLabels = getSectionQuestionLabels(section);
            var employeeIds = uniqueEmployeeIds;
            if (fieldGroupIndex) {
                employeeIds = [employeeIds[fieldGroupIndex]];
            }
            return employeeIds.map((empId, idx) => ({ label: fieldGroupIndex ? questionLabels[fieldGroupIndex] : questionLabels[idx], identifier: empId, total: totals.find(st => st.identifier === empId)?.sectionTotal ?? 0 }))
        } else {
            var periods = scheduleDetail?.schedule.periods ?? [];
            if (timePeriodIndex) {
                periods = [periods[timePeriodIndex]];
            }
            return periods.map(p => ({ label: p.surveyLabel, identifier: p.surveyLabel, total: totals.find(st => st.identifier === p.surveyLabel)?.sectionTotal ?? 0 }))
        }
    }, [surveyTemplateData.groupByRespondents, fieldGroupIndex, timePeriodIndex, scheduleDetail?.schedule.periods, sectionTotals, getSectionQuestionLabels, uniqueEmployeeIds]);

    const renderSection = (section: Section, depthLevel: number, parentSectionHeadings?: SectionHeading[]) => {
        return (
            <Grid key={`${section.title}-${depthLevel}`} item container direction='column'>
                <Grid item container>
                    <ResponsiveSectionTitle section={section} depthLevel={depthLevel} />
                </Grid>
                <DesktopSectionTitleHeadings sectionHeadings={section.headings ?? []} />
                {section && section.sections && section.sections.length > 0 && (
                    <Grid item container direction='column'>
                        {section.sections.map((subsection) => renderSection(subsection, depthLevel + 1, section.headings))}
                    </Grid>
                )}
                {section.questions?.length > 0 && (
                    <Grid item container direction='column' sx={{ marginLeft: '24px', marginBottom: '16px', width: 'calc(100% - 24px)' }}>
                        {section.questions.map((question, i) => renderQuestion(question, i === 0, parentSectionHeadings ?? []))}
                    </Grid>
                )}
                {section.calculateSubtotal && (
                    <SectionTotalRow
                        label={section.subtotalLabel}
                        alignment={section.subtotalAlign}
                        isLightBackgroundOnSmallDisplay={depthLevel > 0}
                        shouldValidateTotalIs100={section?.title === 'Activity'}
                        totals={getTotalsForSection(section)}
                    />
                    )}
            </Grid>
        );
    };

    const renderQuestion = (question: Question, isFirstQuestionOfSection: boolean, sectionHeading: SectionHeading[]) => {
        return (
            <Grid key={`${question.text}`} item container direction='column' pt={1}>
                <Grid item container direction='row' alignItems='start' justifyContent='space-between'>
                    <Grid xs={12} sm={6} md={8} item>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: Marked.parse(question.unordered ? `${question.text}` : `${question.order}\\. ${question.text}`),
                            }}
                        />
                        {question.text === 'Other' && (
                            <TextField
                                inputProps={{ className: 'standard-multiline-outlined-input' }}
                                onChange={(event) =>
                                    setQuestionAnswer(
                                        getOtherDescriptionFieldName(
                                            question.fieldGroups[0]?.fields[0]?.fieldName ?? '',
                                            scheduleDetail?.schedule.periods[0]?.surveyLabel ?? ''
                                        ),
                                        event.target.value
                                    )
                                }
                                defaultValue={getQuestionAnswer(
                                    getOtherDescriptionFieldName(
                                        question.fieldGroups[0]?.fields[0]?.fieldName ?? '',
                                        scheduleDetail?.schedule.periods[0]?.surveyLabel ?? ''
                                    )
                                )}
                                fullWidth
                                sx={{ paddingRight: 8, [theme.breakpoints.down('sm')]: { paddingRight: 3 } }}
                                disabled={isReadOnly || isSavingAnswers}
                                multiline
                            />
                        )}
                    </Grid>
                    <MobileSectionTitleHeadings sectionHeadings={sectionHeading} />
                    <Grid xs={12} sm={6} md={4} item container direction='column' overflow='auto'>
                        <CenteredQuestionInputFields
                            question={question}
                            isFirstQuestionOfSection={isFirstQuestionOfSection}
                            fieldGroupIndex={fieldGroupIndex}
                            timePeriodIndex={timePeriodIndex}
                            getQuestionAnswer={getQuestionAnswer}
                            setQuestionAnswer={setQuestionAnswer}
                            errorMessages={errorMessages}
                            setErrorMessages={setErrorMessages}
                            isPdfView={isPdfView}
                            isLoading={isReadOnly || isSavingAnswers}
                            recipientName={headerData.employee}
                        />
                    </Grid>
                </Grid>
                <NonCenteredQuestionInputFields
                    question={question}
                    fieldGroupIndex={fieldGroupIndex}
                    timePeriodIndex={timePeriodIndex}
                    getQuestionAnswer={getQuestionAnswer}
                    setQuestionAnswer={setQuestionAnswer}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                    isPdfView={isPdfView}
                    isLoading={isReadOnly || isSavingAnswers}
                />
            </Grid>
        );
    };

    return <>{surveyTemplateData.sections.map((section) => renderSection(section, 0))}</>;
};
