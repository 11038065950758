import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    FormValidationMethod,
    IFormFieldValidationConfig,
    isNotBlank,
    isShorterThanMaxLength,
    runFieldValidation,
    runFormValidation,
    useFailedCreateSnackbar,
    useFailedUpdateSnackbar,
    useSuccessfulCreateSnackbar,
    useSuccessfulUpdateSnackbar,
} from '../../../Components/CoreLib/library';
import { CompanyDto, WorkItemDto, WorkItemType, WorkItemDescriptionDisplayMode } from '../../../dtos';
import { emptyGuid } from '../../../models';
import { useCreateWorkItemMutation, useUpdateWorkItemMutation } from '../../../store';

export const DEFAULT_WORK_ITEM: WorkItemDto = {
    id: emptyGuid,
    name: '',
    description: '',
    descriptionDisplayMode: WorkItemDescriptionDisplayMode.Show,
    companyId: '',
    type: '' as unknown as WorkItemType,
    startDate: new Date('1900-01-01'),
    isOngoing: true,
    employeeCount: 0,
    associatedEmployeeIds: [],
    isActive: true,
    createdOn: new Date(),
};

export function useWorkItemForm(
    onSaveComplete: () => void,
    initValue: WorkItemDto = DEFAULT_WORK_ITEM,
    fixedCompanyId?: string,
    maxStartDate: Date | null = null,
    minEndDate: Date | null = null,
    fixedType?: WorkItemType,
    afterSave: (savedRecord: WorkItemDto) => void = () => {}
) {
    // Mutations
    const [
        createWorkItem,
        { isLoading: isCreatingWorkItem, isSuccess: isCreateWorkItemSuccessful, isError: isCreateWorkItemError, reset: resetCreateWorkItem },
    ] = useCreateWorkItemMutation();
    useSuccessfulCreateSnackbar('study', isCreateWorkItemSuccessful, () => {
        resetCreateWorkItem();
        onSaveComplete();
        reset();
    });
    useFailedCreateSnackbar('study', isCreateWorkItemError, resetCreateWorkItem);
    const [
        updateWorkItem,
        {
            isLoading: isUpdatingWorkItem,
            isSuccess: isUpdateWorkItemSuccessful,
            isError: isUpdateWorkItemError,
            reset: resetUpdateWorkItem,
            error: updateWorkItemError,
        },
    ] = useUpdateWorkItemMutation();
    useSuccessfulUpdateSnackbar('study', isUpdateWorkItemSuccessful, () => {
        resetUpdateWorkItem();
        onSaveComplete();
        reset();
    });
    useFailedUpdateSnackbar('study', isUpdateWorkItemError, resetUpdateWorkItem, updateWorkItemError);
    const isSaving = useMemo(() => isCreatingWorkItem || isUpdatingWorkItem, [isCreatingWorkItem, isUpdatingWorkItem]);

    // Form Values
    const [type, setType] = useState<WorkItemType | null>(fixedType ?? null);
    const isActivity = useMemo(() => type === WorkItemType.Activity, [type]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionDisplayMode, setDescriptionDisplayMode] = useState(WorkItemDescriptionDisplayMode.Show);
    const [companyId, setCompanyId] = useState(fixedCompanyId ?? '');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isOngoing, setIsOngoing] = useState(false);
    const [associatedEmployeeIds, setAssociatedEmployeeIds] = useState<string[]>([]);

    const loadFromInitValue = useCallback(
        (workItem: WorkItemDto) => {
            setType(fixedType ?? workItem.type ?? null);
            setName(workItem.name ?? '');
            setDescription(workItem.description ?? '');
            setDescriptionDisplayMode(workItem.descriptionDisplayMode ?? WorkItemDescriptionDisplayMode.Show);
            setCompanyId(fixedCompanyId ?? workItem.companyId ?? '');
            setStartDate(workItem.startDate === DEFAULT_WORK_ITEM.startDate ? null : new Date(workItem.startDate + 'Z'));
            setEndDate(workItem?.endDate ? new Date(workItem.endDate + 'Z') : null);
            setIsOngoing(workItem.isOngoing);
            setAssociatedEmployeeIds(workItem.associatedEmployeeIds);
        },
        [fixedCompanyId, fixedType]
    );

    useEffect(() => {
        loadFromInitValue(initValue);
    }, [loadFromInitValue, initValue]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof WorkItemDto, string>>(
        new Map([
            ['type', ''],
            ['name', ''],
            ['description', ''],
            ['descriptionDisplayMode', ''],
            ['companyId', ''],
            ['startDate', ''],
            ['endDate', ''],
            ['associatedEmployeeIds', ''],
        ])
    );

    // TODO: move these into the core library so they don't need to be duplicated and can be used in other projects
    const isBefore = useCallback(
        (beforeDate: Date | null, beforeDateLabel: string): FormValidationMethod =>
            (dateToCheck: Date) => {
                const errorMessageBuilder = (fieldName: string) => `${fieldName} must be before ${beforeDateLabel}`;
                let isValid = true;
                if (beforeDate && dateToCheck) {
                    isValid = dateToCheck.getTime() < beforeDate.getTime();
                }
                return { errorMessageBuilder, isValid };
            },
        []
    );

    const isAfter = useCallback(
        (afterDate: Date | null, afterDateLabel: string): FormValidationMethod =>
            (dateToCheck: Date) => {
                const errorMessageBuilder = (fieldName: string) => `${fieldName} must be after ${afterDateLabel}`;
                let isValid = true;
                if (afterDate && dateToCheck) {
                    isValid = dateToCheck.getTime() > afterDate.getTime();
                }
                return { errorMessageBuilder, isValid };
            },
        []
    );

    const formFieldValidators = useMemo(() => {
        const validationConfigurations = new Map<keyof WorkItemDto, IFormFieldValidationConfig>();

        validationConfigurations.set('type', {
            validators: [isNotBlank],
            errorMessageEntityName: 'Type',
        });

        validationConfigurations.set('name', {
            validators: [isNotBlank, isShorterThanMaxLength(200)],
            errorMessageEntityName: 'Name',
        });

        validationConfigurations.set('description', {
            validators: [isShorterThanMaxLength(200)],
            errorMessageEntityName: 'Description',
        });

        validationConfigurations.set('descriptionDisplayMode', {
            validators: [isNotBlank],
            errorMessageEntityName: 'When to Display Description',
        });

        validationConfigurations.set('companyId', {
            validators: [isNotBlank],
            errorMessageEntityName: 'Company',
        });

        validationConfigurations.set('startDate', {
            validators: [isNotBlank, isBefore(endDate, 'End Date'), isBefore(maxStartDate, 'STUDY End Date')],
            errorMessageEntityName: 'Start Date',
        });

        if (!isOngoing) {
            validationConfigurations.set('endDate', {
                validators: isOngoing ? [] : [isNotBlank, isAfter(startDate, 'Start Date'), isAfter(minEndDate, 'STUDY Start Date')],
                errorMessageEntityName: 'End Date',
            });
        }

        return validationConfigurations;
    }, [isOngoing, isBefore, endDate, isAfter, startDate, maxStartDate, minEndDate]);

    const getCurrentFormValues = useCallback((): WorkItemDto => {
        return {
            ...initValue,
            type: type!,
            name,
            description,
            descriptionDisplayMode,
            companyId,
            startDate: startDate!,
            endDate: endDate ?? undefined,
            associatedEmployeeIds: isActivity ? [] : associatedEmployeeIds,
        };
    }, [initValue, name, companyId, startDate, endDate, type, associatedEmployeeIds, isActivity, description, descriptionDisplayMode]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<WorkItemDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [getCurrentFormValues, formFieldValidators]);

    const validateField = useCallback(
        (fieldName: keyof WorkItemDto) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [getCurrentFormValues, fieldErrors, formFieldValidators]
    );

    const isFormDirty = useMemo(() => {
        let isDirty = name !== initValue?.name;
        isDirty = isDirty || description !== initValue?.description;
        isDirty = isDirty || descriptionDisplayMode !== initValue?.descriptionDisplayMode;
        isDirty = isDirty || companyId !== initValue?.companyId;
        isDirty = isDirty || startDate !== initValue?.startDate;
        isDirty = isDirty || endDate !== initValue?.endDate;
        return isDirty;
    }, [initValue, name, companyId, startDate, endDate, description, descriptionDisplayMode]);

    const handleTypeChange = useCallback((typeName: string) => {
        switch (typeName) {
            case 'Activity':
                setType(WorkItemType.Activity);
                break;
            case 'Business Component':
                setType(WorkItemType.BusinessComponent);
                break;

            default:
                console.error('Invalid type selected for component');
                break;
        }
    }, []);

    const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleDescriptionChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    }, []);

    const handleDescriptionDisplayModeChange = useCallback((displayMode: string) => {
        switch (displayMode) {
            case 'Always Show':
                setDescriptionDisplayMode(WorkItemDescriptionDisplayMode.Show);
                break;
            case 'Always Hide':
                setDescriptionDisplayMode(WorkItemDescriptionDisplayMode.Hide);
                break;
            case 'Show On Hover':
                setDescriptionDisplayMode(WorkItemDescriptionDisplayMode.Hover);
                break;
            default:
                console.error('Invalid display mode selected for component');
                break;
        }
    }, []);

    const handleCompanyIdChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (fixedCompanyId) {
                return;
            }
            setCompanyId(event.target.value);
        },
        [fixedCompanyId]
    );

    // This is an alternate way to update the company when you have access to the full DTO
    const handleCompanyChange = useCallback(
        (updatedCompany?: CompanyDto) => {
            if (fixedCompanyId) {
                return;
            }
            setCompanyId(updatedCompany?.id ?? '');
        },
        [fixedCompanyId]
    );

    const handleStartDateChange = useCallback((newDate: Date | null) => {
        setStartDate(newDate);
    }, []);

    const handleEndDateChange = useCallback(
        (newDate: Date | null) => {
            if (newDate !== null && isOngoing) {
                setIsOngoing(false);
            }
            setEndDate(newDate);
        },
        [isOngoing]
    );

    const handleIsOngoingChange = useCallback(
        (updatedIsOnGoing: boolean) => {
            if (updatedIsOnGoing && endDate !== null) {
                setEndDate(null);
            }
            setIsOngoing(updatedIsOnGoing);
        },
        [endDate]
    );

    const handleAssociatedEmployeesIdChange = useCallback((updatedAssociatedEmployees: string[]) => {
        setAssociatedEmployeeIds(updatedAssociatedEmployees);
    }, []);

    const save = () => {
        if (isSaving) {
            return;
        }
        const isFormValid = validateForm();
        if (isFormValid) {
            let workItemToSave = getCurrentFormValues();
            if (workItemToSave.id === emptyGuid) {
                createWorkItem(workItemToSave).unwrap().then(afterSave);
            } else {
                updateWorkItem(workItemToSave)
                    .unwrap()
                    .then(() => afterSave(workItemToSave));
            }
        }
    };

    const reset = useCallback(() => {
        loadFromInitValue(initValue);
    }, [loadFromInitValue, initValue]);

    const isCompanySelectVisible = useMemo(() => !fixedCompanyId, [fixedCompanyId]);
    const isTypeSelectVisible = useMemo(() => !fixedType, [fixedType]);

    return {
        type,
        handleTypeChange,
        name,
        handleNameChange,
        description,
        handleDescriptionChange,
        descriptionDisplayMode,
        handleDescriptionDisplayModeChange,
        companyId,
        handleCompanyChange,
        handleCompanyIdChange,
        startDate,
        handleStartDateChange,
        endDate,
        handleEndDateChange,
        isOngoing,
        handleIsOngoingChange,
        associatedEmployeeIds,
        handleAssociatedEmployeesIdChange,
        isFormDirty,
        fieldErrors,
        validateField,
        save,
        reset,
        isCompanySelectVisible,
        isTypeSelectVisible,
        isActivity,
    };
}

export type WorkItemFormManger = ReturnType<typeof useWorkItemForm>;
