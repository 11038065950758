import { FC } from 'react';
import { StudyFormManger } from '../Hooks';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { FormInput } from '../../../Components/CoreLib/library';
import { CompanySelect } from '../../../Components/CommonInputs';
import { StandardDatePicker } from '../../../Components/CommonInputs/StandardDatePicker';

export interface IAddEditStudyFormProps {
    formManager: StudyFormManger;
}

export const AddEditStudyForm: FC<IAddEditStudyFormProps> = ({ formManager }) => {
    return (
        <>
            <Grid item xs={12}>
                <FormInput
                    value={formManager.name}
                    onChange={formManager.handleNameChange}
                    label='Name'
                    name='name'
                    errorText={formManager.fieldErrors.get('name')}
                    error={!!formManager.fieldErrors.get('name')}
                    fullWidth
                    required
                />
            </Grid>
            {formManager.isCompanySelectVisible && (
                <Grid item xs={12}>
                    <CompanySelect
                        selectedCompanyId={formManager.companyId}
                        handleSelectedCompanyChange={formManager.handleCompanyChange}
                        errorMessage={formManager.fieldErrors.get('companyId')}
                        isRequired
                    />
                </Grid>
            )}
            <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item xs={4}>
                    <StandardDatePicker
                        label='Start Date'
                        value={formManager.startDate}
                        handleDateChange={formManager.handleStartDateChange}
                        errorMessage={formManager.fieldErrors.get('startDate')}
                        required
                    />
                </Grid>
                <Grid item pt={3}>
                    <FormControlLabel
                        control={<Switch checked={formManager.isOngoing} onChange={(_, val) => formManager.handleIsOngoingChange(val)} />}
                        label='Ongoing'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={4}>
                    <StandardDatePicker
                        label='End Date'
                        value={formManager.endDate}
                        handleDateChange={formManager.handleEndDateChange}
                        errorMessage={formManager.fieldErrors.get('endDate')}
                        required={!formManager.isOngoing}
                        disabled={formManager.isOngoing}
                    />
                </Grid>
            </Grid>
        </>
    );
};
