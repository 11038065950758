import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { SurveyPayloadFrequencyConfigDto, SurveyPayloadSurveyPeriodConfig } from '../../../dtos';
import { SurveyPayloadTimePeriodDto } from '../../../dtos/generated/SurveyPayloadTimePeriodDto';
import { SurveyBuilderContext } from '../utils/SurveyBuilderContext';
import { FrequencySurveyPeriod, TimePeriodTextBoxRow } from './SubComponents';
import { TAQ_BY_BC_ID } from '../../../Views';

interface ITimePeriodContentProps {
    allowMultipleTimePeriods: boolean;
    isBCM?: boolean;
}

export const TimePeriodContent: FC<ITimePeriodContentProps> = (props) => {
    const { allowMultipleTimePeriods, isBCM = false } = props;
    const { surveyPayload, setSurveyPayload, surveyAliasShort } = useContext(SurveyBuilderContext);
    const [errorMessage, setErrorMessage] = useState('');

    const isPeriodConfigValid = (config: SurveyPayloadSurveyPeriodConfig): boolean => {
        const areAllRequiredFieldsPopulated =
            config.periodStartMonth !== undefined &&
            config.periodStartDay !== undefined &&
            config.periodEndMonth !== undefined &&
            config.periodEndDay !== undefined;
        return areAllRequiredFieldsPopulated;
    };

    const updateFrequencyConfig = (frequencyConfig: SurveyPayloadFrequencyConfigDto) => {
        setSurveyPayload({ ...surveyPayload, frequencyConfig });
    };

    const updateTimePeriodArray = (timePeriodArray: SurveyPayloadTimePeriodDto[]) => {
        setSurveyPayload({ ...surveyPayload, timePeriodArray: _.cloneDeep(timePeriodArray) });
    };

    // TODO: this validation logic is repeated in the SurveyValidator. We should extract this into a single implementation that can be used in both places
    useEffect(() => {
        let localErrorMessage = '';
        const { frequencyConfig, timePeriodArray } = surveyPayload;
        if (frequencyConfig.radioOneTimeOrRecurring === 'one-time') {
            let newTimePeriodArray = [...timePeriodArray];
            newTimePeriodArray.forEach((timePeriod) => {
                if (!timePeriod.startDate || !timePeriod.endDate || !timePeriod.surveyLabel) {
                    localErrorMessage = 'Each time period must have a start date, end date, and label. ';
                }
            });
        }
        if (frequencyConfig.radioOneTimeOrRecurring === 'recurring') {
            let arePeriodConfigsValid = false;
            if (frequencyConfig.frequencyValue === 'every-year') {
                arePeriodConfigsValid = isPeriodConfigValid(frequencyConfig.surveyPeriods[0]);
            } else {
                arePeriodConfigsValid =
                    isPeriodConfigValid(frequencyConfig.surveyPeriods[0]) &&
                    isPeriodConfigValid(frequencyConfig.surveyPeriods[1]) &&
                    isPeriodConfigValid(frequencyConfig.surveyPeriods[2]) &&
                    isPeriodConfigValid(frequencyConfig.surveyPeriods[3]);
            }
            if (!arePeriodConfigsValid) {
                localErrorMessage = 'Period start and end dates are required';
            }
        }

        if (localErrorMessage !== errorMessage) {
            if (localErrorMessage !== '') {
                setErrorMessage(localErrorMessage);
            } else {
                setErrorMessage('');
            }
        }
    }, [surveyPayload, setErrorMessage, errorMessage]);

    const updateSurveyConfig = (config: SurveyPayloadSurveyPeriodConfig, index: number) => {
        let updatedConfig = _.cloneDeep(surveyPayload.frequencyConfig);
        updatedConfig.surveyPeriods[index] = config;
        updateFrequencyConfig(updatedConfig);
    };

    const handleFrequencySurveyPeriodComponents = () => {
        if (surveyPayload.frequencyConfig.frequencyValue === 'every-year') {
            return (
                <FrequencySurveyPeriod
                    surveyPeriodConfig={surveyPayload.frequencyConfig.surveyPeriods[0]}
                    updateSurveyConfig={updateSurveyConfig}
                    surveyPeriodIndex={0}
                    frequency={surveyPayload.frequencyConfig.frequencyValue}
                />
            );
        } else {
            return (
                <>
                    <FrequencySurveyPeriod
                        surveyPeriodConfig={surveyPayload.frequencyConfig.surveyPeriods[0]}
                        updateSurveyConfig={updateSurveyConfig}
                        surveyPeriodIndex={0}
                        frequency={surveyPayload.frequencyConfig.frequencyValue}
                    />
                    <FrequencySurveyPeriod
                        surveyPeriodConfig={surveyPayload.frequencyConfig.surveyPeriods[1]}
                        updateSurveyConfig={updateSurveyConfig}
                        surveyPeriodIndex={1}
                        frequency={surveyPayload.frequencyConfig.frequencyValue}
                    />
                    <FrequencySurveyPeriod
                        surveyPeriodConfig={surveyPayload.frequencyConfig.surveyPeriods[2]}
                        updateSurveyConfig={updateSurveyConfig}
                        surveyPeriodIndex={2}
                        frequency={surveyPayload.frequencyConfig.frequencyValue}
                    />
                    <FrequencySurveyPeriod
                        surveyPeriodConfig={surveyPayload.frequencyConfig.surveyPeriods[3]}
                        updateSurveyConfig={updateSurveyConfig}
                        surveyPeriodIndex={3}
                        frequency={surveyPayload.frequencyConfig.frequencyValue}
                    />
                </>
            );
        }
    };

    const changeTimePeriodArray = (timePeriod: SurveyPayloadTimePeriodDto, index: number) => {
        let newTimePeriodArray = [...surveyPayload.timePeriodArray];
        newTimePeriodArray[index] = { ...timePeriod };
        updateTimePeriodArray(newTimePeriodArray);
    };

    const handleRemoveTextBox = (index: number) => {
        let newTimePeriodArray = [...surveyPayload.timePeriodArray];
        if (surveyPayload.timePeriodArray.length > 1) {
            newTimePeriodArray.splice(index, 1);
            updateTimePeriodArray(newTimePeriodArray);
        }
    };

    const handleAddTextBox = () => {
        let newTimePeriodArray = [...surveyPayload.timePeriodArray];
        if (surveyPayload.timePeriodArray.length < 10) {
            newTimePeriodArray.push({
                startDate: null,
                endDate: null,
                surveyLabel: '',
            } as SurveyPayloadTimePeriodDto);
            updateTimePeriodArray(newTimePeriodArray);
            setErrorMessage('');
        }
    };

    const handleEachRespondent = () => {
        let localErrorMessage = '';
        if (allowMultipleTimePeriods && !isBCM) {
            if (surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'one-time') {
                if (
                    surveyPayload.distributionConfig.surveyDistribution === 'individualRecipient' &&
                    surveyPayload.distributionConfig.fillOutSurveyValue === 'Individual'
                ) {
                    localErrorMessage = 'Cannot select multiple time periods when distributing to more than one person.';
                }
            }
        }
        return localErrorMessage;
    };

    const isTAQByBCSurvey = useMemo(() => surveyPayload.selectedFormType.id === TAQ_BY_BC_ID, [surveyPayload.selectedFormType.id]);

    return (
        <Paper sx={{ p: 4 }}>
            {surveyPayload.frequencyConfig.radioOneTimeOrRecurring === 'recurring' ? (
                <>
                    <Typography variant='h5' sx={{ marginBottom: '16px' }}>
                        What dates should the {surveyAliasShort.toLowerCase()} be sent and what time period should they cover?
                    </Typography>
                    <Grid item xs={12}>
                        <Table size='small' padding='none'>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1 }}>Occurrence</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1, pl: 2, background: '#eeeeee' }}>Send Month</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 2, background: '#eeeeee' }}>Day</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 2 }} />
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1, pl: 2, background: '#eeeeee' }}>Period Start Month</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1, background: '#eeeeee' }}>Day</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1, background: '#eeeeee' }}>Period End Month</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 2, background: '#eeeeee' }}>Day</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 2 }} />
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1, pl: 2, background: '#eeeeee' }}>Label Prefix</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 1, background: '#eeeeee' }}>Year Label</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pr: 2, background: '#eeeeee' }}>Label Suffix</TableCell>
                                    <TableCell sx={{ py: 1, border: 'none', pl: 1 }} width={240}>
                                        Example
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{handleFrequencySurveyPeriodComponents()}</TableBody>
                        </Table>
                    </Grid>
                </>
            ) : (
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Typography variant='h5' sx={{ marginBottom: '16px' }}>
                            What time period should the {surveyAliasShort.toLowerCase()} cover?
                        </Typography>
                    </Grid>
                    {surveyPayload.timePeriodArray.map((timePeriod, index) => {
                        return (
                            <Grid item container direction='row' alignItems='center' spacing={2} xs={12}>
                                <TimePeriodTextBoxRow
                                    timePeriod={timePeriod}
                                    timePeriodIndex={index}
                                    changeTimePeriodArray={changeTimePeriodArray}
                                    handleRemoveTextBox={handleRemoveTextBox}
                                    handleAddTextBox={handleAddTextBox}
                                    timePeriodArrayLength={surveyPayload.timePeriodArray.length}
                                    distributionConfig={surveyPayload.distributionConfig}
                                    allowMultipleTimePeriods={allowMultipleTimePeriods && !isTAQByBCSurvey}
                                    isBCM={isBCM}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
            <Grid item container direction='row' alignItems='center' justifyContent='flex-start'>
                <Grid item container direction='column' xs={6}>
                    {errorMessage && (
                        <Typography variant='body1' sx={{ pr: 2, color: '#DF623B' }}>
                            {errorMessage}
                        </Typography>
                    )}
                    {allowMultipleTimePeriods && (
                        <Typography variant='body1' sx={{ pr: 2, color: '#DF623B' }}>
                            {handleEachRespondent()}
                        </Typography>
                    )}
                    {isTAQByBCSurvey && (
                        <Typography variant='body1' sx={{ pr: 2, color: '#DF623B' }}>
                            TAQ by BC does not support multiple time periods because business components may change from time of approval.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};
