import { Box, Tab, Tabs } from '@mui/material';
import { FC, useContext } from 'react';
import { StudyDashboardContext } from '../../Utils/StudyDashboardContext';
import { StudyDashboardTabContentViewer } from './StudyDashboardTabContentViewer';

export const StudyDashboardTabsContainer: FC = () => {
    const { selectedTab, setSelectedTab } = useContext(StudyDashboardContext);

    return (
        <Box>
            <Tabs
                variant='fullWidth'
                value={selectedTab}
                onChange={(_, newTabValue) => setSelectedTab(newTabValue)}>
                <Tab label='OVERVIEW' />
                <Tab label='COMPONENTS' />
                <Tab label='RESPONDENTS' />
                <Tab label='SURVEYS' />
                <Tab label='BCM' />
                <Tab label='STREAM' />
            </Tabs>
            <StudyDashboardTabContentViewer />
        </Box>
    )
};