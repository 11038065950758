import { FC, useContext } from 'react';
import { StudyDashboardContext } from '../../Utils/StudyDashboardContext';
import { StudyDashboardBCMTabSidebarContent, StudyDashboardEmployeesTabSidebarContent, StudyDashboardOverviewTabSidebarContent, StudyDashboardSurveysTabSidebarContent, StudyDashboardWorkTabSidebarContent } from './Tabs';
import { Divider } from '@mui/material';

const tabSidebarComponentMap: Record<number, FC> = {
    0: StudyDashboardOverviewTabSidebarContent,
    1: StudyDashboardWorkTabSidebarContent,
    2: StudyDashboardEmployeesTabSidebarContent,
    3: StudyDashboardSurveysTabSidebarContent,
    4: StudyDashboardBCMTabSidebarContent,
};

export const StudyDashboardSidebarTabContentViewer: FC = () => {
    const { selectedTab } = useContext(StudyDashboardContext);
    const TabSidebarContent = tabSidebarComponentMap[selectedTab];

    if (!TabSidebarContent) {
        return null;
    }

    return (
        <>
            <Divider />
            <TabSidebarContent />
        </>
    );
};