import { ActivityStreamDto } from "../../../dtos";
import { BaseMessageType } from "./BaseMessageType";

export class ScheduleDetailCrudMessage extends BaseMessageType {
    _action: string;

    constructor(streamEntry: ActivityStreamDto, action: string) {
        super(streamEntry);
        this._action = action;
    }

    getMessage(): JSX.Element[] {
        const { respondent, company, scheduleId, formType, period } = this._streamEntry;
        return this.generateMessageFromPayload({
            noun: 'Survey instance',
            pastTenseAction: this._action,
            targetJoiningText: this._action === 'created' ? 'for' : 'by',
            targetProperNoun: `${respondent?.firstName} ${respondent?.lastName}`,
            targetProperNounRoute: `/companies/${company?.id}/respondents/${respondent?.id}`,
            companyJoiningText: 'at',
            companyName: company?.shortName || company?.name,
            companyRoute: `/companies/${company?.id}`,
            formTypeName: formType?.name,
            formTypeRoute: `/form-types?id=${formType?.id}`,
            periodText: period,
            periodTextRoute: `/companies/${company?.id}/schedules/${scheduleId}`
        });
    }
}