import { FilterList } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, FormLabel, Grid, Switch } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { StandardDialogHeader } from '../../CommonComponents';
import { StandardDatePicker } from '../../CommonInputs/StandardDatePicker';
import { useDepartmentFilterDialogManager } from './useDepartmentFilterDialogManager';

export interface IDepartmentFilterDialogProps {
    companyId: string;
    open: boolean;
    onClose: () => void;
}

export const DepartmentFilterDialog: FC<IDepartmentFilterDialogProps> = ({ companyId, open, onClose }) => {
    const {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty
    } = useDepartmentFilterDialogManager();

    const handleApplyFilters = useCallback(() => {
        applyDialogFilters();
        onClose();
    }, [applyDialogFilters, onClose]);

    const handleClose = useCallback(() => {
        loadAppliedFiltersIntoDialog();
        onClose();
    }, [loadAppliedFiltersIntoDialog, onClose])

    useEffect(() => {
        if (open) {
            loadAppliedFiltersIntoDialog();
        }
    }, [loadAppliedFiltersIntoDialog, open]);

    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '100%' } }} maxWidth='lg'>
            <StandardDialogHeader icon={<FilterList />} text='Filter Respondents' />
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item xs={6} mt={2}>
                        <FormControlLabel
                            control={<Switch checked={dialogFilters.isNoRespondents} onChange={(_, isChecked) => handleDialogFilterValueChange('isNoRespondents', isChecked)} />}
                            label='Not assigned to a department'
                            labelPlacement='end'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel>Created</FormLabel>
                    </Grid>
                    <Grid item container direction='row' xs={8} spacing={2} alignItems='center' pt={0} style={{ paddingTop: 0 }} >
                        <Grid item xs='auto'>
                            <StandardDatePicker
                                value={dialogFilters.createdFromDate ?? null}
                                handleDateChange={(d) => handleDialogFilterValueChange('createdFromDate', d ?? undefined)}
                            />
                        </Grid>
                        <Grid item xs='auto'>
                            to
                        </Grid>
                        <Grid item xs='auto'>
                            <StandardDatePicker
                                value={dialogFilters.createdToDate ?? null}
                                handleDateChange={(d) => handleDialogFilterValueChange('createdToDate', d ?? undefined)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClose}>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormDirty ? 'Cancel' : 'Close'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleApplyFilters} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
