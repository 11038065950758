import { Box, Grid, Stack, Typography } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyDto, TimeRange } from '../../../dtos';
import { useGetCompanyDepartmentsQuery, useGetCompanyManagersQuery, useGetCompanyRespondentsQuery, useGetFormTypesQuery, useLazyGetCompanyRespondentsAssignedToWorkItemsQuery } from '../../../store';
import { LoadingIndicator, PaginatedProps, useFailedActionSnackbar } from '../../CoreLib/library';
import { RespondentQuickAdd } from '../../QuickAdds';
import { SurveyBuilderContext } from '../utils/SurveyBuilderContext';
import { AddRespondentsDropDown, AddRespondentsTable, RespondentSearch } from './SubComponents';
import { TAQ_BY_BC_ID } from '../../../Views';

interface IAddRespondentsStepContentsProps {
    company: CompanyDto;
}

export const AddRespondentsStepContent: FunctionComponent<IAddRespondentsStepContentsProps> = (props) => {
    const { company } = props;
    const { surveyPayload, setSurveyPayload, surveyAliasShort } = useContext(SurveyBuilderContext);
    const { id: companyId } = useParams();
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [searchParams, setSearchParams] = useState(`FirstName:|LastName:|Title:|Department:|Manager:|FormType:${surveyPayload.selectedFormType.id}`);
    const { data: departments, isLoading: departmentsLoading, isError: isDepartmentsError } = useGetCompanyDepartmentsQuery(companyId!);
    const { data: managers, isLoading: managersLoading, isError: isManagersError } = useGetCompanyManagersQuery(companyId!);
    const { data: formTypes, isLoading: formTypesLoading, isError: isFormTypesError } = useGetFormTypesQuery();
    const { data: respondentData, isLoading, isError: isCompanyRespondentsError } = useGetCompanyRespondentsQuery({
        parentId: companyId!,
        searchText: searchParams,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize
    });
    const [getRespondentsAssignedInTimePeriods, { data: assignedRespondents, isLoading: isLoadingAssignedRespondents, isError: isErrorGettingAssignedRespondents }] = useLazyGetCompanyRespondentsAssignedToWorkItemsQuery();

    useFailedActionSnackbar('retrieve', 'departments', isDepartmentsError);
    useFailedActionSnackbar('retrieve', 'managers', isManagersError);
    useFailedActionSnackbar('retrieve', 'form types', isFormTypesError);
    useFailedActionSnackbar('retrieve', 'respondents', isCompanyRespondentsError);
    useFailedActionSnackbar('retrieve', 'respondents assigned to components in specified time period(s)', isErrorGettingAssignedRespondents);

    useEffect(() => {
        if (surveyPayload.respondentQueue.length === 0 && surveyPayload.selectedFormType.id === TAQ_BY_BC_ID && !assignedRespondents) {
            getRespondentsAssignedInTimePeriods({
                companyId: company.id,
                periodTimeRanges: surveyPayload.timePeriodArray.map<TimeRange>(tp => ({ startDate: tp.startDate ? new Date(tp.startDate.toString()).toISOString() as unknown as Date : new Date(), endDate: tp.endDate ? new Date(tp.endDate.toString()).toISOString() as unknown as Date : undefined}))
            }).then((response) => {
                if (response.data) {
                    setSurveyPayload({
                        ...surveyPayload,
                        respondentQueue: response.data
                    });
                }
            });
        }
    }, [surveyPayload, assignedRespondents, company.id, getRespondentsAssignedInTimePeriods, setSurveyPayload]);

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Box sx={{
                backgroundColor: 'primary.contrastText',
                py: 4,
                px: 4
            }}>
                <Grid item container direction="row" alignItems="start">
                    <Grid item container direction="column" justifyContent="start" xs={6}>
                        <Typography variant="h5" sx={{ marginBottom: '8px' }}>
                            Which individual(s) should receive this {surveyAliasShort.toLowerCase()}?
                        </Typography>
                    </Grid>
                    <Grid item container direction="row" alignItems="center" justifyContent="flex-end" xs={6}>
                        <Grid item>
                            <RespondentQuickAdd companyId={company.id} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <AddRespondentsDropDown paginatedProps={paginatedProps} disabled={isLoadingAssignedRespondents}/>
                </Grid>
            </Box>
            <Box sx={{
                backgroundColor: 'primary.contrastText',
                py: 4,
                px: 4
            }}>
                <Grid item>
                    {!departmentsLoading && !managersLoading && !formTypesLoading && !isLoadingAssignedRespondents ? (
                        <RespondentSearch setSearchParams={setSearchParams} departments={departments!} managers={managers!} formTypes={formTypes!} defaultFormTypeId={surveyPayload.selectedFormType.id}/>)
                        : <LoadingIndicator />}
                </Grid>
            </Box>
            <Box sx={{
                backgroundColor: 'primary.contrastText',
                py: 4,
                px: 4
            }}>
                <Grid item>
                    <AddRespondentsTable respondentData={respondentData!} isLoading={isLoading} paginatedProps={paginatedProps} setPaginatedProps={setPaginatedProps}/>
                </Grid>
            </Box>
        </Stack>
    );
}