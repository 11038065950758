import {FC} from 'react';
import { Field } from '../../../../../../models/survey';
import { OutlinedInput, TableCell } from '@mui/material';
import { patterns } from '../../../../FormFields';
import { onlyShowOnDesktop, textAlignCenterOnMobile } from '../../../util';
import { SurveyFormErrorMessage } from '../../../../types';
import { isNaN } from 'lodash';

export interface ISurveyInputFieldProps {
    field: Field;
    fieldName?: string;
    getQuestionAnswer: (fieldName: string) => string | number;
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
    // The error message handling should likely be done at a higher level or a form wide context but I am making minimal changes for now
    errorMessages: SurveyFormErrorMessage[];
    setErrorMessages: (updatedErrors: SurveyFormErrorMessage[]) => void;
    isLastField?: boolean;
    isPdfView?: boolean;
    isLoading?: boolean;
};

export const SurveyInputField: FC<ISurveyInputFieldProps> = ({
    field,
    fieldName = field.fieldName,
    isLastField,
    getQuestionAnswer,
    setQuestionAnswer,
    errorMessages,
    setErrorMessages,
    isPdfView,
    isLoading
}) => {

    const upsertErrorMessage = (currentErrorMessages: SurveyFormErrorMessage[], fieldName: string, message: string) => {
        if (currentErrorMessages.some((e) => e.identifier === fieldName)) {
            return currentErrorMessages.map((e) => (e.identifier === fieldName ? { ...e, message: message } : e));
        } else {
            return [...currentErrorMessages, { identifier: fieldName, message: message }];
        }
    };

    const removeErrorMessage = (currentErrorMessages: SurveyFormErrorMessage[], fieldName: string) => {
        if (currentErrorMessages.some((e) => e.identifier === fieldName)) {
            return currentErrorMessages.filter((e) => e.identifier !== fieldName);
        } else {
            return currentErrorMessages;
        }
    };
    
    const setQuestionAnswerNumeric = (fieldName: string, value: string | number, allowDecimal: boolean) => {
        if (isNaN(Number(value))) {
            setErrorMessages(upsertErrorMessage(errorMessages, fieldName, 'Invalid Number'));
        } else {
            if (allowDecimal === false && value.toString().includes('.')) {
                setErrorMessages(upsertErrorMessage(errorMessages, fieldName, 'This field does not permit decimals'));
            } else {
                setErrorMessages(removeErrorMessage(errorMessages, fieldName));
            }
            setQuestionAnswer(fieldName, value);
        }
    };

    if (field.fieldType === 'percentage') {
        return (
            <>
                <td className='standard-input-cell' key={fieldName}>
                    <OutlinedInput
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: patterns.Numeric.validate.source,
                            className: 'standard-outlined-input',
                            sx: textAlignCenterOnMobile,
                        }}
                        onChange={(event) => setQuestionAnswerNumeric(fieldName, event.target.value, false)}
                        defaultValue={getQuestionAnswer(fieldName)}
                        sx={field.isDisabled ? { width: '90%', marginLeft: '8px', backgroundColor: '#D3D3D3' } : { width: '90%', marginLeft: '8px' }}
                        disabled={isLoading || field.isDisabled}
                        error={!!errorMessages.find((error) => error.identifier === fieldName)}
                        margin='none'
                    />
                </td>
                {isLastField && (
                    <TableCell
                        key={`${fieldName}-suffix`}
                        sx={{ width: '12px', border: 'none', paddingLeft: isPdfView ? '-16px' : '8px', ...onlyShowOnDesktop }}>
                        %
                    </TableCell>
                )}
            </>
        );
    } else if (field.fieldType === 'numeric') {
        return (
            <>
                <td className='standard-input-cell' key={fieldName}>
                    <OutlinedInput
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: patterns.Numeric.validate.source,
                            className: 'standard-outlined-input',
                            sx: textAlignCenterOnMobile,
                        }}
                        onChange={(event) => setQuestionAnswerNumeric(fieldName, event.target.value, true)}
                        defaultValue={getQuestionAnswer(fieldName)}
                        sx={field.isDisabled ? { width: '90%', marginLeft: '8px', backgroundColor: '#D3D3D3' } : { width: '90%', marginLeft: '8px' }}
                        disabled={isLoading || field.isDisabled}
                        error={!!errorMessages.find((error) => error.identifier === fieldName)}
                    />
                </td>
                {isLastField && <td key={`${fieldName}-suffix`}>{field.unitName}</td>}
            </>
        );
    } else {
        // defaults to text field
        return (
            <td className='standard-input-cell' key={fieldName}>
                <OutlinedInput
                    inputProps={{ className: 'standard-multiline-outlined-input' }}
                    onChange={(event) => setQuestionAnswer(fieldName, event.target.value)}
                    defaultValue={getQuestionAnswer(fieldName)}
                    sx={{ width: '100%' }}
                    disabled={isLoading}
                    multiline
                />
            </td>
        );
    }
};