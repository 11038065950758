import { People } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { BusinessComponentDto, EmployeeDto, RespondentDto } from '../../../../../dtos';
import { emptyGuid } from '../../../../../models';
import { CompanyRespondentSelect } from '../../../../CommonInputs';
import { RespondentQuickAdd } from '../../../../QuickAdds';
import { StandardDialog } from '../../../../StandardDialog';

export interface EmployeeDetails {
    employee: EmployeeDto;
    associatedBusinessComponents: string[];
}

export interface IAddEditEmployeeModalProps {
    handleUpdate: (employeeDetails: EmployeeDetails) => void;
    allBusinessComponents: BusinessComponentDto[];
    alreadyAddedRespondentIds: string[];
    companyId: string;
    employeeDetails?: EmployeeDetails;
    onClose: () => void;
}

export const AddEditEmployeeModal: FC<IAddEditEmployeeModalProps> = ({ handleUpdate, allBusinessComponents, companyId, employeeDetails, onClose, alreadyAddedRespondentIds }) => {
    const [employee, setEmployee] = useState<EmployeeDto>();
    const [associatedBusinessComponents, setAssociatedBusinessComponents] = useState<string[]>();

    useEffect(() => {
        const isProvisioningDataProvided = !!employeeDetails;
        const isModalProvisioned = !!employee && !!associatedBusinessComponents;
        if (isProvisioningDataProvided && !isModalProvisioned) {
            setEmployee(_.cloneDeep(employeeDetails.employee));
            setAssociatedBusinessComponents(_.cloneDeep(employeeDetails.associatedBusinessComponents));
        } else if (isModalProvisioned && !isProvisioningDataProvided) {
            setEmployee(undefined);
            setAssociatedBusinessComponents(undefined);
        }
    }, [employee, associatedBusinessComponents, employeeDetails]);

    const isNewRecord = useMemo(() => {
        return employee?.id === emptyGuid;
    }, [employee]);

    const handleSelectedRespondentChange = useCallback(
        (selectedRespondent?: RespondentDto) => {
            setEmployee({
                ...employee,
                id: selectedRespondent?.id ?? '',
                name: selectedRespondent ? `${selectedRespondent?.firstName} ${selectedRespondent?.lastName}` : '',
            });
        },
        [employee]
    );

    const isError = useMemo(() => {
        return !employee;
    }, [employee]);

    const handleConfirm = useCallback(() => {
        if (!employee || employee?.id === emptyGuid || !associatedBusinessComponents || isError) {
            return;
        }
        handleUpdate({ employee: employee, associatedBusinessComponents: associatedBusinessComponents });
    }, [employee, associatedBusinessComponents, handleUpdate, isError]);

    const handleToggleBusinessComponent = useCallback(
        (empId: string) => {
            if (!associatedBusinessComponents) {
                return;
            }
            const isCurrentlyChecked = associatedBusinessComponents.includes(empId);
            if (isCurrentlyChecked) {
                setAssociatedBusinessComponents([...associatedBusinessComponents].filter((e) => e !== empId));
            } else {
                setAssociatedBusinessComponents([...associatedBusinessComponents, empId]);
            }
        },
        [associatedBusinessComponents]
    );

    const isEveryItemChecked = useMemo(() => {
        return associatedBusinessComponents?.length === allBusinessComponents.length;
    }, [associatedBusinessComponents, allBusinessComponents]);

    const isAtLeastOneItemChecked = useMemo(() => {
        return (associatedBusinessComponents?.length ?? 0) > 0;
    }, [associatedBusinessComponents]);

    const handleToggleSelectAll = useCallback(() => {
        if (isEveryItemChecked) {
            setAssociatedBusinessComponents([]);
        } else {
            setAssociatedBusinessComponents(allBusinessComponents.map((emp) => emp.id));
        }
    }, [isEveryItemChecked, allBusinessComponents]);

    const renderRow = useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;
            if (index === 0) {
                return (
                    <ListItem style={style} key={index} component='div' disablePadding>
                        <ListItemButton onClick={() => handleToggleSelectAll()} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge='start'
                                    checked={isEveryItemChecked}
                                    indeterminate={!isEveryItemChecked && isAtLeastOneItemChecked}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary='Select All' />
                        </ListItemButton>
                    </ListItem>
                );
            }

            const rowEmployee = allBusinessComponents[index - 1];

            return (
                <ListItem style={style} key={index} component='div' disablePadding>
                    <ListItemButton onClick={() => handleToggleBusinessComponent(rowEmployee.id)} dense>
                        <ListItemIcon>
                            <Checkbox edge='start' checked={associatedBusinessComponents?.includes(rowEmployee.id)} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={rowEmployee.name} />
                    </ListItemButton>
                </ListItem>
            );
        },
        [allBusinessComponents, associatedBusinessComponents, handleToggleBusinessComponent, handleToggleSelectAll, isEveryItemChecked, isAtLeastOneItemChecked]
    );

    return (
        <StandardDialog
            isVisible={!!employee}
            onClose={onClose}
            icon={<People />}
            title={isNewRecord ? 'New Respondent' : 'Edit Respondent'}
            confirmAction={handleConfirm}
            confirmText='Save'>
            <Grid container direction='column'>
                <Grid item display='flex' sx={{ mt: 1, mb: 2 }}>
                    <CompanyRespondentSelect
                        label='Respondent'
                        required
                        companyId={companyId}
                        selectedRespondentId={employee?.id ?? ''}
                        handleSelectedRespondentChange={handleSelectedRespondentChange}
                        errorMessage={isError ? 'Respondent Required' : undefined}
                        itemFilter={r => !alreadyAddedRespondentIds.includes(r.id)}
                    />
                    <Box pt={3}>
                        <RespondentQuickAdd companyId={companyId} afterCreate={handleSelectedRespondentChange} />
                    </Box>
                </Grid>
                <FixedSizeList
                    height={400}
                    width={'100%'}
                    itemSize={46}
                    itemCount={allBusinessComponents.length + 1}
                    overscanCount={6}
                    style={{ border: '1px solid #cbcbcb', borderRadius: 8 }}>
                    {renderRow}
                </FixedSizeList>
            </Grid>
        </StandardDialog>
    );
};
