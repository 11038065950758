import { SortDirection, SxProps, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Theme } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';

// TODO: this is a good candidate to be moved into the core library. We have a local data table there already but it did not suite this scenario.
export interface IInMemoryTableColumnDefinition<T = any> {
    key: string;
    label: string;
    value: string | ((row: T) => string | JSX.Element);
    isSortable: boolean;
    width?: string;
}

export interface IInMemoryTable<T = any> {
    rows: any[];
    columnDefinitions: IInMemoryTableColumnDefinition<T>[];
    defaultSortKey: string;
    defaultSortDirection?: SortDirection;
    defaultRowsPerPage?: number;
    onRowClick?: (row: T) => void;
}

export const InMemoryTable: FC<IInMemoryTable> = ({
    rows,
    columnDefinitions,
    defaultSortKey,
    defaultSortDirection = 'desc',
    defaultRowsPerPage = 25,
    onRowClick,
}) => {
    const [sortKey, setSortKey] = useState(defaultSortKey);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRecordValue = useCallback((row: any, value: string | ((row: any) => string | JSX.Element)) => {
        return typeof value === 'string' ? row[value] : value(row);
    }, []);

    const handleSortableHeaderClicked = useCallback(
        (headerKey: string) => {
            if (sortKey === headerKey) {
                if (sortDirection === 'asc') {
                    setSortDirection('desc');
                } else {
                    setSortDirection('asc');
                }
            } else {
                setSortKey(headerKey);
                setSortDirection('desc');
            }
        },
        [sortKey, sortDirection]
    );

    const handleRowClick= useCallback((row: any) => {
        if (!!onRowClick) {
            onRowClick(row);
        }
     }, [onRowClick]);

    const visibleRows = useMemo(() => {
        const recordsToSkip = page * rowsPerPage;
        const columnDefinition = columnDefinitions.find((cd) => cd.key === sortKey);
        if (!columnDefinition) {
            throw new Error('Invalid Sort Key');
        }

        return _.cloneDeep(rows)
            .sort((a, b) => {
                const aVal = (getRecordValue(a, columnDefinition.value) as string).toLowerCase();
                const bVal = (getRecordValue(b, columnDefinition.value) as string).toLowerCase();
                if (sortDirection === 'asc') {
                    return aVal > bVal ? 1 : -1;
                } else {
                    return aVal < bVal ? 1 : -1;
                }
            })
            .slice(recordsToSkip, recordsToSkip + rowsPerPage);
    }, [rows, page, rowsPerPage, columnDefinitions, getRecordValue, sortDirection, sortKey]);

    const contextualRowStyles: SxProps<Theme> = useMemo(() => {
        let styles: any = {};
        if (!!onRowClick) {
            styles.cursor = 'pointer';
        }
        return styles;
    }, [onRowClick]);

    return (
        <Table size='small' stickyHeader padding='none' style={{ overflow: 'hidden' }}>
            <TableHead>
                <TableRow>
                    {columnDefinitions.map((cd) =>
                        cd.isSortable ? (
                            <TableCell key={cd.key} width={cd.width} sortDirection={sortKey === cd.key ? sortDirection : false}>
                                <TableSortLabel
                                    active={sortKey === cd.key}
                                    direction={sortDirection || undefined}
                                    onClick={() => handleSortableHeaderClicked(cd.key)}>
                                    {cd.label}
                                </TableSortLabel>
                            </TableCell>
                        ) : (
                            <TableCell key={cd.key} width={cd.width}>
                                {cd.label}
                            </TableCell>
                        )
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {visibleRows.map((row: any, idx) => (
                    <TableRow key={idx} className='hover-content-container' hover={!!onRowClick} onClick={() => handleRowClick(row)} sx={contextualRowStyles}>
                        {columnDefinitions.map((cd) => (
                            <TableCell key={`${cd.key}-row-${idx}`}>{getRecordValue(row, cd.value)}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        sx={{ position: 'sticky', bottom: 0 }}
                        count={rows.length}
                        page={page}
                        onPageChange={(_, p) => setPage(p)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
};
