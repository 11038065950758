import React, { FunctionComponent, useState } from 'react';
import {
    Grid,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
    Button,
} from '@mui/material';
import { Business } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { FormSection } from '..';
import { ICompanyFormProps } from './types';
import { emptyGuid } from '../../../models';
import { patterns } from '../FormFields';
import { useGetUsersLimitedQuery } from '../../../store/api/user-api';
import { CompanyDto } from '../../../dtos';
import { useFailedActionSnackbar } from '../../CoreLib/library';

export const CompanyForm: FunctionComponent<ICompanyFormProps> = (props) => {
    const { save, initValues } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.name : '');
    const [shortName, setShortName] = useState(initValues ? initValues.shortName : '');
    const [areaCode, setAreaCode] = useState(initValues ? initValues.areaCode : '');
    const [phone, setPhone] = useState(initValues ? initValues.phone : '');
    const [representativeId, setRepresentativeId] = useState(initValues ? initValues.userId : '');
    const [yearEndMonth, setYearEndMonth] = useState(initValues ? initValues.yearEndMonth.toString() : '');
    const [yearEndDay, setYearEndDay] = useState(initValues ? initValues.yearEndDay.toString() : '');
    const [yearEndFocus, setYearEndFocus] = useState(false);
    const navigate = useNavigate();
    const { data: users, isError: isErrorGettingUsers } = useGetUsersLimitedQuery();
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        SHORT_NAME: '',
        AREA_CODE: '',
        REPRESENTATIVE: '',
        YEAR_END: '',
    });

    useFailedActionSnackbar('retrieve', 'representatives', isErrorGettingUsers);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleShortNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShortName(event.target.value);
    };

    const handleAreaCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setAreaCode(event.target.value);
        }
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const handleRepresentativeChange = (event: SelectChangeEvent) => {
        setRepresentativeId(event.target.value);
    };

    const handleYearEndMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setYearEndMonth(event.target.value);
        }
    };

    const handleYearEndDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.patternMismatch) {
            setYearEndDay(event.target.value);
        }
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const onYearEndFocus = () => {
        setYearEndFocus(true);
    };

    const onYearEndBlur = () => {
        setYearEndFocus(false);
        validate('YEAR_END');
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NAME') {
            if (name) {
                fieldErrors.NAME = '';
                isValid = true;
            } else {
                fieldErrors.NAME = 'Name is required';
                isValid = false;
            }
        } else if (fieldName === 'SHORT_NAME') {
            if (shortName) {
                fieldErrors.SHORT_NAME = '';
                isValid = true;
            } else {
                fieldErrors.SHORT_NAME = 'Short Name is required';
                isValid = false;
            }
        } else if (fieldName === 'AREA_CODE') {
            const regex = patterns.AreaCode.validate;
            if (!areaCode || regex.test(areaCode)) {
                fieldErrors.AREA_CODE = '';
                isValid = true;
            } else {
                fieldErrors.AREA_CODE = 'Area Code must be ###';
                isValid = false;
            }
        } else if (fieldName === 'REPRESENTATIVE') {
            if (representativeId) {
                fieldErrors.REPRESENTATIVE = '';
                isValid = true;
            } else {
                fieldErrors.REPRESENTATIVE = 'Representative is required';
                isValid = false;
            }
        } else if (fieldName === 'YEAR_END') {
            const monthRegex = patterns.Month.validate;
            const monthDateRegex = patterns.MonthDate.validate;
            if (monthRegex.test(yearEndMonth) && monthDateRegex.test(yearEndDay)) {
                fieldErrors.YEAR_END = '';
                isValid = true;
            } else {
                fieldErrors.YEAR_END = 'Year End must be in format MM/DD';
                isValid = false;
            }
        }
        setFieldErrors({
            NAME: fieldErrors.NAME,
            SHORT_NAME: fieldErrors.SHORT_NAME,
            AREA_CODE: fieldErrors.AREA_CODE,
            REPRESENTATIVE: fieldErrors.REPRESENTATIVE,
            YEAR_END: fieldErrors.YEAR_END,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('NAME');
        isValid = validate('SHORT_NAME') && isValid;
        isValid = validate('REPRESENTATIVE') && isValid;
        isValid = validate('YEAR_END') && isValid;
        return isValid;
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            const updatedCompany: CompanyDto = {
                ...initValues,
                id: initValues?.id ?? emptyGuid,
                isActive: isActive,
                name: name,
                shortName: shortName,
                areaCode: areaCode,
                phone: phone,
                userId: representativeId,
                yearEndMonth: parseInt(yearEndMonth),
                yearEndDay: parseInt(yearEndDay),
                createdOn: initValues?.createdOn ?? new Date(),
                departments: initValues?.departments ?? []
            };

            save(updatedCompany);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Business /> {name ? name : 'New Company'}
                    </Typography>
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={6}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormControl error={fieldErrors.NAME !== ''} fullWidth>
                                <FormLabel>Company Name</FormLabel>
                                <OutlinedInput value={name} onChange={handleNameChange} onBlur={onFieldBlur('NAME')} required />
                                <FormHelperText>{fieldErrors.NAME}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={fieldErrors.SHORT_NAME !== ''} fullWidth>
                                <FormLabel>Short Name</FormLabel>
                                <OutlinedInput value={shortName} onChange={handleShortNameChange} onBlur={onFieldBlur('SHORT_NAME')} required />
                                <FormHelperText>{fieldErrors.SHORT_NAME}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item container direction='row' spacing={2} xs={4}>
                            <Grid item xs={3}>
                                <FormControl error={fieldErrors.AREA_CODE !== ''} fullWidth>
                                    <FormLabel>Area Code</FormLabel>
                                    <OutlinedInput
                                        inputProps={{ inputMode: 'numeric', pattern: patterns.AreaCode.input.source }}
                                        value={areaCode}
                                        onChange={handleAreaCodeChange}
                                        onBlur={onFieldBlur('AREA_CODE')}
                                    />
                                    <FormHelperText>{fieldErrors.AREA_CODE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={9}>
                                <FormControl fullWidth>
                                    <FormLabel>Phone</FormLabel>
                                    <OutlinedInput value={phone} onChange={handlePhoneChange} onBlur={onFieldBlur('PHONE')} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormControl error={fieldErrors.REPRESENTATIVE !== ''} fullWidth>
                                <FormLabel id='representative-label'>H&amp;K Representative</FormLabel>
                                <Select value={representativeId} onChange={handleRepresentativeChange} onBlur={onFieldBlur('REPRESENTATIVE')}>
                                    {users?.pageResults.map((rep) => (
                                        <MenuItem key={rep.id} value={rep.id}>
                                            {rep.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{fieldErrors.REPRESENTATIVE}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel error={fieldErrors.YEAR_END !== ''} focused={yearEndFocus}>
                                Year End - Month/Day
                            </FormLabel>
                            <Grid container direction='row' alignItems='center' spacing={1}>
                                <Grid item xs={2}>
                                    <TextField
                                        inputProps={{ inputMode: 'numeric', pattern: patterns.Month.input.source }}
                                        error={fieldErrors.YEAR_END !== ''}
                                        onFocus={onYearEndFocus}
                                        onBlur={onYearEndBlur}
                                        value={yearEndMonth}
                                        onChange={handleYearEndMonthChange}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>/</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        inputProps={{ inputMode: 'numeric', pattern: patterns.MonthDate.input.source }}
                                        error={fieldErrors.YEAR_END !== ''}
                                        onFocus={onYearEndFocus}
                                        onBlur={onYearEndBlur}
                                        value={yearEndDay}
                                        onChange={handleYearEndDayChange}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <FormHelperText error={fieldErrors.YEAR_END !== ''}>{fieldErrors.YEAR_END}</FormHelperText>
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
