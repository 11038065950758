import { People } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormLabel,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StandardDialogHeader } from '../../../Components/CommonComponents';
import { CompanyRespondentSelect, ReadonlyRespondentName } from '../../../Components/CommonInputs';
import { LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { FormSection } from '../../../Components/Forms';
import { RespondentQuickAdd } from '../../../Components/QuickAdds';
import { RespondentDto, WorkItemDto } from '../../../dtos';
import { useLazyGetRespondentInvolvementWorkItemsQuery, useSetRespondentInvolvementWorkItemsMutation } from '../../../store';
import { useGetWorkItemQuery } from '../../../store/api/work-item-api';

export interface IAddCompanyRespondentsModalProps {
    isOpen: boolean;
    close: () => void;
    companyId: string;
    studyId: string;
    selectedRespondentId?: string;
}

export const AddEditRespondentInvolvementModal: FC<IAddCompanyRespondentsModalProps> = ({ isOpen, close, companyId, studyId, selectedRespondentId }) => {
    const [getRespondentInvolvementWorkItems, { data: currentRespondentInvolvement, isLoading: isLoadingCurrentRespondentInvolvement }] =
        useLazyGetRespondentInvolvementWorkItemsQuery();
    const { data: studyWorkItems, isLoading: isLoadingStudyWorkItems } = useGetWorkItemQuery({
        sortKey: 'NAME',
        sortAsc: true,
        page: 0,
        pageSize: 5000,
        includeInactive: false,
        studyId: studyId,
        itemType: 'BUSINESS COMPONENT'
    });
    const isNewInvolvement = useMemo(() => !selectedRespondentId, [selectedRespondentId]);
    const [
        setRespondentToWorkItems,
        { isSuccess: IsSetInvolvementSuccess, isError: isSetInvolvementError, isLoading: isUpdatingRespondentInvolvement, reset: resetSetInvolvementMutation, error: setRespondentToWorkItemsError },
    ] = useSetRespondentInvolvementWorkItemsMutation();
    useSuccessfulActionSnackbar(isNewInvolvement ? 'added' : 'edited', isNewInvolvement ? 'Respondent' : 'Respondent Involvement', IsSetInvolvementSuccess, () => {
        resetSetInvolvementMutation();
        clearAndClose();
    });
    useFailedActionSnackbar(
        isNewInvolvement ? 'add' : 'edit',
        isNewInvolvement ? 'Respondent' : 'Respondent Involvement',
        isSetInvolvementError,
        resetSetInvolvementMutation,
        setRespondentToWorkItemsError
    );
    const [respondentId, setRespondentId] = useState('');
    const [selectedWorkItemIds, setSelectedWorkItemIds] = useState<string[]>([]);
    useEffect(() => {
        if (selectedRespondentId) {
            getRespondentInvolvementWorkItems({
                studyId: studyId,
                respondentId: selectedRespondentId,
            });
        }
    }, [getRespondentInvolvementWorkItems, selectedRespondentId, studyId]);
    useEffect(() => {
        if (currentRespondentInvolvement) {
            setSelectedWorkItemIds(currentRespondentInvolvement.pageResults.map((wi) => wi.id));
        }
    }, [currentRespondentInvolvement]);
    const handleSelectedRespondentChange = (respondent?: RespondentDto) => setRespondentId(respondent?.id ?? '');
    const handleToggleWorkItem = useCallback(
        (workItemId: string) => {
            var isCurrentlySelected = selectedWorkItemIds.includes(workItemId);
            if (isCurrentlySelected) {
                setSelectedWorkItemIds((currentItems) => currentItems.filter((x) => x !== workItemId));
            } else {
                setSelectedWorkItemIds((currentItems) => [...currentItems, workItemId]);
            }
        },
        [selectedWorkItemIds]
    );

    const isEveryItemChecked = useMemo(() => selectedWorkItemIds.length === studyWorkItems?.totalQueryResults, [selectedWorkItemIds, studyWorkItems]);
    const isAtLeastOneItemChecked = useMemo(() => selectedWorkItemIds.length > 0, [selectedWorkItemIds]);
    const handleToggleSelectAll = useCallback(() => {
        if (!studyWorkItems) {
            return;
        }
        if (isEveryItemChecked) {
            setSelectedWorkItemIds([]);
        } else {
            setSelectedWorkItemIds(studyWorkItems.pageResults.map((workItem) => workItem.id));
        }
    }, [isEveryItemChecked, studyWorkItems]);

    const clearAndClose = useCallback(() => {
        close();
        setRespondentId('');
        setSelectedWorkItemIds([]);
    }, [close]);

    const handleAddClicked = useCallback(() => {
        if (isUpdatingRespondentInvolvement) {
            return;
        }
        setRespondentToWorkItems({
            studyId: companyId,
            respondentId: isNewInvolvement ? respondentId : selectedRespondentId!,
            WorkItemIds: selectedWorkItemIds,
        });
    }, [isNewInvolvement, companyId, isUpdatingRespondentInvolvement, respondentId, selectedRespondentId, selectedWorkItemIds, setRespondentToWorkItems]);

    const renderRespondentInput = useMemo(() => {
        if (selectedRespondentId) {
            return <ReadonlyRespondentName companyId={companyId} respondentId={selectedRespondentId} required />;
        } else {
            return (
                <Box display='flex'>
                    <CompanyRespondentSelect
                        companyId={companyId}
                        selectedRespondentId={respondentId}
                        handleSelectedRespondentChange={handleSelectedRespondentChange}
                        additionalQueryParameters={{
                            notAssignedToStudyId: studyId,
                        }}
                    />
                    <Box>
                        <RespondentQuickAdd companyId={companyId} afterCreate={handleSelectedRespondentChange} />
                    </Box>
                </Box>
            );
        }
    }, [selectedRespondentId, companyId, respondentId, studyId]);

    const renderWorkItemRow = useCallback(
        (item: WorkItemDto): JSX.Element => {
            return (
                <ListItem key={item.id} component='div' disablePadding dense>
                    <ListItemButton onClick={() => handleToggleWorkItem(item.id)} dense>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                checked={selectedWorkItemIds.includes(item.id)}
                                tabIndex={-1}
                                disableRipple
                                size='small'
                                sx={{ height: 16 }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            );
        },
        [handleToggleWorkItem, selectedWorkItemIds]
    );

    const isLoading = useMemo(
        () => isLoadingStudyWorkItems || isLoadingCurrentRespondentInvolvement,
        [isLoadingStudyWorkItems, isLoadingCurrentRespondentInvolvement]
    );
    const isValid = useMemo(() => {
        if (isNewInvolvement) {
            return respondentId && selectedWorkItemIds.length > 0;
        } else {
            return !!selectedRespondentId;
        }
    }, [isNewInvolvement, respondentId, selectedWorkItemIds, selectedRespondentId]);

    return (
        <>
            <Dialog open={isOpen} onClose={close} fullWidth maxWidth='md'>
                <StandardDialogHeader icon={<People />} text={isNewInvolvement ? 'Add Respondents' : 'Edit Respondent Involvement'} />
                <DialogContent sx={{ marginTop: 3 }}>
                    <FormLabel>Respondent</FormLabel>
                    {renderRespondentInput}
                    <Box height={16} />
                    <FormLabel>Associated Components</FormLabel>
                    <FormSection sx={{ maxHeight: 240, overflowY: 'auto' }}>
                        {isLoading && <LoadingIndicator />}
                        {!isLoading && studyWorkItems && (
                            <ListItem key='study-work-item-select-all' component='div' disablePadding dense>
                                <ListItemButton onClick={() => handleToggleSelectAll()} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge='start'
                                            checked={isEveryItemChecked}
                                            indeterminate={!isEveryItemChecked && isAtLeastOneItemChecked}
                                            tabIndex={-1}
                                            disableRipple
                                            size='small'
                                            sx={{ height: 16 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary='Select All' />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {!isLoading && studyWorkItems?.pageResults.map(renderWorkItemRow)}
                    </FormSection>
                </DialogContent>
                <DialogActions sx={{ py: 2 }}>
                    <Grid container direction='row' justifyContent='end'>
                        <Grid item>
                            <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={clearAndClose}>
                                Close
                            </Button>
                            <Button
                                variant='contained'
                                size='medium'
                                onClick={handleAddClicked}
                                disabled={isUpdatingRespondentInvolvement || !isValid}
                                sx={{ color: 'error', backgroundColor: 'primary' }}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};
