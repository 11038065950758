import { createContext } from 'react';
import { CompanyDto } from '../../../dtos';
import { emptyGuid } from '../../../models';

const PLACEHOLDER_METHOD = () => {};

export interface ICompanyDashboardOverviewFilter {
    daysBack: number;
    setDaysBack: (daysBack: number) => void;
}

const CompanyDashboardOverviewFilterDefault: ICompanyDashboardOverviewFilter = {
    daysBack: 90,
    setDaysBack: PLACEHOLDER_METHOD,
};

export interface ICompanyDashboardContext {
    company: CompanyDto;
    searchText: string;
    setSearchText: (searchText: string) => void;
    selectedTab: number;
    setSelectedTab: (tabIndex: number) => void;
    selectedDepartmentId: string;
    setSelectedDepartmentId: (departmentId: string) => void;
    selectedComponentId: string;
    setSelectedComponentId: (componentId: string) => void;
    selectedRespondentId: string;
    setSelectedRespondentId: (respondentId: string) => void;
    overviewFilters: ICompanyDashboardOverviewFilter;
}

const PLACEHOLDER_COMPANY: CompanyDto = {
    name: '',
    shortName: '',
    areaCode: '',
    phone: '',
    userId: '',
    yearEndMonth: 0,
    yearEndDay: 0,
    departments: [],
    id: emptyGuid,
    isActive: true,
    createdOn: new Date(),
    respondentCount: 0
};

export const CompanyDashboardContext = createContext<ICompanyDashboardContext>({
    company: PLACEHOLDER_COMPANY,
    searchText: '',
    setSearchText: PLACEHOLDER_METHOD,
    selectedTab: 0,
    setSelectedTab: PLACEHOLDER_METHOD,
    selectedDepartmentId: '',
    setSelectedDepartmentId: PLACEHOLDER_METHOD,
    selectedComponentId: '',
    setSelectedComponentId: PLACEHOLDER_METHOD,
    selectedRespondentId: '',
    setSelectedRespondentId: PLACEHOLDER_METHOD,
    overviewFilters: CompanyDashboardOverviewFilterDefault,
});
