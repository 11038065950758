import { FC, useContext } from 'react';
import { Schedules } from '../../../../../../Components/Forms/Schedule';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';

export const StudyDashboardSurveysTab: FC = () => {
    const { study, searchText } = useContext(StudyDashboardContext);

    return (
        <>
            {study.company ?
                <Schedules
                    company={study.company}
                    studyId={study.id}
                    searchText={searchText}
                /> :
                <></>}
        </>
    );
};