import { FormControl, FormLabel, Select, Grid, MenuItem, OutlinedInput, SelectChangeEvent, FormHelperText } from "@mui/material";
import { FC } from "react";
import { IVerifyRespondentsStepOneProps } from './types';
import { useEffect } from "react";



export const VerifyRespondentsStepOne: FC<IVerifyRespondentsStepOneProps> = props => {
    const { respondents, respondent, setRespondent, representatives, representative, setRepresentative, taxPeriod, setTaxPeriod, setIsValid, validate, fieldErrors, isVerifyVisible } = props;

    

    useEffect(() => {
        const isValid = () => {
            let isValid = validate('TAX_PERIOD');
            isValid = validate('RESPONDENT') && isValid;
            isValid = validate('REPRESENTATIVE') && isValid;
            setIsValid(isValid);
        };

        isValid();

    }, [isVerifyVisible, respondent, representative, taxPeriod, validate, setIsValid]);

    const validateField = (fieldName: string) => () => {
        validate(fieldName);
    };

    const handleRespondentChange = (event: SelectChangeEvent) => {
        setRespondent(respondents?.pageResults.find(x => x.id === event.target.value));
    };

    const handleRepresentativeChange = (event: SelectChangeEvent) => {
        setRepresentative(representatives?.find(x => x.id === event.target.value));
    };

    const handleTaxPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTaxPeriod(event.target.value);
    };

    return (
        <Grid container direction="column" justifyContent={"space-between"} spacing={3} >

            <Grid item container direction="row" xs={12} spacing={3}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel>To</FormLabel>
                        <Select onChange={handleRespondentChange} onBlur={validateField('RESPONDENT')} value={respondent ? respondent.id : ''}>
                            {respondents ? respondents.pageResults.map((r) => (
                                <MenuItem key={r.id} value={r.id}>
                                    {r.firstName} {r.lastName}{r.isContactOnly ? " (Point of Contact)" : ""}
                                </MenuItem>
                            )) : []}
                        </Select>
                        <FormHelperText>{fieldErrors.RESPONDENT}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel>Reply To</FormLabel>
                        <Select onChange={handleRepresentativeChange} onBlur={validateField('REPRESENTATIVE')} value={representative ? representative.id : ''}>
                            {representatives ? representatives.map((r) => (
                                <MenuItem key={r.id} value={r.id}>
                                    {r.displayName}
                                </MenuItem>
                            )) : []}
                        </Select>
                        <FormHelperText>{fieldErrors.REPRESENTATIVE}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container direction="row" xs={12} spacing={3}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel>Tax Period</FormLabel>
                        <OutlinedInput value={taxPeriod} onChange={handleTaxPeriodChange} onBlur={validateField('TAX_PERIOD')} />
                        <FormHelperText>{fieldErrors.TAX_PERIOD}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>

        </Grid >
    );
}