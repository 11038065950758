import { snakeCaseToPrint } from "../../textFormatters";
import { BaseMessageType } from "./BaseMessageType";

export class FallbackMessage extends BaseMessageType {
    getMessage(): JSX.Element[] {
        const { eventType, createdByUser } = this._streamEntry;

        const formattedEventType = snakeCaseToPrint(eventType);
        const indexOfLastSpace = formattedEventType.lastIndexOf(' ');
        const noun = formattedEventType.slice(0, indexOfLastSpace);
        const action = formattedEventType.slice(indexOfLastSpace);

        return this.generateMessageFromPayload({
            noun: noun,
            pastTenseAction: action,
            performedBy: `${createdByUser.firstName} ${createdByUser.lastName}`,
            performedByRoute: `/users/${createdByUser.id}`
        });
    }
}