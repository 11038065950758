import { Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../Components/CoreLib/library';
import { RespondentForm } from '../../../Components/Forms/CompanyForm/RespondentForm';
import { RespondentDto } from '../../../dtos';
import { useCreateRespondentMutation } from '../../../store';

export const CreateRespondentView: FC = () => {
    const { id: companyId } = useParams();
    const navigate = useNavigate();
    const [createRespondent, { isSuccess, isError, reset }] = useCreateRespondentMutation();

    useSuccessfulCreateSnackbar('respondent', isSuccess, () => {
        reset();
        navigate(`/companies/${companyId}`);
    });
    useFailedCreateSnackbar('respondent', isError, reset);

    const handleSave = (respondent: RespondentDto) => {
        createRespondent(respondent);
    }

    const handleCancel = () => {
        navigate(`/companies/${companyId}`);
    }

    return (
        <>
            <Grid container direction="column">
                <Grid item xs={4} marginBottom={{ xs: 0, md: 0, lg: -8 }}>
                    <RespondentForm companyId={companyId!} save={handleSave} cancel={handleCancel} />
                </Grid>
            </Grid>
        </>
    );
}