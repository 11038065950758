import { Person } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC, useRef } from 'react';
import { RespondentDto } from '../../../../../dtos';
import { useCreateRespondentMutation } from '../../../../../store';
import { StandardDialogHeader } from '../../../../CommonComponents';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../../CoreLib/library';
import { RespondentForm } from '../RespondentForm';

export interface IAddRespondentModalProps {
    companyId: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    afterCreate?: (savedRecord: RespondentDto) => void;
}

export const AddRespondentModal: FC<IAddRespondentModalProps> = ({ companyId, isVisible, setIsVisible, afterCreate}) => {
    const formSave = useRef<() => void>();
    const [createRespondent, { isSuccess, isLoading, isError, reset }] = useCreateRespondentMutation();

    const handleSuccessfulCreate = () => {
        reset();
        setIsVisible(false);
    };
    useSuccessfulCreateSnackbar('respondent', isSuccess, handleSuccessfulCreate);
    useFailedCreateSnackbar('respondent', isError, reset);

    const afterSave = (respondent: RespondentDto) => {
        createRespondent(respondent).unwrap().then(afterCreate);
    };

    const handleSave = () => {
        if (formSave.current) {
            formSave.current();
        }
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <Dialog fullWidth maxWidth='xl' open={isVisible} onClose={handleClose}>
            <StandardDialogHeader icon={<Person />} text='Add Respondent' />
            <DialogContent sx={{ mt: 2 }}>
                <RespondentForm companyId={companyId} save={afterSave} isModal={true} doSaveRef={formSave} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>
                    Cancel
                </Button>
                <LoadingButton loading={isLoading} onClick={handleSave} variant='contained' color='primary'>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
