import { FC, useContext } from 'react';
import { CompanyDashboardContext } from '../../../../Utils';
import { Paper } from '@mui/material';
import { ActivityStreamTable } from '../../../../../../Components/Forms';

export const CompanyDashboardStreamTab: FC = () => {
    const { company } = useContext(CompanyDashboardContext);

    return (
        <Paper elevation={0}>
            <ActivityStreamTable
                hideHeader
                hideSearch
                hideFilterButton
                isBorderVisible={false}
                hideDateDropdownFilter={false}
                headerIcon={<></>}
                headerVariant='h1'
                headerAlignment='start'
                companyId={company.id}
                initialDaysBack='90'
            />
        </Paper>
    );
};
