import { People } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { QueryResultsDto, RespondentDto } from '../../../../dtos';
import { PaginatedProps, DataTableColumn, PaginatedDataTable } from '../../../CoreLib/library';
import { SurveyBuilderContext } from '../../utils';

interface IDistributionCCBCCTableProps {
    stepThreeRespondentData: QueryResultsDto<RespondentDto>;
    isLoading: boolean;
    paginatedProps: PaginatedProps | null;
    setPaginatedProps: (pagProps: PaginatedProps | null) => void;
    addMultipleDistributionConfig: (arrayName: string, respondent: RespondentDto[]) => void;
}

export const DistributionCCBCCTable: FC<IDistributionCCBCCTableProps> = (props) => {
    const { stepThreeRespondentData, isLoading, setPaginatedProps, addMultipleDistributionConfig } = props;
    const { surveyPayload } = useContext(SurveyBuilderContext);

    const tableColumns: DataTableColumn<RespondentDto>[] = [
        { key: 'firstName', label: 'First Name', sortKey: 'FIRST_NAME' },
        { key: 'lastName', label: 'Last Name', sortKey: 'LAST_NAME' },
        { key: 'title', label: 'Title', sortKey: 'TITLE' },
        { key: 'department', label: 'Department', sortKey: 'DEPARTMENT', fieldMapper: (row: RespondentDto) => row.department?.name },
        { key: 'manager', label: 'Manager', sortKey: 'MANAGER', fieldMapper: (row: RespondentDto) => row.manager ? `${row.manager?.firstName} ${row.manager?.lastName}` : '' }
    ];
    const [selectedRespondents, setSelectedRespondents] = useState<RespondentDto[]>([]);

    const handleToButtonEnabled = () => {
        if (selectedRespondents.length === 1) {
            if (surveyPayload.distributionConfig.individualRecipient.length === 0 && surveyPayload.distributionConfig.surveyDistribution === "individualRecipient") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    return (
        <Box sx={{
            backgroundColor: 'primary.contrastText',
            py: 4,
            px: 4
        }}>
            <Grid container direction="column" spacing={3}>
                <Grid item container direction="row" alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="h1">
                            <People /> Results
                        </Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end" alignItems="flex-end">
                        <Button
                            variant="contained"
                            disabled={!handleToButtonEnabled()}
                            onClick={() => addMultipleDistributionConfig("individualRecipient", selectedRespondents)}
                        >
                            To
                        </Button>
                        <Button
                            variant="contained"
                            disabled={selectedRespondents.length < 1}
                            sx={{ mx: 2 }}
                            onClick={() => addMultipleDistributionConfig("CCRecipient", selectedRespondents)}
                        >
                            CC
                        </Button>
                        <Button
                            variant="contained"
                            disabled={selectedRespondents.length < 1}
                            onClick={() => addMultipleDistributionConfig("BCCRecipient", selectedRespondents)}
                        >
                            BCC
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={isLoading}
                        queryResults={stepThreeRespondentData}
                        defaultSortKey="FIRST_NAME"
                        setPagination={setPaginatedProps}
                        selectedRecords={selectedRespondents}
                        setSelectedRecords={setSelectedRespondents as any}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}