import { Stack } from '@mui/material';
import { FC } from 'react';
import { EmailTemplateDto } from '../../../dtos';
import { EmailEmailTemplateCard, EmailReminderCard } from './SubComponents';

interface IEmailStepContentsProps {
    emailTemplates: EmailTemplateDto[]
}

export const EmailStepContent: FC<IEmailStepContentsProps> = (props) => {
    const { emailTemplates } = props;

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <EmailEmailTemplateCard emailTemplates={emailTemplates} />
            <EmailReminderCard />
        </Stack>
    );
}