import { GridOn } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { LoadingIndicator, PaginatedProps, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { ScheduleTableContext } from '../../Components/Forms/Schedule/ScheduleTableContext';
import { SchedulesTable } from '../../Components/Forms/Schedule/SchedulesTable';
import { useGetScheduleTableHeaders } from '../../Components/Forms/Schedule/utils/useGetScheduleTableHeaders';
import { useGetBusinessComponentSchedulesQuery } from '../../store/api/schedule-api';
import { DataFilterContext } from '../../Contexts';

export const BusinessComponentScheduleListView: FC = () => {
    const [searchText, setSearchText] = useState('');
    const [showOnlyExpired, setShowOnlyExpired] = useState(false);

    const {
        showInactive,
        scheduledFromDateFilter: fromDateFilter,
        scheduledToDateFilter: toDateFilter,
        periodStartDateFilter,
        periodEndDateFilter,
        statusFilter,
        reminderFilter,
        approvedByFilter
    } = useContext(DataFilterContext);

    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>({
        sortKey: "SCHEDULED",
        sortAsc: false,
        page: 0,
        pageSize: 25
    });
    const { data, isLoading, isError, refetch } = useGetBusinessComponentSchedulesQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
        isExpired: showOnlyExpired,
        fromDate: fromDateFilter?.toISOString(),
        toDate: toDateFilter?.toISOString(),
        status: statusFilter ? encodeURIComponent(statusFilter) : undefined,
        reminder: reminderFilter ? encodeURIComponent(reminderFilter) : undefined,
        approvedBy: approvedByFilter ? encodeURIComponent(approvedByFilter) : undefined,
        periodStartDate: periodStartDateFilter?.toISOString(),
        periodEndDate: periodEndDateFilter?.toISOString()
    });

    useFailedActionSnackbar('retrieve', 'business component matrices', isError)

    const { tableHeaders } = useGetScheduleTableHeaders({
        hideFormType: true,
        hideScheduleDetailStatusCounts: true,
    });

    return (
        <ScheduleTableContext.Provider value={{
            searchText,
            setSearchText,
            paginatedProps,
            setPaginatedProps,
            showOnlyExpired,
            setShowOnlyExpired,
            surveyAlias: 'Business Component Matrix',
            surveyAliasShort: 'BCM'
        }}>
            {!isLoading ? (
                <Grid container direction="column">
                    <Grid item container direction="column" spacing={3}>
                        <Grid item>
                            {!isLoading &&
                                <SchedulesTable
                                    title='Business Component Matrices'
                                    icon={<GridOn />}
                                    breadcrumbs={[{
                                        label: 'Home',
                                        navLink: '/'
                                    }]}
                                    schedules={data!}
                                    tableColumns={tableHeaders}
                                    isLoading={isLoading}
                                    refetch={refetch}
                                    editRoute='business-component'
                                    detailsRoute='business-component'
                                    hideActiveExpiredTabs
                                    hidePauseResumeControls
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            ) : <LoadingIndicator />}
        </ ScheduleTableContext.Provider>
    );
}