import { FormControl, FormLabel, Select, MenuItem, FormHelperText, CircularProgress, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { RespondentDto } from '../../dtos';
import { useGetCompanyManagersQuery } from '../../store';

export interface IManagerSelectProps {
    companyId: string;
    selectedManagerId: string;
    handleSelectedManagerChange: (selectedManager?: RespondentDto) => void;
}

export const ManagerSelect: FC<IManagerSelectProps> = props => {
    const { companyId, selectedManagerId, handleSelectedManagerChange } = props;
    const { data: managers, isLoading: isLoadingManagers, isError: isErrorLoadingManagers } = useGetCompanyManagersQuery(companyId);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const selectedRecord = managers?.find(x => x.id === event.target.value);
        handleSelectedManagerChange(selectedRecord);
    }

    return (
        <FormControl error={isErrorLoadingManagers} fullWidth>
            <FormLabel>Manager</FormLabel>
            <Select
                value={selectedManagerId}
                onChange={handleSelectChange}
                endAdornment={(isLoadingManagers && <div><CircularProgress size={24} /></div>)}
            >
                <MenuItem key='none' value='' sx={{ height: '36px' }}></MenuItem>
                {managers?.map((manager) => (
                    <MenuItem key={manager.id} value={manager.id}>
                        {manager.firstName} {manager.lastName}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{isErrorLoadingManagers ? 'Failed to load manager options' : ''}</FormHelperText>
        </FormControl>
    )
}