import { Masonry } from '@mui/lab';
import { Grid, styled } from '@mui/material';
import MuiPaper from '@mui/material/Paper';
import { FunctionComponent } from "react";
import { ActivityStreamWidget, AddNewCompany, ExportSurveys, ImportRespondents, StartNewSurvey } from "../Components/Forms/Dashboard";

const DashboardCard = styled(MuiPaper)(
    () => ({
        border: 'solid 1px #AAAAAA',
        padding: 24,
        margin: 0
    })
);

export const DashboardView: FunctionComponent = () => {

    return (
        <Grid container direction="column">
            <Grid item xs={12} marginRight='-24px'>
                <Masonry columns={[1, 2, 3]} spacing={3}>
                    <DashboardCard variant="outlined" square >
                        <StartNewSurvey />
                    </DashboardCard>
                    <DashboardCard variant="outlined" square >
                        <AddNewCompany />
                    </DashboardCard>
                    <DashboardCard variant="outlined" square >
                        <ExportSurveys />
                    </DashboardCard>
                    <DashboardCard variant="outlined" square >
                        <ImportRespondents />
                    </DashboardCard>
                </Masonry>
            </Grid>
            <Grid item xs={12}>
                <DashboardCard variant="outlined" square >
                    <ActivityStreamWidget />
                </DashboardCard>
            </Grid>
        </Grid>
    );
}