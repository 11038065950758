import { Upload } from '@mui/icons-material';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import Papa from 'papaparse';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ImportRespondentsStepTwoProps } from '.';
import { DataTableColumn, LocalDataTable } from '../../CoreLib/library';
import { ImportRespondentRowDto } from '../../../dtos';

export const ImportRespondentsStepTwo: FunctionComponent<ImportRespondentsStepTwoProps> = (props) => {
	const { selectedFile, parsedFileData: csvFileData, setParsedFileData: setCsvFileData, setIsReadyForNext, setAddNewDepartments, addNewDepartments } = props;
	const [isFileValid, setIsFileValid] = useState<boolean>();
	const [isParsingData, setIsParsingData] = useState<boolean>(false);
	const [isParseComplete, setIsParseComplete] = useState<boolean>(false);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [goodRecords, setGoodRecords] = useState<number>(0);
	const [badRecords, setBadRecords] = useState<number>(0);

	const mapStatus = (row: any) => {
		return row.status !== 'Good' ? <Typography sx={{ color: '#DF623B' }}>{row.status}</Typography> : row.status;
	};
	const tableColumns: DataTableColumn<ImportRespondentRowDto>[] = [
		{ key: 'firstName', label: 'First Name', sortKey: 'FIRSTNAME' },
		{ key: 'lastName', label: 'Last Name', sortKey: 'LASTNAME' },
		{ key: 'areaCode', label: 'Area Code', sortKey: 'AREACODE' },
		{ key: 'phone', label: 'Phone', sortKey: 'PHONE' },
		{ key: 'email', label: 'Email', sortKey: 'EMAIL' },
		{ key: 'department', label: 'Department', sortKey: 'DEPARTMENT' },
		{ key: 'isPointOfContactYN', label: 'POC', sortKey: 'POINT_OF_CONTACT' },
		{ key: 'formType', label: 'Form Type', sortKey: 'FORMTYPE' },
		{ key: 'Status', label: 'Status', sortKey: 'STATUS', fieldMapper: mapStatus },
	];

	const headersToCamelCase = (header: string) => {
		return header.slice(0, 1).toLowerCase() + header.slice(1, header.length);
	};

	const toggleAddNewDepartments = () => {
		setAddNewDepartments(!addNewDepartments);
	}

	const parseTable = useCallback(() => {
		if (selectedFile !== undefined) {
			Papa.parse<ImportRespondentRowDto>(selectedFile, {
				header: true,
				transformHeader: headersToCamelCase,
				complete: (results) => {
					let requiredHeaders: string[] = ['firstName', 'lastName', 'areaCode', 'phone', 'email', 'isPointOfContactYN'];
					let isFileValid = requiredHeaders.every((requiredHeader) => {
						return results.meta.fields?.includes(requiredHeader);
					});
					let recordsInError = 0;
					const validatedData = results.data.map((data) => {
						data.status = 'Good';
						data.isValid = false;
						if (data.firstName === '') {
							data.status = 'Missing First Name';
							data.isValid = false;
							recordsInError++;
						} else if (data.lastName === '') {
							data.status = 'Missing Last Name';
							data.isValid = false;
							recordsInError++;
						}
						//regex checks for any non-digits in the area code
						else if ((data.areaCode?.length !== 0 && data.areaCode?.length !== 3) || data.areaCode.match(/\D/g)) {
							data.status = 'Area Code Not 3 Digits';
							data.isValid = false;
							recordsInError++;
						} else if (data.email === '') {
							data.status = 'Missing Email';
							data.isValid = false;
							recordsInError++;
						} else if (
							!(data.isPointOfContactYN?.toLocaleUpperCase() === 'YES' || data.isPointOfContactYN?.toLocaleUpperCase() === 'Y') &&
							data.formType === ''
						) {
							data.status = 'Missing Form Type';
							data.isValid = false;
							recordsInError++;
						}
						data.isManagerYN?.toUpperCase() === 'YES' || data.isManagerYN === 'Y' ? (data.isManager = true) : (data.isManager = false);
						data.isPointOfContactYN?.toUpperCase() === 'YES' || data.isPointOfContactYN === 'Y'
							? (data.isPointOfContact = true)
							: (data.isPointOfContact = false);
						return data;
					});
					setIsFileValid(isFileValid);
					setCsvFileData(validatedData);
					setTotalRecords(validatedData.length);
					setGoodRecords(validatedData.length - recordsInError);
					setBadRecords(recordsInError);
					setIsParsingData(false);
					setIsParseComplete(true);
					setIsReadyForNext(isFileValid);
				},
				error: () => {
					setCsvFileData(null);
					setIsParsingData(true);
				},
			});
		}
	}, [selectedFile, setCsvFileData, setIsReadyForNext]);

	useEffect(() => {
		if (isParseComplete) {
			return;
		}
		if (!isParsingData) {
			parseTable();
			setIsParsingData(true);
		}
	}, [parseTable, isParsingData, isParseComplete]);

	if (!isFileValid) {
		return (
			<Typography
				sx={{
					color: '#DF623B',
					fontWeight: 'bold',
				}}>
				Error: Selected file is not of the correct type or contains invalid header information.
			</Typography>
		);
	}

	return (
		<Grid container direction='column' justifyContent={'space-between'}>
			<Grid container item direction='row' xs={12}>
				<Grid item>
					<Upload />
				</Grid>
				<Grid item>
					<Typography>{selectedFile?.name}</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={12}  alignItems='center'>
				<Grid container xs={6} item justifyContent={'flex-start'}>
					<Typography>Total records found: {totalRecords}</Typography>
					<Typography ml={5}>Good: {goodRecords}</Typography>
					<Typography ml={5} sx={{ color: '#DF623B' }}>
						Error: {badRecords}
					</Typography>
				</Grid>
				<Grid container item xs={6} justifyContent={'flex-end'}>
					<FormControlLabel
						value="start"
						control={<Switch color="primary" onChange={toggleAddNewDepartments} checked={addNewDepartments} />}
						label="Create departments if they don't already exist"
						labelPlacement="start"
					/>
				</Grid>
			</Grid>
			<Grid item xs={4}>
				{!isParsingData && <LocalDataTable columns={tableColumns} loading={isParsingData} rowData={csvFileData} />}
			</Grid>
		</Grid>
	);
};
