import _ from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { DataFilterContext } from '../../../Contexts';

export interface RespondentFilters {
    firstName: string;
    lastName: string;
    title: string;
    departmentId: string;
    isNotAssignedToDepartment: boolean;
    managerId: string;
    formTypeId: string;
}

export const DEFAULT_RESPONDENT_FILTERS: RespondentFilters = {
    firstName: '',
    lastName: '',
    title: '',
    departmentId: '',
    isNotAssignedToDepartment: false,
    managerId: '',
    formTypeId: ''
};

export function useRespondentFiltersDialogManager() {
    const [dialogFilters, setDialogFilters] = useState<RespondentFilters>(DEFAULT_RESPONDENT_FILTERS);
    const {
        firstNameFilter,
        setFirstNameFilter,
        lastNameFilter,
        setLastNameFilter,
        titleFilter,
        setTitleFilter,
        departmentIdFilter,
        setDepartmentIdFilter,
        isNotAssignedToDepartmentFilter,
        setIsNotAssignedToDepartmentFilter,
        managerIdFilter,
        setManagerIdFilter,
        formTypeFilter,
        setFormTypeFilter,
    } = useContext(DataFilterContext);

    const appliedFilters = useMemo<RespondentFilters>(
        () => ({
            firstName: firstNameFilter ?? '',
            lastName: lastNameFilter ?? '',
            title: titleFilter ?? '',
            departmentId: departmentIdFilter ?? '',
            isNotAssignedToDepartment: isNotAssignedToDepartmentFilter ?? false,
            managerId: managerIdFilter ?? '',
            formTypeId: formTypeFilter ?? '',
        }),
        [firstNameFilter, lastNameFilter, titleFilter, departmentIdFilter, isNotAssignedToDepartmentFilter, managerIdFilter, formTypeFilter]
    );

    const handleDialogFilterValueChange = useCallback(
        (fieldName: keyof RespondentFilters, value: any) => {
            let updatedFilterValues = _.cloneDeep(dialogFilters);
            (updatedFilterValues[fieldName] as any) = value;
            if (fieldName === 'departmentId' && !!value && updatedFilterValues.isNotAssignedToDepartment) {
                updatedFilterValues.isNotAssignedToDepartment = false;
            }
            setDialogFilters(updatedFilterValues);
        },
        [dialogFilters]
    );

    const applyDialogFilters = useCallback(() => {
        setFirstNameFilter(dialogFilters.firstName);
        setLastNameFilter(dialogFilters.lastName);
        setTitleFilter(dialogFilters.title);
        setDepartmentIdFilter(dialogFilters.departmentId);
        setIsNotAssignedToDepartmentFilter(dialogFilters.isNotAssignedToDepartment);
        setManagerIdFilter(dialogFilters.managerId);
        setFormTypeFilter(dialogFilters.formTypeId);
    }, [dialogFilters, setFirstNameFilter, setLastNameFilter, setTitleFilter, setDepartmentIdFilter, setIsNotAssignedToDepartmentFilter, setManagerIdFilter, setFormTypeFilter]);

    const loadAppliedFiltersIntoDialog = useCallback(() => {
        setDialogFilters(appliedFilters);
    }, [appliedFilters]);

    const isFormDirty = useMemo(() => JSON.stringify(appliedFilters) !== JSON.stringify(dialogFilters), [appliedFilters, dialogFilters]);

    const setNoDepartmentFilter = (isChecked: boolean) => {
        var updatedFilters = _.cloneDeep(dialogFilters);
        updatedFilters.isNotAssignedToDepartment = isChecked;
        if (isChecked) {
            updatedFilters.departmentId = '';
        }
        setDialogFilters(updatedFilters);
    };

    return {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty,
        setNoDepartmentFilter
    };
}
