import { Work } from '@mui/icons-material';
import { Box, Checkbox, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { BusinessComponentDto, EmployeeDto, StudyDto, WorkItemDescriptionDisplayMode, WorkItemDto } from '../../../../../dtos';
import { emptyGuid } from '../../../../../models';
import { WorkItemSelect } from '../../../../CommonInputs/WorkItemSelect';
import { WorkItemQuickAdd } from '../../../../QuickAdds';
import { StandardDialog } from '../../../../StandardDialog';

export interface BusinessComponentDetails {
    businessComponent: BusinessComponentDto;
    associatedEmployees: string[];
}

export interface IAddEditBusinessComponentModalProps {
    handleUpdate: (businessComponentDetails: BusinessComponentDetails) => void;
    allEmployees: EmployeeDto[];
    alreadyAddedWorkItemIds: string[];
    study: StudyDto;
    businessComponentDetails?: BusinessComponentDetails;
    onClose: () => void;
}

export const AddEditBusinessComponentModal: FC<IAddEditBusinessComponentModalProps> = ({
    handleUpdate,
    allEmployees,
    alreadyAddedWorkItemIds,
    study,
    businessComponentDetails,
    onClose,
}) => {
    const [businessComponent, setBusinessComponent] = useState<BusinessComponentDto>();
    const [associatedEmployees, setAssociatedEmployees] = useState<string[]>();

    useEffect(() => {
        const isProvisioningDataProvided = !!businessComponentDetails;
        const isModalProvisioned = !!businessComponent && !!associatedEmployees;
        if (isProvisioningDataProvided && !isModalProvisioned) {
            setBusinessComponent(_.cloneDeep(businessComponentDetails.businessComponent));
            setAssociatedEmployees(_.cloneDeep(businessComponentDetails.associatedEmployees));
        } else if (isModalProvisioned && !isProvisioningDataProvided) {
            setBusinessComponent(undefined);
            setAssociatedEmployees(undefined);
        }
    }, [businessComponent, associatedEmployees, businessComponentDetails]);

    const isNewRecord = useMemo(() => {
        return businessComponent?.id === emptyGuid;
    }, [businessComponent]);

    const handleSelectedBusinessComponentChange = useCallback(
        (selectedBusinessComponent?: WorkItemDto) => {
            setBusinessComponent({
                id: selectedBusinessComponent?.id ?? '',
                name: selectedBusinessComponent?.name ?? '',
                description: businessComponent?.description ?? '',
                descriptionDisplayMode: businessComponent?.descriptionDisplayMode ?? WorkItemDescriptionDisplayMode.Show
            });
        },
        [businessComponent]
    );

    const isError = useMemo(() => {
        return !businessComponent;
    }, [businessComponent]);

    const handleConfirm = useCallback(() => {
        if (!businessComponent || businessComponent?.id === emptyGuid || !associatedEmployees || isError) {
            return;
        }
        handleUpdate({ businessComponent, associatedEmployees });
    }, [businessComponent, associatedEmployees, handleUpdate, isError]);

    const handleToggleEmployee = useCallback(
        (empId: string) => {
            if (!associatedEmployees) {
                return;
            }
            const isCurrentlyChecked = associatedEmployees.includes(empId);
            if (isCurrentlyChecked) {
                setAssociatedEmployees([...associatedEmployees].filter((e) => e !== empId));
            } else {
                setAssociatedEmployees([...associatedEmployees, empId]);
            }
        },
        [associatedEmployees]
    );

    const isEveryItemChecked = useMemo(() => {
        return associatedEmployees?.length === allEmployees.length;
    }, [associatedEmployees, allEmployees]);

    const isAtLeastOneItemChecked = useMemo(() => {
        return (associatedEmployees?.length ?? 0) > 0;
    }, [associatedEmployees]);

    const handleToggleSelectAll = useCallback(() => {
        if (isEveryItemChecked) {
            setAssociatedEmployees([]);
        } else {
            setAssociatedEmployees(allEmployees.map((emp) => emp.id));
        }
    }, [isEveryItemChecked, allEmployees]);

    const renderRow = useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;
            if (index === 0) {
                return (
                    <ListItem style={style} key={index} component='div' disablePadding>
                        <ListItemButton onClick={() => handleToggleSelectAll()} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge='start'
                                    checked={isEveryItemChecked}
                                    indeterminate={!isEveryItemChecked && isAtLeastOneItemChecked}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary='Select All' />
                        </ListItemButton>
                    </ListItem>
                );
            }

            const rowEmployee = allEmployees[index - 1];

            return (
                <ListItem style={style} key={index} component='div' disablePadding>
                    <ListItemButton onClick={() => handleToggleEmployee(rowEmployee.id)} dense>
                        <ListItemIcon>
                            <Checkbox edge='start' checked={associatedEmployees?.includes(rowEmployee.id)} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={rowEmployee.name} />
                    </ListItemButton>
                </ListItem>
            );
        },
        [allEmployees, associatedEmployees, handleToggleEmployee, handleToggleSelectAll, isEveryItemChecked, isAtLeastOneItemChecked]
    );

    return (
        <StandardDialog
            isVisible={!!businessComponent}
            onClose={onClose}
            icon={<Work />}
            title={isNewRecord ? 'New Business Component' : 'Edit Business Component'}
            confirmAction={handleConfirm}
            confirmText='Save'>
            <Grid container direction='column'>
                <Grid item display='flex' sx={{ mt: 1, mb: 2 }}>
                    <WorkItemSelect
                        label='Business Component'
                        studyId={study.id}
                        selectedWorkItemId={businessComponent?.id ?? ''}
                        handleSelectedWorkItemChange={handleSelectedBusinessComponentChange}
                        required
                        errorMessage={isError ? 'Business Component Required' : undefined}
                        itemFilter={(r) => !alreadyAddedWorkItemIds.includes(r.id)}
                    />
                    <Box pt={3}>
                        <WorkItemQuickAdd study={study} afterCreate={handleSelectedBusinessComponentChange} />
                    </Box>
                </Grid>
                <FixedSizeList
                    height={400}
                    width={'100%'}
                    itemSize={46}
                    itemCount={allEmployees.length + 1}
                    overscanCount={6}
                    style={{ border: '1px solid #cbcbcb', borderRadius: 8 }}>
                    {renderRow}
                </FixedSizeList>
            </Grid>
        </StandardDialog>
    );
};
