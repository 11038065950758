import { Grid, FormControl, FormLabel, Typography } from '@mui/material';
import { FC } from 'react';
import { onlyShowOnDesktop, onlyShowOnMobile } from '../util';

export interface IResponsiveSurveyHeaderProps {
    label: string;
    value: string;
}

export const ResponsiveSurveyHeader: FC<IResponsiveSurveyHeaderProps> = props => {
    const {label, value} = props;

    if (!value) {
        return null;
    }

    return (
        <Grid item xs={12} sm={2}>
            <FormControl fullWidth sx={onlyShowOnDesktop}>
                <FormLabel sx={{ fontWeight: 'bold' }}>{label}</FormLabel>
                <Typography>{value}</Typography>
            </FormControl>
            <Typography sx={onlyShowOnMobile}><span style={{ fontWeight: 'bold' }}>{label}:</span> {value}</Typography>
        </Grid>
    )
}