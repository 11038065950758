import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { Dispatch, FC, useCallback, useMemo } from 'react';
import { ScheduleDetailDto, ScheduleRespondentAnswerDto, SurveyPayloadDto } from '../../../../../dtos';
import { AddEditBCMEntriesButton, EmployeeBusinessComponentRelationship } from '../AddEditBCMEntriesButton';
import { BCMSurveyRow } from '../BCMSurveyRow';
import { emptyGuid } from '../../../../../models';

const LENGTH_OF_GUID = 36;

export interface IBCMSurveyContentRendererProps {
    isPreview?: boolean;
    scheduleDetail: ScheduleDetailDto;
    respondentAnswers: ScheduleRespondentAnswerDto[];
    setRespondentAnswers: Dispatch<React.SetStateAction<ScheduleRespondentAnswerDto[]>>;
    setQuestionAnswer: (fieldName: string, value: string | number) => void;
    isReadOnly: boolean;
    isSavingAnswers: boolean;
}

export const BCMSurveyContentRenderer: FC<IBCMSurveyContentRendererProps> = ({
    isPreview,
    scheduleDetail,
    respondentAnswers,
    setRespondentAnswers,
    setQuestionAnswer,
    isReadOnly,
    isSavingAnswers,
}) => {
    const businessComponentConfig = useMemo(() => {
        let surveyPayload: SurveyPayloadDto = JSON.parse(scheduleDetail.schedule.survey.surveyPayload);
        return surveyPayload.businessComponentMatrixConfig;
    }, [scheduleDetail]);

    const allEmployees = useMemo(() => {
        return [...businessComponentConfig.employees, ...scheduleDetail.addedRespondents];
    }, [businessComponentConfig, scheduleDetail.addedRespondents]);

    const allBusinessComponents = useMemo(() => {
        return [...businessComponentConfig.businessComponents, ...scheduleDetail.addedBusinessComponents];
    }, [businessComponentConfig, scheduleDetail.addedBusinessComponents]);

    const BCMEntries = useMemo(() => {
        // the key format for BCM answer entries is businessComponentId-RespondentId-[research-activity or information-sought]
        let uniqueEntriesById = _.uniq(respondentAnswers.map((ra) => ra.fieldKey.substring(0, LENGTH_OF_GUID * 2 + 1)));
        let allEntries = uniqueEntriesById
            .map((uniqKeySegment) => {
                let businessComponentId = uniqKeySegment.substring(0, LENGTH_OF_GUID);
                let respondentId = uniqKeySegment.substring(LENGTH_OF_GUID + 1);
                return {
                    key: uniqKeySegment,
                    businessComponent: allBusinessComponents.find((bc) => bc.id === businessComponentId)!,
                    respondent: allEmployees.find((emp) => emp.id === respondentId)!,
                    researchActivityEntry: respondentAnswers.find((ra) => ra.fieldKey === `${uniqKeySegment}-research-activity`)!,
                    informationSoughtEntry: respondentAnswers.find((ra) => ra.fieldKey === `${uniqKeySegment}-information-sought`)!,
                };
            })
            .sort((a, b) =>
                // this sorts by business component name and then by respondent name by checking to see if the business component name already matches and then checks the respondent name.
                a.businessComponent.name !== b.businessComponent.name
                    ? a.businessComponent.name.localeCompare(b.businessComponent.name)
                    : a.respondent.name.localeCompare(b.respondent.name)
            );
        return allEntries;
    }, [respondentAnswers, allEmployees, allBusinessComponents]);

    const allRelationships = useMemo(() => BCMEntries.map(answerEntry => ({ respondentId: answerEntry.respondent.id, businessComponentId: answerEntry.businessComponent.id })), [BCMEntries]);

    const createNewAnswerEntryWithKey = useCallback((key: string) => ({
        id: emptyGuid,
        createdOn: new Date(),
        isActive: true,
        scheduleDetailId: scheduleDetail.id,
        fieldKey: key,
        answerValue: ''
    }), [scheduleDetail.id]);

    const handleRelationshipsUpdated = useCallback((updatedRelationships: EmployeeBusinessComponentRelationship[]) => {
        let updatedAnswers = _.cloneDeep(respondentAnswers);
        // remove old answer entries
        const removedRelationships = _.differenceBy(allRelationships, updatedRelationships, (relationship) => `${relationship.businessComponentId}-${relationship.respondentId}`);
        removedRelationships.forEach(removedRelationship => {
            updatedAnswers = updatedAnswers.filter(answer => !answer.fieldKey.includes(`${removedRelationship.businessComponentId}-${removedRelationship.respondentId}`));
        });
        // add new answer entries
        const addedRelationships = _.differenceBy(updatedRelationships, allRelationships, (relationship) => `${relationship.businessComponentId}-${relationship.respondentId}`);
        let newAnswerEntries: ScheduleRespondentAnswerDto[] = [];
        addedRelationships.forEach(addedRelationship => {
            newAnswerEntries.push(createNewAnswerEntryWithKey(`${addedRelationship.businessComponentId}-${addedRelationship.respondentId}-research-activity`));
            newAnswerEntries.push(createNewAnswerEntryWithKey(`${addedRelationship.businessComponentId}-${addedRelationship.respondentId}-information-sought`));
        });
        // update stored answers
        updatedAnswers.push(...newAnswerEntries);
        setRespondentAnswers(updatedAnswers);
    }, [allRelationships, respondentAnswers, createNewAnswerEntryWithKey, setRespondentAnswers]);

    return (
        <Grid item container direction='column' spacing={1} mt={3}>
            {!isReadOnly && (
                <Grid item container direction='row' spacing={1} mt={1} mb={3}>
                    <Grid item ml='-8px'>
                        <AddEditBCMEntriesButton
                            handleUpdate={handleRelationshipsUpdated}
                            currentRelationships={allRelationships}
                            allRespondents={allEmployees}
                            allBusinessComponents={allBusinessComponents}
                            scheduleDetail={scheduleDetail}
                            disabled={isPreview || isSavingAnswers}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid item container direction='row' spacing={1} sx={{ fontWeight: 'bold' }}>
                <Grid item xs={2.75}>
                    Business Component
                </Grid>
                <Grid item xs={2.75}>
                    Employee
                </Grid>
                <Grid item xs={2.75}>
                    Research Activities
                </Grid>
                <Grid item xs={2.75}>
                    Information Sought To Be Discovered
                </Grid>
            </Grid>
            {BCMEntries.length === 0 && (
                <Typography textAlign='center' pt={2}>
                    {' '}
                    There are no Business Components or Employees at this time.{' '}
                </Typography>
            )}
            {/* If performance becomes an issue here we may be able to replace this with a FixedSizeList to control how many elements need to be re-rendered */}
            {BCMEntries.map((bcmEntry) => (
                <BCMSurveyRow
                    key={bcmEntry.key}
                    businessComponent={bcmEntry.businessComponent}
                    respondent={bcmEntry.respondent}
                    researchActivityEntry={bcmEntry.researchActivityEntry}
                    informationSoughtEntry={bcmEntry.informationSoughtEntry}
                    disabled={isPreview || isReadOnly || isSavingAnswers}
                    setQuestionAnswer={setQuestionAnswer}
                />
            ))}
        </Grid>
    );
};
