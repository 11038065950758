import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    FormValidationMethod,
    IFormFieldValidationConfig,
    isNotBlank,
    runFieldValidation,
    runFormValidation,
    useFailedCreateSnackbar,
    useFailedUpdateSnackbar,
    useSuccessfulCreateSnackbar,
    useSuccessfulUpdateSnackbar,
} from '../../../Components/CoreLib/library';
import { CompanyDto, StudyDto } from '../../../dtos';
import { emptyGuid } from '../../../models';
import { useCreateStudyMutation, useUpdateStudyMutation } from '../../../store';

export const DEFAULT_STUDY: StudyDto = {
    id: emptyGuid,
    name: '',
    companyId: '',
    companyName: '',
    startDate: new Date('1900-01-01'),
    isOngoing: true,
    workItemCount: 0,
    respondentCount: 0,
    isActive: true,
    createdOn: new Date(),
    status: '',
    businessComponentCount: 0,
    activityCount: 0
};

export function useStudyForm(onSaveComplete: () => void, initValue: StudyDto = DEFAULT_STUDY, fixedCompanyId?: string, openInNewTabOnCreate?: boolean, afterCreate?: (newStudy: StudyDto) => void) {
    // Mutations
    const [createStudy, { data: createdStudy, isLoading: isCreatingStudy, isSuccess: isCreateStudySuccessful, isError: isCreateStudyError, reset: resetCreateStudy }] =
        useCreateStudyMutation();
    useSuccessfulCreateSnackbar('study', isCreateStudySuccessful, () => {
        resetCreateStudy();
        onSaveComplete();
        if (openInNewTabOnCreate) {
            window.open(`${window.origin}/studies/${createdStudy?.id}`, '_blank')
        }
        reset();
    });
    useFailedCreateSnackbar('study', isCreateStudyError, resetCreateStudy);
    const [updateStudy, { isLoading: isUpdatingStudy, isSuccess: isUpdateStudySuccessful, isError: isUpdateStudyError, reset: resetUpdateStudy, error: updateError }] =
        useUpdateStudyMutation();
    useSuccessfulUpdateSnackbar('study', isUpdateStudySuccessful, () => {
        resetUpdateStudy();
        onSaveComplete();
        reset();
    });
    useFailedUpdateSnackbar('study', isUpdateStudyError, resetUpdateStudy, updateError);
    const isSaving = useMemo(() => isCreatingStudy || isUpdatingStudy, [isCreatingStudy, isUpdatingStudy]);
    

    // Form Values
    const [name, setName] = useState('');
    const [companyId, setCompanyId] = useState(fixedCompanyId ?? '');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isOngoing, setIsOngoing] = useState(false);

    const loadFromInitValue = useCallback((studyDetails: StudyDto) => {
        setName(studyDetails.name ?? '');
        setCompanyId(fixedCompanyId ?? studyDetails.companyId ?? '');
        setStartDate(studyDetails.startDate === DEFAULT_STUDY.startDate ? null : new Date(studyDetails.startDate + 'Z'));
        setEndDate(studyDetails?.endDate ? new Date(studyDetails.endDate + 'Z') : null);
        setIsOngoing(studyDetails.isOngoing);
    }, [fixedCompanyId]);

    useEffect(() => {
        loadFromInitValue(initValue);
    }, [loadFromInitValue, initValue]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof StudyDto, string>>(
        new Map([
            ['name', ''],
            ['companyId', ''],
            ['startDate', ''],
            ['endDate', ''],
        ])
    );

    // TODO: move these into the core library so they don't need to be duplicated and can be used in other projects
    const isBefore = useCallback(
        (beforeDate: Date | null, beforeDateLabel: string): FormValidationMethod =>
            (dateToCheck: Date) => {
                const errorMessageBuilder = (fieldName: string) => `${fieldName} must be before ${beforeDateLabel}`;
                let isValid = true;
                if (beforeDate && dateToCheck) {
                    isValid = dateToCheck.getTime() < beforeDate.getTime();
                }
                return { errorMessageBuilder, isValid };
            },
        []
    );

    const isAfter = useCallback(
        (afterDate: Date | null, afterDateLabel: string): FormValidationMethod =>
            (dateToCheck: Date) => {
                const errorMessageBuilder = (fieldName: string) => `${fieldName} must be after ${afterDateLabel}`;
                let isValid = true;
                if (afterDate && dateToCheck) {
                    isValid = dateToCheck.getTime() > afterDate.getTime();
                }
                return { errorMessageBuilder, isValid };
            },
        []
    );

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof StudyDto, IFormFieldValidationConfig>([
                [
                    'name',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Name',
                    },
                ],
                [
                    'companyId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Company',
                    },
                ],
                [
                    'startDate',
                    {
                        validators: [isNotBlank, isBefore(endDate, 'End Date')],
                        errorMessageEntityName: 'Start Date',
                    },
                ],
                [
                    'endDate',
                    {
                        validators: isOngoing ? [] : [isNotBlank, isAfter(startDate, 'Start Date')],
                        errorMessageEntityName: 'End Date',
                    },
                ],
            ]),
        [isOngoing, isBefore, endDate, isAfter, startDate]
    );

    const getCurrentFormValues = useCallback((): StudyDto => {
        return {
            ...initValue,
            name,
            companyId,
            startDate: startDate!,
            endDate: endDate ?? undefined,
        };
    }, [initValue, name, companyId, startDate, endDate]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<StudyDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [getCurrentFormValues, formFieldValidators]);

    const validateField = useCallback(
        (fieldName: keyof StudyDto) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [getCurrentFormValues, fieldErrors, formFieldValidators]
    );

    const isFormDirty = useMemo(() => {
        let isDirty = name !== initValue?.name;
        isDirty = isDirty || companyId !== initValue?.companyId;
        isDirty = isDirty || startDate !== initValue?.startDate;
        isDirty = isDirty || endDate !== initValue?.endDate;
        return isDirty;
    }, [initValue, name, companyId, startDate, endDate]);

    const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleCompanyIdChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (fixedCompanyId) {
            return;
        }
        setCompanyId(event.target.value);
    }, [fixedCompanyId]);

    // This is an alternate way to update the company when you have access to the full DTO
    const handleCompanyChange = useCallback((updatedCompany?: CompanyDto) => {
        if (fixedCompanyId) {
            return;
        }
        setCompanyId(updatedCompany?.id ?? '');
    }, [fixedCompanyId]);

    const handleStartDateChange = useCallback((newDate: Date | null) => {
        setStartDate(newDate);
    }, []);

    const handleEndDateChange = useCallback(
        (newDate: Date | null) => {
            if (newDate !== null && isOngoing) {
                setIsOngoing(false);
            }
            setEndDate(newDate);
        },
        [isOngoing]
    );

    const handleIsOngoingChange = useCallback(
        (updatedIsOnGoing: boolean) => {
            if (updatedIsOnGoing && endDate !== null) {
                setEndDate(null);
            }
            setIsOngoing(updatedIsOnGoing);
        },
        [endDate]
    );

    const save = (event: React.SyntheticEvent) => {
        if (isSaving) {
            return;
        }
        event.preventDefault();
        const isFormValid = validateForm();
        if (isFormValid) {
            let studyToSave = getCurrentFormValues();
            if (studyToSave.id === emptyGuid) {
                createStudy(studyToSave).unwrap().then(afterCreate);
            } else {
                updateStudy(studyToSave);
            }
        }
    };

    const reset = useCallback(() => {
        loadFromInitValue(initValue);
    }, [loadFromInitValue, initValue]);

    const isCompanySelectVisible = useMemo(() => !fixedCompanyId, [fixedCompanyId]);

    return {
        name,
        handleNameChange,
        companyId,
        handleCompanyChange,
        handleCompanyIdChange,
        startDate,
        handleStartDateChange,
        endDate,
        handleEndDateChange,
        isOngoing,
        handleIsOngoingChange,
        isFormDirty,
        fieldErrors,
        validateField,
        save,
        reset,
        isCompanySelectVisible
    };
}

export type StudyFormManger = ReturnType<typeof useStudyForm>;
