import _ from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { DataFilterContext } from '../../../Contexts';
import { WorkItemType } from '../../../dtos';

type ActivityStatusOptions = 'Active' | 'Inactive' | '';

export interface IStudyWorkItemFilterValues {
    activeStatus?: ActivityStatusOptions;
    itemType?: WorkItemType;
    periodStartDate?: Date;
    periodEndDate?: Date;
}

export function useWorkItemFilterDialogManager() {
    const [dialogFilters, setDialogFilters] = useState<IStudyWorkItemFilterValues>({});
    const {
        activeStatusFilter,
        setActiveStatusFilter,
        itemTypeFilter,
        setItemTypeFilter,
        periodStartDateFilter,
        setPeriodStartDateFilter,
        periodEndDateFilter,
        setPeriodEndDateFilter,
    } = useContext(DataFilterContext);

    const appliedFilters = useMemo<IStudyWorkItemFilterValues>(
        () => ({
            activeStatus: activeStatusFilter,
            itemType: itemTypeFilter,
            periodStartDate: periodStartDateFilter ?? undefined,
            periodEndDate: periodEndDateFilter ?? undefined,
        }),
        [activeStatusFilter, itemTypeFilter, periodStartDateFilter, periodEndDateFilter]
    );

    const handleDialogFilterValueChange = useCallback(
        (fieldName: keyof IStudyWorkItemFilterValues, value: any) => {
            let updatedFilterValues = _.cloneDeep(dialogFilters);
            updatedFilterValues[fieldName] = value;
            setDialogFilters(updatedFilterValues);
        },
        [dialogFilters]
    );

    const applyDialogFilters = useCallback(() => {
        setActiveStatusFilter(dialogFilters.activeStatus);
        setItemTypeFilter(dialogFilters.itemType);
        setPeriodStartDateFilter(dialogFilters.periodStartDate ?? null);
        setPeriodEndDateFilter(dialogFilters.periodEndDate ?? null);
    }, [dialogFilters, setActiveStatusFilter, setItemTypeFilter, setPeriodStartDateFilter, setPeriodEndDateFilter]);

    const loadAppliedFiltersIntoDialog = useCallback(() => {
        setDialogFilters(appliedFilters);
    }, [appliedFilters]);

    const isFormDirty = useMemo(() => JSON.stringify(appliedFilters) !== JSON.stringify(dialogFilters), [appliedFilters, dialogFilters]);

    return {
        dialogFilters,
        applyDialogFilters,
        loadAppliedFiltersIntoDialog,
        handleDialogFilterValueChange,
        isFormDirty
    };
}
