import { Box, Button, Divider, Typography } from '@mui/material';
import { FC, useCallback, useContext } from 'react';
import { StudyDashboardContext } from '../../../../Utils/StudyDashboardContext';
import { useNavigate } from 'react-router-dom';

export const StudyDashboardOverviewTab: FC = () => {
    const { study } = useContext(StudyDashboardContext);
    const navigate = useNavigate();
    
    const handleCreateNewBCM = useCallback(() => {
        navigate(`/companies/${study.companyId}/business-component`);
    }, [study.companyId, navigate]);

    const handleCreateNewSurvey = useCallback(() => {
        navigate(`/companies/${study.companyId}/survey`);
    }, [study.companyId, navigate]);

    return (
        <Box display='flex' flexDirection='column' rowGap={0.5}>
            <Typography>{study.name} currently has {study.respondentCount} active respondent{study.respondentCount > 1 ? 's':''} and {study.workItemCount} active component{study.workItemCount > 1 ? 's':''}</Typography>
            <br/>
            <Typography>{study.businessComponentCount} component{study.businessComponentCount > 1 ? 's are Business Components':' is a Business Component'}</Typography>
            <Typography>{study.activityCount} component{study.activityCount > 1 ? 's are Activities':' is an Activity'}</Typography>
            <Divider/>
            <table width={'100%'}>
                <tr>
                    <td style={{paddingRight:'8px'}}>
                        <Typography>You can create a new Business Component Matrix for this project for a given time period.</Typography>
                    </td>
                    <td width={'240px'}>
                        <Button fullWidth variant='contained' onClick={handleCreateNewBCM}>Start New BCM</Button>
                    </td>
                </tr>
                <tr>
                    <td style={{paddingRight:'8px'}}>
                        <Typography>You can create a new TAQ Survey by Business Component, which will send each employee a survey for only the business components they worked on.</Typography>
                    </td>
                    <td width={'240px'}>
                        <Button fullWidth variant='contained' onClick={handleCreateNewSurvey}>Start New Survey</Button>
                    </td>
                </tr>
            </table>
        </Box>
    );
};