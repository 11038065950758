import { Add } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { BusinessComponentDto, EmployeeDto, ScheduleDetailDto } from '../../../../dtos';
import { ScheduleDetailBusinessComponentQuickAdd } from '../../../QuickAdds';
import { ScheduleDetailRespondentQuickAdd } from '../../../QuickAdds/ScheduleDetailRespondentQuickAdd';
import { StandardDialog } from '../../../StandardDialog';

const RELATED_ENTITY_TYPE = {
    None: 'None',
    Respondent: 'Respondent',
    BusinessComponent: 'BusinessComponent',
}

export interface FixedListEntry {
    value: string;
    name: string;
}

export interface EmployeeBusinessComponentRelationship {
    respondentId: string;
    businessComponentId: string;
}

export interface IAddEditBCMEntriesButtonProps {
    handleUpdate: (updatedRelationships: EmployeeBusinessComponentRelationship[]) => void;
    currentRelationships: EmployeeBusinessComponentRelationship[];
    allRespondents: EmployeeDto[];
    allBusinessComponents: BusinessComponentDto[];
    scheduleDetail: ScheduleDetailDto;
    disabled?: boolean;
    
}

export const AddEditBCMEntriesButton: FC<IAddEditBCMEntriesButtonProps> = ({ handleUpdate, scheduleDetail, disabled, allRespondents, allBusinessComponents, currentRelationships }) => {
    const [isEntriesModalVisible, setIsEntriesModalVisible] = useState(false);
    const [entityRelationships, setEntityRelationships] = useState<EmployeeBusinessComponentRelationship[]>([]);
    const [selectedRespondentId, setSelectedRespondentId] = useState('');
    const selectedRespondent = useMemo(() => allRespondents.find(resp => resp.id === selectedRespondentId) ?? null, [allRespondents, selectedRespondentId]);
    const [selectedBusinessComponentId, setSelectedBusinessComponentId] = useState('');
    const selectedBusinessComponent = useMemo(() => allBusinessComponents.find(businessComponent => businessComponent.id === selectedBusinessComponentId) ?? null, [allBusinessComponents, selectedBusinessComponentId]);

    const handleAddClicked = useCallback(() => {
        setEntityRelationships(_.cloneDeep(currentRelationships));
        setIsEntriesModalVisible(true);
    }, [currentRelationships]);

    const handleClose = useCallback(() => {
        setIsEntriesModalVisible(false)
        setEntityRelationships([]);
        setSelectedRespondentId('');
        setSelectedBusinessComponentId('');
    }, []);

    const handleSave = useCallback(() => {
        handleUpdate(entityRelationships);
        handleClose();
    }, [handleClose, entityRelationships, handleUpdate]);

    const relatedEntityType = useMemo(() => {
        if (selectedRespondentId) {
            return RELATED_ENTITY_TYPE.BusinessComponent;
        } else if (selectedBusinessComponentId) {
            return RELATED_ENTITY_TYPE.Respondent;
        } else {
            return RELATED_ENTITY_TYPE.None;
        }
    }, [selectedRespondentId, selectedBusinessComponentId]);

    const relatableEntities = useMemo<FixedListEntry[]>(() => {
        switch (relatedEntityType) {
            case RELATED_ENTITY_TYPE.Respondent:
                return allRespondents.map(resp => ({ value: resp.id, name: resp.name }));
            case RELATED_ENTITY_TYPE.BusinessComponent:
                return allBusinessComponents.map(comp => ({ value: comp.id, name: comp.name }));
            default:
                return [];
        }
    }, [relatedEntityType, allBusinessComponents, allRespondents]);

    const relatedEntityIds = useMemo(() => {
        switch (relatedEntityType) {
            case RELATED_ENTITY_TYPE.Respondent:
                return entityRelationships
                        .filter(entityRelationship => entityRelationship.businessComponentId === selectedBusinessComponentId)
                        .map(entityRelationship => entityRelationship.respondentId);
            case RELATED_ENTITY_TYPE.BusinessComponent:
                return entityRelationships
                        .filter(entityRelationship => entityRelationship.respondentId === selectedRespondentId)
                        .map(entityRelationship => entityRelationship.businessComponentId);
            default:
                return [];
        }
    }, [relatedEntityType, selectedRespondentId, selectedBusinessComponentId, entityRelationships]);

    const isEveryItemChecked = useMemo(() => {
        return relatedEntityIds.length === relatableEntities.length;
    }, [relatedEntityIds, relatableEntities]);

    const isAtLeastOneItemChecked = useMemo(() => {
        return relatedEntityIds.length > 0;
    }, [relatedEntityIds]);

    const handleToggleSelectAll = useCallback(() => {
        if (isEveryItemChecked) {
            if (selectedRespondentId) {
                setEntityRelationships(entityRelationships => entityRelationships.filter(entityRelationship => entityRelationship.respondentId !== selectedRespondentId));
            } else if (selectedBusinessComponentId) {
                setEntityRelationships(entityRelationships => entityRelationships.filter(entityRelationship => entityRelationship.businessComponentId !== selectedBusinessComponentId));
            }
        } else {
            if (selectedRespondentId) {
                setEntityRelationships(entityRelationships => [...entityRelationships.filter(entityRelationship => entityRelationship.respondentId !== selectedRespondentId), ...allBusinessComponents.map(businessComponent => ({ respondentId: selectedRespondentId, businessComponentId: businessComponent.id}))]);
            } else if (selectedBusinessComponentId) {
                setEntityRelationships(entityRelationships => [...entityRelationships.filter(entityRelationship => entityRelationship.businessComponentId !== selectedBusinessComponentId), ...allRespondents.map(respondent => ({ respondentId: respondent.id, businessComponentId: selectedBusinessComponentId}))]);
            }
        }
    }, [allRespondents, allBusinessComponents, isEveryItemChecked, selectedBusinessComponentId, selectedRespondentId]);

    const handleToggleRelationshipWith = useCallback((relatedEntityId: string) => {
        let isItemCurrentlyChecked = relatedEntityIds.includes(relatedEntityId);
        let relationship: EmployeeBusinessComponentRelationship = {
            respondentId: selectedRespondentId || relatedEntityId,
            businessComponentId: selectedBusinessComponentId || relatedEntityId
        }
        if (isItemCurrentlyChecked) {
            setEntityRelationships(entityRelationships => entityRelationships.filter(entityRelationship => !(entityRelationship.businessComponentId === relationship.businessComponentId && entityRelationship.respondentId === relationship.respondentId)));
        } else {
            setEntityRelationships(entityRelationships => [...entityRelationships, relationship]);
        }
    }, [relatedEntityIds, selectedRespondentId, selectedBusinessComponentId]);

    const renderRow = useCallback(
        (props: ListChildComponentProps) => {
            const { index, style } = props;
            if (index === 0) {
                return (
                    <ListItem style={style} key={index} component='div' disablePadding>
                        <ListItemButton onClick={() => handleToggleSelectAll()} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge='start'
                                    checked={isEveryItemChecked}
                                    indeterminate={!isEveryItemChecked && isAtLeastOneItemChecked}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary='Select All' />
                        </ListItemButton>
                    </ListItem>
                );
            }

            const relatableEntity = relatableEntities[index - 1];

            return (
                <ListItem style={style} key={index} component='div' disablePadding>
                    <ListItemButton onClick={() => handleToggleRelationshipWith(relatableEntity.value)} dense>
                        <ListItemIcon>
                            <Checkbox edge='start' checked={relatedEntityIds.includes(relatableEntity.value)} tabIndex={-1} disableRipple />
                        </ListItemIcon>
                        <ListItemText primary={relatableEntity.name} />
                    </ListItemButton>
                </ListItem>
            );
        },
        [relatableEntities, relatedEntityIds, handleToggleRelationshipWith, handleToggleSelectAll, isEveryItemChecked, isAtLeastOneItemChecked]
    );

    const entryRelationshipContent = useMemo(() => {
        if (!selectedRespondentId && !selectedBusinessComponentId) {
            return <Typography mt={2}>Please select an Employee or Business Component above to manage their entries.</Typography>;
        } else if (relatableEntities.length === 0) {
            return <Typography mt={2}>There are no {selectedRespondentId ? 'Business Components' : 'Employees'} defined. Please use the quick add buttons above to add {selectedRespondentId ? 'Business Components' : 'Employees'}.</Typography>;
        } else {
            return (
                <FixedSizeList
                    height={400}
                    width={'100%'}
                    itemSize={46}
                    itemCount={relatableEntities.length + 1}
                    overscanCount={6}
                    style={{ border: '1px solid #cbcbcb', borderRadius: 8 }}>
                    {renderRow}
                </FixedSizeList>
            )
        }
    }, [relatableEntities.length, renderRow, selectedBusinessComponentId, selectedRespondentId]);

    return (
        <>
            <Button variant='contained' disabled={disabled} color='primary' fullWidth onClick={handleAddClicked}>
                Add / Edit Rows
            </Button>
            <StandardDialog
                isVisible={isEntriesModalVisible}
                onClose={handleClose}
                icon={<Add />}
                title='Add Entires'
                confirmAction={handleSave}
                confirmText='Save'>
                <Grid container direction='column' spacing={2} mt={0}>
                    <Grid item container direction='row'>
                        <Grid item display='flex' xs={5.5}>
                            <FormControl fullWidth>
                                <FormLabel>Employee</FormLabel>
                                <Autocomplete
                                    value={selectedRespondent}
                                    onChange={(_, value) => {
                                        setSelectedRespondentId(value?.id ?? '');
                                        setSelectedBusinessComponentId('');
                                    }}
                                    options={allRespondents}
                                    getOptionLabel={x => x.name}
                                    renderInput={(params) => <TextField {...params} placeholder='Select individual' />}
                                />
                            </FormControl>
                            <Box pt={3}>
                                <ScheduleDetailRespondentQuickAdd
                                    scheduleDetail={scheduleDetail}
                                    companyName={scheduleDetail.schedule.companyName}
                                    tooltip='Add Employee'
                                    disabled={disabled}
                                    onAdd={(value) => {
                                        setSelectedRespondentId(value);
                                        setSelectedBusinessComponentId('');
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={1} paddingTop={5} paddingLeft={1.5}>
                            OR
                        </Grid>
                        <Grid item display='flex'  xs={5.5}>
                            <FormControl fullWidth>
                                <FormLabel>Business Component</FormLabel>
                                <Autocomplete
                                    value={selectedBusinessComponent}
                                    onChange={(_, value) => {
                                        setSelectedBusinessComponentId(value?.id ?? '');
                                        setSelectedRespondentId('');
                                    }}
                                    options={allBusinessComponents}
                                    getOptionLabel={x => x.name}
                                    renderInput={(params) => <TextField {...params} placeholder='Select business component' />}
                                />
                            </FormControl>
                            <Box pt={3}>
                                <ScheduleDetailBusinessComponentQuickAdd
                                    scheduleDetail={scheduleDetail}
                                    companyName={scheduleDetail.schedule.companyName}
                                    tooltip='Add Business Component'
                                    disabled={disabled}
                                    onAdd={(value) => {
                                        setSelectedBusinessComponentId(value);
                                        setSelectedRespondentId('');
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item textAlign='center'>
                        {entryRelationshipContent}
                    </Grid>
                </Grid>
            </StandardDialog>
        </>
    );
};
